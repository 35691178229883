import Divider from '../../../common/ui/Divider'
import React from 'react'
import { AttentionIcon } from '../../../common/icons'

const AttentionMessage = ({ message = 'Learning modes are available' }) => (
  <div className="mt-3 flex block lg:hidden text-sm items-center relative">
    <AttentionIcon />
    <div className="flex">
      <Divider className="border-r pl-2 min-h-full" />
      <div className="pl-2 text-left">
        {message}
        <span className="font-bold text-sm"> only in the desktop version </span>
      </div>
    </div>
  </div>
)

export default AttentionMessage
