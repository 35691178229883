import { useQuery } from '@apollo/client'
import {
  AgentKnowledgeItemQuery,
  AgentKnowledgeItemQueryVariables,
} from 'app/javascript/components/graphql'
import React from 'react'
import { GET_AGENT_KNOWLEDGE_ITEM } from '../../../graphql/queries/agent'
import Spin from '../../../../../components/common/ui/Spin'

import { Marked } from 'marked'
import hljs from 'highlight.js'
import 'highlight.js/styles/default.css' // choose your style

const marked = new Marked()

interface KnowledgeItemProps {
  agentUuid: string
  itemUuid: string
}
const KnowledgeItem = ({ agentUuid, itemUuid }: KnowledgeItemProps) => {
  const { data, loading } = useQuery<AgentKnowledgeItemQuery, AgentKnowledgeItemQueryVariables>(
    GET_AGENT_KNOWLEDGE_ITEM,
    {
      variables: { agentUuid, itemUuid },
    }
  )

  const item = data?.agentKnowledgeItem

  if (loading || !item) {
    return <Spin />
  }

  if (item.documentType == 'markdown') {
    const modifiedText = marked.parse(item.content, {
      gfm: true,
      breaks: true,
    })
    return <div className="prose" dangerouslySetInnerHTML={{ __html: modifiedText }}></div>
  } else if (item.documentType == 'json') {
    const jsonObj = JSON.parse(item.content)
    const prettyJson = JSON.stringify(jsonObj, null, 2)
    const highlightedJson = hljs.highlight(prettyJson, { language: 'json' }).value
    return (
      <pre
        className="whitespace-pre-wrap break-words"
        dangerouslySetInnerHTML={{ __html: highlightedJson }}
      ></pre>
    )
  }

  return <div>{item.content}</div>
}

export default KnowledgeItem
