import IconButton, { Sizes } from '../IconButton'
import { RightArrowIcon } from './icons'
import React from 'react'
import { useLabsContext } from '../../context/LabsContext'

const NextStepButton = () => {
  const { nextStep, completed } = useLabsContext()
  return (
    <IconButton size={Sizes.SMALL} disabled={completed} onClick={nextStep}>
      <RightArrowIcon />
    </IconButton>
  )
}

export default NextStepButton
