import rrwebPlayer from '@flowmo/rrweb-player'
import { useEffect, useRef, useState, useCallback } from 'react'

const initPlayer = (frameRef: any, events: any, width?: number) => {
  return new rrwebPlayer({
    target: frameRef.current,
    props: {
      events: events,
      width: width,
      UNSAFE_replayCanvas: true,
      mouseTail: {
        strokeStyle: '#4C90F7',
      },
      triggerFocus: false,
      autoPlay: false,
    },
  })
}

const useRRWebPlayer = (events?: any, onInit?: (replayer: any) => void) => {
  const [loading, setLoading] = useState(true)

  const frameRef = useRef<HTMLInputElement>(null)
  const replayer = useRef<rrwebPlayer>(null)

  const onResize = useCallback(() => {
    if (frameRef?.current && replayer?.current) {
      // @ts-ignore
      replayer.current.$$set({
        width: frameRef.current.clientWidth,
      })
      replayer.current.triggerResize()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  })

  useEffect(() => {
    if (frameRef && events && events.length > 0) {
      // @ts-ignore
      replayer.current?.$destroy()
      replayer.current = initPlayer(frameRef, events, frameRef.current?.clientWidth)
      onInit && onInit(replayer)

      loading && setLoading(false)
    }
  }, [frameRef, events])

  return { ref: frameRef, loading }
}

export default useRRWebPlayer
