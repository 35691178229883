import React from 'react'
import md5 from 'md5'

const gravatarUrl = (email: string, size = 80): string => {
  const emailHash = md5(email.trim().toLowerCase())
  return `https://www.gravatar.com/avatar/${emailHash}?s=${size}&d=mp`
}

interface GravatarProps {
  email: string
  size?: number
  alt?: string
  className?: string
}

const Gravatar: React.FC<GravatarProps> = ({ email, size, alt, className }) => {
  const url = gravatarUrl(email, size)

  return <img src={url} alt={alt} className={className} />
}

export default Gravatar
