import React from 'react'
import { useEmbedContext } from '../../../../context/EmbedContext'
import { getThumbnailPublicId, getThumbnailUrl } from '../../../../../Walkthrough/utils/walkthrough'
import Title from '../../../Title'
import Description from '../../../Description'
import { shareLink } from '../../../../../common/utils/links'
import ReactHtmlParser from 'react-html-parser'

type ThumbnailProps = {
  loading?: boolean
}

const Thumbnail: React.FC<ThumbnailProps> = ({ loading }) => {
  const { walkthrough, rootUrl } = useEmbedContext()
  const creator = walkthrough.createdBy

  const publicId = getThumbnailPublicId(walkthrough)
  const thumbnailUrl = publicId && getThumbnailUrl(publicId)
  return (
    <div className="relative w-full h-full">
      <div
        className="w-full h-full flex"
        style={{
          background:
            'linear-gradient(90deg, #214A86 0%, #305B9A 25.52%, rgba(46, 46, 46, 0.71) 63.44%, rgba(46, 46, 46, 0.71) 100%)',
        }}
      >
        {!loading && (
          <div className="flex flex-col my-auto ml-[5%] w-[60%] pb-[5%]">
            <Title>{ReactHtmlParser(walkthrough.name)}</Title>
            <Description>
              <a
                href={shareLink(walkthrough.uuid, 'walkthrough')}
                className="underline"
                target="_blank"
                rel="noreferrer"
              >
                Walkthrough
              </a>{' '}
              from {creator.firstName} {creator.lastName} on{' '}
              <a href={rootUrl} className="underline" target="_blank" rel="noreferrer">
                FlowMo
              </a>
            </Description>
          </div>
        )}
      </div>
      <div
        className="w-full h-full absolute top-0 left-0"
        style={{
          zIndex: -1,
          backdropFilter: 'blur(2px)',
        }}
      />
      <img
        style={{
          zIndex: -2,
        }}
        alt={walkthrough.uuid}
        className="w-full h-auto absolute top-0 left-0"
        src={thumbnailUrl}
        width="100%"
        height="auto"
      />
    </div>
  )
}

export default Thumbnail
