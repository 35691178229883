const NOT_TRUSTED = ['path', 'svg']

export const getComputedStyle = (el: any, property: any) => {
  // @ts-ignore
  const computedStyle = window.getComputedStyle
    ? // @ts-ignore
      document.defaultView.getComputedStyle(el, null)
    : el.currentStyle

  return property ? computedStyle[property] : computedStyle
}

export function isCheckbox(el: any) {
  return el.tagName === 'INPUT' && el.type === 'checkbox'
}

export function isVisible(el: any) {
  const isHiddenDueToSize = el.offsetWidth * el.offsetHeight === 0
  const isHiddenDueToOpacity = getComputedStyle(el, 'opacity') === '0'
  const isHiddenDueToDisplay = getComputedStyle(el, 'display') === 'none'
  const isHiddenDueToVisibility = getComputedStyle(el, 'visibility') === 'hidden'

  return !(
    isHiddenDueToOpacity ||
    isHiddenDueToDisplay ||
    isHiddenDueToVisibility ||
    isHiddenDueToSize
  )
}

export const elementWrapper = (element: any) => {
  if (!element) {
    return null
  }
  let target = element
  while (NOT_TRUSTED.includes(target.tagName.toLowerCase()) || !isVisible(target)) {
    if (!target.parentElement) {
      break
    }

    target = target.parentElement
  }

  return target
}
