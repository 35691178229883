import React from 'react'
import { MenuItem } from '@szhsin/react-menu'

interface Props {
  label: string
  onClick?: (event: any) => void
  disabled?: boolean
  className?: string
}

const CardMenuItem: React.FC<Props> = ({ label, onClick, disabled, className = '', ...props }) => (
  <MenuItem
    disabled={disabled}
    className={`w-56 text-sm cursor-pointer ${
      disabled ? 'text-gray-200' : 'text-gray-700 hover:bg-gray-150 hover:text-gray-900'
    } ${className}`}
    onClick={(event: any) => {
      onClick && onClick(event)
    }}
    {...props}
  >
    {label}
  </MenuItem>
)

export default CardMenuItem
