import { useRef, useEffect } from 'react'
import { Howl } from 'howler'

const useAudioControls = (url: string, muted: boolean) => {
  const sound = useRef<any>(null)

  useEffect(() => {
    sound.current = new Howl({
      src: [url],
      format: ['webm'],
      mute: !!muted,
    })
  }, [])

  return {
    play: () => sound.current.play(),
    pause: () => sound.current.pause(),
    stop: () => sound.current.stop(),
    seek: (position: number) => sound.current.seek([position * 0.001]),
    mute: (muted: boolean) => sound.current.mute(muted),
  }
}

export default useAudioControls
