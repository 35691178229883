import { PlayIcon } from '@heroicons/react/24/outline'
import { PlayIcon as PlayIconSolid, XCircleIcon } from '@heroicons/react/24/solid'

import React, { useState } from 'react'
import { Dialog, DialogBackdrop } from '@headlessui/react'
import { GET_WALKTHROUGH_QUERY } from '../../App/graphql/queries/walkthrough'
import { useQuery } from '@apollo/client'
import {
  WalkthroughByUuidQuery,
  WalkthroughByUuidQueryVariables,
} from 'app/javascript/components/graphql'

import PublicWalkthroughContent from '../../../components/Walkthrough/ui/Walkthrough/Viewer/PublicViewer/PublicWalkthroughContent'

interface WalkthroughModalProps {
  walkthrough: any
  closeModal: () => void
}

const WalkthroughModal = ({ walkthrough, closeModal }: WalkthroughModalProps) => {
  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      open={true}
      onClose={closeModal}
    >
      <div className="flex items-center justify-center min-h-screen">
        <DialogBackdrop className="fixed inset-0 bg-black opacity-30" />
        <div className="relative bg-white p-3 mx-4 w-[30rem] sm:w-[40rem] max-w-5xl md:w-full md:mx-10 rounded-xl shadow-lg z-50">
          <div className="relative">
            <div className="aspect-w-16 aspect-h-9">
              <PublicWalkthroughContent walkthrough={walkthrough} />
            </div>
            <div className="absolute -top-5 -right-5">
              <XCircleIcon
                onClick={closeModal}
                className="h-8 w-8 text-blue-400 hover:text-blue-500 cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const WalkthroughCard = ({ walkthrough }: { walkthrough: any }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { data, loading } = useQuery<WalkthroughByUuidQuery, WalkthroughByUuidQueryVariables>(
    GET_WALKTHROUGH_QUERY,
    {
      variables: { uuid: walkthrough.uuid },
    }
  )

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)
  return (
    <div
      className="relative flex h-32 w-72 items-center justify-center rounded-2xl p-1 shadow-lg group"
      style={{
        backgroundImage:
          'conic-gradient(from -49.8deg at 50% 50%, #ff8331 0deg, #fa3e7c 59.07deg, #d11f60 185.61deg, #b08aee 284.23deg, #ff0000 329.41deg, #ffeb7c 360deg)',
      }}
    >
      <div className="bg-white/90 h-full w-full rounded-xl p-2">
        <div className="font-semibold text-sm line-clamp-2">{walkthrough.name}</div>
        <span className="inline-flex items-center rounded-md bg-gray-50 mt-2 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
          {walkthrough.meta?.domains?.length > 0 ? walkthrough.meta?.domains[0] : 'No Domain'}
        </span>
        <div className="absolute bottom-2 left-2 flex flex-row items-center space-x-2">
          <PlayIcon className="h-6 w-6 text-[#7331FF]" />
          <div className="text-gray-900 font-semibold">Walkthrough Video</div>
        </div>
      </div>

      {!loading && data?.walkthrough && (
        <div
          onClick={openModal}
          className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 m-1 flex items-center justify-center transition-opacity duration-300 hover:cursor-pointer"
        >
          <div className="h-12 w-12 text-flowmo-blue-700 rounded-full bg-white p-2 opacity-75 font-bold">
            <PlayIconSolid />
          </div>
        </div>
      )}

      {isOpen && !loading && data.walkthrough && (
        <WalkthroughModal walkthrough={data.walkthrough} closeModal={closeModal} />
      )}
    </div>
  )
}

export default WalkthroughCard
