import { useMutation, useQuery } from '@apollo/client'
import Spin from '../../../../common/ui/Spin'
import React, { useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  AnalysisQuery,
  AnalysisQueryVariables,
  LastAnalysisRunQuery,
  LastAnalysisRunQueryVariables,
} from 'app/javascript/components/graphql'
import {
  GET_ANALYSIS_QUERY,
  GET_LATEST_ANALYSIS_RUN_QUERY,
  MUTATION_SUBMIT_RUN_ANALYSIS,
} from '../../../graphql/queries/analysis'
import PageHeader from '../../../components/PageHeader'
import HeaderActionButton from '../../../components/HeaderActionButton'
import { PlayCircleIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import AnalysisResults from './AnalysisResults'
import AnalysisSetup from './AnalysisSetup'
import AnalysisHistory from './AnalysisHistory'

const OneAnalysis = ({ uuid }: { uuid: string }) => {
  const location = useLocation()
  const history = useHistory()
  const [currentTab, setCurrentTab] = React.useState<number>(() => {
    const hash = location.hash.replace('#', '')
    return hash ? parseInt(hash, 10) : 0
  })
  const [isRunning, setIsRunning] = React.useState(false)

  // Set up an interval to refetch workflow runs every 2 seconds when isRunning is true
  const refetchAnalysisInterval = React.useRef<NodeJS.Timeout | null>(null)

  document.title = 'Analysis | FlowMo'

  const [submitRunAnalysis] = useMutation(MUTATION_SUBMIT_RUN_ANALYSIS)

  const {
    data: analysisData,
    loading: analysisLoading,
    refetch: refetchAnalysis,
  } = useQuery<AnalysisQuery, AnalysisQueryVariables>(GET_ANALYSIS_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
  })

  const { data: lastAnalysisRunData, refetch: refetchLastAnalysisRun } = useQuery<
    LastAnalysisRunQuery,
    LastAnalysisRunQueryVariables
  >(GET_LATEST_ANALYSIS_RUN_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
  })

  // check if the analysis is running on initial load
  useEffect(() => {
    if (analysisData?.analysis) {
      if (analysisData.analysis.status === 'running' && !isRunning) {
        setIsRunning(true)
      }
      if (analysisData.analysis.status != 'running' && isRunning) {
        refetchLastAnalysisRun().then(() => {
          setIsRunning(false)
        })
      }
    }
  }, [analysisData])

  React.useEffect(() => {
    if (isRunning) {
      refetchAnalysisInterval.current = setInterval(() => {
        refetchAnalysis()
      }, 2000)
    } else {
      if (refetchAnalysisInterval.current) {
        clearInterval(refetchAnalysisInterval.current)
        refetchAnalysisInterval.current = null
      }
    }

    // Clear the interval when the component is unmounted
    return () => {
      if (refetchAnalysisInterval.current) {
        clearInterval(refetchAnalysisInterval.current)
      }
    }
  }, [isRunning, refetchAnalysis])

  useEffect(() => {
    history.replace(`#${currentTab}`)
  }, [currentTab, history])

  if (analysisLoading) {
    return <Spin />
  }

  const analysis = analysisData?.analysis

  if (!analysis) {
    history.push('/analyses')
    return <></>
  }

  const handleRunAnalysis = () => {
    setIsRunning(true)
    submitRunAnalysis({ variables: { uuid: uuid } })
  }

  const tabs = [
    { name: 'Results', key: 'results' },
    { name: 'Setup', key: 'setup' },
    { name: 'History', key: 'history' },
  ]

  return (
    <div>
      <PageHeader
        title={
          <div className="flex flex-row space-x-2">
            <div className="flex flex-col mt-2 space-y-2">
              <div>{analysis.name}</div>
              <div className="text-base font-normal text-gray-500">{analysis.description}</div>
            </div>
          </div>
        }
        breadCrumbs={[{ title: 'Analyses', to: '/analyses' }, { title: 'One Analysis' }]}
      >
        <div className="flex flex-row space-x-4">
          <Link to={'/analysis/edit/' + uuid}>
            <HeaderActionButton label="Edit" />
          </Link>

          <button onClick={handleRunAnalysis} disabled={isRunning} className="">
            {isRunning ? (
              <Spin />
            ) : (
              <PlayCircleIcon className="w-8 h-8 hover:text-flowmo-blue-600" />
            )}
          </button>
        </div>
      </PageHeader>

      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-flowmo-blue-500 focus:ring-flowmo-blue-500"
            defaultValue={tabs[currentTab].name}
            onChange={(e) => setCurrentTab(tabs.findIndex((tab) => tab.name === e.target.value))}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="bg-gray-100 px-4 py-6 sm:px-6 lg:px-8 rounded-xl">
            <nav className="flex space-x-4" aria-label="Tabs">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={clsx(
                    index === currentTab
                      ? 'bg-gray-200 text-gray-800'
                      : 'text-gray-600 hover:text-gray-800 cursor-pointer',
                    'rounded-md px-3 py-2 text-sm font-medium'
                  )}
                  onClick={() => setCurrentTab(index)}
                >
                  {tab.name}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <div className="py-4 px-8 bg-slate-50">
        {tabs[currentTab].name === 'Results' && (
          <>
            {isRunning ? (
              <div className="flex flex-col items-center space-y-2 justify-center">
                <span className="text-md">
                  {analysis.agent.firstName} is hard at work for you...
                </span>
                <Spin />
              </div>
            ) : (
              <AnalysisResults
                analysis={analysis}
                analysisRun={lastAnalysisRunData?.lastAnalysisRun}
              />
            )}
          </>
        )}
        {tabs[currentTab].name === 'Setup' && (
          <>
            <AnalysisSetup analysis={analysis} onUpdate={refetchAnalysis} />
          </>
        )}
        {tabs[currentTab].name === 'History' && (
          <>
            <AnalysisHistory analysis={analysis} />
          </>
        )}
      </div>
    </div>
  )
}
export default OneAnalysis
