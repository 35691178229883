import React from 'react'
import CardMenuItem from './index'

interface Props {
  onClick: () => void
}

const AddToAgentItem: React.FC<Props> = ({ onClick }) => (
  <CardMenuItem label="Add to agent" onClick={onClick} />
)

export default AddToAgentItem
