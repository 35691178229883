import React, { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

interface Props {
  id: string
  children: React.ReactNode
}

const ScrollableSection: React.FC<Props> = ({ id, children }) => {
  const ref = useRef<HTMLElement>(null)
  const location = useLocation()

  const scrollToView = useCallback(() => {
    if (location.hash === `#${id}` && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [location])

  useEffect(() => {
    const handleLoad = () => {
      scrollToView()
    }
    handleLoad()
    if (document.readyState === 'complete') {
      // Document has already loaded, call the function directly
      handleLoad()
    } else {
      // Document is not ready, set up event listener
      window.addEventListener('DOMContentLoaded', handleLoad)
    }

    // clean up function
    return () => {
      window.removeEventListener('DOMContentLoaded', handleLoad)
    }
  }, [])

  return (
    <section id={id} ref={ref}>
      {children}
    </section>
  )
}

export default ScrollableSection
