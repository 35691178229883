import React, { useRef, useState } from 'react'
import { Field } from 'formik'
import { EditIcon } from '../../../common/icons'
import ContentEditable from 'react-contenteditable'
import { setCursorOnTheEnd } from '../../../common/utils/setCursorOnTheEnd'
import { pasteFormattedText } from '../../../common/utils/pasteFormattedText'
import { generateClassForField } from '../../../common/utils/generateClassForField'
import { htmlStrip } from '../../../common/utils/htmlStrip'
import { stripField } from './ContentField'

interface Props {
  className?: string
  name: string
  placeholder?: string
  iconClassName?: string
  preventNextLine: boolean
}

const EditableField: React.FC<Props> = ({ className, name, iconClassName, preventNextLine }) => {
  const ref = useRef(null)
  const [isComponentInEditMode, setComponentInEditMode] = useState(false)

  const handleClick = () => {
    setComponentInEditMode(true)

    setTimeout(function () {
      setCursorOnTheEnd(ref.current)
      pasteFormattedText(ref.current)
    }, 0)
  }

  return (
    <Field name={name}>
      {({ field, form, meta }: any) => {
        const fieldClassName = generateClassForField(meta.error, className)

        const handleBlur = (e: any) => {
          setComponentInEditMode(false)
          meta.value !== e.target.innerHTML && form.handleSubmit()
        }

        const onChange = (e: any) => {
          field.onChange({ target: { value: e.target.value, name: field.name } })
        }

        return isComponentInEditMode ? (
          <ContentEditable
            onBlur={!meta.error && handleBlur}
            innerRef={ref}
            className={fieldClassName}
            html={preventNextLine ? htmlStrip(field.value) : field.value}
            onChange={onChange}
          />
        ) : (
          <div
            onClick={handleClick}
            className={`${className} border border-transparent cursor-text hover:bg-[#F9F9F9]`}
          >
            <span>{stripField(preventNextLine, field.value)}</span>
            <EditIcon className={iconClassName} />
          </div>
        )
      }}
    </Field>
  )
}

export default EditableField
