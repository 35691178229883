import React from 'react'

import CardMenuItem from './index'
import { DestinationType } from '../../../../../components/graphql'

type props = {
  onMove: (variables: { destinationType: DestinationType; destinationId?: string }) => void
}

const RemoveFromSkillSetItem: React.FC<props> = ({ onMove }) => {
  const handleRemove = () => {
    onMove({ destinationType: DestinationType.RemoveFromSkillSet })
  }
  return <CardMenuItem label="Remove from Skill Set" onClick={handleRemove} />
}

export default RemoveFromSkillSetItem
