import Background from './Background'
import React from 'react'
import Title from './Title'
import Button from './Button'
import { ArrowIcon } from './Labs/icons'
import { useEmbedContext } from '../context/EmbedContext'

const Unavailable = () => {
  const { rootUrl } = useEmbedContext()
  return (
    <Background>
      <div>
        <Title>Content is unavailable</Title>
        <a href={`${rootUrl}users/sign_up`} target="_blank" rel="noreferrer">
          <Button type="primary" className="mt-[20px]">
            <div>Go to FlowMo</div>
            <ArrowIcon />
          </Button>
        </a>
      </div>
    </Background>
  )
}

export default Unavailable
