import React, { useRef, useEffect } from 'react'
import './style.scss'

type WaveProps = {
  peaks: number[]
}

const Wave: React.FC<WaveProps> = ({ peaks }) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current

      const { width, height } = canvas.getBoundingClientRect()

      const ctx = canvas.getContext('2d')

      ctx.canvas.width = width
      ctx.canvas.height = height

      ctx.clearRect(0, 0, width, height)
      ctx.lineWidth = 2
      ctx.strokeStyle = '#29BC7E'
      const space = width / peaks.length
      peaks.forEach((value: number, i: number) => {
        const length = (height * value) / 100

        ctx.beginPath()
        ctx.moveTo(space * i, (height - length) / 2)
        ctx.lineTo(space * i, (height - length) / 2 + length)
        ctx.stroke()
      })
    }
  }, [peaks])

  return <canvas ref={canvasRef} className="wave-canvas" />
}

export default Wave
