import { gql } from '@apollo/client'
import {
  AGENT_FIELDS,
  MESSAGE_HISTORY_FIELDS,
  SIDE_EFFECT_FIELDS,
  STEP_HISTORY_FIELDS,
} from './fragments'

export const GET_MESSAGE_HISTORIES = gql`
  query getMessageHistories($uuid: String!) {
    getMessageHistories(uuid: $uuid) {
      ...MessageHistoryFields
    }
  }

  ${MESSAGE_HISTORY_FIELDS}
`

export const GET_STEP_HISTORIES = gql`
  query getStepHistories($uuid: String!) {
    getStepHistories(uuid: $uuid) {
      ...StepHistoryFields
    }
  }

  ${STEP_HISTORY_FIELDS}
`

export const GET_SIDE_EFFECT_HISTORIES = gql`
  query getSideEffectHistories($uuid: String!) {
    getSideEffectHistories(uuid: $uuid) {
      ...SideEffectFields
    }
  }

  ${SIDE_EFFECT_FIELDS}
`

export const GET_MESSAGE_THREADS = gql`
  query getMessageThreads($agentUuid: String!) {
    getMessageThreads(agentUuid: $agentUuid) {
      createdAt
      updatedAt
      uuid
    }
  }
`

export const GET_MESSAGE_THREAD = gql`
  query getMessageThread($uuid: String!) {
    getMessageThread(uuid: $uuid) {
      messages {
        ...MessageHistoryFields
      }
      steps {
        ...StepHistoryFields
      }
      agent {
        ...AgentFields
      }
      sideEffects {
        ...SideEffectFields
      }
    }
  }

  ${MESSAGE_HISTORY_FIELDS}
  ${STEP_HISTORY_FIELDS}
  ${SIDE_EFFECT_FIELDS}
  ${AGENT_FIELDS}
`
