import React from 'react'
import * as style from './style.module.scss'
import { useDispatch } from 'react-redux'
import { clickInteractiveElement } from '../../../Player/slices/playerSlice'
import { AppDispatch } from '../../../Player/store'

interface Props {
  element?: HTMLElement
  position?: DOMRect
}

const OFFSET = 4

const BoundedBox = ({ element, position }: Props) => {
  const dispatch: AppDispatch = useDispatch()
  const nextStep = () => {
    dispatch(clickInteractiveElement())
  }
  if (!element || !position) {
    return <React.Fragment></React.Fragment>
  }
  const width = position.width + OFFSET * 2
  const height = position.height + OFFSET * 2
  const top = position.top - OFFSET
  const left = position.left - OFFSET

  return (
    <div onClick={nextStep} className={style['bounded-box']} style={{ width, height, top, left }} />
  )
}

export default BoundedBox
