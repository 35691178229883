import React from 'react'

interface Props {
  url: string
}

const GoToLink: React.FC<Props> = ({ url }) => {
  return (
    <div className="flex flex-col">
      <a
        href={url}
        className="text-flowmo-blue-600 text-sm underline font-normal not-italic"
        target="external"
      >
        <span className="break-all break-words">{url}</span>
      </a>
    </div>
  )
}

export default GoToLink
