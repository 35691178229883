import React, { useState } from 'react'
import Spin from '../../../../common/ui/Spin'
import { useQuery } from '@apollo/client'
import { GET_AGENT_QUERY } from '../../../graphql/queries/agent'
import { AgentQuery, AgentQueryVariables } from 'app/javascript/components/graphql'
import { Link, useHistory } from 'react-router-dom'
import ThreadHistory from './ThreadHistory'
import AgentHeader from './AgentHeader'
import { PencilIcon, QueueListIcon } from '@heroicons/react/24/solid'
import AgentWorkWithYou from './AgentWorkWithYou'
import AgentWorkForYou from './AgentWorkForYou'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import HeaderActionButton from '../../../components/HeaderActionButton'

const OneAgent = ({ uuid }: { uuid: string }) => {
  const history = useHistory()
  const [isHistoryOpen, setIsHistoryOpen] = useState(false)

  const { user } = useSelector((state: RootState) => state.app)

  const { data, loading } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
  })

  const agent = data?.agent

  if (loading) {
    return <Spin />
  }

  if (!agent) {
    window.toastr.error('Agent not found')
    history.push('/agents')
    return <></>
  }

  document.title = agent.label + ' - Agent - FlowMo'

  return (
    <div className="h-full flex flex-col">
      <ThreadHistory
        agentUuid={agent.uuid}
        isOpen={isHistoryOpen}
        setClose={() => setIsHistoryOpen(false)}
      />

      <AgentHeader agent={agent} />

      <div className="relative mt-10 px-10">
        <div className="">
          <div className="flex flex-col">
            <div className="flex flex-row items-center relative ">
              <div className="flex-grow flex flex-col space-y-4">
                <p className="flex flex-row space-x-2 font-display text-2xl  tracking-tight sm:text-4xl">
                  <span className="text-slate-700">Hi, I&apos;m an expert</span>
                  <span className="block font-semibold text-flowmo-blue-900">
                    {agent.jobTitle} –
                  </span>
                </p>

                <div className="flex flex-col max-w-2xl">
                  <div className="my-4 text-base tracking-tight text-slate-800">
                    {agent.description}
                  </div>
                </div>
              </div>

              {(user.isOrganizationAdmin || agent.createdBy.id === user.id.toString()) && (
                <div className="absolute top-0 right-0 flex flex-row space-x-2 justify-end">
                  <Link to={'/agent/edit/' + agent.uuid}>
                    <HeaderActionButton
                      label={'Edit'}
                      icon={<PencilIcon className="h-4 w-4 mr-2" />}
                    />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-8">
          <div className="bg-gray-100 p-5 rounded-xl shadow">
            <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Working with you</h3>
              <div className="mt-3 flex sm:ml-4 sm:mt-0">
                <button
                  type="button"
                  onClick={() => setIsHistoryOpen(true)}
                  className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <QueueListIcon className="h-4 w-4 mr-2" /> History
                </button>
              </div>
            </div>

            <AgentWorkWithYou agent={agent} />
          </div>
          <div className="bg-gray-100 p-5 rounded-xl shadow">
            <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Working for you</h3>
              <div className="mt-3 flex sm:ml-4 sm:mt-0"></div>
            </div>

            <AgentWorkForYou agent={agent} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OneAgent
