import { gql } from '@apollo/client'

export const CREATE_USER_ACTIVITY_MUTATION = gql`
  mutation createUserActivity(
    $action: String!
    $url: String!
    $metadata: JSON
    $objectType: UserActivityObjectType
    $objectId: ID
    $userId: ID
  ) {
    createUserActivity(
      action: $action
      url: $url
      userId: $userId
      metadata: $metadata
      objectType: $objectType
      objectId: $objectId
    ) {
      id
    }
  }
`
