import React, { useState } from 'react'
import { ShareIcon } from '@heroicons/react/24/outline'
import Button from '../../../Button'
import ShareDialog from '../../../ShareDialog'
import { Walkthrough } from 'app/javascript/components/graphql'

interface Props {
  walkthrough: Walkthrough
}

const ShareButton: React.FC<Props> = ({ walkthrough }) => {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal(!showModal)

  return (
    <>
      {showModal && (
        <ShareDialog onClose={toggleModal} object={walkthrough} objectType="walkthrough" />
      )}

      <Button icon={<ShareIcon className="ml-2 h-4 w-4" />} onClick={toggleModal} color="primary">
        Share
      </Button>
    </>
  )
}

export default ShareButton
