import { PuzzlePieceIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { clsx } from 'clsx'
import {
  PluginFieldsFragment,
  PluginTemplateFieldsFragment,
} from 'app/javascript/components/graphql'

type BasePluginCardProps = {
  pluginTemplate: PluginTemplateFieldsFragment
  plugin?: PluginFieldsFragment
  handleCardClick: () => void
  colorful?: boolean
  children?: React.ReactNode
}

const BasePluginCard = ({
  pluginTemplate,
  handleCardClick,
  children,
  colorful,
  plugin,
}: BasePluginCardProps) => {
  if (!pluginTemplate) {
    return null
  }

  const name = plugin?.config?.name || plugin?.name || pluginTemplate.name

  let style = {
    backgroundImage:
      'conic-gradient(from -49.8deg at 50% 50%, #8c8b8e 0deg, #dceef8 59.07deg, #535469 185.61deg, #637f86 284.23deg, #aba8ac 329.41deg, #605871 360deg)',
  }
  if (colorful) {
    style = {
      backgroundImage:
        'conic-gradient(from -49.8deg at 50% 50%, #7331FF 0deg, #00A3FF 59.07deg, #4E51FF 185.61deg, #39DBFF 284.23deg, #B84FF1 329.41deg, #7331FF 360deg)',
    }
  }
  return (
    <div
      onClick={handleCardClick}
      className="relative flex h-32 w-64 items-center justify-center rounded-2xl p-1 shadow-lg hover:shadow-xl transition duration-300 ease-in-out"
      style={style}
    >
      <div className="relative bg-white/90 h-full w-full rounded-xl p-2">
        <div className="absolute bottom-2 left-2 flex flex-row items-center space-x-2">
          <PuzzlePieceIcon
            className={clsx('h-6 w-6', colorful ? ' text-flowmo-blue-600' : 'text-gray-500')}
          />
          <div className="text-gray-900 font-semibold">{pluginTemplate?.type}</div>
        </div>
        <div className="text-gray-900 font-semibold text-sm truncate mb-1">{name}</div>
        <div className="text-gray-600 text-sm line-clamp-2">{pluginTemplate.description}</div>
      </div>

      {children}
    </div>
  )
}

export default BasePluginCard
