import React from 'react'
import { Link } from 'react-router-dom'
import { AnalysisFieldsFragment } from '../../graphql'
import clsx from 'clsx'

interface AnalysisCardProps {
  analysis: AnalysisFieldsFragment
  size: 'small' | 'large'
}

const AnalysisCard = ({ analysis, size }: AnalysisCardProps) => {
  return (
    <div className="relative m-2">
      <Link to={`/analysis/${analysis.uuid}`}>
        <div
          className={clsx(
            size === 'large' ? 'w-96 p-4 h-64' : 'max-w-xs p-2 h-48 w-72',
            'p-6 border rounded-lg shadow hover:shadow-xl hover:bg-gray-900 bg-gray-800 border-gray-700'
          )}
        >
          <h5
            className={clsx(
              size == 'large' ? 'text-2xl' : 'text-xl',
              'mb-2 font-bold tracking-tight text-white flex flex-col'
            )}
          >
            <div className="line-clamp-1">{analysis.name}</div>
            <div
              className={clsx(
                size == 'large' ? 'text-sm' : 'text-xs',
                'text-gray-500 dark:text-gray-400'
              )}
            >
              by {analysis.agent.label}
            </div>
          </h5>

          <p
            className={clsx(
              size == 'large' ? 'text-md line-clamp-3' : 'text-sm line-clamp-2',
              'mb-3 font-normal text-gray-700 dark:text-gray-400'
            )}
          >
            {analysis.description}
          </p>
          <div
            className={clsx(
              size == 'large' ? 'text-sm ' : 'text-xs',
              'absolute bottom-5 inline-flex items-center px-3 py-2 font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
            )}
          >
            Review
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </div>
        </div>
      </Link>
    </div>
  )
}
export default AnalysisCard
