import React from 'react'
import { ADD_PLUGIN_MUTATION } from '../../../graphql/queries/agent'
import { useMutation } from '@apollo/client'
import DynamicForm from '../../DynamicForm'
import Spin from '../../../../../components/common/ui/Spin'
import PluginModalBase from './PluginModelBase'
import PluginTemplateDescription from './PluginTemplateDescription'

type PluginAddProps = {
  agentUuid: string
  pluginTemplate: any
  setOpen: (open: boolean) => void
  onAction: () => void
  open: boolean
}
const PluginAdd = ({ agentUuid, pluginTemplate, setOpen, onAction, open }: PluginAddProps) => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [installing, setInstalling] = React.useState(false)
  const [addPlugin] = useMutation(ADD_PLUGIN_MUTATION)

  const handlePluginInstall = async (formValues: any) => {
    setInstalling(true)
    const result = await addPlugin({
      variables: {
        agentUuid: agentUuid,
        pluginTemplateId: pluginTemplate.id,
        data: formValues,
      },
    })

    setInstalling(false)
    setOpen(false)
    if (result?.data?.addPlugin?.errors.length > 0) {
      window.toastr.error('Error adding plugin')
    } else {
      window.toastr.success('Plugin added successfully')
    }

    onAction()
  }
  return (
    <PluginModalBase title={pluginTemplate.name} setOpen={setOpen} open={open}>
      <div className="flex flex-col">
        <PluginTemplateDescription pluginTemplate={pluginTemplate} />

        <div className="flex items-center space-x-4">
          {pluginTemplate.dataTemplate.length > 0 ? (
            <div className="flex flex-col space-y-2 w-full">
              <div className="flex flex-col w-full">
                <dt className="mb-6 font-semibold leading-none text-gray-900">Settings</dt>
                <div className="pl-4">
                  <DynamicForm
                    formData={pluginTemplate.dataTemplate}
                    ref={formRef}
                    handleSubmit={handlePluginInstall}
                  />
                </div>
              </div>
              <div className="flex">
                <button
                  type="button"
                  className="text-white bg-flowmo-blue-700 hover:bg-flowmo-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onClick={() => formRef.current?.submitForm()}
                >
                  {installing ? <Spin /> : 'Add'}
                </button>
              </div>
            </div>
          ) : (
            <button
              type="button"
              className="text-white bg-flowmo-blue-700 hover:bg-flowmo-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={() => handlePluginInstall({})}
            >
              {installing ? <Spin /> : 'Add'}
            </button>
          )}
        </div>
      </div>
    </PluginModalBase>
  )
}

export default PluginAdd
