import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

const RouterProvider: React.FC<{ basePath: string; children: React.ReactNode }> = ({
  children,
  basePath,
}) => (
  <BrowserRouter basename={basePath}>
    <QueryParamProvider ReactRouterRoute={Route}>
      <Switch>
        <Route path="/">{children}</Route>
      </Switch>
    </QueryParamProvider>
  </BrowserRouter>
)

export default RouterProvider
