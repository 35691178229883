import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/24/solid'

export interface BreadcrumbProps {
  title: string
  href?: string
  to?: string
}

interface BreadcrumbLinkProps {
  page: BreadcrumbProps
  children: React.ReactNode
}
const BreadcrumbLink = ({ page, children }: BreadcrumbLinkProps) => {
  if (page.href) {
    return (
      <a
        href={page.href}
        className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
      >
        {children}
      </a>
    )
  }
  if (page.to) {
    return (
      <Link
        to={page.to}
        className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
      >
        {children}
      </Link>
    )
  }

  return <React.Fragment>{children}</React.Fragment>
}

const Breadcrumb = ({ pages }: { pages: BreadcrumbProps[] }) => {
  if (!pages) {
    return <React.Fragment></React.Fragment>
  }
  const backPageIndex = pages.length < 2 ? 0 : pages.length - 2
  const backPage = pages[backPageIndex]

  const breadcrumbItems = pages.map((page, index) => (
    <li key={index}>
      <div className="flex items-center">
        {index > 0 && (
          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
        )}
        <BreadcrumbLink page={page}>
          <span
            className={`text-sm font-medium text-gray-500 ${index > 0 ? 'ml-4' : ''} ${
              page.href || page.to ? 'hover:text-gray-700' : ''
            }`}
          >
            {page.title}
          </span>
        </BreadcrumbLink>
      </div>
    </li>
  ))

  return (
    <>
      <div className="-mt-5 px-2 py-4 mb-5 bg-gray-50/80 rounded">
        <nav className="sm:hidden" aria-label="Back">
          <BreadcrumbLink page={backPage}>
            <ChevronLeftIcon
              className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            Back
          </BreadcrumbLink>
        </nav>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <BreadcrumbLink page={{ title: 'Home', to: '/home' }}>
                <HomeIcon className="h-6 w-6" />
              </BreadcrumbLink>
            </li>
            <li>
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
            </li>
            {breadcrumbItems}
          </ol>
        </nav>
      </div>
    </>
  )
}

export default Breadcrumb
