import React from 'react'
import Option from './index'
import { PlusIcon } from '../icons'

const RecordWalkthroughOption = () => {
  const onClick = () => {
    window.location.href = '/app/walkthrough/create'
  }

  return (
    <Option
      onClick={onClick}
      icon={<PlusIcon />}
      title="Record a Walkthrough"
      description="Click here to launch our chrome extension’s record mode. Just navigate to any website, do a task, and create a new Walkthrough."
      className="border-r border-b border-[#E5E7EB]"
    />
  )
}

export default RecordWalkthroughOption
