import React from 'react'
import { Walkthrough, SkillSet } from 'app/javascript/components/graphql'
import { createdByFormatter } from '../../../../../../common/utils/createdByFormatter'
// @ts-ignore
import ReactHtmlParser from 'react-html-parser'
import { createdAtFormatter } from '../../../../../../common/utils/createdAtFormatter'
import { htmlStrip } from '../../../../../../common/utils/htmlStrip'

import './styles.scss'
import BackToSkillSetButton from '../BackToSkillSetButton'
import PublicWalkthroughContent from '../PublicWalkthroughContent'

interface Props {
  walkthrough: Walkthrough
  skillSets?: SkillSet[]
  steps: any[]
}

const PublicWalkthroughHeader: React.FC<Props> = ({ walkthrough, steps }) => {
  return (
    <div>
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto flex flex-col py-6 px-8">
          {walkthrough.skillSet && <BackToSkillSetButton uuid={walkthrough.skillSet.uuid} />}
          <div className="break-all break-words rounded-md xl:text-2xl lg:text-xl text-lg leading-[25px] font-semibold text-[#2C2C2C]">
            {htmlStrip(walkthrough.name)}

            <div className="text-gray-400 font-normal text-sm mt-3">
              {ReactHtmlParser(htmlStrip(walkthrough.description))}
            </div>
          </div>

          <div className="flex space-x-3 items-center text-gray-400 text-md mt-3">
            <div>
              <span>by {createdByFormatter(true, walkthrough.createdBy)}</span>
            </div>

            <div className="h-1 w-1 bg-gray-400 inline-block rounded-full"></div>

            <div>
              <span>{walkthrough.viewCount} views</span>
            </div>

            <div className="h-1 w-1 bg-gray-400 inline-block rounded-full"></div>

            <div>
              <span>{walkthrough.interactionCount} interactions</span>
            </div>

            <div className="h-1 w-1 bg-gray-400 inline-block rounded-full"></div>

            <div>
              <span>{steps.length} steps</span>
            </div>

            <div className="h-1 w-1 bg-gray-400 inline-block rounded-full"></div>

            <div>
              <span>{createdAtFormatter(new Date(walkthrough.createdAt))}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto rounded-md border mt-5 border-[#EFEFEF] bg-white shadow-lg p-1">
        <PublicWalkthroughContent walkthrough={walkthrough} />
      </div>
    </div>
  )
}

export default PublicWalkthroughHeader
