import React from 'react'
import Paragraph from '../../../Paragraph'
import { clsx } from 'clsx'
import { ArrowIcon } from '../icons'

interface Props {
  onClick: () => void
  icon: React.ReactElement
  title: string
  description: string
  className?: string
}

const Option: React.FC<Props> = ({ icon, onClick, title, description, className }) => (
  <div onClick={onClick} className={clsx('p-6 w-1/2 space-y-6 cursor-pointer group', className)}>
    <div className="flex justify-between">
      {icon} <ArrowIcon className="pointer-events-none text-gray-300 group-hover:text-gray-400" />
    </div>
    <div>
      {title}
      <Paragraph>{description}</Paragraph>
    </div>
  </div>
)

export default Option
