import React, { useEffect, useState } from 'react'

interface Props {
  showOnScrollPosition: number
  className?: string
  position?: string
  children: React.ReactNode
}

const StickyBar: React.FC<Props> = ({
  position = 'absolute',
  showOnScrollPosition,
  className,
  children,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={`${position} pointer-events-none h-full top-0 right-0 left-0`}>
      <div
        className={`${
          scrollPosition >= showOnScrollPosition ? 'sticky' : 'hidden'
        } flex top-0 items-center z-50 left-0 bg-white pointer-events-auto shadow-md  justify-between ${className}`}
      >
        {children}
      </div>
    </div>
  )
}

export default StickyBar
