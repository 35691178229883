import React, { useEffect, useState } from 'react'
import { GET_ORGANIZATION_CREDIT_USAGES } from '../../graphql/queries/organization'
import { useQuery } from '@apollo/client'
import { CreditUsagesQuery, CreditUsagesQueryVariables } from 'app/javascript/components/graphql'
import PageHeader from '../../components/PageHeader'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import Spin from '../../../../components/common/ui/Spin'
import { clsx } from 'clsx'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  return `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}`
}

const CustomTooltip = ({ active, payload }: { active: boolean; payload: [any] }) => {
  if (active && payload && payload.length) {
    const dateLabel = formatDate(payload[0].payload.usageDate)
    return (
      <div className="px-6 py-4 bg-white rounded-xl shadow-xl border border-gray-300">
        <p className="text-base font-semibold">{`${dateLabel}`}</p>
        <p className="textt-base">{`Credits Used: ${payload[0].value}`}</p>
      </div>
    )
  }
  return null
}
interface MonthNavigatorProps {
  callback: ({ startDate, endDate }: { startDate: Date; endDate: Date }) => void
}
const MonthNavigator = ({ callback }: MonthNavigatorProps) => {
  const [currentMonth, setCurrentMonth] = useState(new Date())

  const decrementMonth = () => {
    setCurrentMonth((prevMonth) => {
      const newDate = new Date(prevMonth)
      newDate.setMonth(prevMonth.getMonth() - 1)
      return newDate
    })
  }

  const incrementMonth = () => {
    setCurrentMonth((prevMonth) => {
      const newDate = new Date(prevMonth)
      newDate.setMonth(prevMonth.getMonth() + 1)
      return newDate
    })
  }

  useEffect(() => {
    const firstDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1)
    const lastDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0) // 0 will give us the last day of the previous month

    callback({ startDate: firstDay, endDate: lastDay })
  }, [currentMonth, callback])

  const formattedMonth = currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })

  return (
    <div className="flex items-center w-64 text-base">
      <button className="p-2 rounded-l text-gray-600 hover:text-gray-800" onClick={decrementMonth}>
        <ChevronLeftIcon className="h-5 w-5" />
      </button>
      <div className="w-64 items-center justify-center flex p-2">{formattedMonth}</div>
      <button className="p-2 rounded-r text-gray-600 hover:text-gray-800" onClick={incrementMonth}>
        <ChevronRightIcon className="h-5 w-5" />
      </button>
    </div>
  )
}

const CreditUsage = () => {
  const [isCumulative, setIsCumulative] = useState(false)
  const { data, loading, refetch } = useQuery<CreditUsagesQuery, CreditUsagesQueryVariables>(
    GET_ORGANIZATION_CREDIT_USAGES
  )

  const processedData = isCumulative
    ? data?.creditUsages.map((item, index, array) => {
        return {
          ...item,
          quantity: array.slice(0, index + 1).reduce((acc, curr) => acc + curr.quantity, 0),
        }
      })
    : data?.creditUsages

  const onMonthChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    refetch({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    })
  }

  return (
    <div>
      <PageHeader title="Credit Usage" />
      <p className="text-sm text-gray-500 mb-4 max-w-2xl">
        This graph shows the number of credits used per day. Credits are used when anyone in the
        organization uses an agent.
      </p>
      <div className="flex flex-col max-w-3xl">
        <div className="flex flex-row justify-between items-center">
          <MonthNavigator callback={onMonthChange} />
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className={clsx(
                isCumulative ? 'bg-gray-200 text-gray-400' : 'bg-gray-300 text-gray-900',
                ' relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 focus:z-10'
              )}
              disabled={!isCumulative}
              onClick={() => setIsCumulative(false)}
            >
              Daily
            </button>
            <button
              type="button"
              className={clsx(
                'relative inline-flex items-center rounded-r-md -ml-px px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10',
                !isCumulative ? 'bg-gray-200 text-gray-400' : 'bg-gray-300 text-gray-900'
              )}
              disabled={isCumulative}
              onClick={() => setIsCumulative(true)}
            >
              Cumulative
            </button>
          </span>
        </div>

        {loading ? (
          <Spin />
        ) : (
          <ResponsiveContainer height={400} className="">
            <BarChart
              width={500}
              height={300}
              data={processedData}
              margin={{
                top: 20,
                right: 20,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="usageDate" tickFormatter={formatDate} className="text text-xs" />
              <YAxis className="text text-xs" />
              <Tooltip content={CustomTooltip} />
              <Bar dataKey="quantity" fill="rgb(76 144 247)" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  )
}

export default CreditUsage
