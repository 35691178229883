import React from 'react'
import { Content, FileAttachment as FileAttachmentType } from 'app/javascript/components/graphql'
import { File, Video, X } from 'lucide-react'
import { Link } from 'react-router-dom'

export const FileAttachment = ({
  fileAttachment,
  handleRemoveFileAttachment,
}: {
  fileAttachment: FileAttachmentType
  handleRemoveFileAttachment?: () => void
}) => {
  const link = `/file_attachments/${fileAttachment.uuid}`
  return (
    <MessageAttachment
      attachmentType="file"
      handleRemoveAttachment={handleRemoveFileAttachment}
      link={link}
    >
      {fileAttachment.fileName}
    </MessageAttachment>
  )
}

export const VideoAttachment = ({
  content,
  handleRemoveContent,
}: {
  content: Content
  handleRemoveContent?: () => void
}) => {
  let url = ''
  if (content.__typename === 'Walkthrough') {
    url = `/walkthrough/${content.uuid}`
  } else if (content.__typename === 'SkillSet') {
    url = `/skill_set/${content.uuid}`
  }

  return (
    <MessageAttachment
      attachmentType="video"
      handleRemoveAttachment={handleRemoveContent}
      link={url}
    >
      {content.name}
    </MessageAttachment>
  )
}

export const MessageAttachment = ({
  children,
  handleRemoveAttachment,
  attachmentType,
  link,
}: {
  children: React.ReactNode
  handleRemoveAttachment?: () => void
  attachmentType: 'file' | 'video'
  link: string
}) => {
  return (
    <div className="border border-gray-200 rounded-lg p-2 relative w-48 bg-white m-2 cursor-pointer">
      <Link to={link} target="_blank">
        <div className="flex flex-row items-center gap-2 text-sm">
          {attachmentType === 'file' ? (
            <>
              <File className="w-4 h-4 text-gray-400" />
              <div>File</div>
            </>
          ) : (
            <>
              <Video className="w-4 h-4 text-gray-400" />
              <div>Video</div>
            </>
          )}
        </div>
        <div className="text-sm line-clamp-1">{children}</div>
      </Link>
      {handleRemoveAttachment && (
        <button
          onClick={() => handleRemoveAttachment()}
          className="absolute -top-2 -right-2 rounded-full bg-gray-200 hover:bg-gray-300 p-2"
        >
          <X className="w-4 h-4" />
        </button>
      )}
    </div>
  )
}
