import React, { useContext, useEffect, useState } from 'react'
import {
  ArrowsPointingIn,
  ArrowsPointingOut,
  NextIcon,
  PauseIcon,
  PlayIcon,
  PreviousIcon,
  RetryIcon,
  SpeakerWave,
  SpeakerXMark,
} from '../../icons'

import * as styles from './style.module.scss'
import { ResizeContext } from '../../modules/Resize'
import { useDispatch, useSelector } from 'react-redux'
import {
  pause,
  play,
  restart,
  seek,
  startInteractiveStep,
  stopInteractiveStep,
} from '../../slices/playerSlice'
import { RootState } from '../../store'
import { NormalizedStepProps } from '../../types'

type PlayPauseButtonProps = {
  completed: boolean
  playing: boolean
}

export const PlayPauseButton: React.FC<PlayPauseButtonProps> = ({ playing, completed }) => {
  const dispatch = useDispatch()
  const { isInteractiveStep } = useSelector((state: RootState) => state.player.playerControls)
  const handleClick = () => {
    if (completed) {
      dispatch(restart())
    }
    if (isInteractiveStep) {
      dispatch(stopInteractiveStep())
    }
    if (playing) {
      dispatch(pause())
    } else {
      dispatch(play())
    }
  }
  return (
    <div className={styles['flow-mo-buttons']} onClick={handleClick}>
      {completed ? <RetryIcon /> : playing ? <PauseIcon /> : <PlayIcon />}
    </div>
  )
}

export const PreviousNextButtons = () => {
  const dispatch = useDispatch()
  const {
    steps,
    position,
    playerControls: { isInteractiveMode },
  } = useSelector((state: RootState) => state.player)
  const [hasPrevious, setHasPrevious] = useState(false)
  const [hasNext, setHasNext] = useState(true)
  const [previousStep, setPreviousStep] = useState<NormalizedStepProps | null>(null)
  const [nextStep, setNextStep] = useState<NormalizedStepProps | null>(null)

  useEffect(() => {
    const findNextPreviousSteps = () => {
      const gotoSteps = steps.filter((step: NormalizedStepProps) => step.type === 'click')
      const sortedSteps = [...gotoSteps].sort((a, b) => a.position - b.position)
      const nextIndex = sortedSteps.findIndex((step) => step.position > position)
      let previousIndex = sortedSteps
        .slice()
        .reverse()
        .findIndex((step) => step.position < position)
      previousIndex = previousIndex >= 0 ? sortedSteps.length - 1 - previousIndex : previousIndex

      if (nextIndex === -1) {
        setHasNext(false)
        setNextStep(null)
      } else {
        setHasNext(true)
        setNextStep(sortedSteps[nextIndex])
      }

      if (previousIndex === -1) {
        setHasPrevious(false)
        setPreviousStep(null)
      } else {
        setHasPrevious(true)
        setPreviousStep(sortedSteps[previousIndex])
      }
    }

    findNextPreviousSteps()
  }, [steps, position])

  const handleClick = (step: NormalizedStepProps) => {
    dispatch(seek(step.position))

    if (isInteractiveMode) {
      dispatch(pause())
      dispatch(startInteractiveStep(step))
    } else {
      dispatch(seek(step.position))
    }
  }

  const handlePreviousClick = () => {
    if (previousStep !== null) {
      handleClick(previousStep)
    }
  }

  const handleNextClick = () => {
    if (nextStep !== null) {
      handleClick(nextStep)
    }
  }

  return (
    <div className={styles['flow-mo-prev-next-buttons']}>
      <button disabled={!hasPrevious} onClick={handlePreviousClick}>
        <PreviousIcon isDisabled={!hasPrevious} />
      </button>
      <button disabled={!hasNext} onClick={handleNextClick}>
        <NextIcon isDisabled={!hasNext} />
      </button>
    </div>
  )
}

type MuteButtonProps = {
  muted: boolean
  toggle: () => void
}

export const MuteButton: React.FC<MuteButtonProps> = ({ muted, toggle }) => {
  return (
    <div className={styles['flow-mo-buttons']} onClick={toggle}>
      {muted ? <SpeakerXMark /> : <SpeakerWave />}
    </div>
  )
}

export const FullscreenButton: React.FC = () => {
  const { fullscreen, fullscreenToggle } = useContext(ResizeContext)

  return (
    <div className={styles['flow-mo-buttons']} onClick={fullscreenToggle}>
      {fullscreen ? <ArrowsPointingIn /> : <ArrowsPointingOut />}
    </div>
  )
}
