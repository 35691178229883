export const generateClassForField = (error: any, className: string) => {
  const defaultClasses = `${className} w-full pl-2 cursor-text focus:outline-none overflow-visible cursor-pointer hover:bg-[#F9F9F9] border shadow-[0px_6px_18px_rgba(0,0,0,0.07)] relative rounded-md `

  if (error) {
    return (
      defaultClasses +
      'border-red-300 text-red-900 placeholder-red-400 focus:outline-none focus:ring-red-500 focus:border-red-500'
    )
  }

  return defaultClasses + 'text-gray-900 border-gray-300'
}
