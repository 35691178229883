import React from 'react'

interface Props {
  children?: React.ReactNode
}

const Background = ({ children }: Props) => {
  return (
    <div
      className="absolute w-full h-[calc(100vh-52px)] top-[52px] flex items-center"
      style={{
        background:
          'linear-gradient(90deg, #214A86 0%, #305B9A 25.52%, rgba(46, 46, 46, 0.71) 63.44%, rgba(46, 46, 46, 0.71) 100%)',
      }}
    >
      <div className="w-full h-full max-w-[1700px] mx-auto flex items-center px-[20px]">
        {children}
      </div>
    </div>
  )
}

export default Background
