import React, { useEffect, useState } from 'react'
import AudioConversation, { OutputConversationItem } from './Audio/AudioConversation'
import { Refresh } from '@mui/icons-material'
import { Cog } from 'lucide-react'
import clsx from 'clsx'
import { CONVERSATION_SUMMARY_QUERY } from '../graphql/queries/conversation'
import { ConversationSummaryQuery, ConversationSummaryQueryVariables } from '../../graphql'
import { useLazyQuery } from '@apollo/client'

interface ConversationSummaryProps {
  onSummaryChange: (summary: string) => void
}

const ActionButton = ({
  children,
  onClick,
  disabled,
}: {
  children: React.ReactNode
  onClick: () => void
  disabled: boolean
}) => {
  return (
    <button
      className={clsx(
        'px-5 py-2.5 text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-200',
        disabled && 'opacity-50 cursor-not-allowed'
      )}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

const ConversationSummary = ({ onSummaryChange }: ConversationSummaryProps) => {
  const [summary, setSummary] = useState('')
  const [isSummarizing, setIsSummarizing] = useState(false)
  const [conversation, setConversation] = useState<OutputConversationItem[]>([])
  useEffect(() => {
    onSummaryChange(summary)
  }, [summary])

  const conversationText = (oneConversation: OutputConversationItem[]) => {
    let conversationText = ''
    oneConversation.forEach((item) => {
      conversationText += `## ${item.role} said:\n${item.content.join(' ')}\n\n`
    })
    return conversationText
  }

  const handleConversationChange = (newConversation: OutputConversationItem[]) => {
    setConversation(newConversation)
    setSummary(conversationText(newConversation))
  }

  const [summarizeConversation] = useLazyQuery<
    ConversationSummaryQuery,
    ConversationSummaryQueryVariables
  >(CONVERSATION_SUMMARY_QUERY)

  const handleShortSummary = async () => {
    handleSummarize('Generate a short summary of the conversation.')
  }

  const handleLongSummary = async () => {
    handleSummarize(
      'Generate a long summary of the conversation. Keep in as much detail as possible'
    )
  }

  const handleSummarize = async (summarizationPrompt: string) => {
    setIsSummarizing(true)

    if (summary.length == 0) {
      setIsSummarizing(false)
      return
    }

    const response = await summarizeConversation({
      variables: {
        conversation: summary,
        summarizationPrompt,
      },
    })

    if (response.data?.summary) {
      setSummary(response.data.summary)
    }

    setIsSummarizing(false)
  }

  const handleUserNotes = async () => {
    setSummary(conversationText(conversation.filter((item) => item.role === 'user')))
  }

  const handleAssistantNotes = async () => {
    setSummary(conversationText(conversation.filter((item) => item.role === 'assistant')))
  }

  const handleReset = () => {
    handleConversationChange(conversation)
  }

  const actionsDisabled = isSummarizing || summary.length == 0

  return (
    <div className="flex flex-col gap-2">
      <AudioConversation onConversationItems={handleConversationChange} />
      <div className="relative flex flex-col gap-2">
        <div className="flex flex-row items-center justify-start gap-2">
          <div className="text-sm font-medium">Conversation Notes</div>
          <button
            type="button"
            className={clsx(
              'cursor-pointer text-lg text-gray-600 hover:text-gray-800',
              isSummarizing && 'opacity-50 cursor-not-allowed'
            )}
            onClick={handleReset}
            disabled={isSummarizing}
          >
            <Refresh fontSize="inherit" color="inherit" />
          </button>
        </div>
        <div className=" overflow-hidden min-h-80 rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-flowmo-blue-600">
          {isSummarizing ? (
            <div className="flex items-center justify-center h-full w-full mt-20">
              <Cog className="w-8 h-8 animate-spin" />
            </div>
          ) : (
            <textarea
              className="block w-full h-80 resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6"
              value={summary}
              disabled={isSummarizing}
              onChange={(e) => setSummary(e.target.value)}
            />
          )}
        </div>
        <div className="flex gap-2">
          <ActionButton disabled={actionsDisabled} onClick={handleShortSummary}>
            Short Summary
          </ActionButton>
          <ActionButton disabled={actionsDisabled} onClick={handleLongSummary}>
            Long Summary
          </ActionButton>
          <ActionButton disabled={actionsDisabled} onClick={handleUserNotes}>
            User Notes
          </ActionButton>
          <ActionButton disabled={actionsDisabled} onClick={handleAssistantNotes}>
            Assistant Notes
          </ActionButton>
        </div>
      </div>
    </div>
  )
}

export default ConversationSummary
