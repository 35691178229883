import React, { useEffect, useMemo, useState } from 'react'
import Permission from '../../components/Permission'
import { Bar } from '../../components/Containers'
import Select from '../../components/Select'
import { RecordIcon } from '../../icons'
import Button from '../../../../../../Embed/Content/Button'
import Controller from '../Controller'
import { useDispatch, useSelector } from 'react-redux'
import { PlayerActions, RootState } from '../../../../../../AI/components/Player/PlayerState'

type SettingsProps = {
  close: () => void
  setAudio: (audio: any) => void
  setWave: (arr: number[]) => void
}

const Settings: React.FC<SettingsProps> = ({ close, setAudio, setWave }) => {
  const dispatch = useDispatch()
  const { position } = useSelector((state: RootState) => state.player)
  const [recording, setRecording] = useState<boolean>(false)
  const [devices, setDevices] = useState<any[]>([
    { deviceId: 'audio-disabled', label: 'Microphone: disabled' },
  ])
  const [currentDevice, setCurrentDevice] = useState<any>(devices[0])
  const [status, setStatus] = useState<any>(null)

  useEffect(() => {
    if (position !== 0) {
      dispatch(PlayerActions.restart())
    }

    // @ts-ignore
    navigator?.permissions?.query({ name: 'microphone' }).then(function (permissionStatus) {
      setStatus(permissionStatus.state)

      permissionStatus.onchange = function () {
        setStatus(this.state)
      }
    })
  }, [])

  useEffect(() => {
    if (status) {
      if (status === 'prompt') {
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then((stream) => {
            stream.getTracks().forEach(function (track) {
              track.stop()
            })
          })
      } else if (status === 'granted') {
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then((stream) => {
            navigator.mediaDevices.enumerateDevices().then(function (devices) {
              const audioDevices = devices
                .filter((device) => device.kind === 'audioinput')
                .map((device) => ({ deviceId: device.deviceId, label: device.label }))
              setDevices(audioDevices)
              audioDevices[0] && setCurrentDevice(audioDevices[0])
              stream.getTracks().forEach(function (track) {
                track.stop()
              })
            })
          })
      }
    }
  }, [status])

  const disabled = useMemo(() => {
    if (status === 'prompt' || status === 'denied') {
      return true
    }

    return !status
  }, [status])

  if (recording) {
    return (
      <Controller setAudio={setAudio} close={close} handleWave={setWave} device={currentDevice} />
    )
  }

  return (
    <React.Fragment>
      {status && <Permission status={status} />}
      <Bar>
        <div className="flex items-center justify-between">
          <div className="flex max-w-[75%] w-[500px] items-center">
            <Select
              disabled={disabled}
              options={devices}
              option={currentDevice}
              handleSelect={setCurrentDevice}
            >
              <div className="flex whitespace-nowrap">
                <button
                  type="button"
                  className="rounded-r-md border-l-0 py-2 md:py-[9px] px-4 md:px-6 inline-flex items-center text-white text-[13px] sm:text-sm text-white hover:bg-flowmo-blue-700 bg-flowmo-blue-600 whitespace-nowrap"
                  onClick={() => setRecording(true)}
                >
                  <RecordIcon className="mr-2" />
                  Start recording
                </button>
              </div>
            </Select>
          </div>
          <div>
            <Button type="neutral" onClick={close}>
              Cancel
            </Button>
          </div>
        </div>
      </Bar>
    </React.Fragment>
  )
}

export default Settings
