import { useEffect, useRef } from 'react'

const HIGHLIGHTER_SIZE = 86
const HIGHLIGHTER_RADIUS = HIGHLIGHTER_SIZE / 2

const getLeftPosition = (position: DOMRect) => {
  return position.left + position.width / 2 - HIGHLIGHTER_RADIUS
}

const getTopPosition = (position: DOMRect) => {
  return position.top + 2 + position.height / 2 - HIGHLIGHTER_RADIUS
}

const useCirclesPosition = (position: DOMRect) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (position && ref.current) {
      Object.assign(ref.current.style, {
        left: `${getLeftPosition(position)}px`,
        top: `${getTopPosition(position)}px`,
        position: 'absolute',
      })
    }
  }, [position, ref.current])

  return ref
}

export default useCirclesPosition
