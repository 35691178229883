import React, { useEffect, useState } from 'react'

import * as styles from './style.module.scss'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'

const Thumbnail: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [touched, setTouched] = useState<boolean>(false)
  const { position } = useSelector((state: RootState) => state.player)

  useEffect(() => {
    if (position !== 0 && !touched) {
      setTouched(true)
    }
  }, [position])

  if (touched) {
    return <React.Fragment />
  }

  return <div className={styles['flow-mo-thumbnail']}>{children}</div>
}

export default Thumbnail
