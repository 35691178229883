import {
  RRWEB_INCREMENTAL_SNAPSHOT_TYPES,
  RRWEB_MOUSE_INTERACTION_TYPES,
  RRWEB_EVENT_TYPES,
  RRWEB_TARGET_TYPES,
  RRWEB_INPUT_TYPES,
} from '../constants/RRWebEventTypes'
import { StepTypes } from '../../App/models/Walkthrough'

export const ClickDefaultMeta = {
  title: 'Click',
  description: '',
}

export const DropdownSelectDefaultMeta = {
  title: 'Dropdown',
  description: '',
}

export const InputDefaultMeta = {
  title: 'Input',
  description: '',
}

const isAutoGenerated = ({ data }: any) => data && data.userTriggered === false

const isHidden = ({ meta }: any) => meta && meta.isElementVisible === false

const isInputEvent = ({ type, data }: any) =>
  type === RRWEB_EVENT_TYPES.INCREMENTAL_SNAPSHOT &&
  data.source === RRWEB_INCREMENTAL_SNAPSHOT_TYPES.INPUT

class NodesParser {
  static getNodeIds(chunks: any[]) {
    const ids: Record<any, any> = {}
    const snapshot = chunks.find((event) => event.type === RRWEB_EVENT_TYPES.FULL_SNAPSHOT)

    const recursiveNodeFunction = (node: any) => {
      if (node.id !== undefined) {
        ids[node.id] = node
      }

      if (!node.childNodes) {
        return
      }

      for (const child of node.childNodes) {
        recursiveNodeFunction(child)
      }
    }

    if (snapshot) {
      recursiveNodeFunction(snapshot.data.node)
    }

    const mutations = chunks.filter(
      (event) =>
        event.type === RRWEB_EVENT_TYPES.INCREMENTAL_SNAPSHOT &&
        event.data &&
        event.data.source === RRWEB_INCREMENTAL_SNAPSHOT_TYPES.MUTATION &&
        event.data.adds &&
        event.data.adds.length
    )

    for (const mutation of mutations) {
      for (const add of mutation.data.adds) {
        ids[add.node.id] = add.node
      }
    }

    return ids
  }

  static sortByTimestamp(chunks: any[]) {
    chunks.forEach((chunk) => {
      chunk.sort(
        (event1: { timestamp: number }, event2: { timestamp: number }) =>
          event1.timestamp - event2.timestamp
      )
    })

    return chunks
  }

  static getNavigations(chunks: any[]) {
    const navigations: any[] = []

    chunks.forEach((chunk, chunkIndex: number) => {
      const eventIndex = chunk.findIndex((event: any) => event.type === RRWEB_EVENT_TYPES.META)
      const event = chunk[eventIndex]

      //first chunk meta
      if (chunkIndex === 0) {
        navigations.push({ type: StepTypes.goto, chunkIndex, event, eventIndex })
        return
      }

      const previous = chunks[chunkIndex - 1].find(
        (event: any) => event.type === RRWEB_EVENT_TYPES.META
      )

      //if chunk meta doesn't have referrer
      if (!event.meta.referrer) {
        if (previous.data.href !== event.data.href) {
          navigations.push({ type: StepTypes.goto, chunkIndex, event, eventIndex })
        }
        return
      }

      //chunk meta has different referrer
      if (!previous.data.href.includes(event.meta.referrer)) {
        navigations.push({ type: StepTypes.goto, chunkIndex, event, eventIndex })
        return
      }
    })

    return navigations
  }

  static getActions(inputChunks: any[]) {
    const actions: any[] = []

    inputChunks.forEach((chunk, chunkIndex: number) => {
      const nodes = NodesParser.getNodeIds(chunk)

      chunk.forEach(
        (
          event: {
            type: number
            timestamp: number
            data: { source: number; type: number; id: any } | any
            meta: any
          },
          eventIndex: number
        ) => {
          // ignore script generated events and hidden elements
          if (isAutoGenerated(event) || isHidden(event)) {
            return
          }
          //input
          if (
            event.data.source === RRWEB_INCREMENTAL_SNAPSHOT_TYPES.MOUSE_INTERACTION &&
            event.data.type === RRWEB_MOUSE_INTERACTION_TYPES.FOCUS
          ) {
            const node = nodes[event.data.id]

            //ignore clickable inputs
            if (node && node.tagName === RRWEB_TARGET_TYPES.INPUT) {
              if (
                node.attributes &&
                [RRWEB_INPUT_TYPES.CHECKBOX, RRWEB_INPUT_TYPES.RADIO].includes(node.attributes.type)
              ) {
                return
              }
            }

            if (
              node &&
              [RRWEB_TARGET_TYPES.INPUT, RRWEB_TARGET_TYPES.TEXTAREA].includes(node.tagName)
            ) {
              let blur: any
              //input mutation between focus and blur
              const events = chunk
                .filter((next: any, nextIndex: number) => {
                  if (nextIndex > eventIndex && next.data.id === event.data.id) {
                    if (
                      next.data.source === RRWEB_INCREMENTAL_SNAPSHOT_TYPES.MOUSE_INTERACTION &&
                      next.data.type === RRWEB_MOUSE_INTERACTION_TYPES.BLUR
                    ) {
                      blur = next
                    }
                    return !blur
                  }
                  return false
                })
                .filter((event: any) => isInputEvent(event))

              //ignore actions without input changes
              if (events && events.length > 0) {
                if (!event.meta) {
                  event.meta = { ...InputDefaultMeta }
                }
                actions.push({ type: StepTypes.input, chunkIndex, event, eventIndex })
              }
            }
          }

          //dropdown select
          if (isInputEvent(event)) {
            const node = nodes[event.data.id]

            if (node && node.tagName === RRWEB_TARGET_TYPES.SELECT) {
              if (!event.meta) {
                event.meta = { ...DropdownSelectDefaultMeta }
              }

              actions.push({ type: StepTypes.dropdownSelect, chunkIndex, event, eventIndex })
            }
          }

          //click
          if (
            event.type === RRWEB_EVENT_TYPES.INCREMENTAL_SNAPSHOT &&
            event.data.source === RRWEB_INCREMENTAL_SNAPSHOT_TYPES.MOUSE_INTERACTION &&
            event.data.type === RRWEB_MOUSE_INTERACTION_TYPES.MOUSE_DOWN
          ) {
            const node = nodes[event.data.id]

            //inputs click
            if (
              node &&
              [
                RRWEB_TARGET_TYPES.SELECT,
                RRWEB_TARGET_TYPES.INPUT,
                RRWEB_TARGET_TYPES.TEXTAREA,
              ].includes(node.tagName)
            ) {
              let blur: any
              //input mutation between click and blur
              const events = chunk
                .filter((next: any, nextIndex: number) => {
                  if (nextIndex > eventIndex && next.data.id === event.data.id) {
                    if (
                      next.data.source === RRWEB_INCREMENTAL_SNAPSHOT_TYPES.MOUSE_INTERACTION &&
                      next.data.type === RRWEB_MOUSE_INTERACTION_TYPES.BLUR
                    ) {
                      blur = next
                    }
                    return !blur
                  }
                  return false
                })
                .filter((event: any) => isInputEvent(event))

              const isClickable =
                node.tagName === RRWEB_TARGET_TYPES.INPUT &&
                node.attributes &&
                [RRWEB_INPUT_TYPES.CHECKBOX, RRWEB_INPUT_TYPES.RADIO].includes(node.attributes.type)

              if (!isClickable && events && events.length > 0) {
                return
              }
            }

            if (!event.meta) {
              event.meta = { ...ClickDefaultMeta }
            }

            actions.push({ type: StepTypes.click, chunkIndex, event, eventIndex })
          }
        }
      )
    })

    return actions
  }

  static parse(chunks: any[]) {
    const nodes: any[] = []

    nodes.push(...NodesParser.getNavigations(chunks))
    nodes.push(...NodesParser.getActions(chunks))

    return nodes.sort((a, b) => a.event.timestamp - b.event.timestamp)
  }
}

export default NodesParser
