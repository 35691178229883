import React from 'react'
import { ChevronDown, MicOff, Mic } from 'lucide-react'
import clsx from 'clsx'

interface AudioDropdownProps {
  devices: MediaDeviceInfo[]
  selectedName: string
  onSelect: (deviceId: string, name: string) => void
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  dropdownRef: React.RefObject<HTMLDivElement>
  isMuted: boolean
  onMuteToggle: () => void
  audioLevel?: number[]
}

const AudioDropdown = ({
  devices,
  selectedName,
  onSelect,
  isOpen,
  setIsOpen,
  dropdownRef,
  isMuted,
  onMuteToggle,
  audioLevel = [],
}: AudioDropdownProps) => (
  <div className="relative flex flex-row w-full" ref={dropdownRef}>
    <button
      onClick={() => setIsOpen(!isOpen)}
      className="flex-grow flex items-center gap-2 text-gray-300 bg-transparent hover:bg-gray-700 px-2 py-2 rounded text-sm"
    >
      <div className="flex-shrink-0">
        {isMuted ? <MicOff className="w-4 h-4" /> : <Mic className="w-4 h-4" />}
      </div>
      <div className="line-clamp-1 flex-grow text-left">{selectedName}</div>
      <ChevronDown className="w-4 h-4 opacity-60 justify-self-end" />
    </button>

    {isOpen && (
      <div className="absolute mt-1 w-64 bg-gray-800 rounded-md shadow-lg z-10">
        <div className="py-1">
          {devices.map((device, index) => (
            <button
              key={device.deviceId}
              className="w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700 flex items-center line-clamp-1"
              onClick={() => {
                onSelect(device.deviceId, device.label || `Audio Input ${index + 1}`)
                setIsOpen(false)
              }}
            >
              {selectedName === device.label && <div className="mr-2 line-clamp-1">✓</div>}
              <div className={clsx(index === 0 ? 'font-medium' : '', 'line-clamp-1')}>
                {device.label || `Audio Input ${index + 1}`}
              </div>
            </button>
          ))}

          <div className="border-t border-gray-700 mt-1">
            <button
              onClick={(e) => {
                e.stopPropagation()
                onMuteToggle()
              }}
              className="w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700 flex items-center justify-between"
            >
              <div className="flex items-center gap-2">
                {isMuted ? <MicOff className="w-4 h-4" /> : <Mic className="w-4 h-4" />}
                {isMuted ? 'Off' : 'On'}
              </div>
              {!isMuted && (
                <div className="flex items-center gap-px h-3">
                  {audioLevel.map((level, index) => (
                    <div
                      key={index}
                      className="w-0.5 bg-gray-400"
                      style={{
                        height: `${(level / 255) * 12}px`,
                      }}
                    />
                  ))}
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
)

export default AudioDropdown
