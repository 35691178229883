import React, { useState } from 'react'
import { ClockIcon, UserIcon } from '@heroicons/react/24/outline'
import { SkillSet } from 'app/javascript/components/graphql'
import ConfirmDialog from '../../Walkthrough/ui/ConfirmDialog'
import MoveMenuItem from '../../common/components/Menu/MenuItem/MoveItem'
import useDeleteSkillSetMutation from '../hooks/useDeleteSkillSetMutation'
import { pluralizeWord } from '../../common/utils/pluralize'
import useMoveSkillSetMutation from '../hooks/useMoveSkillSetMutation'
import { DestinationType } from '../../graphql'
import { createdAtFormatter } from '../../common/utils/createdAtFormatter'
import DeleteMenuItem from '../../common/components/Menu/MenuItem/DeleteItem'
import CardMenu from '../../common/components/Menu'
import InformationBar from '../../common/ui/InformationBar'
import Divider from '../../common/ui/Divider'
import { createdByFormatter } from '../../common/utils/createdByFormatter'
import { FolderIcon } from '../../common/icons'
import ContentField from '../../Walkthrough/ui/Walkthrough/ContentField'
import AddToAgentItem from '../../common/components/Menu/MenuItem/AddToAgentItem'
import { useHistory } from 'react-router-dom'

interface Props {
  skillSet: SkillSet
  walkthroughsCount: number
  isPublicView?: boolean
  isEditing?: boolean
}

const SkillSetHeader: React.FC<Props> = ({
  skillSet,
  walkthroughsCount,
  isPublicView = false,
  isEditing = false,
}) => {
  const history = useHistory()

  const [moveSkillSet] = useMoveSkillSetMutation()

  // delete
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)
  const onDeleteAccept = () => {
    toggleDeleteModal()
    deleteSkillSet().then(() => {
      window.location.href = '/app/my_library'
    })
    return window.toastr.success('The Skill Set was deleted successfully')
  }
  const [deleteSkillSet] = useDeleteSkillSetMutation(skillSet.id)

  const handleMove = (variables: { destinationType: DestinationType; destinationId?: string }) => {
    moveSkillSet({
      variables: {
        id: skillSet.id,
        destinationType: variables.destinationType,
        destinationId: variables.destinationId,
      },
    }).then(({ data }) => {
      let path = 'home'
      if (data.skillSet.isInMyLibrary) {
        path = 'my_library'
        window.open(
          '/app/' + path + `?message=Skill Set was moved successfully to your library`,
          '_self'
        )
      } else {
        path = 'organization_library'
        window.open(
          '/app/' + path + '?message=Skill Set was moved successfully to organization library',
          '_self'
        )
      }
    })
  }

  const handleAgentAdd = () => {
    history.push('/skill_set/' + skillSet.uuid + '/add_to_agent')
  }

  let cardMenu

  if (!isEditing && skillSet.canEdit) {
    const menuItems = [
      <AddToAgentItem onClick={handleAgentAdd} key="agent" />,
      <div className="border-b pt-2 mb-2" key="divider"></div>,
      <DeleteMenuItem onClick={toggleDeleteModal} key="delete" />,
    ]

    if (skillSet.canMove) {
      menuItems.splice(2, 0, <MoveMenuItem onMove={handleMove} key="move" subject={skillSet} />)
    }

    cardMenu = (
      <div className="top-3 right-5 self-center flex items-center">
        <CardMenu key={skillSet.id} items={menuItems} />
      </div>
    )
  }

  return (
    <div className="max-w-7xl mx-auto">
      <ConfirmDialog
        title="Delete this Skill Set?"
        description={
          skillSet.walkthroughs.length
            ? `Are you sure you want to delete this Skill Set? The ${
                skillSet.walkthroughs.length > 1 ? skillSet.walkthroughs.length : ''
              } ${pluralizeWord(
                'Walkthrough',
                skillSet.walkthroughs.length
              )} in this Skill Set will also be deleted`
            : 'Are you sure you want to delete this Skill Set?'
        }
        onAccept={onDeleteAccept}
        onCancel={toggleDeleteModal}
        actionLabel="Delete"
        cancelLabel="Cancel"
        open={showDeleteModal}
        close={toggleDeleteModal}
      />

      <div className="relative">
        <div className="rounded-lg relative md:pb-0">
          <div className="sm:flex sm:items-center sm:justify-center">
            <div className="sm:flex sm:space-x-5 w-full">
              <div className="text-center w-full sm:pt-1 sm:text-left">
                <div className="flex flex-col items-center md:flex-row align-center">
                  <ContentField
                    name="name"
                    className="break-all text-center md:text-left w-full py-2.5 break-words rounded-md text-lg lg:text-xl xl:text-2xl font-bold text-gray-900"
                    canEdit={skillSet.canEdit}
                  >
                    {skillSet.name}
                  </ContentField>
                </div>
                <div className="">
                  <ContentField
                    name="description"
                    className="text-center md:text-left w-full py-2.5 break-words rounded-md text-md text-gray-700"
                    canEdit={skillSet.canEdit}
                  >
                    {skillSet.description}
                  </ContentField>
                </div>
                <InformationBar
                  stepsCount={skillSet.walkthroughs.length}
                  object={skillSet}
                  objectType="skillSet"
                  cardMenu={cardMenu}
                  className="mt-[30px] border-t"
                >
                  <>
                    <div className="info pl-0">
                      <FolderIcon className="icon" />
                      <span>{walkthroughsCount} Walkthroughs</span>
                    </div>
                    <Divider className="border-r h-5" />
                    <div className="info">
                      <ClockIcon className="icon" />
                      <span>{createdAtFormatter(new Date(skillSet.createdAt))}</span>
                    </div>
                    {skillSet.createdBy.firstName && (
                      <>
                        <Divider className="border-r h-5" />
                        <div className="info">
                          <UserIcon className="icon" />
                          <span>by {createdByFormatter(isPublicView, skillSet.createdBy)}</span>
                        </div>
                      </>
                    )}
                  </>
                </InformationBar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkillSetHeader
