import React from 'react'
import Launcher from './Viewer/Launcher'
import { Walkthrough } from 'app/javascript/components/graphql'
interface Props {
  walkthrough: Walkthrough
  isEditMode?: boolean
  name?: string
}

enum LaunchType {
  LABS = 'labs',
}

const WalkthroughLearningModes: React.FC<Props> = ({ walkthrough }) => {
  return (
    <div className="mt-8">
      <div className="flex w-full sm:flex-col xl:flex-row justify-between mt-8 space-x-6 sm:space-x-0 xl:space-x-6">
        <Launcher
          launchUrl={`/labs/walkthrough/${walkthrough.uuid}`}
          launchType={LaunchType.LABS}
          isLearnModeEnabled={walkthrough.labs}
          canEdit={walkthrough.canEdit}
        />
      </div>
    </div>
  )
}

export default WalkthroughLearningModes
