import React from 'react'
import PageHeader from '../../components/PageHeader'
import { sendRRWebWalkthroughCreationAction } from '../../../../components/App/lib/extension'
import { ArrowDownOnSquareIcon, GlobeAltIcon } from '@heroicons/react/24/solid'
import { clsx } from 'clsx'
import StartRecording from '../../../../../assets/images/StartRecording.png'

const CreateWalkthroughWizard: React.FC = () => {
  const onClick = () => {
    sendRRWebWalkthroughCreationAction()
  }

  const steps = [
    {
      name: 'Install Browser Extension',
      description:
        'Walkthroughs are powered by a browser extension. We currently only support the Google Chrome Browser',
      href: 'https://chrome.google.com/webstore/detail/ckfldhejolipdmhhmofaandhaimbcbdn',
      status: 'complete',
      extra: (
        <div>
          <a target="_blank" href="https://www.google.com/chrome/downloads/" rel="noreferrer">
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-flowmo-blue-600 hover:bg-flowmo-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowmo-blue-500"
            >
              Download Chrome
              <GlobeAltIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
            </button>
          </a>
          <a
            target="_blank"
            href="https://chrome.google.com/webstore/detail/ckfldhejolipdmhhmofaandhaimbcbdn"
            rel="noreferrer"
          >
            <button
              type="button"
              className="ml-2 mt-2 lg:mt-0 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-flowmo-blue-600 hover:bg-flowmo-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowmo-blue-500"
            >
              Install Extension
              <ArrowDownOnSquareIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
            </button>
          </a>
        </div>
      ),
    },
    {
      name: 'Create a Walkthrough',
      description: 'Initiate a new Walkthrough recording.',
      href: '#',
      status: 'current',
      extra: (
        <div className="flex flex-col align-middle">
          <button
            onClick={onClick}
            type="button"
            className="px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white w-48 bg-flowmo-orange-500 hover:bg-flowmo-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowmo-orange-500"
          >
            Start Recording
          </button>
          <div className="my-4">
            <span className="italic text-lg text-gray-600">OR</span>
          </div>
          <div>
            <span className="text-sm text-gray-600">
              Initiate recording from the browser extension just like this:
              <img src={StartRecording} alt="Start Recording" className="w-96" />
            </span>
          </div>
        </div>
      ),
    },
    {
      name: 'Add to Skill Set',
      description: 'Once you have a Walkthrough, you can share it via a link.',
      href: '#',
      status: 'upcoming',
    },
    {
      name: 'Add to Agent',
      description: 'Organize your Walkthroughs into Skill Sets.',
      href: '#',
      status: 'upcoming',
    },
  ]

  return (
    <>
      <nav aria-label="Progress">
        <ol role="list" className="overflow-hidden">
          {steps.map((step, stepIdx) => (
            <li
              key={step.name}
              className={clsx(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}
            >
              {step.status === 'complete' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-flowmo-blue-600"
                      aria-hidden="true"
                    />
                  ) : null}
                  <a
                    href={step.href}
                    target="_blank"
                    className="relative flex items-start group"
                    rel="noreferrer"
                  >
                    <span className="h-9 flex items-center">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-flowmo-blue-600 rounded-full group-hover:bg-flowmo-blue-800 text-white">
                        {stepIdx + 1}
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase">
                        {step.name}
                      </span>
                      <span className="text-sm text-gray-500">{step.description}</span>
                    </span>
                  </a>
                </>
              ) : step.status === 'current' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <a
                    href={step.href}
                    className="relative flex items-start group"
                    aria-current="step"
                  >
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-flowmo-blue-600 rounded-full">
                        <span className="h-2.5 w-2.5 bg-flowmo-blue-600 rounded-full" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-flowmo-blue-600">
                        {step.name}
                      </span>
                      <span className="text-sm text-gray-500">{step.description}</span>
                    </span>
                  </a>
                </>
              ) : (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <a href={step.href} className="relative flex items-start group">
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                        <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                        {step.name}
                      </span>
                      <span className="text-sm text-gray-500">{step.description}</span>
                    </span>
                  </a>
                </>
              )}
              {step.extra && <div className="flex ml-12 mt-4">{step.extra}</div>}
            </li>
          ))}
        </ol>
      </nav>
    </>
  )
}

const CreateWalkthrough = () => {
  return (
    <>
      <PageHeader
        title="Create Walkthrough"
        breadCrumbs={[{ title: 'My Library', to: '/my_library' }, { title: 'Create Walkthrough' }]}
      />

      <div className="ml-5 mt-10">
        <CreateWalkthroughWizard />
      </div>
    </>
  )
}

export default CreateWalkthrough
