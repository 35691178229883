import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserType {
  firstName: string
  lastName: string
  email: string
  id: number
  roles: string[]
  isAdmin: boolean
  isOrganizationAdmin: boolean
}

interface OrganizationType {
  id: number
  name: string
  emailNamespace: string
}

export interface appState {
  user: UserType
  organization: OrganizationType
}

const initialState: appState = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    id: 0,
    roles: null,
    isAdmin: false,
    isOrganizationAdmin: false,
  },
  organization: {
    name: '',
    id: null,
    emailNamespace: '',
  },
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload
      state.user.isAdmin = state.user.roles.includes('admin')
      state.user.isOrganizationAdmin =
        state.user.roles.includes('organization_admin') || state.user.isAdmin
    },
    setOrganization: (state, action: PayloadAction<OrganizationType>) => {
      state.organization = action.payload
    },
  },
})

export const { setUser, setOrganization } = appSlice.actions

export default appSlice.reducer
