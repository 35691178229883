import React from 'react'
import Spin from '../../common/ui/Spin'
import { Chunk } from '../hooks/useRRWebEvents'

const getProgressString = (chunk: Chunk) => {
  if (chunk.progress.total) {
    const { loaded, total } = chunk.progress
    const percentage = loaded / total

    if (percentage < 0.3) {
      return 'Starting data download'
    }
    if (percentage < 0.6) {
      return 'Extracting required events'
    }

    if (percentage < 0.9) {
      return 'Preparing walkthrough to be displayed'
    }

    if (percentage <= 1) {
      return 'Walkthrough is ready to be viewed'
    }
  }

  return 'Preparing to download'
}

interface Props {
  chunk: Chunk
}

const ProgressLoader: React.FC<Props> = ({ chunk }) => {
  return (
    <Spin
      className="h-20 w-20 text-ddu-blue m-auto mt-6"
      loadMessage={
        <div className="mt-6 text-flowmo-blue-400 text-lg text-center">
          <p>{getProgressString(chunk)}</p>
        </div>
      }
    />
  )
}

export default ProgressLoader
