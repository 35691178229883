import React from 'react'
import { SideEffectFieldsFragment } from '../../../graphql'
import DataSetCard from './DataSetCard'
import AgentCard from './AgentCard'
import ChartResults from './ChartResults'
import LookerExplore from './LookerExplore'

const CardContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="flex flex-wrap gap-2">{children}</div>
}

const SideEffect: React.FC<{ sideEffect: SideEffectFieldsFragment }> = ({ sideEffect }) => {
  const sideEffectType = sideEffect.sideEffect
  if (!sideEffectType) {
    return <></>
  }

  if (sideEffectType === 'side_effect_show_data_sets') {
    return (
      <CardContainer>
        {sideEffect.data.data_set_uuid_list.map((one_uuid: string) => (
          <DataSetCard key={one_uuid} uuid={one_uuid} />
        ))}
      </CardContainer>
    )
  } else if (sideEffectType === 'side_effect_show_agent') {
    return (
      <CardContainer>
        {sideEffect.data.agent_uuid_list.map((one_uuid: string) => (
          <AgentCard key={one_uuid} uuid={one_uuid} />
        ))}
      </CardContainer>
    )
  } else if (sideEffectType === 'side_effect_chart_results') {
    return <ChartResults config={sideEffect.data} />
  } else if (sideEffectType.startsWith('side_effect_looker_data_set_')) {
    return <LookerExplore sideEffect={sideEffect} />
  }
  return <></>
}

export default SideEffect
