import { gql } from '@apollo/client'

export const ADD_TO_WAITLIST = gql`
  mutation addToWaitlist($email: String!) {
    addToWaitlist(email: $email) {
      errors
      success
      placeInLine
    }
  }
`
