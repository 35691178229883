export enum LearningActivityObjectType {
  walkthrough = 'walkthrough',
  skillSet = 'skill_set',
}

export enum LearningActivityActionType {
  completed = 'completed',
  started = 'started',
  viewed = 'viewed',
  viewedShared = 'viewed_shared',
}
