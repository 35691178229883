import { useQuery } from '@apollo/client'
import React from 'react'
import { GET_ONE_ANALYSIS } from '../../../graphql/queries/analysis'
import {
  OneAnalysisRunQuery,
  OneAnalysisRunQueryVariables,
} from 'app/javascript/components/graphql'
import AnalysisResults from './AnalysisResults'
import PageHeader from '../../../components/PageHeader'
import HeaderActionButton from '../../../components/HeaderActionButton'
import { Link } from 'react-router-dom'

const AnalysisRun = ({ uuid }: { uuid: string }) => {
  const { data, loading } = useQuery<OneAnalysisRunQuery, OneAnalysisRunQueryVariables>(
    GET_ONE_ANALYSIS,
    {
      variables: { uuid },
      fetchPolicy: 'network-only',
    }
  )

  const analysis = data?.oneAnalysisRun.analysis
  const run = data?.oneAnalysisRun
  if (loading) {
    return <></>
  }

  return (
    <div>
      <PageHeader
        title={
          <div className="flex flex-row space-x-2">
            <div className="flex flex-col mt-2 space-y-2">
              <div>{analysis.name}</div>
              <div className="text-base font-normal text-gray-500">{analysis.description}</div>
            </div>
          </div>
        }
        breadCrumbs={[
          { title: 'Analyses', to: '/analyses' },
          { title: analysis.name, to: `/analysis/${analysis.uuid}` },
          { title: 'One Run' },
        ]}
      >
        <div className="flex flex-row space-x-4">
          <Link to={'/analysis/' + analysis.uuid}>
            <HeaderActionButton label="back" />
          </Link>
        </div>
      </PageHeader>
      <AnalysisResults analysis={analysis} analysisRun={run} />
    </div>
  )
}

export default AnalysisRun
