import React from 'react'

const ActionButton = ({
  children,
  onClick,
  disabled,
}: {
  children: React.ReactNode
  onClick: () => void
  disabled?: boolean
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className="flex flex-row gap-2 items-center justify-center text-sm text-gray-500 hover:text-gray-900 rounded-full bg-gray-100 py-2 px-4"
    >
      {children}
    </button>
  )
}

export default ActionButton
