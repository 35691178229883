import React from 'react'
import { useField } from 'formik'

const StepCard = ({
  stepNumber,
  title,
  children,
  isLast,
}: {
  step: any
  stepNumber?: number
  title?: string
  children?: any
  isLast?: boolean
}) => {
  const [field] = useField({ name: `steps[${stepNumber - 1}].visible` })
  return (
    <div className={`flex relative duration-200 ease-in-out ${!field.value && 'opacity-50'}`}>
      {stepNumber && <StepNumber isLast={isLast} stepNumber={stepNumber} />}
      <Container>
        <div className="w-full shrink flex flex-col space-y-2">
          {title && <h3 className="text-md font-semibold">{title}</h3>}
          {children}
        </div>
      </Container>
    </div>
  )
}

const StepNumber: React.FC<{ stepNumber: number; isLast: boolean }> = ({ stepNumber, isLast }) => (
  <div className="flex-0 h-full absolute left-0">
    <div className="flex relative shrink-0 mt-4 rounded-full items-center justify-center bg-flowmo-blue-600 text-white h-[20px] w-[20px] md:h-[30px] md:w-[30px]">
      <span className="text-xs md:text-base md:font-semibold">{stepNumber}</span>
    </div>
    {!isLast && (
      <div className="-ml-px z-10 relative md:mt-0.5 md:top-1 left-[10px] md:left-[15px] w-px h-full bg-[#E0E0E0]" />
    )}
  </div>
)

const Container: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="flex-1 max-w-full pl-8 sm:pl-10 md:pl-14">
    <div className="bg-white border rounded-md">
      <div className="flex flex-row p-2 md:p-4">{children}</div>
    </div>
  </div>
)

export default StepCard
