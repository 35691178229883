import React, { useState } from 'react'
import { clsx } from 'clsx'
import { HandThumbDownIcon, HandThumbUpIcon, UserIcon } from '@heroicons/react/24/solid'
import { getRelativeTimeString } from '../../../../components/common/utils/dateDisplay'
import { FileAttachment, Note } from 'app/javascript/components/graphql'
import { Knowledge } from 'app/javascript/components/graphql'
import { Link } from 'react-router-dom'
import { Dialog, DialogTitle, DialogPanel, DialogBackdrop } from '@headlessui/react'
import TextContent from './TextContent'

const BaseAttachment = ({
  initial,
  url,
  children,
}: {
  initial?: string
  url?: string
  children: React.ReactNode
}) => {
  const content = (
    <div className="flex flex-row border items-center space-x-2 border-gray-200 rounded-lg p-2 w-48 text-xs hover:bg-gray-100 hover:cursor-pointer hover:shadow-sm">
      <div className="text-xs bg-gray-200 rounded-full p-2">{initial}</div>
      <div className="flex-grow">{children}</div>
    </div>
  )
  return (
    <>
      {url ? (
        <Link to={url} target="_blank">
          {content}
        </Link>
      ) : (
        content
      )}
    </>
  )
}

const NoteComponent = ({ note }: { note: Note }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <BaseAttachment initial="N">
      <div onClick={() => setIsOpen(true)}>
        <div className="text-xs line-clamp-1">{note.note}</div>
      </div>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mt-3">
                  <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                    Note Details
                  </DialogTitle>
                  <div className="mt-2">
                    <div className="border border-gray-300 max-w-full h-96 rounded-lg p-3 overflow-y-auto">
                      <TextContent content={{ type: 'text', value: note.note }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 flex items-center justify-center">
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="inline-flex w-32 justify-center rounded-md bg-flowmo-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-flowmo-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-flowmo-blue-600 sm:col-start-2"
                >
                  Close
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </BaseAttachment>
  )
}

const FileAttachmentComponent = ({ fileAttachment }: { fileAttachment: FileAttachment }) => {
  return (
    <BaseAttachment initial="F" url={fileAttachment.downloadUrl}>
      <div className="line-clamp-1">{fileAttachment.fileName}</div>
    </BaseAttachment>
  )
}

const KnowledgeComponent = ({ knowledge }: { knowledge: Knowledge }) => {
  // fetch the contents of the knowledge
  let url = ''
  let initial = ''
  if (knowledge.type == 'Walkthrough') {
    url = `/walkthrough/${knowledge.uuid}`
    initial = 'W'
  } else {
    initial = 'S'
    url = `/skill_set/${knowledge.uuid}`
  }
  return (
    <BaseAttachment initial={initial} url={url}>
      {knowledge.type}
    </BaseAttachment>
  )
}

interface MessageBaseProps {
  actor: string
  agentFirstName?: string
  agentImage?: React.ReactNode
  createdAt: string
  children: React.ReactNode
  showVoting: boolean
  showName: boolean
  onThumbsUp?: () => void
  onThumbsDown?: () => void
  isThumbsUp?: boolean | null
  notes?: Note[]
  fileAttachments?: FileAttachment[]
  knowledge?: Knowledge[]
}

const MessageBase: React.FC<MessageBaseProps> = ({
  actor,
  agentFirstName,
  agentImage,
  createdAt,
  children,
  showVoting,
  showName,
  onThumbsUp,
  onThumbsDown,
  isThumbsUp,
  notes,
  fileAttachments,
  knowledge,
}) => {
  const isUser = actor.toLowerCase() != 'system'
  return (
    <div className={clsx('flex flex-col', isUser ? 'items-end' : 'items-start')}>
      {showName && (
        <div className="flex items-center space-x-2 mb-4">
          {!isUser && agentImage}
          <span className="text-sm font-semibold text-gray-500">
            {isUser ? 'you' : agentFirstName}
          </span>
          {isUser && <UserIcon className="h-5 w-5 text-gray-400" />}
        </div>
      )}
      <div
        className={`px-4 py-2 rounded-lg ${
          isUser ? 'bg-[rgb(237,243,253)] text-gray-800' : 'bg-gray-100 text-gray-800'
        }`}
      >
        {children}
      </div>
      <div className="flex flex-wrap gap-1 mt-2">
        {notes?.map((note) => (
          <NoteComponent key={note.uuid} note={note} />
        ))}
        {fileAttachments?.map((fileAttachment) => (
          <FileAttachmentComponent key={fileAttachment.uuid} fileAttachment={fileAttachment} />
        ))}
        {knowledge?.map((knowledge) => (
          <KnowledgeComponent key={knowledge.uuid} knowledge={knowledge} />
        ))}
      </div>
      <div className="flex items-center space-x-4 mt-1">
        <span className="text-xs text-gray-500">{getRelativeTimeString(createdAt)}</span>
        {!isUser && showVoting && (
          <>
            <HandThumbUpIcon
              onClick={onThumbsUp}
              className={clsx(
                isThumbsUp === true
                  ? 'text-green-600'
                  : 'text-gray-400 hover:text-gray-500 cursor-pointer',
                'h-4 w-4'
              )}
            />
            <HandThumbDownIcon
              onClick={onThumbsDown}
              className={clsx(
                isThumbsUp === false
                  ? 'text-red-600'
                  : 'text-gray-400 hover:text-gray-500 cursor-pointer',
                'h-4 w-4'
              )}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default MessageBase
