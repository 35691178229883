import React from 'react'
import * as queryString from 'query-string'
import { useExtensionDetector } from '../../App/hooks/useExtensionDetector'
import Countdown from 'react-countdown'
import { CheckCircleIcon, ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import { useAcceptPrivacyConsent, useNotifyAboutInstallation } from '../useUserStatus'
import LaunchSteps from '../LaunchSteps'
import LaunchAuth from '../Launch/Auth'

type LaunchProps = {
  refetch: () => void
  isConsented: boolean
  isLoggedIn: boolean
  isExtensionInstalled: boolean
}

const LaunchCard: React.FunctionComponent<LaunchProps> = ({
  isConsented,
  isLoggedIn,
  isExtensionInstalled,
  refetch,
}) => {
  const [currentStep, setCurrentStep] = React.useState(isConsented ? 1 : 0)
  const [isPrivacyAccepted, setPrivacyAccepted] = React.useState(isConsented)
  const [initialLoggedIn] = React.useState(isLoggedIn)
  const { url } = queryString.parse(location.search)
  const detected = useExtensionDetector()
  const { loading, acceptPrivacyConsent } = useAcceptPrivacyConsent()
  const { notifyAboutInstallation } = useNotifyAboutInstallation()

  React.useEffect(() => {
    if (detected && currentStep === 1) {
      setCurrentStep(2)
    }
    if (detected && !isExtensionInstalled) {
      notifyAboutInstallation().then(() => refetch())
    }
  }, [detected])

  const downloadExtension = () => {
    window.open(
      'https://chrome.google.com/webstore/detail/ckfldhejolipdmhhmofaandhaimbcbdn',
      '_target'
    )
  }

  const handleComplete = () => {
    typeof url === 'string' && window.open(url, '_self')
  }

  const allSteps = [
    {
      content: (
        <>
          <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">
            We value your privacy!
          </h3>
          <p className="mt-1 text-sm text-gray-500 text-justify">
            We and our partners use technology such as cookies on our site to personalise content
            and analyse our traffic.
          </p>
          <p className="mt-1 text-sm text-gray-500 text-justify">
            Click below to consent to the use of this technology across the web. You can change your
            mind and change your consent choices at anytime be returning to this site.
          </p>
          <p className="mt-1 text-sm text-gray-500 text-justify w-full">
            Check our{' '}
            <a
              className="text-blue-500 hover:text-blue-700"
              href="/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            to see what data we are collecting.
          </p>
          <div className="relative flex items-start mt-5">
            <div className="flex items-center h-5">
              <input
                disabled={isConsented}
                id="offers"
                aria-describedby="offers-description"
                name="offers"
                type="checkbox"
                className="focus:ring-flowmo-orange-500 h-4 w-4 text-flowmo-orange-600 border-gray-300 rounded disabled:opacity-50"
                checked={isPrivacyAccepted || isConsented}
                onChange={(event) => setPrivacyAccepted(event.target.checked)}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="offers" className="font-medium text-gray-700">
                I agree to FlowMo Privacy Policy
              </label>
            </div>
          </div>
        </>
      ),
    },
    {
      content: (
        <>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">
              Install the Browser Extension
            </h3>
            <p className="mt-1 text-sm text-gray-500 text-justify">
              In order to use the Live Learning mode, you need to download the FlowMo browser
              extension in the Chrome Browser.
            </p>
          </div>
          <div className="flex justify-center">
            {!detected ? (
              <button
                type="button"
                onClick={downloadExtension}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-flowmo-orange-600 hover:bg-flowmo-orange-700 focus:outline-none"
              >
                <ArrowDownTrayIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Install
              </button>
            ) : (
              <CheckCircleIcon className="h-20 w-20 text-flowmo-orange-500" />
            )}
          </div>
        </>
      ),
    },
    {
      content: !isLoggedIn ? (
        <LaunchAuth callback={refetch} />
      ) : (
        <>
          <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">Sign In</h3>
          <p className="mt-1 text-sm text-gray-500 text-justify">
            Thanks, You are successfully signed in!
          </p>
          <div className="flex justify-center">
            <CheckCircleIcon className="h-20 w-20 text-flowmo-orange-500" />
          </div>
        </>
      ),
      skip: isLoggedIn && initialLoggedIn,
      additionalButtons: !url &&
        isLoggedIn && [
          <button
            key={1}
            type="button"
            onClick={() => {
              window.open(`${window.location.origin}/app/home`, '_self')
            }}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-flowmo-orange-600 hover:bg-flowmo-orange-700 focus:outline-none ml-3 w-1/4 justify-center align-middle disabled:opacity-50"
          >
            {loading && (
              <div
                style={{ borderTopColor: 'white' }}
                className="animate-spin bg-flowmo-orange-600 rounded-full border-4 border-t-4 border-gray-200 -ml-1 mr-2 h-5 w-5"
              />
            )}
            Finish
          </button>,
        ],
    },
    {
      content: (
        <>
          <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">
            You&apos;re all set!
          </h3>
          <p className="mt-1 text-sm text-gray-500 text-justify">
            Thanks for setting up FlowMo. You can now use our guided walkthroughs to learn!
          </p>
          <>
            <div className="flex justify-center">
              <CheckCircleIcon className="h-20 w-20 text-flowmo-orange-500" />
            </div>
            <div className="mt-3 break-words text-sm text-gray-500 text-center">
              You will be redirected automatically in{' '}
              <Countdown
                date={Date.now() + 10000}
                onComplete={handleComplete}
                renderer={({ seconds }) => {
                  return <span className="font-bold text-gray-900">{seconds}</span>
                }}
              />{' '}
              seconds.
              <br />
              <br />
              If your redirect doesn&apos;t happen, please click here:{' '}
              <a className="text-blue-500 hover:text-blue-700" href={`${url}`}>
                {url}
              </a>
              .
            </div>
          </>
        </>
      ),
      skip: !url,
    },
  ]

  const onFirstStepCompleted = () => {
    acceptPrivacyConsent().then(() => refetch())
  }

  const handleNext = () => {
    if (currentStep === 0 && !isConsented) {
      onFirstStepCompleted()
    }

    setCurrentStep(currentStep + 1)
  }

  const handlePrev = () => {
    setCurrentStep(currentStep - 1)
  }

  const nextButtonDisabled =
    (currentStep === 0 && !isPrivacyAccepted) ||
    (currentStep === 1 && !detected) ||
    loading ||
    (currentStep > 1 && !isLoggedIn)

  const steps = allSteps.filter((step) => !step.skip)

  return (
    <div className="shadow rounded-xl bg-white p-6">
      <LaunchSteps steps={steps} current={currentStep} />
      <div
        style={{ minHeight: '305px' }}
        className="flex flex-col items-center justify-center mt-2"
      >
        {steps[currentStep].content}
      </div>
      <div className="w-full flex justify-end mt-5">
        {currentStep > 0 && (
          <button
            onClick={handlePrev}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none w-1/4 justify-center"
          >
            Previous
          </button>
        )}
        {currentStep < steps.length - 1 && (
          <button
            type="button"
            disabled={nextButtonDisabled}
            onClick={handleNext}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-flowmo-orange-600 hover:bg-flowmo-orange-700 focus:outline-none ml-3 w-1/4 justify-center align-middle disabled:opacity-50"
          >
            {loading && (
              <div
                style={{ borderTopColor: 'white' }}
                className="animate-spin bg-flowmo-orange-600 rounded-full border-4 border-t-4 border-gray-200 -ml-1 mr-2 h-5 w-5"
              />
            )}
            Next
          </button>
        )}
        {steps[currentStep].additionalButtons &&
          steps[currentStep].additionalButtons.map((component, index) => (
            <React.Fragment key={index}>{component}</React.Fragment>
          ))}
      </div>
    </div>
  )
}

export default LaunchCard
