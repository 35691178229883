import React from 'react'
import ToolbarContainer from './ToolbarContainer'
import CancelButton from './buttons/CancelButton'
import SaveButton from './buttons/SaveButton'

interface Props {
  backRoute: string
}

const Toolbar: React.FC<Props> = ({ backRoute }) => (
  <ToolbarContainer>
    <CancelButton backRoute={backRoute} />
    <SaveButton />
  </ToolbarContainer>
)

export default Toolbar
