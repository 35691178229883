import { AcademicCapIcon, BookOpenIcon } from '@heroicons/react/24/solid'
import React from 'react'
import CreateButton from '../../SkillSet/Toolbar/CreateButton'
import { Link } from 'react-router-dom'

const links = [
  {
    path: '/shared_with_me',
    name: 'Shared With Me',
  },
  {
    path: '/recently_viewed',
    name: 'Recently Viewed',
  },
  {
    path: '/completed',
    name: 'Completed',
  },
]

const libraries = [
  {
    name: 'My Library',
    path: '/my_library',
    icon: (
      <BookOpenIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />
    ),
  },
  {
    name: 'Organization Library',
    path: '/organization_library',
    icon: (
      <AcademicCapIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />
    ),
  },
]

const InternalLayout = () => {
  return (
    <>
      <div>
        <CreateButton />
      </div>
      <div className="py-8 space-y-1">
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.path}
            className="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
          >
            <span className="truncate">{link.name}</span>
          </Link>
        ))}
      </div>
      <div className="pt-10">
        <p className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
          Libraries
        </p>
        <div className="mt-3 space-y-2">
          {libraries.map((library, index) => (
            <Link
              key={index}
              to={library.path}
              className="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
            >
              {library.icon}
              <span className="truncate">{library.name}</span>
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}

export default InternalLayout
