import { useQuery } from '@apollo/client'
import { GET_WALKTHROUGH_QUERY } from '../../App/graphql/queries/walkthrough'
import { CREATED_CONTENT_QUERY, GET_ORGANIZATION_CONTENT } from '../../App/graphql/queries/content'
import {
  GetCreatedContentQueryVariables,
  GetCreatedContentQuery,
  WalkthroughByUuidQuery,
  WalkthroughByUuidQueryVariables,
  ContentType,
} from '../../graphql'

const useWalkthroughQuery = (uuid: string, usePublicEndpoint: boolean) => {
  const props = useQuery<WalkthroughByUuidQuery, WalkthroughByUuidQueryVariables>(
    GET_WALKTHROUGH_QUERY,
    {
      variables: { uuid },
    }
  )

  const { data } =
    !usePublicEndpoint &&
    useQuery<GetCreatedContentQuery, GetCreatedContentQueryVariables>(
      props.data?.walkthrough && props.data?.walkthrough.isInMyLibrary
        ? CREATED_CONTENT_QUERY
        : GET_ORGANIZATION_CONTENT,
      {
        variables: {
          contentType: [ContentType.SkillSet],
          sortDirection: 'DESC',
          sortBy: 'created_at',
        },
      }
    )

  const skillSets = data && data.items

  return { ...props, walkthrough: props.data?.walkthrough, skillSets }
}

export default useWalkthroughQuery
