import React from 'react'
import { Field } from 'formik'
import Input from './index'

interface InputField extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

export const InputField: React.FC<InputField> = ({
  label,
  placeholder,
  className,
  disabled,
  ...props
}) => {
  return (
    <div className={className}>
      <Field {...props}>
        {({ field, meta }: any) => {
          return (
            <Input
              {...field}
              disabled={disabled}
              label={label}
              placeholder={placeholder}
              validate={meta.touched && meta.error && 'error'}
            />
          )
        }}
      </Field>
    </div>
  )
}
