import React from 'react'
import * as style from './style.module.scss'
import clsx from 'clsx'
export interface Props {
  elementRef: any
  className?: string
}

const Circles = ({ elementRef, className }: Props) => {
  return (
    <div className={clsx(style['circles'], className)} ref={elementRef}>
      <svg
        viewBox="0 0 600 600"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
      >
        <circle id={style['limit-line']} cx="300" cy="300" r="250" />
        <circle id={style['core']} cx="300" cy="300" r="50" />
        <circle id={style['mover1']} cx="300" cy="300" r="60" />
        <circle id={style['mover2']} cx="300" cy="300" r="70" />
        <circle id={style['mover3']} cx="300" cy="300" r="80" />
        <circle id={style['mover4']} cx="300" cy="300" r="90" />
        <circle id={style['core-outline']} cx="300" cy="300" r="70" />
      </svg>
    </div>
  )
}

export default Circles
