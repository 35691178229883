import React from 'react'
import AudioPageLayout from './containers/Layout'
import { DeniedScreen, GrantedScreen, PromptScreen } from './screens'
import { DeniedIcon, GrantedIcon, PromptIcon } from './icons'
import Divider from '../../common/ui/Divider'

export const AUDIO_SCREENS: any = {
  granted: {
    text: `Access granted successfully. Do not close this page while the recording walkthrough is in progress`,
    mockup: <GrantedScreen />,
    icon: <GrantedIcon />,
  },
  denied: {
    text: 'Access is not granted for now. Follow those steps to enable permissions',
    mockup: <DeniedScreen />,
    icon: <DeniedIcon />,
  },
  prompt: {
    text: 'Please choose the "Allow" button in the popup windows to enable permissions',
    mockup: <PromptScreen />,
    icon: <PromptIcon />,
  },
}

const AudioApp = () => {
  const [permissionState, setPermissionState] = React.useState(null)
  const screen = React.useMemo(() => AUDIO_SCREENS[permissionState], [permissionState])

  React.useEffect(() => {
    navigator?.permissions?.query({ name: 'microphone' } as any).then(function (permissionStatus) {
      setPermissionState(permissionStatus.state)

      permissionStatus.onchange = function () {
        setPermissionState(this.state)
      }
    })
  }, [])

  if (!screen) {
    return <></>
  }

  return (
    <AudioPageLayout>
      <div className="flex w-full items-center flex-col">
        <div className="flex mb-4 w-[632px] px-4">
          <div className="h-full flex items-center">{screen.icon}</div>
          <Divider className="border-r min-h-full mx-4" />
          <p className="text-lg text-[#434E5F] font-medium">{screen.text}</p>
        </div>
        <div>{screen.mockup}</div>
      </div>
    </AudioPageLayout>
  )
}

export default AudioApp
