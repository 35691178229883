import React, { createContext, useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import * as styles from './style.module.scss'
import useWindowSize from '../../../../hooks/useWindowSize'

type ResizeProps = React.PropsWithChildren & {
  rrwebScreenSize?: SizeProps
  enabledFullscreen: boolean
  maxHeight?: number
}

export type ResizeContextProps = {
  width?: number
  height?: number
  scale: number
  root?: HTMLElement
  resize: (size: SizeProps) => void
  size: SizeProps
  fullscreen: boolean
  fullscreenToggle: () => void
}

export type SizeProps = {
  width: number
  height: number
}

export const ResizeContext = createContext<ResizeContextProps>({
  width: 0,
  height: 0,
  scale: 1,
  size: {
    width: 0,
    height: 0,
  },
  resize: (size) => size,
  fullscreen: false,
  fullscreenToggle: () => undefined,
})

const DEFAULT_SIZE: SizeProps = {
  width: 1920,
  height: 1080,
}

const Resize: React.FC<ResizeProps> = ({
  children,
  rrwebScreenSize,
  enabledFullscreen,
  ...props
}) => {
  const [size, setSize] = useState<SizeProps>(rrwebScreenSize || DEFAULT_SIZE)
  const [fullscreen, setFullscreen] = useState<boolean>(!!document.fullscreenElement)

  const parent = useRef<HTMLDivElement | null>(null)
  const { width, height, ref } = useResizeDetector()
  const win = useWindowSize()
  const [scale, setScale] = useState<number>(1)

  const maxHeight = useMemo(() => {
    if (fullscreen && enabledFullscreen) {
      return win.height
    }

    return props.maxHeight || win.height
  }, [props.maxHeight, win.height, fullscreen])

  useEffect(() => {
    if (width) {
      const scale = width / size.width
      if (size.height * scale > maxHeight) {
        setScale(maxHeight / size.height)
      } else {
        setScale(scale)
      }
    }
  }, [width, height, size, win])

  useEffect(() => {
    const fullscreenchanged = () => {
      setFullscreen(!!document.fullscreenElement)
    }

    document.addEventListener('fullscreenchange', fullscreenchanged)

    return () => {
      document.removeEventListener('fullscreenchange', fullscreenchanged)
    }
  }, [])

  useEffect(() => {
    if (enabledFullscreen) {
      if (fullscreen) {
        parent.current &&
          parent.current.requestFullscreen().then(() => setFullscreen(!!document.fullscreenElement))
      } else if (document.exitFullscreen) {
        document.fullscreenElement && document.exitFullscreen().then(() => undefined)
      }
    }
  }, [fullscreen])

  const resize = useCallback(setSize, [])
  const fullscreenToggle = useCallback(() => setFullscreen((prevState) => !prevState), [])

  const value = useMemo(
    () => ({ width, height, scale, resize, fullscreen, fullscreenToggle, size, root: ref.current }),
    [width, height, scale, fullscreen, ref.current]
  )
  const style = useMemo(
    () => ({
      height: width ? size.height * scale + 'px' : 'auto',
    }),
    [scale, size, width]
  )

  return (
    <ResizeContext.Provider value={value}>
      <div ref={parent} className={styles['flow-mo-full-screen']}>
        <div ref={ref} className={styles['flow-mo-player-wrapper']} style={style}>
          {ref.current && children}
        </div>
      </div>
    </ResizeContext.Provider>
  )
}
export default Resize
