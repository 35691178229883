import { useQuery } from '@apollo/client'
import React from 'react'
import { GET_ANALYSES_QUERY } from '../../graphql/queries/analysis'
import PageHeader from '../../components/PageHeader'
import { Link } from 'react-router-dom'
import HeaderActionButton from '../../components/HeaderActionButton'
import Spin from '../../../../components/common/ui/Spin'
import EmptyState from '../../../Walkthrough/ui/EmptyState'
import AnalysisCard from '../../components/AnalysisCard'
import { AnalysesQuery, AnalysesQueryVariables } from 'app/javascript/components/graphql'

const Analyses = () => {
  const { data, loading } = useQuery<AnalysesQuery, AnalysesQueryVariables>(GET_ANALYSES_QUERY, {
    fetchPolicy: 'network-only',
  })
  const analyses = data?.analyses

  return (
    <React.Fragment>
      <PageHeader title="Analyses" breadCrumbs={[{ title: 'Analyses' }]}>
        <Link to="/analysis/create">
          <HeaderActionButton label="Create" />
        </Link>
      </PageHeader>
      {loading ? (
        <Spin />
      ) : (
        <>
          {analyses?.length === 0 ? (
            <EmptyState title="No analyses yet" description="Create your first analysis" />
          ) : (
            <div className="flex flex-wrap w-full">
              {analyses?.map((analysis) => (
                <AnalysisCard key={analysis.uuid} analysis={analysis} size={'large'} />
              ))}
            </div>
          )}
        </>
      )}
    </React.Fragment>
  )
}
export default Analyses
