import React, { useEffect, useMemo, useRef, useState } from 'react'
import AudioDropdown from './AudioDropdown'
import { Mic, Play, MicOff, X } from 'lucide-react'

interface AudioRecorderProps {
  onAudioData: (audioData: Float32Array) => void
  isRecording: boolean
  setIsRecording: (isRecording: boolean) => void
  timeLeft: number
  timeLimit: number
}

const AudioRecorder = ({
  onAudioData,
  isRecording,
  setIsRecording,
  timeLeft,
  timeLimit,
}: AudioRecorderProps) => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const [selectedDevice, setSelectedDevice] = useState<string>('')
  const [selectedDeviceName, setSelectedDeviceName] = useState('')
  const [isInputDropdownOpen, setIsInputDropdownOpen] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [audioLevel, setAudioLevel] = useState<number[]>(new Array(15).fill(0))
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const audioContextRef = useRef<AudioContext | null>(null)
  const analyserRef = useRef<AnalyserNode | null>(null)
  const scriptProcessorRef = useRef<ScriptProcessorNode | null>(null)
  const animationFrameRef = useRef<number>()
  const timerRef = useRef<NodeJS.Timeout>()
  const inputDropdownRef = useRef<HTMLDivElement>(null)
  const streamRef = useRef<MediaStream | null>(null)

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const audioDevices = devices.filter((device) => device.kind === 'audioinput')
      setDevices(audioDevices)
      if (audioDevices.length > 0) {
        setSelectedDevice(audioDevices[0].deviceId)
        setSelectedDeviceName(audioDevices[0].label || 'Default')
      }
    })

    const handleClickOutside = (event: MouseEvent) => {
      if (inputDropdownRef.current && !inputDropdownRef.current.contains(event.target as Node)) {
        setIsInputDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // Update audio processing when recording or mute state changes
  useEffect(() => {
    if (isRecording && streamRef.current && audioContextRef.current) {
      setupAudioProcessing(streamRef.current, audioContextRef.current)
    }
  }, [isMuted, isRecording])

  const setupAudioProcessing = (stream: MediaStream, audioContext: AudioContext) => {
    const source = audioContext.createMediaStreamSource(stream)

    // Create analyser for visualization
    if (!analyserRef.current) {
      analyserRef.current = audioContext.createAnalyser()
      analyserRef.current.fftSize = 64
    }

    // Create script processor for audio data
    if (!scriptProcessorRef.current) {
      scriptProcessorRef.current = audioContext.createScriptProcessor(2048, 1, 1)
    }

    // Disconnect any existing connections
    source.disconnect()
    if (scriptProcessorRef.current) {
      scriptProcessorRef.current.disconnect()
    }
    if (analyserRef.current) {
      analyserRef.current.disconnect()
    }

    // Set up new audio processing chain
    source.connect(analyserRef.current)

    // Only process audio data when recording and not muted
    if (isRecording && !isMuted) {
      scriptProcessorRef.current.connect(audioContext.destination)
      source.connect(scriptProcessorRef.current)

      scriptProcessorRef.current.onaudioprocess = (e) => {
        const inputData = e.inputBuffer.getChannelData(0)

        // if the input data is empty, don't process it
        // if there are a bunch of 0s, don't process it
        if (inputData.length === 0 || inputData.every((value) => value === 0)) {
          return
        }

        onAudioData(inputData)
      }
    }
  }

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: selectedDevice },
      })
      streamRef.current = stream

      // @ts-ignore
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 24000,
      })
      setupAudioProcessing(stream, audioContextRef.current)

      // Start audio level monitoring
      const bufferLength = analyserRef.current?.frequencyBinCount || 0

      const updateLevel = () => {
        if (!analyserRef.current || isMuted) {
          setAudioLevel(new Array(15).fill(0))
        } else {
          const dataArray = new Uint8Array(bufferLength)
          analyserRef.current.getByteFrequencyData(dataArray)
          setAudioLevel(Array.from(dataArray.slice(0, 15)))
        }
        animationFrameRef.current = requestAnimationFrame(updateLevel)
      }

      updateLevel()

      mediaRecorderRef.current = new MediaRecorder(stream)
      mediaRecorderRef.current.start()
      setIsRecording(true)
    } catch (err) {
      console.error('Error starting recording:', err)
    }
  }

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop()
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop())
      setIsRecording(false)

      // Disconnect audio processing when stopping recording
      if (scriptProcessorRef.current) {
        scriptProcessorRef.current.disconnect()
        scriptProcessorRef.current = null
      }
      if (analyserRef.current) {
        analyserRef.current.disconnect()
        analyserRef.current = null
      }
      if (audioContextRef.current) {
        audioContextRef.current.close()
        audioContextRef.current = null
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop())
        streamRef.current = null
      }

      // Cancel animation frame
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
      }

      // Reset audio level
      setAudioLevel(new Array(15).fill(0))
    }
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
  }

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
  }

  const currentAudioLevel = useMemo(() => {
    if (isRecording && !isMuted) {
      return audioLevel
    }
    return new Array(15).fill(50)
  }, [isRecording, isMuted, audioLevel])

  useEffect(() => {
    if (timeLeft <= 0) {
      stopRecording()
    }
  }, [timeLeft])

  return (
    <div className="flex flex-col bg-gray-800 rounded-lg p-2 max-w-sm">
      <div className="flex flex-row items-center gap-2">
        <div className="text-nowrap flex-nowrap">
          {!isRecording ? (
            <button
              onClick={startRecording}
              className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md flex items-center gap-2 text-sm ease-in-out transition-all"
            >
              <Play className="w-4 h-4" />
              Start Recording
            </button>
          ) : (
            <div className="flex items-center gap-3 bg-gray-900 px-3 py-2 rounded-md text-sm text-gray-300 w-64 ease-in-out transition-all">
              <span className="text-gray-400 font-mono">
                {formatTime(timeLeft)}/{formatTime(timeLimit)}
              </span>

              <div className="flex items-center h-4 gap-px flex-grow">
                {currentAudioLevel.map((value, index) => (
                  <div
                    key={index}
                    className="w-0.5 bg-gray-600"
                    style={{
                      height: `${(value / 255) * 16}px`,
                    }}
                  />
                ))}
              </div>

              <div className="flex items-center gap-2">
                <button
                  className="text-gray-400 hover:text-white transition-colors"
                  onClick={() => {
                    setIsMuted(!isMuted)
                  }}
                >
                  {isMuted ? <MicOff className="w-4 h-4" /> : <Mic className="w-4 h-4" />}
                </button>

                <button
                  onClick={stopRecording}
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="flex-grow">
          <AudioDropdown
            devices={devices}
            selectedName={selectedDeviceName}
            onSelect={(deviceId, name) => {
              setSelectedDevice(deviceId)
              setSelectedDeviceName(name)
            }}
            isOpen={isInputDropdownOpen}
            setIsOpen={setIsInputDropdownOpen}
            dropdownRef={inputDropdownRef}
            isMuted={isMuted}
            onMuteToggle={() => setIsMuted(!isMuted)}
            audioLevel={audioLevel}
          />
        </div>
      </div>
    </div>
  )
}

export default AudioRecorder
