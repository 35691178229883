import React from 'react'
import { SkillSet } from 'app/javascript/components/graphql'
import { htmlStrip } from '../../common/utils/htmlStrip'
import Breadcrumb from '../../AI/components/Breadcrumb'

interface Props {
  skillSet: SkillSet
}

const SkillSetBreadcrumb: React.FC<Props> = ({ skillSet }) => {
  let title
  let path

  // if in my library
  if (skillSet.isInMyLibrary) {
    title = 'My Library'
    path = 'my_library'
  } else if (skillSet.isInMyOrganizationLibrary) {
    title = 'Organization Library'
    path = 'organization_library'
  } else {
    title = 'Shared With Me'
    path = 'shared_with_me'
  }

  return (
    <Breadcrumb pages={[{ title: title, to: `/${path}` }, { title: htmlStrip(skillSet.name) }]} />
  )
}

export default SkillSetBreadcrumb
