import React from 'react'
import { GET_AGENT_QUERY } from '../../graphql/queries/agent'
import { AgentQuery, AgentQueryVariables } from 'app/javascript/components/graphql'
import LinearProgress from '@mui/material/LinearProgress'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { Bot } from 'lucide-react'

const AgentCard: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { loading, data } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    variables: { uuid },
  })
  if (loading) {
    return <LinearProgress />
  }
  const agent = data?.agent
  if (!agent) {
    return <></>
  }
  return (
    <Link
      to={`/agent/${uuid}`}
      className="flex flex-row items-center gap-2 p-2 rounded-full border border-gray-200 cursor-pointer hover:shadow-md text-gray-500"
    >
      <Bot className="w-4 h-4" />
      <div className="text-xs font-semibold">{agent.label}</div>
    </Link>
  )
}

export default AgentCard
