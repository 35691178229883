import React, { useState } from 'react'
import CardContainer from './Container'
import CardThumbnail from './Thumbnail'
import CardContent from './Content'
import CardTitle from './Title'
import { getThumbnailPublicId, getThumbnailUrl } from '../../../utils/walkthrough'
import CardWrapper from './Wrapper'
import CardDescription from './Description'
import CardTags from './Tags'
import useDeleteWalkthroughMutation from '../../../hooks/useDeleteWalkthroughMutation'
import client from '../../../../common/ApolloClient'
import ConfirmDialog from '../../ConfirmDialog'
import useMoveWalkthroughMutation from '../../../hooks/useMoveWalkthroughMutation'
import { DestinationType, SkillSet } from 'app/javascript/components/graphql'
import AddToSkillSetMenuItem from '../../../../common/components/Menu/MenuItem/AddToSkillSetItem'
import RemoveFromSkillSetItem from '../../../../common/components/Menu/MenuItem/RemoveFromSkillSetItem'
import { Link, useHistory } from 'react-router-dom'
import CardMenu from '../../../../common/components/Menu'
import ShareMenuItem from '../../../../common/components/Menu/MenuItem/ShareItem'
import DeleteMenuItem from '../../../../common/components/Menu/MenuItem/DeleteItem'
import MoveMenuItem from '../../../../common/components/Menu/MenuItem/MoveItem'
import ShareDialog from '../../ShareDialog'
// @ts-ignore
import ReactHtmlParser from 'react-html-parser'
import { htmlStrip } from '../../../../common/utils/htmlStrip'
import AddToAgentItem from '../../../../../components/common/components/Menu/MenuItem/AddToAgentItem'

interface Props {
  walkthrough: any
  isPublic?: boolean
  skillSets?: SkillSet[]
}

const Card: React.FC<Props> = ({ walkthrough, skillSets }) => {
  const history = useHistory()
  const [showShareModal, setShowShareModal] = useState(false)
  const toggleShareModal = () => setShowShareModal(!showShareModal)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)

  const [deleteWalkthrough] = useDeleteWalkthroughMutation()
  const [moveWalkthrough] = useMoveWalkthroughMutation()

  const onDeleteAccept = () => {
    toggleDeleteModal()

    deleteWalkthrough({
      variables: { id: walkthrough.id },
    }).then(() => {
      client.resetStore()
      window.toastr.success('The Walkthrough was deleted successfully')
    })
  }

  const onMove = (variables: { destinationType: DestinationType; destinationId?: string }) => {
    moveWalkthrough({
      variables: {
        id: walkthrough.id,
        destinationType: variables.destinationType,
        destinationId: variables.destinationId,
      },
    }).then(({ data }) => {
      if (data.walkthrough.isInMyLibrary) {
        window.toastr.success('The Walkthrough was moved successfully to your library')
      } else if (data.walkthrough.isInMyOrganizationLibrary) {
        window.toastr.success('The Walkthrough was moved successfully to organization library')
      } else {
        window.toastr.success('The Walkthrough was moved successfully to Skill Set')
      }
      client.resetStore()
    })
  }

  const handleAddToAgent = () => {
    history.push(`/walkthrough/${walkthrough.uuid}/add_to_agent`)
  }

  const publicId = getThumbnailPublicId(walkthrough)
  const thumbnailUrl = publicId && getThumbnailUrl(publicId)

  const href = `/walkthrough/${walkthrough.uuid}`

  const menuItems = [
    <AddToAgentItem onClick={handleAddToAgent} key="add_to_agent" />,
    <ShareMenuItem onClick={toggleShareModal} key="share" />,
    <DeleteMenuItem onClick={toggleDeleteModal} key="delete" />,
  ]

  if (walkthrough.canMove && !walkthrough.skillSet) {
    menuItems.splice(1, 0, <MoveMenuItem onMove={onMove} key="move" subject={walkthrough} />)
  }

  skillSets?.length &&
    menuItems.splice(
      1,
      0,
      <AddToSkillSetMenuItem
        onAddToSkillSet={onMove}
        key="move to Skill Set"
        walkthrough={walkthrough}
        skillSets={skillSets}
      />
    )

  walkthrough.skillSet &&
    menuItems.splice(1, 0, <RemoveFromSkillSetItem onMove={onMove} key="remove" />)

  const cardTitle = walkthrough.canEdit ? (
    <div className="flex w-full">
      <CardTitle>{htmlStrip(walkthrough.name)}</CardTitle>
      <CardMenu key={walkthrough.id} items={menuItems} />
    </div>
  ) : (
    <CardTitle>{htmlStrip(walkthrough.name)}</CardTitle>
  )

  return (
    <>
      {showShareModal && (
        <ShareDialog onClose={toggleShareModal} object={walkthrough} objectType="walkthrough" />
      )}

      <ConfirmDialog
        title="Delete the Walkthrough?"
        description="Are you sure that you want to delete the Walkthrough? This can't be undone!"
        onAccept={onDeleteAccept}
        onCancel={toggleDeleteModal}
        actionLabel="Delete"
        cancelLabel="Cancel"
        open={showDeleteModal}
        close={toggleDeleteModal}
      />

      <CardWrapper>
        <Link to={href}>
          <CardContainer>
            <CardThumbnail url={thumbnailUrl} />
            <CardContent>
              {cardTitle}
              <CardTags tags={walkthrough.meta.domains} />
              <CardDescription>{ReactHtmlParser(walkthrough.description)}</CardDescription>
            </CardContent>
          </CardContainer>
        </Link>
      </CardWrapper>
    </>
  )
}

export default Card
