import React from 'react'
import ModeOption from './ModeOption'
import { EyeIcon, HandIcon } from './icons'
import { useEmbedContext } from '../../context/EmbedContext'
import { ModeTypes } from '../../context/EmbedContext/types'

const modes = [
  {
    label: 'Watch it',
    icon: <EyeIcon />,
    type: ModeTypes.VIDEO,
  },
  {
    label: 'Try it',
    icon: <HandIcon />,
    type: ModeTypes.LABS,
  },
]

const ModeSelector = () => {
  const { mode, setMode } = useEmbedContext()

  const elements = modes.map(({ label, icon, type }, key) => (
    <ModeOption
      key={key}
      label={label}
      icon={icon}
      active={type === mode}
      onClick={() => setMode(type)}
    />
  ))

  return <>{elements}</>
}

export default ModeSelector
