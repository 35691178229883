import React from 'react'
import { clsx } from 'clsx'

interface Props {
  children?: React.ReactNode
  type: 'primary' | 'secondary' | 'action' | 'neutral'
  onClick?: () => void
  className?: string
}

const Styles = {
  primary: 'text-white hover:bg-flowmo-blue-700 bg-flowmo-blue-600',
  secondary: 'text-flowmo-blue-600 hover:bg-white/90 bg-white',
  action: 'text-white hover:bg-[#d96125] bg-[#EA6A29]',
  neutral: 'text-white bg-[#BDC3CF] hover:bg-gray-400',
}

const Button = ({ children, type, onClick, className = '' }: Props) => (
  <button
    onClick={onClick}
    className={clsx(
      'flex items-center justify-center py-2 px-4 border space-x-2',
      'border-transparent rounded-md shadow-sm text-sm font-medium',
      className,
      Styles[type]
    )}
  >
    {children}
  </button>
)

export default Button
