import React, { useState } from 'react'
import { hot } from 'react-hot-loader'
import { CheckIcon } from '@heroicons/react/20/solid'

const tiers = [
  {
    name: 'Just me',
    href: '/contact',
    priceMonthly: 0,
    description:
      'Give us a try and see how easy it is to create and share walkthroughs. Free forever, useful forever.',
    includedFeatures: ['1 User', '10 Walkthroughs'],
  },
  {
    name: 'Getting Started',
    href: '/contact',
    priceMonthly: 25,
    description: 'Get your team started with one flat fee that covers your core group of users.',
    includedFeatures: ['Up to 5 Users', 'Up to 50 Walkthroughs'],
  },
  {
    name: 'Teams',
    href: '/contact',
    pricePerSeatMonthly: 10,
    description:
      'We scale with you as your team grows and usage expands. Create unlimited walkthroughs, go nuts! ',
    includedFeatures: ['Unlimited Users', 'Unlimited Walkthroughs'],
  },
  {
    name: 'Enterprise',
    href: '/contact',
    description:
      "Let's talk when your 50+ member team needs advanced security, control and suport.",
    includedFeatures: [
      'Unlimited Users',
      'Unlimited Walkthroughs',
      'Walkthrough Embedding',
      'Event webhooks',
      'Advanced Support',
      'Advanced Security',
    ],
  },
]

const Pricing = () => {
  const [billingPeriod, setBillingPeriod] = useState('monthly')
  return (
    <div>
      <div className="">
        <div className="sm:pt-16">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-600 sm:text-4xl lg:text-5xl">
                <div className="">Make your life at work easier.</div>
                <div className="text-flowmo-orange-500">Start free.</div>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="sm:align-center sm:flex sm:flex-col">
            <div className="relative mt-6 flex self-center rounded-lg bg-gray-100 p-0.5 sm:mt-8">
              <button
                type="button"
                onClick={() => setBillingPeriod('monthly')}
                className={
                  'relative w-1/2 whitespace-nowrap ' +
                  (billingPeriod == 'monthly'
                    ? ' rounded-md border-gray-200 bg-white '
                    : ' border-transparent hover:bg-gray-200 ') +
                  'py-2 text-sm font-medium text-gray-900 shadow-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-flowmo-blue-500 sm:w-auto sm:px-8'
                }
              >
                Monthly billing
              </button>
              <button
                type="button"
                onClick={() => setBillingPeriod('yearly')}
                className={
                  'relative ml-0.5 w-1/2 whitespace-nowrap border ' +
                  (billingPeriod == 'yearly'
                    ? ' rounded-md border-gray-200 bg-white '
                    : ' hover:bg-gray-200 ') +
                  ' border-transparent py-2 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-2 focus:ring-flowmo-blue-500 sm:w-auto sm:px-8'
                }
              >
                Yearly billing
              </button>
            </div>
          </div>
          <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
            {tiers.map((tier) => (
              <div
                key={tier.name}
                className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm"
              >
                <div className="p-6">
                  <h2 className="text-lg font-medium leading-6 text-gray-900">{tier.name}</h2>
                  <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                  <p className="mt-8">
                    {tier.priceMonthly > -1 && tier.pricePerSeatMonthly == null && (
                      <>
                        <span className="text-4xl font-bold tracking-tight text-gray-900">
                          $
                          {billingPeriod == 'monthly' ? tier.priceMonthly : tier.priceMonthly * 0.8}
                        </span>{' '}
                        <span className="text-base font-medium text-gray-500">/mo</span>
                      </>
                    )}

                    {tier.pricePerSeatMonthly && (
                      <>
                        <span className="text-4xl font-bold tracking-tight text-gray-900">
                          $
                          {billingPeriod == 'monthly'
                            ? tier.pricePerSeatMonthly
                            : tier.pricePerSeatMonthly * 0.8}
                        </span>{' '}
                        <span className="text-base font-medium text-gray-500">/seat/mo</span>
                      </>
                    )}

                    {tier.pricePerSeatMonthly == null && tier.priceMonthly == null && (
                      <>
                        <span className="text-4xl font-bold tracking-tight text-gray-900"></span>{' '}
                        <span className="text-base font-medium text-gray-500">
                          Let&apos;s talk about it
                        </span>
                      </>
                    )}
                  </p>
                  <a
                    href={tier.href}
                    className="mt-8 block w-full rounded-md border border-flowmo-blue-800 bg-transparent py-2 text-center text-sm font-semibold text-flowmo-blue-600 hover:bg-ddu-blue hover:text-white"
                  >
                    Get started
                  </a>
                </div>
                <div className="px-6 pt-6 pb-8">
                  <h3 className="text-sm font-medium text-gray-900">What&apos;s included</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {tier.includedFeatures.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon
                          className="h-5 w-5 flex-shrink-0 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default hot(module)(Pricing)
