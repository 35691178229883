import React from 'react'
import { SkillSet } from 'app/javascript/components/graphql'
import Card from '../../../Walkthrough/ui/Walkthrough/Card'
import Spin from '../../../common/ui/Spin'
import SkillSetHeader from '../SkillSetHeader'
import EmptyState from '../../../Walkthrough/ui/EmptyState'
import useUpdateSkillSet from '../../hooks/useUpdateSkillSet'
import { Form, Formik } from 'formik'
import { SkillSetValidateSchema } from '../../../Walkthrough/utils/validateSchema'
import PublicSkillSetHeader from '../PublicSkillSetHeader'

interface Props {
  skillSet: SkillSet
  isPublicView?: boolean
}

const SkillSetViewer: React.FC<Props> = ({ skillSet, isPublicView }) => {
  const updateSkillSet = useUpdateSkillSet(skillSet)

  const header = isPublicView ? (
    <PublicSkillSetHeader skillSet={skillSet} />
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...skillSet }}
      onSubmit={updateSkillSet}
      validationSchema={SkillSetValidateSchema}
      validateOnMount
    >
      {() => (
        <Form>
          <SkillSetHeader
            skillSet={skillSet}
            isPublicView={isPublicView}
            walkthroughsCount={skillSet.walkthroughs.length}
          />
        </Form>
      )}
    </Formik>
  )

  return (
    <>
      <div className="m-auto">
        {header}

        <div className="max-w-7xl mx-auto">
          {!skillSet && <Spin className="h-20 w-20 text-ddu-blue m-auto mt-6" />}

          {skillSet.walkthroughs.length ? (
            <div className="m-auto mt-8 px-8">
              <ul className="inter-font grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 mb-10">
                {skillSet.walkthroughs.map((walkthrough: any, index: number) => (
                  <Card walkthrough={walkthrough} key={index} isPublic={isPublicView} />
                ))}
              </ul>
            </div>
          ) : (
            <div>
              <EmptyState
                description="Add a new Walkthrough by clicking the 'Create' button or add existing Walkthroughs from one of your libraries."
                className="mt-20"
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SkillSetViewer
