import { gql } from '@apollo/client'

export const CREATE_USER_MUTATION = gql`
  mutation createUser(
    $email: String!
    $firstName: String
    $lastName: String
    $organization: ID!
    $roles: [ID]
    $password: String
  ) {
    data: createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      organization: $organization
      roles: $roles
      password: $password
    ) {
      id
      email
      firstName
      lastName
      shortName
      longName
    }
  }
`

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $id: ID!
    $email: String!
    $firstName: String
    $lastName: String
    $roles: [ID]
    $password: String
  ) {
    data: updateUser(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      roles: $roles
      password: $password
    ) {
      id
      email
      firstName
      lastName
      shortName
      longName
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    data: deleteUser(id: $id) {
      id
    }
  }
`
