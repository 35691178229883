import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Layout from '../../Walkthrough/layout'
import WalkthroughShow from '../../Walkthrough/ui/Walkthrough/Show'
import SkillSetShow from '../../SkillSet/ui/SkillSet/Show'

const AppRouter = () => {
  return (
    <Switch>
      <Layout>
        <Route
          exact
          path="/walkthrough/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          render={(props) => <WalkthroughShow uuid={props.match.params.uuid} />}
        />

        <Route
          exact
          path="/skill_set/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          render={(props) => <SkillSetShow uuid={props.match.params.uuid} />}
        />
      </Layout>

      <Redirect to="/home" />
    </Switch>
  )
}

export default AppRouter
