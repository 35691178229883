import { gql } from '@apollo/client'

export const CREATE_LEARNING_ACTIVITY = gql`
  mutation createLearningActivity(
    $subjectType: LearningActivitySubjectType
    $subjectId: ID
    $objectType: LearningActivityObjectType!
    $action: LearningActivityActionType!
    $objectId: ID!
  ) {
    item: createLearningActivity(
      objectType: $objectType
      action: $action
      objectId: $objectId
      subjectId: $subjectId
      subjectType: $subjectType
    ) {
      id
      objectId
      objectType
      action
      subjectType
      subjectId
    }
  }
`
