import { gql } from '@apollo/client'

export const UPDATE_CREDENTIAL_MUTATION = gql`
  mutation updateDataCredentials($uuid: String!, $credentials: JSON!) {
    updateDataCredentials(uuid: $uuid, credentials: $credentials) {
      errors
      success
    }
  }
`
export const GET_CREDENTIALS = gql`
  query dataCredentials {
    dataCredentials {
      credentials
      credentialType
      uuid
    }
  }
`

export const CREATE_CREDENTIAL_MUTATION = gql`
  mutation createDataCredentials($credentials: JSON!, $credentialType: String!) {
    createDataCredentials(credentials: $credentials, credentialType: $credentialType) {
      errors
      success
    }
  }
`

export const DELETE_CREDENTIAL_MUTATION = gql`
  mutation deleteDataCredentials($uuid: String!) {
    deleteDataCredentials(uuid: $uuid) {
      errors
      success
    }
  }
`
