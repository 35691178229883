import React, { Fragment, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import client from '../../../../../common/ApolloClient'
import { Dialog, DialogBackdrop, Listbox, Switch, Transition } from '@headlessui/react'
import { clsx } from 'clsx'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import Button from '../../../Button'
import { useCopyToClipboard } from '../../../../../common/hooks/useCopyToClipboard'
import {
  GET_WALKTHROUGH_QUERY,
  SHARE_EMBED_WALKTHROUGH_QUERY,
} from '../../../../../App/graphql/queries/walkthrough'
import { TOP_BAR_SIZE } from '../../../../../Embed/constants'
import {
  WalkthroughByUuidQuery,
  WalkthroughByUuidQueryVariables,
} from 'app/javascript/components/graphql'

const DEFAULT_SIZES = [
  {
    width: 640,
    height: 450,
  },
  {
    width: 800,
    height: 600,
  },
  {
    width: 1000,
    height: 800,
  },
]

type Props = {
  onClose: () => void
  object: WalkthroughByUuidQuery['walkthrough']
}

const EmbedDialog: React.FC<Props> = ({ onClose, object }) => {
  const sizes = useMemo(() => {
    return DEFAULT_SIZES.map((size) => {
      const meta = object?.steps[0]?.event?.data
      const width = size.width
      const height =
        meta && meta.width && meta.height
          ? Math.round((size.width * meta.height) / meta.width) + TOP_BAR_SIZE
          : size.height

      return {
        width,
        height,
        name: `${width} x ${height}`,
      }
    })
  }, [])

  const [size, setSize] = useState(sizes[1])
  const [checked, setChecked] = useState(object.embedEnabled)

  const [shareEmbed] = useMutation(SHARE_EMBED_WALKTHROUGH_QUERY, {
    client,
  })

  const save = (checked: boolean) => {
    shareEmbed({
      variables: {
        id: object.id,
        enabled: checked,
      },
      update(cache) {
        const uuid = object.uuid

        const data = cache.readQuery<WalkthroughByUuidQuery, WalkthroughByUuidQueryVariables>({
          query: GET_WALKTHROUGH_QUERY,
          variables: { uuid },
        })

        cache.writeQuery({
          query: GET_WALKTHROUGH_QUERY,
          variables: { uuid },
          data: {
            walkthrough: {
              ...data.walkthrough,
              embedEnabled: checked,
            },
          },
        })
      },
    })
  }

  const onChange = (checked: boolean) => {
    setChecked(checked)
    save(checked)
  }

  const embedLink = new URL(`/embed/${object.uuid}`, document.baseURI).href
  const iframeCode = `<iframe width="${size.width}" height="${size.height}" src="${embedLink}" allowFullScreen allow="clipboard-write"></iframe>`

  const [copyTextStatus, copyText] = useCopyToClipboard(iframeCode)

  return (
    <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={onClose} open>
      <div className="flex items-end justify-center min-h-screen p-4 text-center sm:block sm:p-0">
        <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div
          className={clsx(
            'inline-block bg-[#F8F9FB] rounded px-[30px] pt-[24px] pb-[60px]',
            'overflow-hidden transform transition-all align-middle max-w-[720px] w-full'
          )}
        >
          <div className="text-left">
            <div className="flex items-center justify-between pb-[24px] border-b border-b-[#E4E7ED] mb-[38px]">
              <div className="font-semibold text-[#2C2C2C] text-[22px]">Embed link</div>
              <button
                className="text-[#777d87] hover:text-[#636870] transition-colors"
                onClick={onClose}
              >
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>

            <div className="text-[15px] mb-2 text-[#2C2C2C]">Size</div>

            <Listbox value={size} onChange={(size) => setSize(size)}>
              <div className="flex w-full mb-[30px]">
                <Listbox.Button className="relative rounded cursor-pointer flex items-center w-full border bg-white py-2 pl-3 pr-2 text-left focus:outline-none sm:text-sm">
                  <span className="block whitespace-nowrap ml-0 mr-4 w-44 text-[13px]">
                    {size.name}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute divide-y z-10 mt-10 w-[calc(100%-60px)] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {sizes.map((size, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `cursor-pointer select-none py-2 px-3.5 pr-4 ${
                            active ? 'bg-[#F9F9F9]' : 'text-gray-900'
                          }`
                        }
                        value={size}
                      >
                        {({ selected }) => (
                          <div className="flex flex-col space-y-0.5">
                            <div
                              className={clsx(
                                `text-sm`,
                                selected ? 'text-flowmo-blue-600' : 'text-[#2C2C2C]'
                              )}
                            >
                              {size.name}
                            </div>
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>

            <div className="text-[15px] mb-2 text-[#2C2C2C]">
              Copy and paste this code to embed this on your website
            </div>

            <textarea
              disabled
              className="block w-full border bg-white rounded py-[17px] px-[14px] text-[#646464] resize-none text-[15px] border-[#DEDEDE] mb-[34px]"
              value={iframeCode}
              onCopy={() => onChange(true)}
            />

            <div className="flex justify-between items-center">
              <div className="flex space-x-3 items-center">
                <Switch
                  checked={checked}
                  onChange={onChange}
                  className={clsx(
                    checked ? 'bg-flowmo-blue-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 items-center flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={clsx(
                      checked ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                  {checked ? (
                    <CheckIcon className="absolute h-3 w-3 left-1 text-white" />
                  ) : (
                    <XMarkIcon className="absolute h-3 w-3 left-6 text-gray-400" />
                  )}
                </Switch>
                <span className="text-[15px] text-[#2C2C2C]">
                  Embedded content is {checked ? 'available' : 'unavailable'} to users
                </span>
              </div>

              <div className="flex items-center space-x-6">
                <Button
                  onClick={() => {
                    onChange(true)
                    copyText()
                  }}
                  icon={copyTextStatus === 'copied' ? <SuccessIcon /> : <CopyIcon />}
                  color="primary"
                  className={clsx(
                    'space-x-2 !ring-0 !ring-offset-0 !ring-transparent',
                    copyTextStatus === 'copied' && '!bg-[#155AC0]'
                  )}
                >
                  <span className="whitespace-nowrap">Copy</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const CopyIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 1.5H2.5C1.94772 1.5 1.5 1.94772 1.5 2.5V8.5C1.5 9.05229 1.94772 9.5 2.5 9.5V10.5C1.39543 10.5 0.5 9.60457 0.5 8.5V2.5C0.5 1.39543 1.39543 0.5 2.5 0.5H8.5C9.60457 0.5 10.5 1.39543 10.5 2.5H9.5C9.5 1.94772 9.05229 1.5 8.5 1.5ZM4.5 5.5C4.5 4.94772 4.94772 4.5 5.5 4.5H11.5C12.0523 4.5 12.5 4.94772 12.5 5.5V11.5C12.5 12.0523 12.0523 12.5 11.5 12.5H5.5C4.94772 12.5 4.5 12.0523 4.5 11.5V5.5ZM3.5 5.5C3.5 4.39543 4.39543 3.5 5.5 3.5H11.5C12.6046 3.5 13.5 4.39543 13.5 5.5V11.5C13.5 12.6046 12.6046 13.5 11.5 13.5H5.5C4.39543 13.5 3.5 12.6046 3.5 11.5V5.5Z"
      fill="currentColor"
    />
  </svg>
)

const SuccessIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.82925 4.62372C10.0371 4.80556 10.0581 5.12144 9.87629 5.32926L6.37629 9.32926C6.28516 9.4334 6.15495 9.49512 6.01664 9.49973C5.87833 9.50433 5.7443 9.45141 5.64645 9.35356L3.64645 7.35356C3.45118 7.1583 3.45118 6.84172 3.64645 6.64645C3.84171 6.45119 4.15829 6.45119 4.35355 6.64645L5.97565 8.26855L9.12371 4.67075C9.30555 4.46294 9.62143 4.44188 9.82925 4.62372Z"
      fill="currentColor"
    />
  </svg>
)

export default EmbedDialog
