import React from 'react'

export const SuccessIcon = () => (
  <svg width="89" height="95" viewBox="0 0 89 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M54.5777 82.7579L34.4712 82.7865C34.4617 78.5393 37.9099 75.0938 42.1604 75.0842L46.8694 75.0747C51.12 75.0747 54.5682 78.5107 54.5777 82.7579Z"
      fill="#FDB813"
    />
    <path d="M51.0242 54.7461H38.0146V77.7576H51.0242V54.7461Z" fill="#FDB813" />
    <path d="M38.0146 64.6913V54.7461H51.0242L51.0146 59.5755L38.0146 64.6913Z" fill="#E89623" />
    <path
      d="M66.8891 36.9931L66.0581 34.2539C81.2932 29.644 87.7502 20.1282 85.8016 5.14358C85.7825 5.12449 85.6201 5.02905 85.4196 5.08631L83.3182 5.64943C82.4776 5.8785 81.9141 6.50843 81.9141 7.21471V10.4598C81.9141 12.4259 80.252 14.0008 78.122 14.0485C75.9251 14.0867 72.1712 14.163 71.6746 14.163V11.2997C72.0184 11.2997 74.4064 11.2615 78.0742 11.1852C78.5996 11.1756 79.0485 10.8416 79.0485 10.4598V7.21471C79.0485 5.20085 80.4622 3.46377 82.5636 2.89111L84.665 2.32799C85.5915 2.07984 86.5658 2.23255 87.349 2.75749C88.0559 3.23471 88.5239 3.96962 88.6385 4.78089C89.6605 12.655 88.5143 19.2979 85.2285 24.5377C81.6753 30.1975 75.5048 34.3875 66.8891 36.9931Z"
      fill="#FDB813"
    />
    <path
      d="M22.1206 36.9929C13.5049 34.3873 7.33449 30.1973 3.77168 24.528C0.485865 19.2977 -0.660348 12.6452 0.361692 4.77113C0.466762 3.95986 0.934797 3.21539 1.65118 2.74772C2.43443 2.22278 3.4087 2.07007 4.33523 2.31823L6.42707 2.88134C8.52846 3.454 9.94212 5.19108 9.94212 7.20494V10.45C9.94212 10.8318 10.3911 11.1659 10.9164 11.1754C14.5747 11.2422 16.9722 11.2899 17.3161 11.2899V14.1532C16.8194 14.1532 13.056 14.0864 10.8686 14.0387C8.74815 14.0005 7.07659 12.4257 7.07659 10.45V7.21448C7.07659 6.5082 6.51303 5.87827 5.67248 5.64921L3.58064 5.08609C3.38005 5.02882 3.21767 5.12427 3.18902 5.17199C1.25001 20.128 7.707 29.6533 22.9421 34.2537L22.1206 36.9929Z"
      fill="#FDB813"
    />
    <path
      d="M73.1554 5.44029L70.0415 44.2763C69.669 48.934 67.3957 53.019 64.0239 55.7868C61.1488 58.1538 57.4618 59.5664 53.4596 59.5664H35.5691C26.8961 59.5664 19.6845 52.914 18.9968 44.2763L17.3826 24.1186L15.8829 5.43074C15.6537 2.51016 17.9557 0 20.8976 0H68.1312C71.0731 0.00954437 73.3846 2.51971 73.1554 5.44029Z"
      fill="#FDB813"
    />
    <path
      d="M64.0241 55.7864C61.149 58.1534 57.4621 59.5659 53.4599 59.5659H35.5694C26.8964 59.5659 19.6848 52.9135 18.9971 44.2759L17.3828 24.1182C28.7112 38.4252 46.7832 50.6992 64.0241 55.7864Z"
      fill="#E89623"
    />
    <path d="M54.2141 59.3276H35.5786V59.8049H54.2141V59.3276Z" fill="#052140" />
    <path d="M40.9282 74.8564H37.4609V75.3337H40.9282V74.8564Z" fill="#052140" />
    <path d="M51.6738 74.8564H48.2065V75.3337H51.6738V74.8564Z" fill="#052140" />
    <path
      d="M59.2595 94.1257L29.7827 94.1639C29.7732 86.9675 35.6093 81.1263 42.8113 81.1167H46.1927C53.4043 81.0977 59.25 86.9293 59.2595 94.1257Z"
      fill="#FDB813"
    />
    <path d="M54.5593 80.9263H34.4814V81.4035H54.5593V80.9263Z" fill="#E89623" />
    <path
      opacity="0.6"
      d="M59.8396 21.8952C61.2903 21.8952 62.4664 19.6005 62.4664 16.7699C62.4664 13.9392 61.2903 11.6445 59.8396 11.6445C58.3889 11.6445 57.2129 13.9392 57.2129 16.7699C57.2129 19.6005 58.3889 21.8952 59.8396 21.8952Z"
      fill="white"
    />
  </svg>
)

export const ResetIcon = () => (
  <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.67882 1.28033C7.97171 0.987437 7.97171 0.512563 7.67882 0.21967C7.38592 -0.0732233 6.91105 -0.0732233 6.61816 0.21967L4.23167 2.60616L6.61816 4.99264C6.91105 5.28553 7.38592 5.28553 7.67882 4.99264C7.97171 4.69975 7.97171 4.22487 7.67882 3.93198L6.93141 3.18457C7.59602 3.23986 8.24829 3.42275 8.85115 3.72806C9.88156 4.24989 10.7091 5.09968 11.2034 6.14357C11.6977 7.18746 11.8307 8.36614 11.5814 9.49392C11.3321 10.6217 10.7147 11.6345 9.82644 12.3728C8.9382 13.1111 7.82958 13.5329 6.67523 13.5718C5.52087 13.6107 4.38638 13.2644 3.45046 12.5876C2.51454 11.9108 1.83038 10.9418 1.50574 9.83337C1.28195 9.06922 1.23779 8.26872 1.37051 7.49301C1.39414 7.35489 1.42338 7.21756 1.45821 7.08136C1.46906 7.03892 1.4762 6.99604 1.47975 6.95323C1.50523 6.64633 1.34665 6.34291 1.0544 6.22791C0.721383 6.09687 0.34181 6.26006 0.244562 6.60446C0.229937 6.65626 0.215966 6.7082 0.202649 6.76027C0.158656 6.9323 0.121805 7.10577 0.0921162 7.28024C-0.0724433 8.24731 -0.0169468 9.24508 0.262024 10.1976C0.667499 11.5821 1.52204 12.7924 2.69103 13.6377C3.86002 14.4831 5.27704 14.9156 6.71886 14.867C8.16068 14.8184 9.54538 14.2916 10.6548 13.3694C11.7643 12.4473 12.5354 11.1823 12.8468 9.77364C13.1582 8.365 12.9921 6.8928 12.3747 5.58895C11.7573 4.28509 10.7237 3.22368 9.43666 2.5719C8.69125 2.19441 7.88538 1.96675 7.06394 1.89521L7.67882 1.28033Z"
      fill="currentColor"
    />
  </svg>
)

export const ArrowIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.35355 4.64645C8.15829 4.45118 7.84171 4.45118 7.64645 4.64645C7.45118 4.84171 7.45118 5.15829 7.64645 5.35355L8.79289 6.5L4.1 6.5C3.76863 6.5 3.5 6.72386 3.5 7C3.5 7.27614 3.76863 7.5 4.1 7.5L8.79289 7.5L7.64645 8.64645C7.45118 8.84171 7.45118 9.15829 7.64645 9.35355C7.84171 9.54882 8.15829 9.54882 8.35355 9.35355L10.3536 7.35355C10.5488 7.15829 10.5488 6.84171 10.3536 6.64645L8.35355 4.64645Z"
      fill="currentColor"
    />
  </svg>
)
