import React, { useState } from 'react'
import {
  ClockIcon,
  EllipsisVerticalIcon,
  ListBulletIcon,
  UserIcon,
  ChartBarIcon,
  CursorArrowRippleIcon,
} from '@heroicons/react/24/outline'
import useDeleteWalkthroughMutation from '../../hooks/useDeleteWalkthroughMutation'
import ConfirmDialog from '../ConfirmDialog'
import useMoveWalkthroughMutation from '../../hooks/useMoveWalkthroughMutation'
import AddToSkillSetMenuItem from '../../../common/components/Menu/MenuItem/AddToSkillSetItem'
import RemoveFromSkillSetItem from '../../../common/components/Menu/MenuItem/RemoveFromSkillSetItem'
import DeleteMenuItem from '../../../common/components/Menu/MenuItem/DeleteItem'
import MoveMenuItem from '../../../common/components/Menu/MenuItem/MoveItem'
import CardMenu from '../../../common/components/Menu'
import InformationBar from '../../../common/ui/InformationBar'
import StickyBar from '../../../common/ui/StickyBar'
import ContentField from './ContentField'
import { createdByFormatter } from '../../../common/utils/createdByFormatter'
import { createdAtFormatter } from '../../../common/utils/createdAtFormatter'
import AttentionMessage from './AttentionMessage'
import InternalStickyBarContent from './InternalStickyBarContent'
import Divider from '../../../common/ui/Divider'
import EmbedItem from '../../../common/components/Menu/MenuItem/EmbedItem'
import EmbedDialog from './Toolbar/Embed/EmbedDialog'
import { Chunk } from '../../hooks/useRRWebEvents'
import ErrorMessage from './ErrorMessage'
import Video from './Audio'
import AddToAgentItem from '../../../../components/common/components/Menu/MenuItem/AddToAgentItem'
import { useHistory } from 'react-router-dom'
import { DestinationType, SkillSet, Walkthrough } from '../../../../components/graphql'

interface Props {
  walkthrough: Walkthrough
  skillSets?: SkillSet[]
  chunks: Chunk[]
  steps: any[]
}

const WalkthroughHeader: React.FC<Props> = ({ walkthrough, skillSets, steps, chunks }) => {
  const history = useHistory()
  const [showEmbedModal, setShowEmbedModal] = useState(false)

  const [moveWalkthrough] = useMoveWalkthroughMutation()
  const [deleteWalkthrough] = useDeleteWalkthroughMutation()

  // delete
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)
  const onDeleteAccept = () => {
    toggleDeleteModal()

    deleteWalkthrough({
      variables: { id: walkthrough.id },
    }).then(() => {
      window.location.href = '/app/my_library'
    })
    return window.toastr.success('The Walkthrough was deleted successfully')
  }

  const handleMove = (variables: { destinationType: DestinationType; destinationId?: string }) => {
    moveWalkthrough({
      variables: {
        id: walkthrough.id,
        destinationType: variables.destinationType,
        destinationId: variables.destinationId,
      },
    }).then(({ data }) => {
      let path = 'home'
      if (data.walkthrough.isInMyLibrary) {
        path = 'my_library'
        window.open(
          '/app/' + path + '?message=Walkthrough was moved successfully to your library',
          '_self'
        )
      } else {
        path = 'organization_library'
        window.open(
          '/app/' + path + '?message=Walkthrough was moved successfully to organization library',
          '_self'
        )
      }
    })
  }

  const onAddToSkillSet = (variables: {
    destinationType: DestinationType
    destinationId?: string
  }) => {
    moveWalkthrough({
      variables: {
        id: walkthrough.id,
        destinationType: variables.destinationType,
        destinationId: variables.destinationId,
      },
    }).then(
      ({
        data: {
          walkthrough: { skillSet },
        },
      }) => {
        window.open(
          `/app/skill_set/${skillSet.uuid}?message=The Walkthrough was added to Skill Set successfully`,
          '_self'
        )
      }
    )
  }

  const handleAddtoAgent = () => {
    history.push('/walkthrough/' + walkthrough.uuid + '/add_to_agent')
  }

  const toggleEmbedModal = () => setShowEmbedModal(!showEmbedModal)

  let cardMenu = null
  if (walkthrough.canEdit) {
    const menuItems = []

    menuItems.push(<AddToAgentItem onClick={handleAddtoAgent} key="agent" />)
    menuItems.push(<div key="separator" className="border-b pt-2 mb-2"></div>)
    menuItems.push(<DeleteMenuItem onClick={toggleDeleteModal} key="delete" />)

    if (skillSets?.length && !walkthrough.skillSet) {
      menuItems.push(
        <AddToSkillSetMenuItem
          onAddToSkillSet={onAddToSkillSet}
          key="move to Skill Set"
          walkthrough={walkthrough}
          skillSets={skillSets}
        />
      )
    }

    if (walkthrough.skillSet) {
      menuItems.push(<RemoveFromSkillSetItem onMove={handleMove} key="remove" />)
    }

    if (walkthrough.canMove && !walkthrough.skillSet) {
      menuItems.push(<MoveMenuItem onMove={handleMove} key="move" subject={walkthrough} />)
    }

    menuItems.push(<EmbedItem onClick={toggleEmbedModal} key="embed" />)

    cardMenu = (
      <div className="flex">
        <CardMenu
          key={walkthrough.id}
          icon={<EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />}
          items={menuItems}
        />
      </div>
    )
  }

  return (
    <>
      {showEmbedModal && <EmbedDialog object={walkthrough} onClose={toggleEmbedModal} />}
      <StickyBar showOnScrollPosition={540} className="p-4">
        <InternalStickyBarContent
          walkthrough={walkthrough}
          cardMenu={cardMenu}
          toggleEmbedModal={toggleEmbedModal}
        />
      </StickyBar>

      <ConfirmDialog
        title="Delete the Walkthrough?"
        description="Are you sure that you want to delete the Walkthrough? This can't be undone!"
        onAccept={onDeleteAccept}
        onCancel={toggleDeleteModal}
        actionLabel="Delete"
        cancelLabel="Cancel"
        open={showDeleteModal}
        close={toggleDeleteModal}
      />
      <div className="overflow-hidden">
        <div className="rounded-lg relative">
          <div className="sm:flex sm:items-center sm:justify-center">
            <>
              <div className="sm:flex sm:space-x-5 w-full">
                <div className="text-center w-full sm:pt-1 sm:text-left">
                  <div className="flex flex-col items-center md:flex-row align-center">
                    <ContentField
                      name="name"
                      className="break-all w-full py-2.5 break-words rounded-md text-lg lg:text-xl xl:text-2xl font-bold text-gray-900"
                      canEdit={walkthrough.canEdit}
                    >
                      {walkthrough.name}
                    </ContentField>
                  </div>
                  <ErrorMessage chunks={chunks} />
                  <InformationBar
                    stepsCount={steps.length}
                    object={walkthrough}
                    objectType="walkthrough"
                    cardMenu={cardMenu}
                    className="my-[30px] border-y"
                    toggleEmbedModal={toggleEmbedModal}
                  >
                    <div className="info pl-0">
                      <ListBulletIcon className="icon" />
                      <span>{steps.length} steps</span>
                    </div>
                    <Divider className="border-r h-5" />
                    <div className="info pl-0">
                      <ChartBarIcon className="icon" />
                      <span>{walkthrough.viewCount} views</span>
                    </div>
                    <Divider className="border-r h-5" />
                    <div className="info pl-0">
                      <CursorArrowRippleIcon className="icon" />
                      <span>{walkthrough.interactionCount} interactions</span>
                    </div>
                    <Divider className="border-r h-5" />
                    <div className="info">
                      <ClockIcon className="icon" />
                      <span>{createdAtFormatter(new Date(walkthrough.createdAt))}</span>
                    </div>
                    {walkthrough.createdBy.firstName && (
                      <>
                        <Divider className="border-r h-5" />
                        <div className="info">
                          <UserIcon className="icon" />
                          <span>by {createdByFormatter(false, walkthrough.createdBy)}</span>
                        </div>
                      </>
                    )}
                  </InformationBar>
                  <div className="mb-2 lg:mb-7">
                    <Video chunks={chunks} walkthrough={walkthrough} />
                  </div>
                  <AttentionMessage />
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  )
}

export default WalkthroughHeader
