import React from 'react'

interface Props {
  children?: React.ReactNode
}

const Description = ({ children }: Props) => (
  <div className="text-[14px] md:text-[16px] lg:text-[20px] text-[#CBDDFF] mb-[30px] md:mb-[40px] lg:mb-[50px]">
    {children}
  </div>
)

export default Description
