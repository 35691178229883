import React from 'react'
import * as styles from './style.module.scss'

type StepDetailProps = {
  title: string
  description: string
  stepNumber: number
  stepType: string
  position: number
  style?: any
}

const StepDetail: React.FC<StepDetailProps> = ({ description, stepNumber }) => {
  return (
    <div className={styles['flow-mo-step-detail-card']}>
      <div className={styles['header']}>
        <div className={styles['step-number']}>
          <span>{stepNumber}</span>
        </div>
        <div>Click</div>
      </div>
      {description ? <p>{description}</p> : <i>Click on the highlighted element.</i>}
    </div>
  )
}

export default StepDetail
