import React from 'react'
import PromptInput from '../../../components/Chat/PromptInput'
import { AgentQuery } from 'app/javascript/components/graphql'
import { Link, useHistory } from 'react-router-dom'

const AgentWorkWithYou = ({ agent }: { agent: AgentQuery['agent'] }) => {
  const history = useHistory()
  const handleSubmit = (message: string) => {
    history.push(queryLink(message))
  }

  const queryLink = (prompt: string) => {
    return `/agent/${agent.uuid}/chat?query=${prompt}`
  }
  return (
    <div>
      <div className="mt-8 text-sm flex flex-col space-y-4">
        {agent && agent.prompts && agent.prompts.length > 0 && (
          <div className="flex flex-col text-base font-medium tracking-tight">
            <div className="text-blue-600">I can answer questions like -</div>
            <div className="">
              <ul className="mt-4 ml-6 list-disc list-inside text-slate-700">
                {agent.prompts.map((prompt: string, index: number) => (
                  <li key={index} className="mt-1 text-sm">
                    <Link to={queryLink(prompt)}>{prompt}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div className="flex flex-col text-base font-medium tracking-tight">
          <div className="text-blue-600">Let&apos;s start -</div>
          <div className="max-w-xl mt-2">
            <PromptInput
              isQuerying={false}
              handleSubmit={handleSubmit}
              backgroundColor="bg-white"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentWorkWithYou
