import { gql } from '@apollo/client'

export const WALKTHROUGH_FIELDS = gql`
  fragment WalkthroughFields on Walkthrough {
    id
    uuid
    name
    description
    steps
    meta
    createdBy {
      firstName
      lastName
      id
    }
    audioEnabled
    hasAudio
    canEdit
    canMove
    isOwner
    isInMyLibrary
    isInMyOrganizationLibrary
    canEditSharing
    canDelete
    sharedAccess
    completed
    createdAt
    embedEnabled
    updatedAt
    canEditSharing
    viewCount
    interactionCount
    skillSet {
      id
      name
      uuid
      sharedAccess
    }
  }
`

export const SKILL_SET_FIELDS = gql`
  fragment SkillSetFields on SkillSet {
    id
    name
    uuid
    completed
    canEdit
    canMove
    isInMyLibrary
    isInMyOrganizationLibrary
    sharedAccess
    isInMyLibrary
    isInMyOrganizationLibrary
    canEditSharing
    createdAt
    updatedAt
    walkthroughs {
      id
      name
      uuid
      steps
      completed
      meta
    }
  }
`

export const CREATED_CONTENT_QUERY = gql`
  query getCreatedContent(
    $page: Int
    $perPage: Int
    $sortBy: String
    $sortDirection: String
    $contentType: [ContentType!]
  ) {
    items: getCreatedContent(
      page: $page
      perPage: $perPage
      sortBy: $sortBy
      sortDirection: $sortDirection
      contentType: $contentType
    ) {
      ... on Walkthrough {
        ...WalkthroughFields
      }
      ... on SkillSet {
        ...SkillSetFields
      }
    }
    total: getCreatedContentMeta(contentType: $contentType) {
      count
    }
  }
  ${WALKTHROUGH_FIELDS}
  ${SKILL_SET_FIELDS}
`

export const GET_ORGANIZATION_CONTENT = gql`
  query getOrganizationContent(
    $page: Int
    $perPage: Int
    $sortBy: String
    $sortDirection: String
    $contentType: [ContentType!]
  ) {
    items: getOrganizationContent(
      page: $page
      perPage: $perPage
      sortBy: $sortBy
      sortDirection: $sortDirection
      contentType: $contentType
    ) {
      ... on Walkthrough {
        ...WalkthroughFields
      }
      ... on SkillSet {
        ...SkillSetFields
      }
    }
    total: getOrganizationContentMeta(contentType: $contentType) {
      count
    }
  }
  ${WALKTHROUGH_FIELDS}
  ${SKILL_SET_FIELDS}
`
