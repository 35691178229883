import { Link } from 'react-router-dom'
import { getRelativeTimeString } from '../../../../components/common/utils/dateDisplay'
import React from 'react'

interface FeedItemProps {
  title: string
  actionUrl?: string
  actionLabel?: string
  dataTable?: Record<string, string | React.ReactNode>
  createdAt: string
  children?: React.ReactNode
}

const FeedItem = ({
  title,
  dataTable,
  actionLabel,
  actionUrl,
  createdAt,
  children,
}: FeedItemProps) => {
  return (
    <div className="mb-10 ms-4">
      <div className="absolute w-3 h-3 bg-blue-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
      <time className="mb-1 text-sm font-normal leading-none text-gray-400 ">
        {getRelativeTimeString(createdAt)}
      </time>
      <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
      <div className="mb-4 text-base font-normal text-gray-500">
        {dataTable && (
          <div className="flex flex-col ml-2 space-y-2 mt-2">
            {Object.keys(dataTable).map((key) => (
              <div key={key} className="flex flex-col">
                <div className="text-sm text-gray-400 lowercase">{key}</div>
                <div className="col-span-2">{dataTable[key]}</div>
              </div>
            ))}
          </div>
        )}

        {children}
      </div>

      {actionUrl && (
        <Link
          to={actionUrl}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 focus:text-blue-700"
        >
          {actionLabel || 'View details'}
          <svg
            className="w-3 h-3 ms-2 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </Link>
      )}
    </div>
  )
}

export default FeedItem
