import { gql } from '@apollo/client'
import {
  AGENT_FIELDS,
  ANALYSIS_RUN_FIELDS,
  TASK_RUN_FIELDS,
  WORKFLOW_FIELDS,
  SUGGESTION_FIELDS,
} from './fragments'

export const GET_FEED_ITEMS = gql`
  query feedItems($limit: Int, $offset: Int, $types: [FeedItemTypeEnum!]) {
    feedItems(limit: $limit, offset: $offset, types: $types) {
      ... on Agent {
        ...AgentFields
      }

      ... on AnalysisRun {
        ...AnalysisRunFields
      }

      ... on TaskRun {
        ...TaskRunFields
      }

      ... on Workflow {
        ...WorkflowFields
      }

      ... on Suggestion {
        ...SuggestionFields
      }
    }
  }

  ${AGENT_FIELDS}
  ${ANALYSIS_RUN_FIELDS}
  ${TASK_RUN_FIELDS}
  ${WORKFLOW_FIELDS}
  ${SUGGESTION_FIELDS}
`
