import React, { useEffect, useState, useRef } from 'react'
import AudioRecorder from './AudioRecorder'
import useAudioConversation from '../../hooks/useAudioConversation'
import { LinearProgress } from '@mui/material'
import clsx from 'clsx'
import TextContent from '../Chat/TextContent'

interface EventItem {
  type: string
  event_id: string
  content?: string
  createdAt: Date
}

interface ConversationItem {
  id: string
  previousItemId?: string
  role: 'user' | 'assistant'
  content: string
  createdAt: Date
  events: EventItem[]
}

export interface OutputConversationItem {
  role: 'user' | 'assistant'
  createdAt: Date
  content: string[]
}

const AudioConversation = ({
  onConversationItems,
}: {
  onConversationItems: (items: OutputConversationItem[]) => void
}) => {
  const [conversation, setConversation] = useState<ConversationItem[]>([])
  const [isRecording, setIsRecording] = useState(false) // Track recording state
  const conversationEndRef = useRef<HTMLDivElement | null>(null)

  const addToConversation = (newItem: ConversationItem) => {
    setConversation((prev) => {
      const updated = [...prev, newItem]

      const sortedConversation: ConversationItem[] = []
      const itemsMap = new Map<string, ConversationItem>()

      updated.forEach((item) => itemsMap.set(item.id, item))

      updated.forEach((item) => {
        if (!item.previousItemId) {
          sortedConversation.push(item)
        } else {
          const previousIndex = sortedConversation.findIndex(
            (convItem) => convItem.id === item.previousItemId
          )
          if (previousIndex !== -1) {
            sortedConversation.splice(previousIndex + 1, 0, item)
          } else {
            sortedConversation.push(item)
          }
        }
      })

      return sortedConversation
    })
  }

  useEffect(() => {
    const outputConversation = conversation.map((item) => ({
      role: item.role,
      createdAt: item.createdAt,
      content: item.events.map((event) => event.content),
    }))

    onConversationItems(outputConversation)
  }, [conversation])

  const updateItemEvents = (itemId: string, event: EventItem) => {
    setConversation((prev) =>
      prev.map((item) => (item.id === itemId ? { ...item, events: [...item.events, event] } : item))
    )
  }

  const handleResponse = (responseType: string, data: any) => {
    const createdAt = new Date()

    if (responseType === 'conversation.item.created') {
      const newItem: ConversationItem = {
        id: data.item.id,
        previousItemId: data.previous_item_id,
        role: data.item.role,
        content: '',
        createdAt,
        events: [],
      }
      addToConversation(newItem)
    } else if (responseType === 'response.text.done') {
      updateItemEvents(data['item_id'], {
        type: responseType,
        event_id: data['event_id'],
        content: data['text'],
        createdAt,
      })
    } else if (responseType === 'conversation.item.input_audio_transcription.completed') {
      updateItemEvents(data['item_id'], {
        type: responseType,
        event_id: data['event_id'],
        content: data['transcript'],
        createdAt,
      })
    }
  }

  const { startConversation, stopConversation, sendAudioData, timeLeft, timeLimit } =
    useAudioConversation(handleResponse)

  const handleAudioData = (audioData: Float32Array) => {
    sendAudioData(audioData)
  }

  useEffect(() => {
    if (isRecording) {
      startConversation()
    } else {
      stopConversation()
    }
  }, [isRecording])

  useEffect(() => {
    if (conversationEndRef.current) {
      conversationEndRef.current.scrollTop = conversationEndRef.current.scrollHeight
    }
  }, [conversation])

  return (
    <div className="flex flex-col gap-2 bg-gray-700 rounded-lg p-2">
      <div className="">
        <AudioRecorder
          onAudioData={handleAudioData}
          isRecording={isRecording}
          setIsRecording={setIsRecording}
          timeLeft={timeLeft}
          timeLimit={timeLimit}
        />
      </div>
      <div
        className="flex flex-col gap-2 overflow-y-auto h-80  border rounded-lg shadow-inner p-2 bg-white"
        ref={conversationEndRef}
      >
        {conversation.length == 0 && (
          <div className="text-center text-gray-400 mt-4">
            Start a conversation by starting the recording and speaking
          </div>
        )}
        {conversation.map((oneConversation, index) => (
          <div
            key={index}
            className={`px-4 py-2 text-sm rounded-lg max-w-xs ${
              oneConversation.role === 'user'
                ? 'bg-[rgb(237,243,253)] text-black self-end'
                : 'bg-[rgb(247,250,255)] text-black self-start'
            }`}
          >
            {oneConversation.events.length == 0 && (
              <div
                className={clsx(
                  'w-full',
                  oneConversation.role === 'user'
                    ? 'self-end text-blue-400'
                    : 'self-start text-blue-400'
                )}
              >
                <LinearProgress color="inherit" />
                <div className="text-xs">Transcribing...</div>
              </div>
            )}
            {oneConversation.events.map((event, eventIndex) => (
              <div key={eventIndex}>
                <TextContent content={{ type: 'text', value: event.content }} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default AudioConversation
