import React from 'react'
import { useQuery } from '@apollo/client'
import Spin from '../../../../common/ui/Spin'
import Pager from '../../Pager'
import { DocumentNode } from 'graphql'
import useQueryParams, { useContentType, usePagination, useSort } from './useQueryParams'
import EmptyState from '../../EmptyState'
import { useHistory, useLocation } from 'react-router-dom'
import List from './List'
import FilterPanel from '../../../../../components/common/components/FilterPanel'
import {
  ActiveFilter,
  SortOption,
} from '../../../../../components/common/components/FilterPanel/types'
import {
  ContentType,
  GetCreatedContentQuery,
  GetCreatedContentQueryVariables,
} from 'app/javascript/components/graphql'

interface Props {
  query: DocumentNode
  emptyState: {
    title?: string
    description: any
    actions?: React.ReactElement
  }
  defaultSortBy?: {
    label: string
    sortBy: string
    sortDirection: string
  }
  showHeader?: boolean
}

const Content: React.FC<Props> = ({ query, emptyState, showHeader = true }) => {
  const { page, perPage, sortDirection, sortBy, contentType } = useQueryParams()
  const history = useHistory()
  const location = useLocation()

  const [, setSort] = useSort()
  const [, setPagination] = usePagination()
  const [, setContentType] = useContentType()

  const params = new URLSearchParams(location.search)
  params.delete('page')

  const wrongPageHandler = () => {
    const queryParams = new URLSearchParams(location.search)

    queryParams.delete('page')
    queryParams.set('page', String(1))
    history.replace({ search: queryParams.toString() })
  }

  const { data, loading } = useQuery<GetCreatedContentQuery, GetCreatedContentQueryVariables>(
    query,
    {
      variables: {
        page,
        perPage,
        sortBy,
        sortDirection,
        contentType: contentType as ContentType[],
      },
    }
  )

  let defaultFilterValues: { name: string; value: string }[] = []
  if (contentType.length > 0) {
    defaultFilterValues = contentType.map((contentType) => ({
      name: 'contentType',
      value: contentType,
    }))
  }

  const content = data && data.items
  const totalCount = data && data.total.count
  const hasItems = !!data?.items?.length

  const handleSetPage = (page: number) => {
    setPagination({ page })
  }

  const handleSort = (sortOption: SortOption) => {
    setSort(sortOption)
  }

  const handleFilters = (newActiveFilters: ActiveFilter[]) => {
    const contentTypeValues = newActiveFilters
      .filter((f) => f.name === 'contentType')
      .map((f) => f.value)

    setContentType(contentTypeValues)
  }

  return (
    <>
      {showHeader && (
        <div className="pt-10">
          <FilterPanel
            defaultSortOption={{
              sortBy,
              sortDirection,
            }}
            defaultFilterValues={defaultFilterValues}
            handleSort={handleSort}
            handleFilters={handleFilters}
          />
        </div>
      )}
      <div className="m-auto mt-10 px-5 max-w-7xl">
        <>
          {loading && <Spin className="h-20 w-20 text-ddu-blue m-auto" />}

          {!loading && !hasItems && page >= 2 && wrongPageHandler()}

          {!loading && !hasItems && <EmptyState {...emptyState} />}

          {!loading && hasItems && (
            <>
              <List content={content} />
              <Pager total={totalCount} perPage={perPage} page={page} setPage={handleSetPage} />
            </>
          )}
        </>
      </div>
    </>
  )
}

export default Content
