import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import Cron, { HEADER } from 'react-cron-generator'
import clsx from 'clsx'
import TimeFrameSelector from './TimeFrameSelector'
import KnowledgeField from './KnowledgeField'
import { KnowledgeInput, NoteInput } from 'app/javascript/components/graphql'
import { FileAttachmentField } from './FileAttachmentField'
import NoteField, { Note } from './NoteField'
export type FieldData = {
  name: string
  label: string
  type: string
  required: boolean
  defaultValue: string | string[] | KnowledgeInput[] | Note[]
  disabled?: boolean
  hidden?: boolean
  options?: { label: string; value: string }[]
}

type DynamicFormProps = {
  formData: FieldData[]
  initialData?: { [key: string]: string | string[] | KnowledgeInput[] | NoteInput[] }
  handleSubmit: (values: any) => void
}

const JSONField = ({ field, formik }: { field: FieldData; formik: any }) => (
  <>
    <textarea
      id={field.name}
      name={field.name}
      onChange={formik.handleChange}
      value={formik.values[field.name]}
      onBlur={formik.handleBlur}
      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-50"
      placeholder={`Type ${field.label.toLowerCase()}`}
      rows={5}
      disabled={field.disabled}
    />
    {formik.touched[field.name] && formik.errors[field.name] ? (
      <div className="text-red-500 text-xs mt-1">{formik.errors[field.name]}</div>
    ) : null}
  </>
)

const CronScheduleField = ({ field, formik }: { field: FieldData; formik: any }) => {
  const handleChange = (e: string) => {
    formik.setFieldValue(field.name, e)
  }

  const options = {
    headers: [HEADER.HOURLY, HEADER.DAILY, HEADER.WEEKLY, HEADER.MONTHLY],
  }

  return (
    <>
      <input
        type="hidden"
        name={field.name}
        onChange={formik.handleChange}
        value={formik.values[field.name]}
        onBlur={formik.handleBlur}
      />
      <Cron
        onChange={handleChange}
        value={formik.values[field.name]}
        showResultText={true}
        showResultCron={false}
        options={options}
      />
    </>
  )
}

const TextField = ({ field, formik }: { field: FieldData; formik: any }) => (
  <>
    <input
      id={field.name}
      name={field.name}
      type={field.type === 'url' ? 'text' : field.type}
      onChange={formik.handleChange}
      value={formik.values[field.name]}
      onBlur={formik.handleBlur}
      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-50"
      placeholder={`Type ${field.label.toLowerCase()}`}
      disabled={field.disabled}
    />
    {formik.touched[field.name] && formik.errors[field.name] ? (
      <div className="text-red-500 text-xs mt-1">{formik.errors[field.name]}</div>
    ) : null}
  </>
)

const TextAreaField = ({ field, formik }: { field: FieldData; formik: any }) => (
  <>
    <textarea
      id={field.name}
      name={field.name}
      onChange={formik.handleChange}
      value={formik.values[field.name] as string}
      onBlur={formik.handleBlur}
      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-50"
      placeholder={`Type ${field.label.toLowerCase()}`}
      rows={3}
      disabled={field.disabled}
    />
    {formik.touched[field.name] && formik.errors[field.name] ? (
      <div className="text-red-500 text-xs mt-1">{formik.errors[field.name]}</div>
    ) : null}
  </>
)

const ListField = ({ field, formik }: { field: FieldData; formik: any }) => {
  const urlList: string[] = formik.values[field.name] as string[]

  return (
    <>
      {urlList.map((url, index) => (
        <div key={index} className="mb-2 flex items-center space-x-2">
          <input
            id={`${field.name}-${index}`}
            name={`${field.name}[${index}]`}
            type="text"
            onChange={formik.handleChange}
            value={url}
            onBlur={formik.handleBlur}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
            placeholder={`Type ${field.label.toLowerCase()}`}
            disabled={field.disabled}
          />
          {formik.touched[field.name] &&
          formik.errors[field.name] &&
          (formik.touched[field.name] as unknown as boolean[])[index] &&
          formik.errors[field.name][index] ? (
            <div className="text-red-500 text-xs mt-1 ml-2">{formik.errors[field.name][index]}</div>
          ) : null}
          {urlList.length > 1 && (
            <button
              type="button"
              onClick={() => {
                const updatedUrls = [...urlList]
                updatedUrls.splice(index, 1)
                formik.setFieldValue(field.name, updatedUrls)
              }}
              className="text-red-600 underline"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      ))}
      <button
        type="button"
        onClick={() => {
          const newUrls = [...urlList, '']
          formik.setFieldValue(field.name, newUrls)
        }}
        className="text-primary-600 underline"
      >
        <PlusIcon className="h-8 w-8 text-green-600" />
      </button>
    </>
  )
}

const SelectField = ({ field, formik }: { field: FieldData; formik: any }) => (
  <>
    <select
      id={field.name}
      name={field.name}
      onChange={formik.handleChange}
      value={formik.values[field.name] as string}
      onBlur={formik.handleBlur}
      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-50"
      disabled={field.disabled}
    >
      <option value="" disabled>
        Select an option
      </option>

      {field.options?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {formik.touched[field.name] && formik.errors[field.name] ? (
      <div className="text-red-500 text-xs mt-1">{formik.errors[field.name]}</div>
    ) : null}
  </>
)

const DynamicForm = React.forwardRef(
  ({ formData, initialData, handleSubmit }: DynamicFormProps, ref) => {
    const initialValues = formData.reduce((acc, field) => {
      if (initialData && initialData[field.name]) {
        acc[field.name] = initialData[field.name]
      } else {
        if (field.type === 'urlList' || field.type === 'stringList') {
          acc[field.name] = ['']
        } else if (field.type === 'knowledge') {
          acc[field.name] = []
        } else if (field.type === 'fileAttachments') {
          acc[field.name] = []
        } else if (field.type === 'noteList') {
          acc[field.name] = []
        } else {
          acc[field.name] = field.defaultValue
        }
      }
      return acc
    }, {} as { [key: string]: string | string[] | KnowledgeInput[] | Note[] })

    const validationSchema = formData.reduce((acc, field) => {
      let validator: Yup.AnySchema = Yup.string()

      if (field.type === 'textarea') {
        validator = Yup.string()
      }

      if (field.type === 'url') {
        validator = Yup.string().url('Invalid URL format')
      }

      if (field.type === 'json') {
        validator = Yup.string().test('is-json', 'Invalid JSON format', (value) => {
          try {
            JSON.parse(value)
            return true
          } catch (e) {
            return false
          }
        })
      }

      if (field.type === 'noteList') {
        validator = Yup.array()
        if (field.required) {
          validator = validator.required('Required')
        }
      }

      if (field.type === 'knowledge') {
        validator = Yup.array()
        if (field.required) {
          validator = validator.required('Required')
        }
      }

      if (field.type === 'fileAttachments') {
        validator = Yup.array()
        if (field.required) {
          validator = validator.required('Required')
        }
      }

      if (field.type === 'stringList') {
        if (field.required) {
          validator = Yup.array()
            .of(Yup.string().required('Value cannot be empty'))
            .min(1, 'At least one value is required')
        } else {
          validator = Yup.array()
            .of(Yup.string())
            .test('empty', { 0: 'At least one value is required' }, (value) => {
              if (value && value.length > 1) {
                // at least one non zero value
                return value.some((v) => v && v.trim().length > 0)
              }
              return true
            })
        }
      }

      if (field.type === 'urlList') {
        validator = Yup.array()
          .of(Yup.string().url('Invalid URL format').required('URL cannot be empty'))
          .min(1, 'At least one URL is required')
      }

      if (field.required) {
        validator = validator.required('Required')
      }

      acc[field.name] = validator
      return acc
    }, {} as { [key: string]: Yup.AnySchema })

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: Yup.object(validationSchema),
      onSubmit: async (values) => {
        await handleSubmit(values)
      },
    })
    React.useImperativeHandle(ref, () => ({
      submitForm: formik.submitForm,
      resetForm: () => formik.resetForm(),
    }))

    return (
      <form onSubmit={formik.handleSubmit} className="w-full scroll-auto">
        <div className="grid gap-4 grid-cols-1 sm:gap-6">
          {formData.map((field) => {
            const renderField = () => {
              switch (field.type) {
                case 'urlList':
                case 'stringList':
                  return <ListField field={field} formik={formik} />
                case 'textarea':
                  return <TextAreaField field={field} formik={formik} />
                case 'select':
                  return <SelectField field={field} formik={formik} />
                case 'cronSchedule':
                  return <CronScheduleField field={field} formik={formik} />
                case 'timeFrameSelector':
                  return <TimeFrameSelector field={field} formik={formik} />
                case 'json':
                  return <JSONField field={field} formik={formik} />
                case 'knowledge':
                  return <KnowledgeField field={field} formik={formik} />
                case 'fileAttachments':
                  return <FileAttachmentField field={field} formik={formik} />
                case 'noteList':
                  return <NoteField field={field} formik={formik} />
                default:
                  return <TextField field={field} formik={formik} />
              }
            }

            return (
              <div key={field.name} className={clsx(['w-full', field.hidden && 'hidden'])}>
                <label
                  htmlFor={field.name}
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  {field.label} {field.required && <span className="text-red-500">*</span>}
                </label>
                {renderField()}
              </div>
            )
          })}
        </div>
      </form>
    )
  }
)

DynamicForm.displayName = 'DynamicForm'

export default DynamicForm
