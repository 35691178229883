import React from 'react'
import { DataSetFieldsFragment } from 'app/javascript/components/graphql'

interface DataSetFieldsProps {
  dataSet: DataSetFieldsFragment
}

const DataSetFields: React.FC<DataSetFieldsProps> = ({ dataSet }) => {
  const fields = dataSet.functionDefinition?.fields || []

  return (
    <>
      <table className="prose text-sm max-w-none my-5 mx-2 text-wrap break-words">
        <thead>
          <tr>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Type</th>
            <th className="px-4 py-2">Category</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field) => (
            <tr key={field.name}>
              <td className="px-4 py-2">{field.name}</td>
              <td className="px-4 py-2">{field.type}</td>
              <td className="px-4 py-2">{field.category}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default DataSetFields
