export const stepNormalizer = (steps: any[]) => {
  const visibleSteps = steps.filter((step: any) => step.event.meta.visible !== false)
  const minStepTime = Math.min(...visibleSteps.map((step) => step.event.timestamp))

  let lastUrl = ''
  let lastDomain = ''
  return visibleSteps.map((step) => {
    const eventUrl = step.event.data.href
    if (eventUrl !== null && eventUrl !== undefined && eventUrl !== lastUrl) {
      lastUrl = eventUrl
      if (lastUrl !== null && lastUrl !== undefined) {
        lastDomain = new URL(lastUrl).hostname
      }
    }
    return {
      type: step.type || '',
      eventIndex: step.eventIndex || 0,
      chunkIndex: step.chunkIndex || 0,
      nodeId: step.event.data.id || 0,
      position: step.event.timestamp - minStepTime,
      timestamp: step.event.timestamp || 0,
      title: step.event.meta.title || '',
      description: step.event.meta.description || '',
      url: lastUrl || '',
      domain: lastDomain || '',
      stepNumber: step.stepNumber || 0,
      textValue: step.event.data.text || null,
    }
  })
}
