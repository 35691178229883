import React from 'react'

interface Props {
  onClick?: (event: any) => void
  children?: React.ReactNode
}

const CardContainer: React.FC<Props> = ({ children, onClick }) => (
  <div
    className="shadow rounded h-80 bg-white w-full hover:shadow-lg cursor-pointer transition-shadow"
    onClick={onClick}
  >
    {children}
  </div>
)

export default CardContainer
