import React from 'react'
import { Link } from 'react-router-dom'
import {
  AcademicCapIcon,
  ChatBubbleBottomCenterTextIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import HeaderActionButton from '../../../components/HeaderActionButton'
import AgentImage from '../../../components/AgentImage'
import { AgentQuery } from 'app/javascript/components/graphql'
import { Tooltip } from '@mui/material'
import clsx from 'clsx'

const AgentHeader = ({ agent }: { agent: AgentQuery['agent'] }) => {
  if (!agent) {
    return <></>
  }

  const isExpertAgent = agent.type === 'ExpertAgent'
  const uuid = agent.uuid
  const agentLink = '/agent/' + uuid
  return (
    <div
      className={clsx(
        'bg-white pt-px rounded-2xl shadow-xl',
        isExpertAgent ? 'bg-gradient-to-r from-blue-50 to-white' : 'bg-white'
      )}
    >
      <div className="flex flex-row">
        <div className="min-w-0 flex-1  flex flex-row items-center space-x-5">
          <Link to={agentLink} className="cursor-pointer">
            <AgentImage agent={agent} className="w-24 h-24 rounded-l-xl" />
          </Link>

          <div className={clsx('flex flex-col font-display items-baseline tracking-tight ')}>
            <Link
              to={agentLink}
              className="text-3xl sm:text-4xl font-extrabold text-blue-600 cursor-pointer hover:underline"
            >
              {agent.firstName}
            </Link>
            <div className="font-semibold">{agent.jobTitle}</div>
          </div>
          <div className="flex items-center"></div>
        </div>

        <div className="flex flex-row items-center space-x-2 mr-2">
          <Tooltip title={'Info'} placement="bottom" arrow={true}>
            <Link to={'/agent/' + uuid}>
              <HeaderActionButton icon={<ChatBubbleBottomCenterTextIcon className="size-4" />} />
            </Link>
          </Tooltip>
          <Tooltip title={'Knowledge'} placement="bottom" arrow={true}>
            <Link to={'/agent/knowledge/' + uuid}>
              <HeaderActionButton icon={<AcademicCapIcon className="size-4" />} />
            </Link>
          </Tooltip>

          <Link to={'/agent/' + uuid + '/chat'}>
            <HeaderActionButton label={'New Chat'} icon={<PlusIcon className="h-4 w-4 mr-2" />} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AgentHeader
