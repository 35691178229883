import React from 'react'

import './style.scss'

type TimerProps = {
  duration: number
  position: number
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}

function convertMsToTime(milliseconds: number) {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)

  seconds = seconds % 60
  minutes = minutes % 60

  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
}

const Timer: React.FC<TimerProps> = ({ duration, position }) => {
  return (
    <div className="audio-timer">
      {convertMsToTime(position)} / {convertMsToTime(duration)}
    </div>
  )
}
export default Timer
