import User from '../models/User'

export const isAdmin = (user: User) => hasRole(user, 'admin')
export const isOrganizationAdmin = (user: User) => hasRole(user, 'organization_admin')

export const hasRole = (user: User, role: string) => {
  const index = user.roles.findIndex((userRole) => {
    return userRole.name.toLowerCase() === role
  })

  return index !== -1
}
