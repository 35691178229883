import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { ResponsiveAreaBump } from '@nivo/bump'
import { ColorSchemeId } from '@nivo/colors'

interface ChartConfig {
  chartType: 'pie' | 'bar' | 'line' | 'scatter' | 'area' | 'histogram'
  title: string
  xAxis: { label: string; dataKey: string }
  yAxis: { label: string; dataKey: string }
  data: Array<{ xValue: string | number; yValue: number }>
  options?: {
    colorScheme?: ColorSchemeId
    interactive?: boolean
    legend?: boolean
    innerRadius?: number
    stacked?: boolean
    pointSize?: number
  }
}

interface NivoContainerProps {
  title: string
  children: React.ReactNode
}

const NivoContainer: React.FC<NivoContainerProps> = ({ title, children }) => {
  const defaultHeight = 400
  return (
    <div
      className="flex flex-col items-center justify-center w-full"
      style={{ height: defaultHeight }}
    >
      {title && <h3 className="text-center text-base font-bold mb-4">{title}</h3>}
      {children}
    </div>
  )
}

const NivoChart: React.FC<{ config: ChartConfig }> = ({ config }) => {
  const { chartType, data, xAxis, yAxis, options, title } = config

  // Filter and validate data to prevent NaN values in `yValue`
  const validData = data
    .filter((d) => d.yValue != null && !isNaN(d.yValue))
    .map((d) => ({
      ...d,
      yValue: d.yValue ?? 0,
    }))

  const maxItemsInRow = 5
  const hasLongColumnName = data.some((d) => d.xValue.toString().length > 20)
  const columnLayout = data.length > maxItemsInRow || hasLongColumnName
  // Dynamic legend configuration based on number of items
  const legendConfig = [
    {
      anchor: 'right' as const,
      direction: columnLayout ? ('column' as const) : ('row' as const),
      translateY: columnLayout ? 0 : 56,
      itemWidth: columnLayout ? 80 : 100, // Adjust width for row layout
      itemHeight: 24,
      symbolSize: 18,
      symbolShape: 'circle' as const,
    },
  ]

  switch (chartType) {
    case 'pie':
      return (
        <NivoContainer title={title}>
          <ResponsivePie
            data={validData.map((d) => ({
              id: d.xValue.toString(),
              label: d.xValue.toString(),
              value: d.yValue,
            }))}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={options?.innerRadius || 0}
            colors={{ scheme: options?.colorScheme || 'nivo' }}
            enableArcLabels={options?.interactive}
            legends={options?.legend ? legendConfig : []}
          />
        </NivoContainer>
      )
    case 'bar':
    case 'histogram':
      return (
        <NivoContainer title={title}>
          <ResponsiveBar
            data={validData.map((d) => ({ [xAxis.dataKey]: d.xValue, [yAxis.dataKey]: d.yValue }))}
            keys={[yAxis.dataKey]}
            indexBy={xAxis.dataKey}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            colors={{ scheme: options?.colorScheme || 'nivo' }}
            enableLabel={options?.interactive}
            legends={
              options?.legend ? legendConfig.map((legend) => ({ ...legend, dataFrom: 'keys' })) : []
            }
            groupMode={
              chartType === 'histogram' ? 'stacked' : options?.stacked ? 'stacked' : 'grouped'
            }
            padding={chartType === 'histogram' ? 0.1 : 0.3} // Adjust padding for histogram look
          />
        </NivoContainer>
      )
    case 'line':
      return (
        <NivoContainer title={title}>
          <ResponsiveLine
            data={[{ id: 'series', data: validData.map((d) => ({ x: d.xValue, y: d.yValue })) }]}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            colors={{ scheme: options?.colorScheme || 'nivo' }}
            enablePoints={options?.interactive}
            legends={options?.legend ? legendConfig : []}
          />
        </NivoContainer>
      )
    case 'scatter':
      return (
        <NivoContainer title={title}>
          <ResponsiveScatterPlot
            data={[
              { id: 'scatterSeries', data: validData.map((d) => ({ x: d.xValue, y: d.yValue })) },
            ]}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            colors={{ scheme: options?.colorScheme || 'nivo' }}
            legends={options?.legend ? legendConfig : []}
          />
        </NivoContainer>
      )
    case 'area':
      return (
        <NivoContainer title={title}>
          <ResponsiveAreaBump
            data={[
              { id: 'areaSeries', data: validData.map((d) => ({ x: d.xValue, y: d.yValue })) },
            ]}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            colors={{ scheme: options?.colorScheme || 'nivo' }}
          />
        </NivoContainer>
      )
    default:
      return <div>Chart type not supported</div>
  }
}

export default NivoChart
