import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { FieldData } from '.'
import {
  CreateFileAttachmentMutation,
  CreateFileAttachmentMutationVariables,
  FileAttachment,
  GetFileAttachmentQuery,
  GetFileAttachmentQueryVariables,
} from '../../../graphql'
import ActionButton from './ActionButton'
import { CloudArrowUpIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/solid'
import EmptyContentBox from './EmptyContentBox'
import { Dialog, DialogBackdrop, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_FILE_ATTACHMENT_QUERY,
  MUTATION_CREATE_FILE_ATTACHMENT,
} from '../../graphql/queries/file_attachment'
import Spin from '../../../../components/common/ui/Spin'
import { Link } from 'react-router-dom'

const SUPPORTED_FILE_EXTENSIONS: string[] = [
  '.c',
  '.cpp',
  '.css',
  '.csv',
  '.doc',
  '.docx',
  '.gif',
  '.go',
  '.html',
  '.java',
  '.jpeg',
  '.jpg',
  '.js',
  '.json',
  '.md',
  '.pdf',
  '.php',
  '.pkl',
  '.png',
  '.pptx',
  '.py',
  '.rb',
  '.tar',
  '.tex',
  '.ts',
  '.txt',
  '.webp',
  '.xlsx',
  '.xml',
  '.zip',
]

const SelectableFileAttachment = ({
  fileAttachment,
  onRemove,
}: {
  fileAttachment: FileAttachment
  onRemove: (fileAttachment: FileAttachment) => void
}) => {
  const url = `/file_attachments/${fileAttachment.uuid}`
  return (
    <div className="relative">
      <Link to={url} target="_blank">
        <div
          className={`relative border-2 border-gray-300 rounded-lg py-2 px-1 h-24 w-48 flex flex-col items-center justify-center text-center`}
        >
          <div className="text-sm line-clamp-1 ">{fileAttachment.fileName}</div>
        </div>
      </Link>
      <button
        type="button"
        className="absolute -top-3 -right-3 p-1 text-white rounded-full bg-red-500 hover:bg-red-600"
        onClick={() => onRemove(fileAttachment)}
      >
        <MinusIcon className="w-4 h-4" />
      </button>
    </div>
  )
}

interface FileUploadModalProps {
  isOpen: boolean
  onClose: () => void
  onUploadSuccess?: (response: any) => void
}

export const FileUploadModal: React.FC<FileUploadModalProps> = ({
  isOpen,
  onClose,
  onUploadSuccess,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [error, setError] = useState<string>('')

  const maxFileSize = 10 * 1024 * 1024 // 10MB in byte

  const [uploadFile, { loading }] = useMutation<
    CreateFileAttachmentMutation,
    CreateFileAttachmentMutationVariables
  >(MUTATION_CREATE_FILE_ATTACHMENT)

  const isFileExtensionAllowed = (fileName: string) => {
    const fileExtension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase()
    return SUPPORTED_FILE_EXTENSIONS.includes(fileExtension)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (!isFileExtensionAllowed(file.name)) {
        setError('Invalid file type. Only specific file extensions are allowed.')
        return
      }
      if (file.size > maxFileSize) {
        setError('File is too large. Maximum size is 10MB.')
        return
      }
      setSelectedFile(file)
      setError('')
    }
  }

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    const file = event.dataTransfer.files?.[0]
    if (file) {
      if (!isFileExtensionAllowed(file.name)) {
        setError('Invalid file type. Only specific file extensions are allowed.')
        return
      }
      if (file.size > maxFileSize) {
        setError('File is too large. Maximum size is 10MB.')
        return
      }
      setSelectedFile(file)
      setError('')
    }
  }, [])

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  const handleUpload = async () => {
    if (!selectedFile) {
      setError('Please select a file first')
      return
    }

    try {
      const response = await uploadFile({
        variables: { fileAttachment: selectedFile },
        context: {
          fetchOptions: {
            onUploadProgress: (progressEvent: any) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              setUploadProgress(progress)
            },
          },
        },
      })

      if (response.data) {
        onUploadSuccess?.(response.data.createFileAttachment)
        onClose()
      }
    } catch (err) {
      setError('Failed to upload file. Please try again.')
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-50" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Upload File
                </Dialog.Title>

                <div className="mt-4">
                  <div
                    className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    <input
                      type="file"
                      className="hidden"
                      id="fileInput"
                      onChange={handleFileChange}
                    />
                    <label
                      htmlFor="fileInput"
                      className="cursor-pointer text-blue-500 hover:text-blue-700"
                    >
                      {selectedFile ? (
                        <span>Selected: {selectedFile.name}</span>
                      ) : (
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <CloudArrowUpIcon className="w-8 h-8 mb-4 text-gray-500" />

                          <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold">Click to upload</span> or drag and drop
                          </p>
                          <p className="text-xs text-gray-500">
                            {SUPPORTED_FILE_EXTENSIONS.map((ext) => ext.toUpperCase()).join(', ')}{' '}
                            (MAX. 10MB)
                          </p>
                        </div>
                      )}
                    </label>
                  </div>

                  {error && <p className="mt-2 text-sm text-red-600">{error}</p>}

                  {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="mt-4">
                      <div className="h-2 bg-gray-200 rounded-full">
                        <div
                          className="h-2 bg-blue-500 rounded-full"
                          style={{ width: `${uploadProgress}%` }}
                        />
                      </div>
                      <p className="text-sm text-gray-500 mt-1">{uploadProgress}% uploaded</p>
                    </div>
                  )}

                  <div className="mt-6 flex justify-end gap-3">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={handleUpload}
                      disabled={!selectedFile || loading}
                    >
                      {loading ? 'Uploading...' : 'Upload'}
                    </button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export const FileAttachmentField = ({ field, formik }: { field: FieldData; formik: any }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedFileAttachments, setSelectedFileAttachments] = useState<FileAttachment[]>([])
  const [isDefaultFileAttachmentsLoading, setIsDefaultFileAttachmentsLoading] = useState(false)

  const defaultFileAttachments = formik.values[field.name]
  const [getFileAttachment] = useLazyQuery<GetFileAttachmentQuery, GetFileAttachmentQueryVariables>(
    GET_FILE_ATTACHMENT_QUERY
  )

  useEffect(() => {
    setIsDefaultFileAttachmentsLoading(true)

    const fetchData = async () => {
      const fileAttachments = await Promise.all(
        defaultFileAttachments.map(async (uuid: string) => {
          const { data } = await getFileAttachment({ variables: { uuid } })
          return data?.fileAttachment
        })
      )
      setSelectedFileAttachments(fileAttachments)
      setIsDefaultFileAttachmentsLoading(false)
    }

    fetchData()
  }, [])

  const handleFileUploadSuccess = (fileAttachment: FileAttachment) => {
    setSelectedFileAttachments([...selectedFileAttachments, fileAttachment])
  }

  const handleRemoveFileAttachment = (fileAttachment: FileAttachment) => {
    setSelectedFileAttachments(
      selectedFileAttachments.filter((fa) => fa.uuid !== fileAttachment.uuid)
    )
  }

  useEffect(() => {
    const newFileList = selectedFileAttachments.map((fa) => fa.uuid)
    formik.setFieldValue(field.name, newFileList)
  }, [selectedFileAttachments])

  return (
    <>
      <div className="flex flex-row flex-wrap gap-2">
        {isDefaultFileAttachmentsLoading ? (
          <Spin className="h-4 w-4 text-ddu-blue m-auto" />
        ) : (
          <>
            {selectedFileAttachments.map((fa) => (
              <SelectableFileAttachment
                key={fa.uuid}
                fileAttachment={fa}
                onRemove={handleRemoveFileAttachment}
              />
            ))}
            {selectedFileAttachments.length === 0 && (
              <EmptyContentBox title="No files selected" widthClass="w-48" heightClass="h-24" />
            )}
          </>
        )}
      </div>
      <div className="flex flex-row gap-2 items-center justify-start mt-2">
        <ActionButton onClick={() => setIsOpen(true)}>
          <PlusIcon className="w-4 h-4" /> Add Files
        </ActionButton>
      </div>

      {isOpen && (
        <FileUploadModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onUploadSuccess={handleFileUploadSuccess}
        />
      )}
    </>
  )
}
