import React from 'react'
import { Switch } from '@headlessui/react'
import { Tooltip } from 'react-tooltip'
import * as style from './style.module.scss'
import 'react-tooltip/dist/react-tooltip.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setIsInteractiveMode } from '../../slices/playerSlice'
import clsx from 'clsx'

const ModeToggle = () => {
  const dispatch = useDispatch()
  const {
    playerControls: { isInteractiveMode },
  } = useSelector((state: RootState) => state.player)

  const handleToggle = () => {
    dispatch(setIsInteractiveMode(!isInteractiveMode))
  }

  return (
    <div className={style['mode-toggle']}>
      <Switch
        checked={isInteractiveMode}
        onChange={handleToggle}
        className={clsx(
          isInteractiveMode ? style['bg-ddu-blue-600'] : style['bg-gray'],
          style['switch']
        )}
        data-tooltip-id="switch-tooltip"
        data-tooltip-html="Interactive Mode"
      >
        <span
          aria-hidden="true"
          className={clsx(
            isInteractiveMode ? 'translate-x-5' : 'translate-x-0',
            style['switch-button']
          )}
        />
      </Switch>
      <Tooltip id="switch-tooltip" />
    </div>
  )
}

export default ModeToggle
