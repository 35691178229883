import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react'
import { MuteIcon, UnMuteIcon, PauseIcon, PlayIcon, DeleteIcon } from './icons'
import { clsx } from 'clsx'

export const IconButton: React.FC<PropsWithChildren & ButtonHTMLAttributes<any>> = ({
  children,
  ...props
}) => (
  <button {...props} className="icon-button">
    {children}
  </button>
)

export type MuteButtonProps = {
  muted: boolean
  toggle: () => void
}

export const MuteButton: React.FC<MuteButtonProps> = ({ muted, toggle }) => {
  return <IconButton onClick={toggle}>{muted ? <UnMuteIcon /> : <MuteIcon />}</IconButton>
}

type PlayPauseButtonProps = {
  playing: boolean
  toggle: () => void
}

export const PlayPauseButton: React.FC<PlayPauseButtonProps> = ({ playing, toggle }) => {
  return <IconButton onClick={toggle}>{playing ? <PauseIcon /> : <PlayIcon />}</IconButton>
}

type DeleteButtonProps = {
  handleDelete: () => void
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ handleDelete }) => {
  return (
    <IconButton onClick={handleDelete}>
      <DeleteIcon />
    </IconButton>
  )
}

interface Props {
  children?: React.ReactNode
  type: 'primary' | 'neutral' | 'action'
  onClick?: () => void
  className?: string
}

const Styles = {
  primary: 'text-white hover:bg-flowmo-blue-700 bg-flowmo-blue-600',
  neutral: 'text-white bg-[#BDC3CF] hover:bg-[#ADB2BD]',
  action: 'text-white bg-[#29BC7E] hover:bg-[#24AB72] action:bg-[#1D9663]',
}

const Button = ({ children, type, onClick, className = '' }: Props) => (
  <button
    onClick={onClick}
    className={clsx(
      'flex items-center justify-center py-2 px-4 border space-x-2',
      'border-transparent rounded-md shadow-sm text-sm font-medium whitespace-nowrap',
      className,
      Styles[type]
    )}
  >
    {children}
  </button>
)

export default Button
