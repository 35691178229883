import React from 'react'
import WorkflowCard from '../../../components/WorkflowCard'
import { GET_AGENT_WORKFLOWS } from '../../../graphql/queries/workflow'
import {
  AgentFieldsFragment,
  AgentWorkflowsQuery,
  AgentWorkflowsQueryVariables,
  AnalysesQuery,
  AnalysesQueryVariables,
  CompanyAgentFieldsFragment,
} from 'app/javascript/components/graphql'
import { useQuery } from '@apollo/client'
import { GET_ANALYSES_QUERY } from '../../../graphql/queries/analysis'
import AnalysisCard from '../../../components/AnalysisCard'

const AgentWorkForYou = ({
  agent,
}: {
  agent: AgentFieldsFragment | CompanyAgentFieldsFragment
}) => {
  const { data: workflowData } = useQuery<AgentWorkflowsQuery, AgentWorkflowsQueryVariables>(
    GET_AGENT_WORKFLOWS,
    {
      variables: {
        agentUuid: agent.uuid,
      },
      fetchPolicy: 'network-only',
    }
  )
  const { data: analysesData } = useQuery<AnalysesQuery, AnalysesQueryVariables>(
    GET_ANALYSES_QUERY,
    {
      variables: {
        agentUuid: agent.uuid,
      },
      fetchPolicy: 'network-only',
    }
  )
  const workflows = workflowData?.agentWorkflows
  const analyses = analysesData?.analyses

  return (
    <div>
      {workflows && workflows.length > 0 ? (
        <div className="mt-8">
          <div className="flex flex-col text-base font-medium tracking-tight">
            <div className="text-blue-600">I&apos;m managing these workflows -</div>
            <div className="flex flex-wrap space-x-0 sm:space-x-2 pt-2">
              {workflows.map((workflow: any, index: number) => (
                <WorkflowCard workflow={workflow} key={index} size={'small'} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-8">
          <div className="flex flex-col text-base font-medium tracking-tight">
            <div className="text-gray-600">I&apos;m not managing any workflows for you yet.</div>
          </div>
        </div>
      )}

      {analyses && analyses.length > 0 ? (
        <div className="mt-8">
          <div className="flex flex-col text-base font-medium tracking-tight">
            <div className="text-blue-600">I&apos;m managing these analyses -</div>
            <div className="flex flex-wrap space-x-0 sm:space-x-2 pt-2">
              {analyses.map((analysis: any, index: number) => (
                <AnalysisCard analysis={analysis} key={index} size={'small'} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-8">
          <div className="flex flex-col text-base font-medium tracking-tight">
            <div className="text-gray-600">I&apos;m not managing any analyses for you yet.</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AgentWorkForYou
