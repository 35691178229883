import React, { useRef, useEffect } from 'react'
import { Howl } from 'howler'
import { RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { setHasAudioError, setIsAudioReady } from '../../slices/playerSlice'

type AudioProps = {
  url?: string
  muted: boolean
}

const AudioControl: React.FC<AudioProps> = ({ url = '', muted }) => {
  const dispatch = useDispatch()
  const {
    position,
    playerControls: { isPlaying, isVideoAtEnd, isSeeking },
  } = useSelector((state: RootState) => state.player)
  const audio = useRef<Howl | null>(null)

  useEffect(() => {
    audio.current = new Howl({
      src: [url],
      format: ['webm'],
      onload: () => dispatch(setIsAudioReady(true)),
      onloaderror: () => dispatch(setHasAudioError(true)),
    })

    return () => {
      audio?.current?.stop()
    }
  }, [])

  useEffect(() => {
    audio.current && audio.current.mute(muted)
  }, [muted])

  useEffect(() => {
    if (isPlaying) {
      if (audio.current) {
        audio.current.seek(isVideoAtEnd ? 0 : position * 0.001)
        audio.current.play()
      }
    } else {
      if (audio.current) {
        audio.current.seek(position * 0.001)
        audio.current.pause()
      }
    }
  }, [isPlaying])

  useEffect(() => {
    if (audio.current && isSeeking) {
      audio.current.seek(position * 0.001)
    }
  }, [isSeeking])

  return <React.Fragment />
}

const NoAudio: React.FC<AudioProps> = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setIsAudioReady(true))
  }, [])

  return <React.Fragment />
}

export default function Audio(props: AudioProps) {
  if (!props.url) {
    return <NoAudio {...props} />
  }

  return <AudioControl {...props} />
}
