import React from 'react'

import * as styles from './style.module.scss'

type LoadingProps = React.PropsWithChildren & {
  loaded: number
  total: number
  loading: boolean
}

const getProgressString = (loaded: number, total: number) => {
  const percentage = loaded / total

  if (percentage) {
    if (percentage < 0.3) {
      return 'Starting data download'
    }
    if (percentage < 0.6) {
      return 'Extracting required events'
    }

    if (percentage < 0.9) {
      return 'Preparing video to be displayed'
    }

    if (percentage <= 1) {
      return 'Video is ready to be viewed'
    }
  }

  return 'Preparing to download'
}

const Loading: React.FC<LoadingProps> = ({ children, loaded, total, loading }) => {
  return (
    <React.Fragment>
      <div
        className={
          styles['flow-mo-loading'] +
          ' ' +
          (loading ? styles['flow-mo-loading-visible'] : styles['flow-mo-loading-hidden'])
        }
      >
        <div className={styles['flow-mo-loading-content']}>
          <Loader />
          <div>{getProgressString(loaded, total)}</div>
        </div>
        <div className={styles['flow-mo-loading-blur']} />
      </div>
      {children}
      <div className={styles['flow-mo-loading-background']} />
    </React.Fragment>
  )
}

const Loader = () => (
  <div className={styles['lds-ring']}>
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default Loading
