import { createContext, useContext, useMemo } from 'react'
import { LabsContextType } from './types'

const DefaultContextValues: LabsContextType = {
  walkthrough: null,
  completed: false,
  stepIndex: 0,
  nextStep: () => null,
  prevStep: () => null,
  reset: () => null,
  steps: [],
}

export const LabsContext = createContext(DefaultContextValues)

export function useLabsContext() {
  const context: LabsContextType = useContext(LabsContext)
  return useMemo(() => context, [context])
}
