import React from 'react'

export const GrantedIcon = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="19" cy="19" r="17.5" fill="#29BB7D" stroke="white" strokeWidth="3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.875 12.9688H19.8125C20.3475 12.9688 20.7812 13.4025 20.7812 13.9375V19.75C20.7812 20.285 20.3475 20.7188 19.8125 20.7188H17.875C17.34 20.7188 16.9062 20.285 16.9062 19.75V13.9375C16.9062 13.4025 17.34 12.9688 17.875 12.9688ZM15.9375 13.9375C15.9375 12.8674 16.8049 12 17.875 12H19.8125C20.8826 12 21.75 12.8674 21.75 13.9375V19.75C21.75 20.8201 20.8826 21.6875 19.8125 21.6875H17.875C16.8049 21.6875 15.9375 20.8201 15.9375 19.75V13.9375ZM14.9688 18.7812C14.9688 18.5137 14.7519 18.2969 14.4844 18.2969C14.2169 18.2969 14 18.5137 14 18.7812V20.2344C14 22.107 15.518 23.625 17.3906 23.625H18.3594V24.5938H16.9062C16.6387 24.5938 16.4219 24.8106 16.4219 25.0781C16.4219 25.3456 16.6387 25.5625 16.9062 25.5625H20.7812C21.0488 25.5625 21.2656 25.3456 21.2656 25.0781C21.2656 24.8106 21.0488 24.5938 20.7812 24.5938H19.3281V23.625H20.2969C22.1695 23.625 23.6875 22.107 23.6875 20.2344V18.7812C23.6875 18.5137 23.4706 18.2969 23.2031 18.2969C22.9356 18.2969 22.7188 18.5137 22.7188 18.7812V20.2344C22.7188 21.5719 21.6344 22.6562 20.2969 22.6562H17.3906C16.0531 22.6562 14.9688 21.5719 14.9688 20.2344V18.7812Z"
      fill="white"
    />
  </svg>
)

export const DeniedIcon = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="19" cy="19" r="17.5" fill="#D83C32" stroke="white" strokeWidth="3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 13.5V19.5C25 19.7761 24.7761 20 24.5 20C24.2239 20 24 19.7761 24 19.5V13.5C24 13.2239 24.2239 13 24.5 13C24.7761 13 25 13.2239 25 13.5ZM23 13.5C23 12.6716 23.6716 12 24.5 12C25.3284 12 26 12.6716 26 13.5V19.5C26 20.3284 25.3284 21 24.5 21C23.6716 21 23 20.3284 23 19.5V13.5ZM25 23.5C25 23.7761 24.7761 24 24.5 24C24.2239 24 24 23.7761 24 23.5C24 23.2239 24.2239 23 24.5 23C24.7761 23 25 23.2239 25 23.5ZM26 23.5C26 24.3284 25.3284 25 24.5 25C23.6716 25 23 24.3284 23 23.5C23 22.6716 23.6716 22 24.5 22C25.3284 22 26 22.6716 26 23.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7153 18.2585C23.7153 17.6694 23.4411 17.1373 23 16.7579V19.5C23 20.0408 23.2862 20.5147 23.7153 20.7786V18.2585ZM21.3723 16.1724C21.5897 16.1724 21.8002 16.1987 22 16.2481V17.296C21.8211 17.2186 21.6109 17.1724 21.3723 17.1724H14.3431C13.489 17.1724 13 17.7647 13 18.2585V23.4739C13 23.9677 13.489 24.5601 14.3431 24.5601H21.3723C21.682 24.5601 21.9438 24.4821 22.1516 24.3593C22.2681 24.6776 22.4475 24.9656 22.6747 25.2083C22.3022 25.4305 21.8543 25.5601 21.3723 25.5601H14.3431C13.049 25.5601 12 24.6261 12 23.4739V18.2585C12 17.1065 13.0489 16.1725 14.3428 16.1724V15.1292C14.3428 13.401 15.9163 12 17.8574 12C19.7984 12 21.372 13.401 21.372 15.1292V16.1724H21.3723ZM23.7153 23.4739V22.2214C23.2862 22.4853 23 22.9592 23 23.5C23 23.8938 23.1518 24.2522 23.4 24.5198C23.6005 24.2123 23.7153 23.8551 23.7153 23.4739ZM20.2004 16.1724V15.1292C20.2004 13.9771 19.1514 13.0431 17.8574 13.0431C16.5633 13.0431 15.5143 13.9771 15.5143 15.1292V16.1724H20.2004Z"
      fill="white"
    />
  </svg>
)

export const PromptIcon = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="19" cy="19" r="17.5" fill="#434E5F" stroke="white" strokeWidth="3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.875 12.9688H17.8125C18.3475 12.9688 18.7812 13.4025 18.7812 13.9375V15.8585L18.8013 15.8675C19.0773 15.6685 19.4032 15.5379 19.75 15.4994V13.9375C19.75 12.8674 18.8826 12 17.8125 12H15.875C14.8049 12 13.9375 12.8674 13.9375 13.9375V19.75C13.9375 20.8201 14.8049 21.6875 15.875 21.6875H17.8125C18.1713 21.6875 18.5074 21.5899 18.7956 21.4199L18.5605 20.3657C18.3828 20.5813 18.1137 20.7188 17.8125 20.7188H15.875C15.34 20.7188 14.9062 20.285 14.9062 19.75V13.9375C14.9062 13.4025 15.34 12.9688 15.875 12.9688ZM18.9926 17.7094C18.864 17.133 19.2465 16.6288 19.75 16.514V16.514C19.9492 16.4686 20.1673 16.4841 20.3802 16.5802L27.1061 19.6166C27.8989 19.9745 27.8884 21.104 27.089 21.447L24.4594 22.5754C24.2881 22.6489 24.1408 22.7688 24.034 22.9215L22.3949 25.2671C21.8966 25.9801 20.7885 25.7609 20.5992 24.912L20.16 22.943C20.16 22.943 20.1599 22.943 20.1599 22.9431L19.9504 22.0039C19.9505 22.0039 19.9505 22.0039 19.9505 22.0038L19.6096 20.4754C19.6096 20.4755 19.6096 20.4755 19.6095 20.4756L18.9926 17.7094ZM19.2539 23.4748L19.0451 22.5385C18.8094 22.6149 18.558 22.6562 18.2969 22.6562H15.3906C14.0531 22.6562 12.9688 21.5719 12.9688 20.2344V18.7812C12.9688 18.5137 12.7519 18.2969 12.4844 18.2969C12.2169 18.2969 12 18.5137 12 18.7812V20.2344C12 22.107 13.518 23.625 15.3906 23.625H16.3594V24.5938H14.9062C14.6387 24.5938 14.4219 24.8106 14.4219 25.0781C14.4219 25.3456 14.6387 25.5625 14.9062 25.5625H18.7812C19.0488 25.5625 19.2656 25.3456 19.2656 25.0781C19.2656 24.8106 19.0488 24.5938 18.7812 24.5938H17.3281V23.625H18.2969C18.6454 23.625 18.9648 23.5724 19.2539 23.4748ZM19.9687 17.4917L26.6947 20.528L24.065 21.6564C23.7225 21.8034 23.4278 22.0432 23.2143 22.3487L21.5752 24.6943L19.9687 17.4917Z"
      fill="white"
    />
  </svg>
)
