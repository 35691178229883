import { useCallback, useEffect, useState } from 'react'

export default (handler: () => void, delay = 500) => {
  const [init, setInit] = useState<boolean>(false)
  const [counter, setCounter] = useState<number>(0)

  useEffect(() => {
    const timeoutId = init && setTimeout(handler, delay)

    return () => {
      timeoutId && clearTimeout(timeoutId)
    }
  }, [delay, counter, init])

  const actionTracker = useCallback(() => {
    !init && setInit(true)
    setCounter((prevState) => prevState + 1)
  }, [counter])

  return { actionTracker }
}
