import React from 'react'
import { ShareIcon } from '@heroicons/react/24/solid'
import { Dialog } from '@headlessui/react'

interface Props {
  objectType: 'walkthrough' | 'skillSet'
}

const CONTENT_NAME = {
  walkthrough: 'Walkthrough',
  skillSet: 'Skill Set',
}

const Header: React.FC<Props> = ({ objectType }) => (
  <>
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-flowmo-blue-200">
      <ShareIcon className="h-6 w-6 text-flowmo-blue-600" aria-hidden="true" />
    </div>
    <div className="mt-3 text-center sm:mt-5">
      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 mb-5">
        {CONTENT_NAME[objectType]} Sharing
      </Dialog.Title>
    </div>
  </>
)

export default Header
