import React from 'react'
import { useEmbedContext } from '../../context/EmbedContext'
import IconButton, { Sizes } from '../IconButton'
import { ShareIcon } from './icons'
import { shareLink } from '../../../common/utils/links'
import { useCopyToClipboard } from '../../../common/hooks/useCopyToClipboard'
import { SuccessIcon } from '../StepNavigation/icons'

const ShareButton = () => {
  const { walkthrough } = useEmbedContext()

  const shareUrl = new URL(shareLink(walkthrough.uuid, 'walkthrough'), document.baseURI).href
  const [copyTextStatus, copyText] = useCopyToClipboard(shareUrl)

  return (
    <IconButton size={Sizes.MEDIUM} onClick={copyText}>
      <ShareIcon />

      {copyTextStatus === 'copied' && (
        <div className="absolute bg-[rgba(0,0,0,0.7)] rounded px-2.5 py-1 text-[12px] top-[56px] flex items-center space-x-1.5 z-50">
          <SuccessIcon />
          <div>Link copied</div>
        </div>
      )}
    </IconButton>
  )
}

export default ShareButton
