import { gql } from '@apollo/client'

export const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation updateOrganization($id: Int!, $name: String!) {
    updateOrganization(id: $id, name: $name) {
      success
      errors
    }
  }
`

export const GET_ORGANIZATION_CREDIT_USAGES = gql`
  query creditUsages($startDate: String!, $endDate: String!) {
    creditUsages(startDate: $startDate, endDate: $endDate) {
      usageDate
      quantity
    }
  }
`
