import React from 'react'
import useCirclesPosition from './useCirclesPosition'
import useBeaconPosition from './useBeaconPosition'

import DescriptionHighlighter from './strategies/DescriptionHighlighter'
import PureHighlighter from './strategies/PureHighlighter'
import { NormalizedStepProps } from '../../../Player/types'

export interface Props {
  step: NormalizedStepProps
  element?: HTMLElement
  position: DOMRect
}

const Highlighter = ({ step, element, position }: Props) => {
  const description = ''

  const circlesRef = useCirclesPosition(position)
  const { beaconRef, styles } = useBeaconPosition(position)
  if (!element || !styles || !position || !position.width || !position.height) {
    return <React.Fragment></React.Fragment>
  }

  return description ? (
    <DescriptionHighlighter
      description={description}
      circleRef={circlesRef}
      beaconRef={beaconRef}
      styles={styles}
      step={step}
    />
  ) : (
    <PureHighlighter circleRef={circlesRef} />
  )
}

export default Highlighter
