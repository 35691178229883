export const RECORD_WALKTHROUGH = 'RECORD_WALKTHROUGH'

export const sendMessageToExtension = (payload: any) => {
  if (typeof window !== 'undefined') {
    window.top.postMessage({ id: process.env.EXTENSION_MESSAGE_ID, payload }, '*')
  }
}

export const sendRRWebWalkthroughCreationAction = () => {
  sendMessageToExtension({
    action: RECORD_WALKTHROUGH,
  })
}
