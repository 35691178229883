import { createContext, useContext, useMemo } from 'react'
import { EmbedContextType, ModeTypes } from './types'

const DefaultContextValues: EmbedContextType = {
  mode: ModeTypes.VIDEO,
  setMode: () => null,
  walkthrough: null,
  rootUrl: null,
  enabled: false,
  chunks: [],
}

export const EmbedContext = createContext(DefaultContextValues)

export function useEmbedContext() {
  const context: EmbedContextType = useContext(EmbedContext)
  return useMemo(() => context, [context])
}
