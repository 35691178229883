import React, { useEffect, useRef, useState } from 'react'
import StepBeacon from './StepBeacon'
import * as styles from './style.module.scss'
import { RootState } from '../../../../store'
import { useSelector } from 'react-redux'
import { NormalizedStepProps } from '../../../../types'
import { THUMB_WIDTH } from '../../../../constants'

const StepTimeline = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [timelineWidth, setTimelineWidth] = useState<number>(0)
  const { steps, duration } = useSelector((state: RootState) => state.player)
  const clickSteps = steps.filter((step: NormalizedStepProps) => step.type !== 'goto')

  useEffect(() => {
    const updateTimelineWidth = () => {
      if (ref.current) {
        setTimelineWidth(ref.current.clientWidth)
      }
    }

    // Initially set the timelineWidth
    updateTimelineWidth()

    // Add a 'resize' event listener to update the timelineWidth when the window is resized
    window.addEventListener('resize', updateTimelineWidth)

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', updateTimelineWidth)
    }
  }, [])

  return (
    <div ref={ref} className={styles['flow-mo-step-list']}>
      {clickSteps.map((oneStep: NormalizedStepProps, index: number) => {
        return (
          <StepBeacon
            step={oneStep}
            key={index}
            style={{
              left: (oneStep.position / duration) * (timelineWidth - THUMB_WIDTH) + 'px',
            }}
            stepNumber={index + 1}
          />
        )
      })}
    </div>
  )
}

export default StepTimeline
