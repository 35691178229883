import React from 'react'
import Divider from '../../../../../../common/ui/Divider'
import { DeniedScreen, PromptScreen } from './screens'
import { DeniedIcon, PromptIcon } from './icons'

import './style.scss'

type PermissionProps = {
  status: string | null
}

const AUDIO_SCREENS: any = {
  granted: null,
  denied: {
    text: 'Access is not granted for now. Follow those steps to enable permissions',
    mockup: <DeniedScreen />,
    icon: <DeniedIcon />,
  },
  prompt: {
    text: 'Please choose the "Allow" button in the popup windows to enable permissions',
    mockup: <PromptScreen />,
    icon: <PromptIcon />,
  },
}

const Permission: React.FC<PermissionProps> = ({ status }) => {
  const screen = React.useMemo(() => AUDIO_SCREENS[status], [status])

  if (!screen) {
    return <React.Fragment />
  }

  return (
    <div className="audio-permission-screen">
      <div className="flex w-full items-center flex-col pb-20">
        <div className="flex mb-4 w-[632px] px-4">
          <div className="h-full flex items-center">{screen.icon}</div>
          <Divider className="border-r min-h-full mx-4" />
          <p className="text-lg text-white font-medium">{screen.text}</p>
        </div>
        <div>{screen.mockup}</div>
      </div>
    </div>
  )
}

export default Permission
