import React, { useEffect } from 'react'
import { useLabsContext } from '../../context/LabsContext'
import { StepTypes } from '../../../App/models/Walkthrough'
import Screenshot from './Screenshot'
import { useEventTracking } from '../../../common/hooks/useEventTracking'
import { clsx } from 'clsx'

interface Props {
  className?: string
  completedView: React.ReactNode
  notFoundHeight: string
}
const LabsContent = ({ className, completedView, notFoundHeight }: Props) => {
  const { stepIndex, completed, walkthrough, steps } = useLabsContext()

  const logEvent = useEventTracking()

  useEffect(() => {
    if (walkthrough) {
      logEvent({
        type: 'walkthrough_embed:try_it',
        subjectType: 'walkthrough',
        subjectId: walkthrough.id,
      })
    }
  }, [walkthrough])

  return (
    <>
      <div
        id="flowmo-description-container"
        className={clsx('w-full max-w-full overflow-hidden relative bg-[#f1f1f1]', className)}
      >
        {steps
          .map(
            (step, index) =>
              step.type !== StepTypes.goto && (
                <Screenshot
                  key={index}
                  step={step}
                  active={index === stepIndex}
                  notFoundHeight={notFoundHeight}
                />
              )
          )
          .filter((component) => component)}
      </div>

      {completed && completedView}
    </>
  )
}

export default LabsContent
