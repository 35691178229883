import React, { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import ModalView from './SkillSetModal'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'

function clsx(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const CreateButton = () => {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal(!showModal)

  return (
    <>
      {showModal ? <ModalView onClose={toggleModal} open={showModal} /> : <></>}
      <Menu as="div" className="relative inline-block text-left w-full pb-6">
        <div>
          <Menu.Button className="inline-flex items-center justify-left w-full border shadow-sm rounded-md px-4 py-2 bg-flowmo-blue-600 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-flowmo-blue-500 justify-between border-transparent  hover:bg-flowmo-blue-700 focus:ring-offset-gray-100">
            <span className="m-auto">Create</span>
            <ChevronDownIcon className="h-5 w-5 group-hover:text-gray-900" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right w-full absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={toggleModal}
                    className={clsx(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'px-4 py-2 inline-block text-left w-full text-sm'
                    )}
                  >
                    Skill Set
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link to="/walkthrough/create">
                    <button
                      className={clsx(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'px-4 py-2 inline-block text-left w-full text-sm'
                      )}
                    >
                      Walkthrough
                    </button>
                  </Link>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}

export default CreateButton
