import React from 'react'
import SignIn from './SignIn'
import { SignInParams, useAuth } from '../../useAuth'

type LaunchAuthProps = {
  callback: () => void
}

const LaunchAuth: React.FunctionComponent<LaunchAuthProps> = ({ callback }) => {
  const { login, clearError, error, loading } = useAuth()

  React.useEffect(clearError)

  const handleSignIn = (values: SignInParams) => {
    login({
      username: values.email,
      password: values.password,
      callback,
    })
  }

  return (
    <div className="max-w-md w-full space-y-8 px-5 pb-3">
      <div>
        <h2 className="mt-4 text-center text-2xl font-bold text-gray-900">Sign In</h2>
        <SignIn
          loading={loading}
          error={error}
          redirect={() => true}
          handleSubmit={handleSignIn}
          handleBlur={clearError}
        />
      </div>
    </div>
  )
}

export default LaunchAuth
