import React from 'react'
import { Walkthrough } from 'app/javascript/components/graphql'
import CloseButton from './CloseButton'
import Header from './Header'
import CopyInput from './CopyInput'
import Container from './Container'
import Options from './options'
import { SkillSet } from 'app/javascript/components/graphql'

type Props = {
  object: Walkthrough | SkillSet
  objectType: 'walkthrough' | 'skillSet'
  onClose: () => void
}

const ShareDialog = ({ object, onClose, objectType }: Props) => (
  <Container onClose={onClose}>
    <CloseButton onClick={onClose} />
    <Header objectType={objectType} />
    <CopyInput objectType={objectType} object={object} />

    <Options object={object} objectType={objectType} />
  </Container>
)

export default ShareDialog
