import React from 'react'
import { useEmbedContext } from '../context/EmbedContext'
import { ModeTypes } from '../context/EmbedContext/types'
import LabsContent from './Labs'
import VideoContent from './Video'
import Unavailable from './Unavailable'
import SuccessView from './Labs/SuccessView'

const Content = () => {
  const { mode, enabled } = useEmbedContext()

  if (!enabled) {
    return <Unavailable />
  }

  if (mode === ModeTypes.LABS) {
    return (
      <LabsContent
        className="h-[calc(100vh-52px)]"
        completedView={<SuccessView />}
        notFoundHeight="min-h-[calc(100vh-52px)]"
      />
    )
  }

  return <VideoContent />
}

export default Content
