import { gql } from '@apollo/client'
import { AGENT_FIELDS } from './fragments'

export const GET_ORGANIZATION_SUGGESTIONS_QUERY = gql`
  query organizationSuggestions {
    organizationSuggestions {
      uuid
      body
      status
      createdAt
      updatedAt
      closedAt
      agent {
        ...AgentFields
      }
    }
  }

  ${AGENT_FIELDS}
`

export const GET_AGENT_SUGGESTIONS_QUERY = gql`
  query agentSuggestions($agentUuid: String!) {
    agentSuggestions(agentUuid: $agentUuid) {
      uuid
      body
      status
      createdAt
      updatedAt
      closedAt
    }
  }
`

export const TOGGLE_SUGGESTION_STATUS = gql`
  mutation toggleSuggestionStatus($uuid: String!) {
    toggleSuggestionStatus(uuid: $uuid) {
      success
      errors
      status
    }
  }
`
