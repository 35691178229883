import { DataSetFieldsFragment } from '../../../../../components/graphql'
import React from 'react'
import TextContent from '../../../components/Chat/TextContent'
interface DataSetSampleProps {
  dataSet: DataSetFieldsFragment
}

const DataSetSample = ({ dataSet }: DataSetSampleProps) => {
  return (
    <div>
      <TextContent content={{ type: 'text', value: dataSet.dataSample }} />
    </div>
  )
}

export default DataSetSample
