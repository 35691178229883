import React from 'react'
import { PhotoIcon } from '@heroicons/react/24/solid'

const Loader = () => (
  <div
    className="w-full min-h-[calc(100vh-52px)] bg-[#d6e2ff] flex justify-center items-center"
    style={{
      background:
        'linear-gradient(90deg, #214A86 0%, #305B9A 25.52%, rgba(46, 46, 46, 0.71) 63.44%, rgba(46, 46, 46, 0.71) 100%)',
    }}
  >
    <PhotoIcon className="max-w-[90%] w-[300px] text-white opacity-[15%] animate-pulse" />
  </div>
)

export default Loader
