import Circles from '../Circles'
import React, { useEffect } from 'react'
import { applyInitialDescriptionAnimation } from '../animation/with-description'
import Beacon from '../Beacon'
import { NormalizedStepProps } from '../../../../Player/types'

interface Props {
  circleRef: any
  beaconRef: any
  styles: any
  description?: any
  step: NormalizedStepProps
  onClose: () => void
}

const InitialDescriptionHighlighter = ({
  circleRef,
  beaconRef,
  styles,
  step,
  description,
  onClose,
}: Props) => {
  useEffect(() => {
    applyInitialDescriptionAnimation({ beaconClass: '.beacon', contentClass: '.beacon-content' })
  }, [])

  return (
    <React.Fragment>
      <Beacon
        elementRef={beaconRef}
        styles={styles}
        description={description}
        step={step}
        onClose={onClose}
      />
      <Circles elementRef={circleRef} className="initial-animation with-description" />
    </React.Fragment>
  )
}

export default InitialDescriptionHighlighter
