import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SidebarChat from '../../components/SidebarChat'
import Workspace from '../../components/Workspace'
import { chatActions } from '../../slices/chatSlice'
import { useChatSelector } from '../../store' // Assuming you have a root state type
import { GET_COMPANY_AGENT, SEND_AGENT_MESSAGE } from '../../graphql/queries/agent'
import {
  ActorEnum,
  CompanyAgentQuery,
  CompanyAgentQueryVariables,
  GetMessageHistoriesQuery,
  GetMessageHistoriesQueryVariables,
  GetSideEffectHistoriesQuery,
  GetSideEffectHistoriesQueryVariables,
  GetStepHistoriesQuery,
  GetStepHistoriesQueryVariables,
  SendAgentMessageMutation,
  SendAgentMessageMutationVariables,
} from '../../../../components/graphql'
import { useMutation, useQuery } from '@apollo/client'
import Spin from '../../../../components/common/ui/Spin'
import {
  GET_MESSAGE_HISTORIES,
  GET_SIDE_EFFECT_HISTORIES,
  GET_STEP_HISTORIES,
} from '../../graphql/queries/message_thread'

const Home = () => {
  const dispatch = useDispatch()
  const [activeChatId, setActiveChatId] = useState<string | null>(null)

  const activeChat = useChatSelector(activeChatId)

  // get the company agent
  const { data, loading } = useQuery<CompanyAgentQuery, CompanyAgentQueryVariables>(
    GET_COMPANY_AGENT
  )

  const companyAgent = data?.companyAgent

  const [sendMessage] = useMutation<SendAgentMessageMutation, SendAgentMessageMutationVariables>(
    SEND_AGENT_MESSAGE
  )

  const threadUuid = activeChat?.threadUuid

  const { data: messageHistoryData, refetch: refetchMessageHistoryData } = useQuery<
    GetMessageHistoriesQuery,
    GetMessageHistoriesQueryVariables
  >(GET_MESSAGE_HISTORIES, {
    // Only execute the query if the thread_uuid exists.
    skip: !threadUuid,
    variables: {
      uuid: threadUuid,
    },
    fetchPolicy: 'network-only',
  })

  const { data: stepHistoryData, refetch: refetchStepHistoryData } = useQuery<
    GetStepHistoriesQuery,
    GetStepHistoriesQueryVariables
  >(GET_STEP_HISTORIES, {
    // Only execute the query if the thread_uuid exists.
    skip: !threadUuid,
    variables: {
      uuid: threadUuid,
    },
    fetchPolicy: 'network-only',
  })

  const { data: sideEffectHistoryData, refetch: refetchSideEffectHistoryData } = useQuery<
    GetSideEffectHistoriesQuery,
    GetSideEffectHistoriesQueryVariables
  >(GET_SIDE_EFFECT_HISTORIES, {
    skip: !threadUuid,
    variables: {
      uuid: threadUuid,
    },
  })

  useEffect(() => {
    if (!stepHistoryData?.getStepHistories) {
      return
    }

    dispatch(
      chatActions.setSteps({ chatId: activeChat.chatId, steps: stepHistoryData.getStepHistories })
    )
  }, [stepHistoryData])

  useEffect(() => {
    const messageHistory = messageHistoryData?.getMessageHistories || []
    if (messageHistory.length === 0) {
      return
    }

    dispatch(chatActions.setMessages({ chatId: activeChat.chatId, messages: messageHistory }))
  }, [messageHistoryData])

  useEffect(() => {
    if (!sideEffectHistoryData?.getSideEffectHistories) {
      return
    }

    dispatch(
      chatActions.setSideEffects({
        chatId: activeChat.chatId,
        sideEffects: sideEffectHistoryData.getSideEffectHistories,
      })
    )
  }, [sideEffectHistoryData])

  useEffect(() => {
    // Create a new chat when the component mounts
    if (!activeChatId) {
      const action = chatActions.createChat()
      dispatch(action)
      setActiveChatId(action.payload.chatId)
    }

    // Cleanup function to remove the chat when the component unmounts
    return () => {
      if (activeChatId) {
        dispatch(chatActions.removeChat({ chatId: activeChatId }))
      }
    }
  }, [dispatch, activeChatId])

  useEffect(() => {
    if (companyAgent && activeChatId) {
      dispatch(chatActions.setAgent({ chatId: activeChatId, agent: companyAgent }))
      dispatch(chatActions.setAgentUuid({ chatId: activeChatId, agentUuid: companyAgent.uuid }))
    }
  }, [companyAgent, activeChatId])

  useEffect(() => {
    if (!activeChat || !activeChat.query || !activeChat.query.trim() || !activeChatId) {
      return
    }

    const processMessage = async () => {
      dispatch(
        chatActions.addMessage({
          chatId: activeChatId,
          message: {
            message: [{ type: 'text', value: activeChat.query, __typename: 'TextContent' }],
            actor: ActorEnum.User,
            createdAt: new Date().toISOString(),
            createdAtMilli: new Date().getTime(),
            id: null,
            updatedAt: new Date().toISOString(),
            uuid: '',
          },
        })
      )

      try {
        const { data } = await sendMessage({
          variables: {
            agentUuid: companyAgent.uuid,
            chatId: activeChat.chatId,
            enableStreaming: true,
            threadUuid: activeChat.threadUuid,
            message: activeChat.query,
          },
        })

        if (data.sendAgentMessage.errors.length) {
          window.toastr.error('Something went wrong. Please try again later.')
        }

        const newThreadUuid = data.sendAgentMessage.threadUuid
        if (newThreadUuid && newThreadUuid !== activeChat.threadUuid) {
          dispatch(
            chatActions.setThreadUuid({
              chatId: activeChat.chatId,
              threadUuid: data.sendAgentMessage.threadUuid,
            })
          )
        }

        await refetchMessageHistoryData()
        await refetchStepHistoryData()
        await refetchSideEffectHistoryData()
      } catch (error) {
        console.error('Failed to process message:', error)
        // Handle any other errors here
      } finally {
        dispatch(
          chatActions.setQuery({
            chatId: activeChat.chatId,
            query: '',
            fileAttachments: [],
            content: [],
          })
        )
        dispatch(chatActions.setIsQuerying({ chatId: activeChat.chatId, isQuerying: false }))
      }
    }

    processMessage()
  }, [activeChat?.query, activeChatId, companyAgent])

  if (!activeChatId || !activeChat || loading) {
    return <Spin />
  }

  const handleReset = () => {
    dispatch(chatActions.resetChat({ chatId: activeChatId }))
    setActiveChatId(null)
  }

  return (
    <div className="flex flex-row h-full">
      <div className="workspace flex-grow mr-[30em]">
        <Workspace />
      </div>
      <div className="conversation fixed w-[30em] right-0 top-0 pt-16 lg:pt-0 flex flex-col h-full border-l shadow ">
        <SidebarChat chatId={activeChatId} handleResetChat={handleReset} />
      </div>
    </div>
  )
}

export default Home
