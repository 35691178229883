import React from 'react'
import PromptForm from './PromptForm'
import { AgentQuery } from '../../../../../components/graphql'
import { useHistory } from 'react-router-dom'

const Prompts = ({ agent, onUpdate }: { agent: AgentQuery['agent']; onUpdate: () => void }) => {
  const history = useHistory()
  const onPromptSaved = () => {
    onUpdate()
    history.push(`/agent/${agent.uuid}`)
  }
  return (
    <div>
      <div className="flex flex-col space-y-4">
        <h3 className="text-xl font-semibold mb-5 pb-5 border-b">Prompts</h3>
        <div className="flex flex-col ml-12 space-y-4">
          <PromptForm agentUuid={agent.uuid} prompts={agent.prompts} onSaved={onPromptSaved} />
        </div>
      </div>
    </div>
  )
}
export default Prompts
