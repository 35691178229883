import React, { useEffect } from 'react'

import Spin from '../../../common/ui/Spin'
import useSkillSet from '../../hooks/useSkillSet'
import SkillSetViewer from '../Viewer/SkillSetViewer'
import SkillSetBreadcrumb from '../SkillSetBreadcrumb'
import {
  LearningActivityActionType,
  LearningActivityObjectType,
} from '../../../App/models/LearningActivity'
import { useMutation } from '@apollo/client'
import { CREATE_LEARNING_ACTIVITY } from '../../../App/graphql/queries/learning_activity'
import client from '../../../common/ApolloClient'
import { usePageTitle } from '../../../common/hooks/usePageTitle'
import { SkillSet } from 'app/javascript/components/graphql'

const Show = ({
  uuid,
  usePublicEndpoint = false,
}: {
  uuid: string
  usePublicEndpoint?: boolean
}) => {
  const {
    skillSet: { data: skillSet },
  } = useSkillSet(uuid)
  const [createLearningActivity] = useMutation(CREATE_LEARNING_ACTIVITY)

  useEffect(() => {
    if (skillSet && !usePublicEndpoint) {
      createLearningActivity({
        variables: {
          objectId: skillSet.id,
          objectType: LearningActivityObjectType.skillSet,
          action: LearningActivityActionType.viewed,
        },
      })
      client.resetStore()
    }
  }, [skillSet])

  usePageTitle(skillSet && `Skill Set | ${skillSet.name}`)

  if (!skillSet) {
    return <Spin className="-ml-1 mr-3 h-20 w-20 mt-20 text-ddu-blue" />
  }

  return (
    <>
      {!usePublicEndpoint && <SkillSetBreadcrumb skillSet={skillSet as SkillSet} />}

      <div className="relative">
        <SkillSetViewer skillSet={skillSet as SkillSet} isPublicView={usePublicEndpoint} />
      </div>
    </>
  )
}

export default Show
