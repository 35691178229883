import React from 'react'
import CheckerSection from './CheckerSection'
import { clsx } from 'clsx'
import PluginPreview from './ContentPreviews/PluginPreview'
import WalkthroughPreview from './ContentPreviews/WalkthroughPreview'

const testimonials = [
  [
    [
      {
        name: 'Data Fetcher',
        title: 'Marketing dashboards',
        description: 'Custom list of marketing dashboards refreshed daily',
        type: 'plugin',
      },
      {
        title: 'Mutlti-Cloud Costs',
        domain: 'trial.looker.com',
        type: 'walkthrough',
      },
    ],
    [
      {
        title: 'Navigating the homepage',
        domain: 'trial.looker.com',
        type: 'walkthrough',
      },
      {
        name: 'Web Content',
        title: 'API Reference',
        description: 'The documentation of the Looker API',
        type: 'plugin',
      },
      {
        title: 'Folder Structure',
        domain: 'trial.looker.com',
        type: 'walkthrough',
      },
    ],
  ],
  [
    [
      {
        name: 'Web Content',
        title: 'Looker Documentation',
        description: 'The full web content of Looker Documentation',
        type: 'plugin',
      },
      {
        title: 'Admin Panel',
        domain: 'trial.looker.com',
        type: 'walkthrough',
      },
    ],
    [
      {
        title: 'Enabled Beta Features in the Admin Panel',
        domain: 'trial.looker.com',
        type: 'walkthrough',
      },
      {
        name: 'Web Content',
        title: 'Looker Community Posts',
        description: 'The top posts from the Looker community',
        type: 'plugin',
      },
      {
        title: 'Web Session Explore',
        domain: 'trial.looker.com',
        type: 'walkthrough',
      },
      {
        name: 'Data Fetcher',
        title: 'Sales Explores',
        description: 'Custom list of explores refreshed daily',
        type: 'plugin',
      },
    ],
  ],
]

const HowItWorks = () => {
  return (
    <div className="pt-8 mt-4 mb-24">
      <CheckerSection>
        <div className="mx-auto max-w-xl text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-flowmo-blue-600">
            How it works
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Combine walkthroughs and plugins to create powerful intelligence
          </p>
        </div>

        <div className="mx-auto mt-16 sm:grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-1 md:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
          {testimonials.map((columnGroup, columnGroupIdx) => (
            <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
              {columnGroup.map((column, columnIdx) => (
                <div
                  key={columnIdx}
                  className={clsx(
                    (columnGroupIdx === 0 && columnIdx === 0) ||
                      (columnGroupIdx === testimonials.length - 1 &&
                        columnIdx === columnGroup.length - 1)
                      ? 'xl:row-span-2'
                      : 'xl:row-start-1',
                    'space-y-8'
                  )}
                >
                  {column.map((testimonial, contentIdx) => {
                    const key = columnGroupIdx + '_' + columnIdx + '_' + contentIdx
                    if (testimonial.type === 'plugin') {
                      return <PluginPreview key={key} plugin={testimonial} />
                    } else if (testimonial.type === 'walkthrough') {
                      return <WalkthroughPreview key={key} walkthrough={testimonial} />
                    }
                    return null
                  })}
                </div>
              ))}
            </div>
          ))}
        </div>
      </CheckerSection>
    </div>
  )
}

export default HowItWorks
