export const RRWEB_EVENT_TYPES = {
  DOM_CONTENT_LOADED: 0,
  LOAD: 1,
  FULL_SNAPSHOT: 2,
  INCREMENTAL_SNAPSHOT: 3,
  META: 4,
  CUSTOM: 5,
  PLUGIN: 6,
}

export const RRWEB_INCREMENTAL_SNAPSHOT_TYPES = {
  MUTATION: 0,
  MOUSE_MOVE: 1,
  MOUSE_INTERACTION: 2,
  SCROLL: 3,
  VIEW_PORT_RESIZE: 4,
  INPUT: 5,
  TOUCH_MOVE: 6,
  MEDIA_INTERACTION: 7,
  STYLE_SHEET_RULE: 8,
  CANVAS_MUTATION: 9,
  FONT: 10,
  LOG: 11,
  DRAG: 12,
  STYLE_DECLARATION: 13,
}

export const RRWEB_MOUSE_INTERACTION_TYPES = {
  MOUSE_UP: 0,
  MOUSE_DOWN: 1,
  CLICK: 2,
  CONTEXT_MENU: 3,
  DBL_CLICK: 4,
  FOCUS: 5,
  BLUR: 6,
  TOUCH_START: 7,
  TOUCH_MOVE_DEPARTED: 8,
  TOUCH_END: 9,
  TOUCH_CANCEL: 10,
}

export const RRWEB_TARGET_TYPES = {
  SELECT: 'select',
  INPUT: 'input',
  TEXTAREA: 'textarea',
}

export const RRWEB_INPUT_TYPES = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
}

export const RRWEB_NODE_TYPES = {
  DOCUMENT: 1,
  DOCUMENT_TYPE: 2,
  ELEMENT: 3,
  TEXT: 4,
  CDATA: 5,
  COMMENT: 6,
}
