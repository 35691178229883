import React from 'react'
import { StepTypes } from '../../components/Highlighter/icons'
import { isCheckbox } from '../../utils/helpers/element'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../Player/store'
import { clickInteractiveElement } from '../../../Player/slices/playerSlice'

interface Props {
  element: Node | null
}

const EventListener: React.FC<Props> = ({ element }) => {
  const dispatch: AppDispatch = useDispatch()
  const nextStep = () => {
    dispatch(clickInteractiveElement())
  }
  const { step } = useSelector((state: RootState) => state.player.playground)
  if (!element || !step) {
    return <React.Fragment></React.Fragment>
  }

  const events = {
    [StepTypes.click]: {
      type: 'click',
      listener: (event: Event) => {
        const { target }: { target: any } = event
        event.stopPropagation()
        !isCheckbox(target) && event.preventDefault()
        nextStep()
      },
    },
    [StepTypes.input]: {
      type: 'change',
      listener: (event: Event) => {
        event.stopPropagation()
        event.preventDefault()
        nextStep()
      },
    },
    [StepTypes.dropdownSelect]: {
      type: 'change',
      listener: (event: Event | any) => {
        event.stopPropagation()
        event.preventDefault()
        if (event.target.value === step.textValue) {
          nextStep()
        }
      },
    },
  }

  const event = React.useMemo(() => events[step.type], [step])

  React.useEffect(() => {
    element && event && element.addEventListener(event.type, event.listener)
    return () => {
      element && event && element.removeEventListener(event.type, event.listener)
    }
  }, [element, step])

  return <React.Fragment></React.Fragment>
}

export default EventListener
