import { useQuery } from '@apollo/client'
import { GET_SKILL_SET_QUERY } from '../../App/graphql/queries/skill_set'
import { SkillSetQuery, SkillSetQueryVariables } from '../../graphql'

const useSkillSetQuery = (uuid: string) => {
  const props = useQuery<SkillSetQuery, SkillSetQueryVariables>(GET_SKILL_SET_QUERY, {
    variables: { uuid },
  })

  return { ...props, data: props.data?.skillSet }
}

export default useSkillSetQuery
