import React from 'react'

import { Chunk } from '../../../../../hooks/useRRWebEvents'
import { Walkthrough } from 'app/javascript/components/graphql'
import Thumbnail from '../../../../../../common/components/Player/components/Thumbnail'
import PlayerEventTracking from '../../../../../../common/components/PlayerEventTracking'
import CompletedVideo from './CompletedVideo'
import Player from '../../../../../../AI/components/Player'

type PlayerProps = {
  chunks: Chunk[]
  walkthrough: Walkthrough
  maxHeight?: number
}

const PublicPlayer: React.FC<PlayerProps> = ({ chunks, walkthrough, maxHeight }) => (
  <Player
    audio={walkthrough.audioUrl}
    duration={walkthrough.meta.duration}
    thumbnail={(loading: boolean) => <Thumbnail loading={loading} walkthrough={walkthrough} />}
    chunks={chunks}
    rrwebScreenSize={{
      width: walkthrough?.steps[0]?.event?.data?.width,
      height: walkthrough?.steps[0]?.event?.data?.height,
    }}
    maxHeight={maxHeight}
    fullscreen={false}
    completed={() => <CompletedVideo walkthrough={walkthrough} />}
    steps={walkthrough.steps}
  >
    <PlayerEventTracking walkthrough={walkthrough} eventPrefix="walkthrough" />
  </Player>
)

export default PublicPlayer
