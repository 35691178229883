import React from 'react'
import ModeToggle from './ModeToggle'
import FullscreenButton from './FullscreenButton'

interface Props {
  showTryIt: boolean
  toggleMode: () => void
  children?: React.ReactNode
  enableFullscreen: () => void
}

const ControlPanel = ({ children, toggleMode, showTryIt, enableFullscreen }: Props) => {
  return (
    <div className="bg-[#353535] w-full h-[65px] rounded-b-md flex justify-between items-center px-[15px]">
      <ModeToggle value={showTryIt} toggle={toggleMode} />

      <div className="flex space-x-[40px]">
        {children}
        <FullscreenButton enableFullscreen={enableFullscreen} />
      </div>
    </div>
  )
}

export default ControlPanel
