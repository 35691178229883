import { CheckBadgeIcon, XMarkIcon } from '@heroicons/react/24/solid'
import hljs from 'highlight.js'
import React from 'react'
interface WebhookResponseProps {
  status: string
  message: any
}

function highlightJson(json: object): string {
  const jsonString = JSON.stringify(json, null, 2) // Pretty print JSON
  const highlightedJson = hljs.highlight(jsonString, { language: 'json' }).value // Highlight JSON
  return highlightedJson
}

const WebhookResponse = ({ status, message }: WebhookResponseProps) => {
  message = JSON.parse(message)
  return (
    <div>
      <div className="flex flex-row items-center justify-start w-full space-x-2">
        <h3 className="text-md">Webhook Response</h3>

        {status === '200' ? (
          <CheckBadgeIcon className="h-6 w-6 text-green-500" />
        ) : (
          <XMarkIcon className="h-6 w-6 text-red-500" />
        )}
      </div>

      <div
        className="m-4 bg-gray-300/20 p-4 rounded-lg overflow-auto"
        dangerouslySetInnerHTML={{ __html: highlightJson(message) }}
      ></div>
    </div>
  )
}

export default WebhookResponse
