import React from 'react'
import Option from './index'
import { ArrowDownOnSquareIcon } from '../icons'

const InstallExtensionOption = () => {
  const onClick = () => {
    window.location.href =
      'https://chrome.google.com/webstore/detail/ckfldhejolipdmhhmofaandhaimbcbdn'
  }

  return (
    <Option
      onClick={onClick}
      icon={<ArrowDownOnSquareIcon />}
      title="Install the Extension"
      description="Don’t have our extension yet? Download it here to unlock powerful learning experiences."
      className="border-b border-[#E5E7EB]"
    />
  )
}

export default InstallExtensionOption
