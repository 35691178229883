import React, { useEffect, useState } from 'react'
import FlowMoLogo from './FlowMoLogo'
import ModeSelector from './ModeSelector'
import ShareButton from './Actions/ShareButton'
import FullscreenButton from './Actions/FullscreenButton'
import { useEmbedContext } from '../context/EmbedContext'
import { ModeTypes } from '../context/EmbedContext/types'
import StepNavigation from './StepNavigation'

const isInIFrame = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}
const Header = () => {
  const { mode, enabled } = useEmbedContext()

  const [allowClipboard, setAllowClipboard] = useState(false)

  useEffect(() => {
    if (isInIFrame()) {
      navigator?.permissions
        // @ts-ignore
        ?.query({ name: 'clipboard-write' })
        .then((permissionStatus) => {
          permissionStatus.state === 'granted' && setAllowClipboard(true)
        })
        .catch(() => setAllowClipboard(false))
    } else {
      setAllowClipboard(true)
    }
  }, [])

  return (
    <Container>
      <div className="h-full flex items-center space-x-[35px]">
        <FlowMoLogo />
        {enabled && <ModeSelector />}
      </div>

      {enabled && (
        <div className="h-full flex items-center space-x-2.5">
          {mode === ModeTypes.LABS && <StepNavigation />}
          {allowClipboard && <ShareButton />}
          {document.fullscreenEnabled && <FullscreenButton />}
        </div>
      )}
    </Container>
  )
}

const Container = ({ children }: { children: React.ReactNode }) => (
  <div
    className="w-full h-[52px] border-b border-b-[rgba(255,255,255,0.26)]"
    style={{
      background:
        'linear-gradient(90deg, #214A86 0%, #305B9A 25.52%, #6B6B6B 63.44%, #6B6B6B 100%)',
    }}
  >
    <div className="w-full h-full max-w-[1700px] mx-auto flex items-center justify-between px-[20px]">
      {children}
    </div>
  </div>
)

export default Header
