import React, { useState } from 'react'
import { clsx } from 'clsx'

const TutorialModal = () => {
  const [show, setShow] = useState(true)

  return show ? (
    <div className="absolute w-full h-full z-[15] flex justify-center items-center bg-[rgba(20,20,20,0.63)]">
      <div className="bg-[#F8F9FB] w-[272px] px-[20px] pt-[20px] py-[27px] rounded flex flex-col items-center">
        <div className="flex relative items-start">
          <div className="w-[38px] h-[38px] flex items-center justify-center">
            <div className="flowmo-beacon" />
          </div>
          <div className="absolute -bottom-4 left-8">
            <ArrowIcon />
          </div>
          <div className="flex-1 text-[15px] text-[#646464] text-left ml-4">
            We highlight the element you need to click on
          </div>
        </div>

        <button
          onClick={() => setShow(false)}
          className={clsx(
            'w-[70px] h-[30px] mt-[15px] text-center text-white text-[13px]',
            'bg-[#EA6A29] hover:bg-[#E15D19] active:bg-[#D4520F] rounded '
          )}
        >
          Got it
        </button>
      </div>
    </div>
  ) : (
    <></>
  )
}
const ArrowIcon = () => (
  <svg width="41" height="12" viewBox="0 0 41 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.97892 4.56613C3.40735 5.84595 3.79906 7.09546 4.27399 8.5063C3.39447 8.49021 2.84045 8.00196 2.58096 7.41728C1.80249 5.66325 1.05421 3.87239 0.469913 2.03169C0.131249 0.980326 0.845104 0.33483 1.91474 0.569611C3.70645 0.937449 5.4745 1.40924 7.27273 1.84422C7.37636 1.86802 7.51342 1.88857 7.55341 1.95245C7.74352 2.17115 7.87 2.42992 8.02993 2.68543C7.80557 2.80892 7.59428 3.06667 7.3903 3.05263C6.67803 3.02027 5.99269 2.91753 5.3106 2.84835C4.93611 2.81702 4.5918 2.74886 4.07291 2.96877C4.48007 3.33577 4.89048 3.73634 5.29763 4.10335C14.0362 11.3177 26.1415 11.8342 35.5172 5.4666C36.7198 4.63802 37.8089 3.68494 38.9715 2.79248C39.3802 2.48164 39.7923 2.20436 40.201 1.89351C40.3079 1.95088 40.3846 2.04507 40.4915 2.10245C40.4205 2.41431 40.4262 2.82037 40.2117 3.04455C39.5443 3.82106 38.877 4.59757 38.0995 5.28313C30.3915 12.1323 18.8738 13.4901 9.38061 8.58551C7.56684 7.64378 5.92438 6.38041 4.22809 5.25783C3.86745 5.02184 3.57045 4.74577 3.24 4.47296C3.16983 4.4459 3.07274 4.48923 2.97892 4.56613Z"
      fill="#EA6A29"
    />
  </svg>
)

export default TutorialModal
