import React from 'react'
import { htmlStrip } from '../../../common/utils/htmlStrip'
import { Walkthrough } from 'app/javascript/components/graphql'
import Breadcrumb from '../../../../components/AI/components/Breadcrumb'

interface Props {
  walkthrough: Walkthrough
}

const WalkthroughBreadcrumb: React.FC<Props> = ({ walkthrough }) => {
  let title = 'Shared With Me'
  let path = 'shared_with_me'

  if (walkthrough.isInMyLibrary || walkthrough.skillSet?.isInMyLibrary) {
    title = 'My Library'
    path = 'my_library'
  } else if (
    walkthrough.isInMyOrganizationLibrary ||
    walkthrough.skillSet?.isInMyOrganizationLibrary
  ) {
    title = 'Organization Library'
    path = 'organization_library'
  }

  return (
    <Breadcrumb
      pages={
        walkthrough.skillSet
          ? [
              {
                title: title,
                to: `/${path}`,
              },
              {
                title: htmlStrip(walkthrough.skillSet.name),
                to: `/skill_set/${walkthrough.skillSet.uuid}`,
              },
              {
                title: htmlStrip(walkthrough.name),
              },
            ]
          : [
              {
                title: title,
                to: `/${path}`,
              },
              {
                title: htmlStrip(walkthrough.name),
              },
            ]
      }
    />
  )
}

export default WalkthroughBreadcrumb
