import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import React from 'react'
import 'react-circular-progressbar/dist/styles.css'
import { FlowMoLabsIcon } from './icons'
import { useLabsContext } from '../../context/LabsContext'

const styles = buildStyles({
  trailColor: '#F6A47B',
  pathColor: '#FFFFFF',
})

const percentage = (stepIndex: number, stepCount: number) => {
  return (stepIndex / stepCount) * 100
}

const ProgressBar: React.FC = () => {
  const { stepIndex, walkthrough } = useLabsContext()

  return (
    <div className="w-[34px] h-[34px] p-0.5 rounded-full bg-[#EA6A29]">
      <CircularProgressbarWithChildren
        value={percentage(stepIndex + 1, walkthrough.steps.length)}
        styles={styles}
        strokeWidth={4}
      >
        <div className="flex flex-col items-center justify-center">
          <FlowMoLabsIcon />
        </div>
      </CircularProgressbarWithChildren>
    </div>
  )
}

export default ProgressBar
