import parse from 'html-react-parser'
import { NormalizedStepProps } from '../../../Player/types'
import { StepTypes } from './icons'
import { isVisible } from '../../utils/helpers/element'

export const RRWEB_TARGET_TYPES = {
  SELECT: 'select',
  INPUT: 'input',
  TEXTAREA: 'textarea',
}

const hasDescription = (step: any) => step.description && step.description.length > 0

const isSmallElement = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect()
  return rect?.width * rect?.height <= 200
}

const getClickDescription = (step: any, element?: HTMLElement) => {
  if (hasDescription(step)) {
    return parse(step.description)
  }

  if (element && isSmallElement(element)) {
    return 'Click here'
  }

  return null
}

const getOptionName = (step: any, element?: HTMLElement) => {
  const options = Array.from((element as any).options)
  const option: any = options.find((option: any) => option.value === step.value)
  return option ? option.text || option.label : 'any option'
}

const getDropdownDescription = (step: any, element?: HTMLElement) => {
  if (element && element.tagName?.toLowerCase() === RRWEB_TARGET_TYPES.SELECT) {
    if (hasDescription(step)) {
      return parse(step.description)
    }

    return `Select ${getOptionName(step, element)}`
  }

  return null
}

const getInputDescription = (step: any) => {
  if (hasDescription(step)) {
    return parse(step.description)
  }

  return 'Enter text'
}

export const useStepDescription = (step: NormalizedStepProps, element?: HTMLElement) => {
  if (!element || !step || !isVisible(element)) {
    return null
  }

  if (step.type === StepTypes.click) {
    return getClickDescription(step, element)
  }

  if (step.type === StepTypes.dropdownSelect) {
    return getDropdownDescription(step, element)
  }

  if (step.type === StepTypes.input) {
    return getInputDescription(step)
  }

  return null
}
