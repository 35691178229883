import React from 'react'
import Content from '../../../../components/Walkthrough/ui/Walkthrough/List/Content'

import PageHeader from '../../components/PageHeader'
import { GET_ORGANIZATION_CONTENT } from '../../../../components/App/graphql/queries/content'

const OrganizationLibrary = () => {
  return (
    <React.Fragment>
      <PageHeader title="Organization Library" breadCrumbs={[{ title: 'Organization Library' }]} />
      <Content
        {...{
          emptyState: { description: 'No content has been shared with your organization' },
          query: GET_ORGANIZATION_CONTENT,
        }}
      />
    </React.Fragment>
  )
}

export default OrganizationLibrary
