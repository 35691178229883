import React, { ReactElement } from 'react'
import { Menu, MenuButton } from '@szhsin/react-menu'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

interface Props {
  items: any
  icon?: ReactElement
}

const CardMenu: React.FC<Props> = ({
  items,
  icon = <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />,
}) => {
  return (
    <Menu
      transition={true}
      menuClassName={'relative inline-block text-left'}
      onClick={(e: any) => e.preventDefault()}
      menuButton={
        <MenuButton
          onClick={(e: any) => e.preventDefault()}
          className="py-2 rounded-full ml-auto items-center text-gray-900 hover:text-gray-600"
        >
          {icon}
        </MenuButton>
      }
    >
      {items}
    </Menu>
  )
}

export default CardMenu
