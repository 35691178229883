import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from '@headlessui/react'
import React, { useState, useEffect } from 'react'
import EmptyContentBox from './EmptyContentBox'
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import ActionButton from './ActionButton'
import ConversationSummary from '../ConversationSummary'
import { v4 as uuidv4 } from 'uuid'
import TextContent from '../Chat/TextContent'

export interface Note {
  uuid: string
  note: string
}

interface SelectableNoteProps {
  note: Note
  handleRemoveNote: (note: Note) => void
}

const SelectableNote = ({ note, handleRemoveNote }: SelectableNoteProps) => {
  // create a dialog that opens with the summary
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="relative m-2">
      <div
        className="border h-24 w-48 border-gray-300 rounded-lg p-3 cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <div className="text-sm line-clamp-2">{note.note}</div>

        <button
          className="absolute -top-3 -right-3 p-1 text-white rounded-full bg-red-500 hover:bg-red-600"
          onClick={() => handleRemoveNote(note)}
        >
          <MinusIcon className="w-4 h-4" />
        </button>
      </div>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mt-3">
                  <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                    Note Details
                  </DialogTitle>
                  <div className="mt-2">
                    <div className="border border-gray-300 max-w-full h-96 rounded-lg p-3 overflow-y-auto">
                      <TextContent content={{ type: 'text', value: note.note }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 flex items-center justify-center">
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="inline-flex w-32 justify-center rounded-md bg-flowmo-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-flowmo-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-flowmo-blue-600 sm:col-start-2"
                >
                  Close
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

const NoteDialog = ({
  isOpen,
  setIsOpen,
  onAddNote,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onAddNote: (note: Note) => void
}) => {
  const [note, setNote] = useState('')
  const onNoteChange = (note: string) => {
    setNote(note)
  }

  const handleAddNote = () => {
    onAddNote({ uuid: uuidv4(), note: note })
    setIsOpen(false)
  }

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50 ">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-screen max-w-3xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <TransitionChild>
                <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <DialogTitle className="text-base font-semibold text-gray-900">
                    Add Note
                  </DialogTitle>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <ConversationSummary onSummaryChange={onNoteChange} />
                </div>
                <div className="flex justify-end p-4">
                  <button
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    type="button"
                    onClick={handleAddNote}
                  >
                    Add Note
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const NoteField = ({ field, formik }: { field: any; formik: any }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedNoteList, setSelectedNoteList] = useState<Note[]>([])

  useEffect(() => {
    if (formik.values[field.name]) {
      setSelectedNoteList(
        formik.values[field.name].map((n: Note) => {
          return {
            uuid: n.uuid,
            note: n.note,
          }
        })
      )
    }
  }, [])

  const handleAddNote = (note: Note) => {
    if (!note.uuid) {
      return
    }
    setSelectedNoteList([...selectedNoteList, note])
  }

  const handleRemoveNote = (note: Note) => {
    if (!note.uuid) {
      return
    }
    setSelectedNoteList(selectedNoteList.filter((n) => n.uuid !== note.uuid))
  }

  useEffect(() => {
    formik.setFieldValue(field.name, selectedNoteList)
  }, [selectedNoteList])

  return (
    <div>
      <div className="flex flex-wrap ">
        {selectedNoteList.map((n) => (
          <SelectableNote key={n.uuid} note={n} handleRemoveNote={handleRemoveNote} />
        ))}
        {selectedNoteList.length === 0 && (
          <EmptyContentBox title="No notes selected" heightClass="h-24" widthClass="w-48" />
        )}
      </div>
      {isOpen && <NoteDialog isOpen={isOpen} setIsOpen={setIsOpen} onAddNote={handleAddNote} />}
      <ActionButton onClick={() => setIsOpen(true)}>
        <PlusIcon className="w-4 h-4" /> Add Note
      </ActionButton>
    </div>
  )
}

export default NoteField
