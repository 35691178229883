import { Switch } from '@headlessui/react'
import { clsx } from 'clsx'
import React from 'react'

interface Props {
  value: boolean
  toggle: () => void
}

const ModeToggle = ({ toggle, value }: Props) => (
  <div className="flex space-x-3 items-center">
    <Switch
      checked={value}
      onChange={toggle}
      className={clsx(
        value ? 'bg-flowmo-blue-600' : 'bg-[#818181]',
        'relative inline-flex h-6 w-11 items-center flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
      )}
    >
      <span
        aria-hidden="true"
        className={clsx(
          value ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
    <span className="text-[14px] text-white">Interactive Mode</span>
  </div>
)

export default ModeToggle
