import React from 'react'

export const BookOpenIcon = () => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    className="fill-[#9F9F9F] group-hover:fill-flowmo-blue-600 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
    fill="#9F9F9F"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.6875 0.664551V11.3521H6.3125C6.61719 11.3521 6.88086 11.4634 7.10352 11.686C7.32617 11.9087 7.4375 12.1724 7.4375 12.4771H8.5625C8.5625 12.1724 8.67383 11.9087 8.89648 11.686C9.11914 11.4634 9.38281 11.3521 9.6875 11.3521H15.3125V0.664551H9.6875C9.35938 0.664551 9.04883 0.734863 8.75586 0.875488C8.46289 1.00439 8.21094 1.18604 8 1.42041C7.78906 1.18604 7.53711 1.00439 7.24414 0.875488C6.95117 0.734863 6.64062 0.664551 6.3125 0.664551H0.6875ZM1.8125 1.78955H6.3125C6.61719 1.78955 6.88086 1.90088 7.10352 2.12354C7.32617 2.34619 7.4375 2.60986 7.4375 2.91455H8.5625C8.5625 2.60986 8.67383 2.34619 8.89648 2.12354C9.11914 1.90088 9.38281 1.78955 9.6875 1.78955H14.1875V10.2271H9.6875C9.35938 10.2271 9.04883 10.2974 8.75586 10.438C8.46289 10.5669 8.21094 10.7485 8 10.9829C7.78906 10.7485 7.53711 10.5669 7.24414 10.438C6.95117 10.2974 6.64062 10.2271 6.3125 10.2271H1.8125V1.78955ZM7.4375 4.03955V5.16455H8.5625V4.03955H7.4375ZM7.4375 6.28955V7.41455H8.5625V6.28955H7.4375ZM7.4375 8.53955V9.66455H8.5625V8.53955H7.4375Z"
      fill="9F9F9F"
    />
  </svg>
)

export const FolderIcon = ({ className }: any) => (
  <svg
    width="11"
    height="13"
    className={className}
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.28125V12.4688H9.375V6.11133L10.1807 5.30566L10.3125 5.15918V0.28125H0ZM0.9375 1.21875H7.5V5.15918L7.63184 5.30566L8.4375 6.11133V11.5312H0.9375V1.21875ZM8.4375 1.21875H9.375V4.76367L8.90625 5.23242L8.4375 4.76367V1.21875Z"
      fill="#1B72F5"
    />
  </svg>
)

export const AcademicIcon = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="#9F9F9F"
    className="fill-[#9F9F9F] group-hover:fill-flowmo-blue-600 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.539551L0.6875 2.22705V3.35205L8 1.66455L15.3125 3.35205V2.22705L8 0.539551ZM4.625 3.91455C4.08594 3.91455 3.50586 3.979 2.88477 4.10791C2.43945 4.20166 2 4.31885 1.56641 4.45947L1.03906 4.65283L0.6875 4.81104V13.4771H7.0332C7.13867 13.6528 7.27344 13.7935 7.4375 13.8989C7.61328 13.9927 7.80078 14.0396 8 14.0396C8.19922 14.0396 8.38086 13.9927 8.54492 13.8989C8.7207 13.7935 8.86133 13.6528 8.9668 13.4771H15.3125V4.81104L14.9609 4.65283L14.4336 4.45947C14 4.31885 13.5605 4.20166 13.1152 4.10791C12.4941 3.979 11.9141 3.91455 11.375 3.91455C10.6719 3.91455 9.93945 4.01416 9.17773 4.21338C8.75586 4.31885 8.36328 4.44189 8 4.58252C7.63672 4.44189 7.24414 4.31885 6.82227 4.21338C6.06055 4.01416 5.32812 3.91455 4.625 3.91455ZM4.625 5.03955C5.44531 5.03955 6.38281 5.22119 7.4375 5.58447V11.8423C6.44141 11.5142 5.50391 11.3501 4.625 11.3501C3.74609 11.3501 2.80859 11.5142 1.8125 11.8423V5.58447C2.82031 5.22119 3.75781 5.03955 4.625 5.03955ZM11.375 5.03955C12.2422 5.03955 13.1797 5.22119 14.1875 5.58447V11.8423C13.1914 11.5142 12.2539 11.3501 11.375 11.3501C10.4961 11.3501 9.55859 11.5142 8.5625 11.8423V5.58447C9.61719 5.22119 10.5547 5.03955 11.375 5.03955Z"
      fill="9F9F9F"
    />
  </svg>
)

export const AttentionIcon = ({ className }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="none"
    viewBox="0 0 12 12"
    className={className}
  >
    <path
      fill="#EA6A29"
      d="M5.625 0a5.504 5.504 0 00-2.842.776A5.35 5.35 0 00.776 2.783 5.504 5.504 0 000 5.625c0 1.016.259 1.963.776 2.842a5.46 5.46 0 002.007 2.021 5.585 5.585 0 002.842.762 5.585 5.585 0 002.842-.762 5.573 5.573 0 002.021-2.021 5.585 5.585 0 00.762-2.842 5.585 5.585 0 00-.762-2.842A5.46 5.46 0 008.467.776 5.504 5.504 0 005.625 0zm0 .938c.85 0 1.64.214 2.373.644.703.41 1.26.967 1.67 1.67.43.732.645 1.523.645 2.373 0 .85-.215 1.64-.645 2.373a4.565 4.565 0 01-1.67 1.67 4.609 4.609 0 01-2.373.645c-.85 0-1.64-.215-2.373-.645a4.565 4.565 0 01-1.67-1.67 4.61 4.61 0 01-.645-2.373c0-.85.215-1.64.645-2.373.41-.703.967-1.26 1.67-1.67A4.61 4.61 0 015.625.937zm-.469 1.875v3.75h.938v-3.75h-.938zm0 4.687v.938h.938V7.5h-.938z"
    ></path>
  </svg>
)

export const ArrowIcon = ({ className }: any) => (
  <svg
    className={className}
    width="23"
    height="49"
    viewBox="0 0 23 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6306 47.3189L18.15 46.7244C18.425 46.6994 18.6683 46.902 18.6933 47.177C18.7184 47.452 18.5158 47.6952 18.2408 47.7203L10.8385 48.3953C10.6882 48.409 10.5398 48.3541 10.4346 48.2459C10.3294 48.1378 10.2787 47.9879 10.2966 47.8381L11.1199 40.9408C11.1527 40.6666 11.4015 40.4709 11.6757 40.5036C11.9499 40.5363 12.1456 40.7851 12.1129 41.0593L11.5006 46.1887C18.4163 41.2076 22.0292 32.3064 20.9413 23.6106C19.8239 14.6791 13.7389 5.93971 1.08343 1.95669C0.820022 1.87379 0.673695 1.59305 0.756596 1.32964C0.839497 1.06624 1.12023 0.919912 1.38364 1.00281C14.3948 5.09779 20.7654 14.149 21.9336 23.4865C23.0866 32.7025 19.1634 42.1737 11.6306 47.3189Z"
      fill="#2C2C2C"
    />
  </svg>
)

export const ArrowToRight = () => (
  <svg
    width="60"
    height="11"
    className="w-24 h-9 sm:w-16 sm:h-3"
    viewBox="0 0 39 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.252 0.568193C32.0135 0.429052 31.7074 0.50962 31.5682 0.748146C31.4291 0.986672 31.5097 1.29283 31.7482 1.43197L36.435 4.16597C32.8397 4.73312 25.306 5.76027 19.5226 5.50039C10.57 5.09808 6.1079 4.01101 0.631714 2.5175C0.365302 2.44484 0.0904315 2.60191 0.0177737 2.86832C-0.0548842 3.13474 0.102185 3.40961 0.368597 3.48226C5.89241 4.98876 10.4303 6.09282 19.4777 6.49938C25.4534 6.76791 33.212 5.69156 36.7535 5.128L32.6301 9.66375C32.4444 9.86807 32.4594 10.1843 32.6638 10.3701C32.8681 10.5558 33.1843 10.5407 33.3701 10.3364L38.3701 4.83642C38.4716 4.72478 38.5172 4.57328 38.4943 4.42415C38.4714 4.27502 38.3824 4.14422 38.252 4.06819L32.252 0.568193Z"
      fill="#2C2C2C"
    />
  </svg>
)

export const ArrowToLeft = () => (
  <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.9999 6.5C18.9999 8.5 9.49988 14 4.33582 25.8291"
      stroke="#2C2C2C"
      strokeLinecap="round"
    />
    <path
      d="M0.999998 16.84C1.95538 21.2086 2.13033 23.0452 3.74408 25.6638C4.64714 27.1292 5.31589 26.1124 6.4684 25.4245C8.53369 24.1918 10.5516 22.7204 12.6835 21.6775"
      stroke="#2C2C2C"
      strokeLinecap="round"
    />
  </svg>
)

export const CircleRecordIcon = () => (
  <svg
    width="14"
    height="14"
    className="ml-2 text-white"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
      fill="white"
    />
    <circle cx="7" cy="7" r="3" fill="white" />
  </svg>
)

export const EditIcon = ({ className }: any) => (
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`inline-flex ml-1 items-baseline ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="group-hover:fill-[#2C2C2C]"
      d="M8.42673 0.180451C8.65296 0.0861919 8.8886 0.0390625 9.13368 0.0390625C9.37875 0.0390625 9.6144 0.0861919 9.84062 0.180451C10.0668 0.274709 10.2695 0.411385 10.4486 0.590476C10.6937 0.835549 10.8539 1.11833 10.9293 1.43881C11.0141 1.74986 11.0141 2.06563 10.9293 2.38611C10.8539 2.69716 10.6937 2.97522 10.4486 3.2203L3.29434 10.3745L0.664524 10.8977L0 11.0391L0.141388 10.3745L0.664524 7.74472L7.81877 0.590476C7.99786 0.411385 8.20051 0.274709 8.42673 0.180451ZM9.81234 1.22672C9.59554 1.00993 9.36932 0.90153 9.13368 0.90153C8.89803 0.90153 8.67181 1.00993 8.45501 1.22672L8.14396 1.55192L9.48715 2.8951L9.81234 2.58405C10.0291 2.36725 10.1375 2.14103 10.1375 1.90539C10.1375 1.66974 10.0291 1.44352 9.81234 1.22672ZM8.8509 3.53135L7.50771 2.18816L2.03599 7.65989C2.62982 7.96151 3.07755 8.40924 3.37918 9.00307L8.8509 3.53135ZM2.16324 8.87582C1.9653 8.66845 1.72965 8.50821 1.4563 8.3951L1.15938 9.87968L2.64396 9.58276C2.53085 9.30941 2.37061 9.07377 2.16324 8.87582ZM10.5 13.0391C10.7761 13.0391 11 12.8152 11 12.5391C11 12.2629 10.7761 12.0391 10.5 12.0391H0.5C0.223858 12.0391 0 12.2629 0 12.5391C0 12.8152 0.223858 13.0391 0.5 13.0391H10.5Z"
      fill="#646464"
    />
  </svg>
)
