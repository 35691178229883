import React from 'react'
import AgentImage from '../../../components/AgentImage'
import {
  AgentFieldsFragment,
  CompanyAgentFieldsFragment,
  StepHistoryFieldsFragment,
} from '../../../../graphql'
import StepChip from './StepChip'
import MessageBase from '../../../components/Chat/MessageBase'

interface RunStepProps {
  agent: AgentFieldsFragment | CompanyAgentFieldsFragment
  step: StepHistoryFieldsFragment
  showName?: boolean
}

const RunStep: React.FC<RunStepProps> = ({ agent, step, showName }) => {
  let agentImmage = null
  if (agent.__typename != 'CompanyAgent') {
    agentImmage = <AgentImage agent={agent} className="size-6 rounded-full" />
  }

  let agentFirstName = ''
  if (agent.__typename !== 'CompanyAgent') {
    agentFirstName = agent.firstName
  }

  return (
    <MessageBase
      actor="system"
      agentFirstName={agentFirstName}
      agentImage={agentImmage}
      createdAt={step.createdAt}
      showName={showName}
      showVoting={false}
    >
      <div className="flex flex-col">
        <div className="flex flex-wrap">
          {step.step.map((oneStep, index) => (
            <StepChip key={index} step={oneStep} />
          ))}
        </div>
      </div>
    </MessageBase>
  )
}

export default RunStep
