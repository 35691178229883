import React from 'react'
import PageHeader from '../../../components/PageHeader'
import { OneSection, SectionContainer } from '../../../components/SectionContainer'
import OrganizationSettingsForm from './OrganizationSettingsForm'
import DataBackendForm from './DataBackendForm'

const Settings = () => {
  const handleFormSuccess = ({ formName }: { formName: string }) => {
    window.toastr.success(`${formName} updated successfully`)
  }
  const handleFormError = ({ formName }: { formName: string }) => {
    window.toastr.error(`${formName} update failed`)
  }

  return (
    <React.Fragment>
      <PageHeader title="Settings" />
      <div className="flex flex-col space-y-5">
        <SectionContainer>
          <OneSection
            title="Organization"
            description="You can change your organization information"
          >
            <OrganizationSettingsForm
              handleError={() => handleFormError({ formName: 'Organization settings' })}
              handleSuccess={() => handleFormSuccess({ formName: 'Organization settings' })}
            />
          </OneSection>
        </SectionContainer>

        <SectionContainer>
          <OneSection
            title="Data Sources"
            description="Provide the details for FlowMo to gather data"
          >
            <DataBackendForm />
          </OneSection>
        </SectionContainer>

        <SectionContainer>
          <OneSection
            title="Billing"
            description="You can change your billing information using the form"
          >
            <div className="flex flex-col text-gray-600">Current Plan: Beta Tester</div>
          </OneSection>
        </SectionContainer>
      </div>
    </React.Fragment>
  )
}

export default Settings
