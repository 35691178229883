import React from 'react'
import Button from '../../../Button'
import { CheckIcon } from '@heroicons/react/24/outline'
import Spin from '../../../../../common/ui/Spin'
import { useFormikContext } from 'formik'

const SaveButton: React.FC = () => {
  const { isValid, isSubmitting, handleSubmit } = useFormikContext()

  return (
    <Button
      color="success"
      icon={
        isSubmitting ? <Spin className="ml-2 h-4 w-4" /> : <CheckIcon className="ml-2 h-4 w-4" />
      }
      className="w-30"
      onClick={handleSubmit}
      disabled={isSubmitting || !isValid}
    >
      Save
    </Button>
  )
}

export default SaveButton
