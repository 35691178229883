import React, { Fragment, useState } from 'react'

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { SortOption } from './types'
import { clsx } from 'clsx'

interface Props {
  defaultSortOption: {
    sortBy: string
    sortDirection: string
  }
  sortOptions: SortOption[]
  handleSort: (sortOption: SortOption) => void
}

const Sort: React.FC<Props> = ({ defaultSortOption, sortOptions, handleSort }) => {
  const [sort, setSort] = useState(defaultSortOption)

  const label = sortOptions.find(
    (option) => option.sortBy === sort.sortBy && option.sortDirection === sort.sortDirection
  ).label

  const onSort = (sortOption: SortOption) => {
    setSort(sortOption)
    handleSort(sortOption)
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
          Sort by {label}
          <ChevronDownIcon
            className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {sortOptions.map((oneSortOption, index) => (
              <MenuItem key={index}>
                {({ active }) => (
                  <div
                    onClick={() => {
                      onSort(oneSortOption)
                    }}
                    className={clsx(
                      oneSortOption.sortBy === sort.sortBy &&
                        oneSortOption.sortDirection === sort.sortDirection
                        ? 'font-medium text-gray-900'
                        : 'text-gray-500',
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm cursor-pointer'
                    )}
                  >
                    {oneSortOption.label}
                  </div>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  )
}

export default Sort
