import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

if (process.env.ENV === 'staging' || process.env.ENV === 'production') {
  Sentry.init({
    dsn: 'https://0962be8674d941b1ad7c7ddf4083d4da@o1001655.ingest.sentry.io/5961775',
    environment: process.env.ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.0,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      'Extension context invalidated',
      'unhandledrejection',
      "Failed to execute 'importScripts' on 'WorkerGlobalScope'",
    ],
  })
}
