import React, { useEffect, useMemo, useState } from 'react'
import Replayer, { ReplayerChildrenProps } from '../../components/Replayer'
import { NormalizedStepProps } from '../../types'
import Loading from '../../components/Loading'
import { RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import ElementLayer from '../../../InteractiveStep/modules/ElementLayer'
import { elementWrapper } from '../../../InteractiveStep/utils/helpers/element'
import { clearSeekRequest, pause } from '../../slices/playerSlice'
import { CLOCK_INTERVAL } from '../../constants'

type VideoProps = {
  chunks: Chunk[]
}

export type Chunk = {
  loading: boolean
  events?: any[]
  error?: Error
  progress: {
    loaded: number
    total: number
  }
}

const Video: React.FC<VideoProps> = ({ chunks }) => {
  const dispatch = useDispatch()
  const {
    playerControls: { isPlaying, isInteractiveStep, isInteractiveMode },
    rrwebTimeline: { currentChunkIndex, isChunkDataDownloadComplete },
    playground,
  } = useSelector((state: RootState) => state.player)

  const events = useMemo<any[] | undefined>(
    () => chunks[currentChunkIndex] && chunks[currentChunkIndex].events,
    [currentChunkIndex, isChunkDataDownloadComplete]
  )
  const error = useMemo<Error | undefined>(
    () => chunks[currentChunkIndex] && chunks[currentChunkIndex].error,
    [currentChunkIndex, isChunkDataDownloadComplete]
  )

  const loading = useMemo<boolean>(
    () => (!events && !error) || !isChunkDataDownloadComplete,
    [events, isChunkDataDownloadComplete]
  )

  // we were playing, but we have to pause and
  // wait for the data load to complete
  useEffect(() => {
    if (isPlaying && loading) {
      dispatch(pause())
    }
  }, [isPlaying, loading])

  const { total, loaded } = useMemo<{ loaded: number; total: number }>(() => {
    if (chunks[currentChunkIndex] && chunks[currentChunkIndex].loading) {
      return chunks[currentChunkIndex].progress
    } else if (chunks[currentChunkIndex + 1]) {
      return chunks[currentChunkIndex + 1].progress
    } else {
      return { total: 100, loaded: 0 }
    }
  }, [chunks, currentChunkIndex])
  return (
    <Loading loading={loading} total={total} loaded={loaded}>
      {events && (
        <React.Fragment>
          <Replayer events={events}>
            {({ replayer }: any) => {
              return (
                <React.Fragment>
                  {isInteractiveMode && isInteractiveStep && playground.step && (
                    <InteractiveControl
                      events={events}
                      replayer={replayer}
                      step={playground.step}
                    />
                  )}
                  {isInteractiveMode && !isInteractiveStep && (
                    <VideoHighlight replayer={replayer} />
                  )}
                  <VideoControl replayer={replayer} />
                </React.Fragment>
              )
            }}
          </Replayer>
        </React.Fragment>
      )}
    </Loading>
  )
}

type InteractiveControlProps = {
  step: NormalizedStepProps
  events: any[]
}

const InteractiveControl: React.FC<ReplayerChildrenProps & InteractiveControlProps> = ({
  replayer,
  step,
  events,
}) => {
  const [element, setElement] = useState<Node | null>(null)
  useEffect(() => {
    replayer.pause(events[step.eventIndex].timestamp - events[0].timestamp)
    if (replayer && replayer.iframe) {
      const elements: Iterable<any> | ArrayLike<any> =
        replayer.iframe?.contentWindow?.document.getElementsByTagName('a') || []
      if (elements !== undefined) {
        const arrayOfElements = Array.from(elements)
        for (const element of arrayOfElements) {
          element.removeAttribute('href')
        }
      }
    }
    const node = replayer.getMirror().getNode(step.nodeId)
    if (node && node.constructor.name !== 'Text') {
      const wrappedNode = elementWrapper(node)
      setElement(wrappedNode)
    }
  }, [step, replayer])

  if (!element || !step) {
    return <React.Fragment />
  }

  return <ElementLayer replayer={replayer} element={element as HTMLElement} step={step} />
}

const VideoHighlight: React.FC<ReplayerChildrenProps> = ({ replayer }) => {
  const [element, setElement] = useState<Node | null>(null)
  const {
    playground: { nextStep },
  } = useSelector((state: RootState) => state.player)

  useEffect(() => {
    if (!nextStep) {
      setElement(null)
      return undefined
    }

    const interval = setInterval(() => {
      const nextElement = replayer.getMirror().getNode(nextStep.nodeId)
      if (nextElement != element) {
        setElement(nextElement)
      }
    }, CLOCK_INTERVAL)
    return () => {
      interval && clearInterval(interval)
    }
  }, [replayer, nextStep])

  if (!replayer || !element || !nextStep) {
    return <React.Fragment />
  }
  return <ElementLayer replayer={replayer} element={element as HTMLElement} step={nextStep} />
}

const VideoControl: React.FC<ReplayerChildrenProps> = ({ replayer }) => {
  const dispatch = useDispatch()
  const {
    playerControls: { isSeeking },
    rrwebTimeline: { isRRWebPlaying, positionDelta },
  } = useSelector((state: RootState) => state.player)

  useEffect(() => {
    if (isRRWebPlaying) {
      replayer.play(positionDelta)
    } else {
      replayer.pause(positionDelta)
    }

    if (isSeeking) {
      dispatch(clearSeekRequest())
    }
  }, [isRRWebPlaying, replayer, isSeeking])

  return <React.Fragment />
}

export default Video
