import React from 'react'
import CardMenuItem from './index'

interface Props {
  onClick: () => void
}

const DeleteMenuItem: React.FC<Props> = ({ onClick }) => (
  <CardMenuItem label="Delete" onClick={onClick} />
)

export default DeleteMenuItem
