import { CheckCircleIcon, PlayCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { Link } from 'react-router-dom'
import DeleteModal from '../../../components/DeleteModal'
import { DELETE_TASK_MUTATION } from '../../../graphql/queries/task'
import { useMutation } from '@apollo/client'
import {
  DeleteTaskMutation,
  DeleteTaskMutationVariables,
  TaskFieldsFragment,
} from 'app/javascript/components/graphql'
import { XCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline'

export const TaskType = ({ taskType }: { taskType: string }) => {
  if (!taskType) {
    return null
  }

  return (
    <>
      {taskType == 'yes_no' ? (
        <div className="">Pass/Fail - does the check pass</div>
      ) : (
        <div className="">Value Change - has the value changed since we last checked</div>
      )}
    </>
  )
}

export const TaskStatus = ({ status }: { status: string }) => {
  return (
    <>
      {status == 'pending' && (
        <div className="">
          <QuestionMarkCircleIcon className="h-8 w-8 text-gray-400" />
        </div>
      )}
      {status == 'running' && (
        <div className="">
          <PlayCircleIcon className="h-8 w-8 text-blue-400" />
        </div>
      )}
      {status == 'pass' && (
        <div className="">
          <CheckCircleIcon className="h-8 w-8 text-green-400" />
        </div>
      )}
      {status == 'fail' && (
        <div className="">
          <XCircleIcon className="h-8 w-8 text-red-400" />
        </div>
      )}
    </>
  )
}

interface oneTaskProps {
  task: TaskFieldsFragment
  onDelete: () => void
}

const TaskCard = ({ task, onDelete }: oneTaskProps) => {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [deleteTask] = useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DELETE_TASK_MUTATION
  )

  const handleDelete = async () => {
    const resposne = await deleteTask({
      variables: { uuid: task.uuid },
    })
    if (resposne.data.deleteTask.errors.length > 0) {
      window.toastr.error('Error deleting task')
    } else {
      window.toastr.success('Task deleted successfully')
    }
    onDelete()
  }

  return (
    <>
      <DeleteModal
        buttonLabel={'Delete'}
        description={'Are you sure you want to delete this task?'}
        title={'Delete Task'}
        open={isDeleting}
        setOpen={setIsDeleting}
        onDelete={handleDelete}
      />
      <div className="relative flex flex-row space-x-2 border rounded-xl bg-white p-2">
        <div className="flex">
          <TaskStatus status={task.status} />
        </div>
        <div className="flex flex-col">
          <div className="text-lg font-semibold text-gray-800 text-ellipsis">
            <Link className="hover:underline" to={`/task/` + task.uuid}>
              {task.name}
            </Link>
          </div>
          <div className="text-base text-gray-700">{task.description}</div>
          <div className="mt-2 text-sm text-gray-500">
            <TaskType taskType={task.taskType} />
          </div>
        </div>
        <div className="absolute top right-2 flex flex-row space-x-2">
          <Link
            className="text-flowmo-blue-800 hover:text-flowmo-blue-600"
            to={'/task/edit/' + task.uuid}
          >
            <PencilSquareIcon className="size-5" />
          </Link>
          <a
            href="#"
            className="text-flowmo-blue-800 hover:text-flowmo-blue-600"
            onClick={() => setIsDeleting(true)}
          >
            <XCircleIcon className="size-5" />
          </a>
        </div>
      </div>
    </>
  )
}

export default TaskCard
