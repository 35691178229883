import React from 'react'

const ToolbarContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="absolute flex h-full flex-col right-0 left-0">
    <div className="sticky mt-auto backdrop-blur-sm bg-white/90 bottom-0 flex z-20 shadow-md p-4">
      <div className="max-w-7xl ml-auto mr-12 lg:flex lg:items-center lg:justify-end">
        <div className="mt-5 flex lg:mt-0 lg:ml-4">
          <span className="space-x-3  sm:block">{children}</span>
        </div>
      </div>
    </div>
  </div>
)

export default ToolbarContainer
