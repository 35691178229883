import * as Yup from 'yup'
import { StepTypes } from '../../App/models/Walkthrough'

const URL_REGEX = /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/

export const WalkthroughValidateSchema = Yup.object({
  name: Yup.string().trim().required('Title is required').max(2045),
  description: Yup.string().max(2045),
  steps: Yup.array()
    .of(
      Yup.object({
        type: Yup.string().oneOf(Object.values(StepTypes)),
        title: Yup.string().trim(),
        description: Yup.string(),
        url: Yup.string().when('type', {
          is: StepTypes.goto,
          then: (schema) =>
            schema.matches(URL_REGEX, 'Link is not valid').required('Link is required'),
        }),
      })
    )
    .required('Steps is required'),
})

export const SkillSetValidateSchema = Yup.object({
  name: Yup.string().trim().required('Title is required').max(2045),
})
