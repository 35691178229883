import { Popover } from '@headlessui/react'
import React from 'react'
import { ActiveFilter, Filter as FilterType } from '../../types'
import Filter from './filter'

interface Props {
  filters: FilterType[]
  activeFilters: ActiveFilter[]
  handleAddFilter: (name: string, value: string) => void
  handleRemoveFilter: (name: string, value: string) => void
}

const DesktopFilters: React.FC<Props> = ({
  filters,
  activeFilters,
  handleAddFilter,
  handleRemoveFilter,
}) => {
  if (!filters || filters.length === 0) {
    return <></>
  }
  return (
    <div className="hidden sm:block">
      <div className="flow-root">
        <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
          {filters.map((filter, index) => (
            <Filter
              key={index}
              filter={filter}
              activeFilters={activeFilters}
              handleAddFilter={handleAddFilter}
              handleRemoveFilter={handleRemoveFilter}
            />
          ))}
        </Popover.Group>
      </div>
    </div>
  )
}

export default DesktopFilters
