import React, { PropsWithChildren } from 'react'
import './style.scss'

export const Container: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="audio-container">{children}</div>
}

export const Bar: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="audio-bar">{children}</div>
}
