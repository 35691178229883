import { Cloudinary } from '@cloudinary/url-gen'

export const cloudinary = new Cloudinary({
  cloud: { cloudName: 'data-driven' },
})

export const getPublicId = (url: string) => {
  const filename = url.split('/').reverse()[0]
  return `ddu-${process.env.ENV}/${filename}`
}

export const getCloudinaryUrl = (publicId: string) =>
  `https://res.cloudinary.com/data-driven/image/upload/ddu-${process.env.ENV}/${publicId}.png`

export const imageExists = async (url: string) => {
  if (!url) {
    return false
  }

  const response = await fetch(url, { method: 'HEAD' })
  return response.status != 404
}
