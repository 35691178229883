import { Walkthrough, SkillSet } from 'app/javascript/components/graphql'
import React from 'react'
import WalkthroughHeader from '../../WalkthroughHeader'
import WalkthroughContent from '../../WalkthroughContent'
import WalkthroughBreadcrumb from '../../WalkthroughBreadcrumb'
import useRRWebEvents from '../../../../hooks/useRRWebEvents'
import clsx from 'clsx'
import EmptyContent from '../../../../../AI/pages/app/EditAgent/EmptyContent'
import ContentField from '../../ContentField'
import TaskVisualization from '../../../../../AI/pages/app/OneTask/TaskVisualization'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import TextContent from '../../../../../AI/components/Chat/TextContent'

interface Props {
  walkthrough: Walkthrough
  skillSets?: SkillSet[]
  rrwebData?: any
  steps: any[]
}

const InternalViewer: React.FC<Props> = ({ walkthrough, skillSets, steps }) => {
  const [currentTab, setCurrentTab] = React.useState(0)
  const chunks = useRRWebEvents(walkthrough)

  const tabs = [
    { name: 'Summary', key: 'summary' },
    { name: 'Details', key: 'details' },
    { name: 'Steps', key: 'steps' },
  ]

  return (
    <>
      <WalkthroughBreadcrumb walkthrough={walkthrough} />
      <div className="max-w-7xl flex flex-col m-auto">
        <WalkthroughHeader
          steps={steps}
          walkthrough={walkthrough}
          skillSets={skillSets}
          chunks={chunks}
        />

        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-flowmo-blue-500 focus:ring-flowmo-blue-500"
              defaultValue={tabs[currentTab].name}
              onChange={(e) => setCurrentTab(tabs.findIndex((tab) => tab.name === e.target.value))}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="bg-gray-100 px-4 py-6 sm:px-6 lg:px-8 rounded-xl">
              <nav className="flex space-x-4" aria-label="Tabs">
                {tabs.map((tab, index) => (
                  <div
                    key={index}
                    className={clsx(
                      index === currentTab
                        ? 'bg-gray-200 text-gray-800'
                        : 'text-gray-600 hover:text-gray-800 cursor-pointer',
                      'rounded-md px-3 py-2 text-sm font-medium'
                    )}
                    onClick={() => setCurrentTab(index)}
                  >
                    {tab.name}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {tabs[currentTab]['key'] === 'steps' && <WalkthroughContent walkthrough={walkthrough} />}
        {tabs[currentTab]['key'] === 'summary' && (
          <div className="rounded-lg relative p-4 md:p-8">
            {walkthrough.updatedAt > walkthrough.lastSynthesisAt && (
              <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                      The summary is outdated. We are working on updating it. Please check back
                      later.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {!walkthrough.summary && <EmptyContent title="No summary generated yet!" />}
            <TextContent content={{ type: 'text', value: walkthrough.summary }} />
          </div>
        )}
        {tabs[currentTab]['key'] === 'details' && (
          <div className="rounded-lg relative p-4 md:p-8">
            <div>
              <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Title</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <ContentField
                        name="name"
                        className="break-all text-left text-sm px-3.5 rounded-md break-words font-small w-full text-gray-600"
                        canEdit={walkthrough.canEdit}
                        iconClassName="mb-1"
                      >
                        {walkthrough.name}
                      </ContentField>
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Description</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <ContentField
                        name="description"
                        className="break-all text-left text-sm px-3.5 rounded-md break-words font-small w-full text-gray-600"
                        canEdit={walkthrough.canEdit}
                        iconClassName="mb-1"
                      >
                        {walkthrough.description}
                      </ContentField>
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Synthesis</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <ContentField
                        name="synthesis"
                        className="break-all text-left text-sm px-3.5  rounded-md break-words font-small w-full text-gray-600"
                        canEdit={walkthrough.canEdit}
                        iconClassName="mb-1"
                      >
                        <TaskVisualization task={walkthrough.synthesis} />
                      </ContentField>
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Domains</dt>
                    <dd className="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <div className="w-full text-gray-600 py-2">
                        {walkthrough.meta.domains.map((one_domain: any, one_index: any) => (
                          <span
                            key={one_index}
                            className="mr-2 inline-flex items-center px-2.5 rounded text-xs bg-[#EBF1FF] border border-gray-300"
                          >
                            {one_domain}
                          </span>
                        ))}
                      </div>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default InternalViewer
