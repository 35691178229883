import React, { useEffect, useRef, useState } from 'react'
import { Bar } from '../../components/Containers'
import Counter from '../../components/Counter'
import Timer from '../../components/Timer'
import { MuteButton, PlayPauseButton } from '../../components/Buttons'
import Button from '../../components/Buttons'
import { DoneIcon } from '../../icons'
import Progress from '../../components/Progress'
import RecordRTC from 'recordrtc'
import Modal from '../../components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { PlayerActions, RootState } from '../../../../../../AI/components/Player/PlayerState'

type ControllerProps = {
  close: () => void
  setAudio: (audio: any) => void
  device: any
  handleWave: (arr: number[]) => void
}

function handleVolume(stream: MediaStream, callback: (n: number) => void) {
  const audioContext = new AudioContext()
  const analyser = audioContext.createAnalyser()
  const microphone = audioContext.createMediaStreamSource(stream)
  const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1)

  analyser.smoothingTimeConstant = 0.8
  analyser.fftSize = 1024

  microphone.connect(analyser)
  analyser.connect(scriptProcessor)
  scriptProcessor.connect(audioContext.destination)

  const pcmData = new Float32Array(analyser.fftSize)

  const onFrame = () => {
    analyser.getFloatTimeDomainData(pcmData)
    let sumSquares = 0.0
    for (const amplitude of pcmData) {
      sumSquares += amplitude * amplitude
    }

    const value = Math.round(Math.sqrt(sumSquares / pcmData.length) * 300)
    callback(value >= 100 ? 100 : value <= 5 ? 0 : value)
  }

  scriptProcessor.onaudioprocess = function () {
    onFrame()
  }
}

const Controller: React.FC<ControllerProps> = ({ close, setAudio, device, handleWave }) => {
  const dispatch = useDispatch()
  const {
    position,
    duration,
    playerControls: { isVideoAtEnd, isPlaying },
  } = useSelector((state: RootState) => state.player)
  const [wave, setWave] = useState<number[]>([])
  const [stream, setStream] = useState<MediaStream | null>(null)
  const [peak, setPeak] = useState<number>(0)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [modal, setModal] = useState<boolean>(false)
  const audioRecorder = useRef<RecordRTC | null>(null)
  const [muted, setMuted] = useState<boolean>(false)
  const [recording, setRecording] = useState<boolean>(false)

  const handleStart = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: { ...device },
      })
      .then(function (stream) {
        setStream(stream)

        audioRecorder.current = new RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm',
        })
        handleVolume(stream, (value) =>
          setPeak((prevState) => {
            if (value === prevState) {
              return value + 1
            }
            return value
          })
        )
        setRecording(true)
      })
  }

  useEffect(() => {
    if (recording) {
      setWave((prevState) => [...prevState, peak])
    }
  }, [peak, recording, position])

  useEffect(() => {
    stream && stream.getAudioTracks().forEach((track) => (track.enabled = !muted))
  }, [muted])

  const handleDestroy = () => {
    stream?.getTracks().forEach(function (track) {
      track.stop()
    })
  }

  const handleCancel = () => {
    setRecording(false)
    setModal(true)
    handleDestroy()
  }

  const handleDone = () => {
    handleWave(wave)
    setRecording(false)
    dispatch(PlayerActions.restart())
    audioRecorder.current.stopRecording(() => {
      const blob = audioRecorder.current.getBlob()
      setAudio(blob)
      handleDestroy()
    })
  }

  const handleCount = () => {
    setDisabled(false)
    handleStart()
  }

  useEffect(() => {
    if (recording) {
      audioRecorder.current?.startRecording()
      !isVideoAtEnd && dispatch(PlayerActions.play())
    } else {
      audioRecorder.current?.pauseRecording()
      !isVideoAtEnd && dispatch(PlayerActions.pause())
    }
  }, [recording])

  useEffect(() => {
    if (isVideoAtEnd) {
      handleDone()
    }
  }, [isVideoAtEnd])

  return (
    <React.Fragment>
      <Modal display={modal}>
        <div className="text-base text-gray-600 mb-6">Are you sure you want to discard?</div>
        <div className="flex gap-x-2">
          <Button type="neutral" className="flex-1" onClick={() => setModal(false)}>
            Cancel
          </Button>
          <Button type="primary" className="flex-1" onClick={close}>
            Discard
          </Button>
        </div>
      </Modal>
      <Counter handleDone={handleCount} />
      <Bar>
        <div
          className={`w-full flex items-center ${
            disabled || modal ? 'opacity-50 pointer-events-none' : ''
          }`}
        >
          <div className="flex flex-1 items-center mr-4">
            <div className="flex-1 mr-4">
              <Progress wave={wave} duration={duration} position={position} playing={isPlaying} />
            </div>
            <Timer duration={duration} position={position} />
          </div>
          <div className="flex items-center gap-x-2">
            <MuteButton muted={muted} toggle={() => setMuted((prevState) => !prevState)} />
            <PlayPauseButton playing={recording} toggle={() => setRecording((p) => !p)} />
            <Button type="action" onClick={handleDone}>
              <DoneIcon />
              <span className="ml-2">Done</span>
            </Button>
            <Button type="neutral" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Bar>
    </React.Fragment>
  )
}

export default Controller
