import React from 'react'
import ProgressBar from './ProgressBar'
import Counter from './Counter'
import PrevStepButton from './PrevStepButton'
import NextStepButton from './NextStepButton'

const StepNavigation = () => (
  <div className="flex items-center space-x-1.5">
    <ProgressBar />

    <div className="h-[34px] min-w-[120px] rounded-full bg-[#595757] flex items-center justify-between space-x-2 px-[5px]">
      <PrevStepButton />
      <Counter />
      <NextStepButton />
    </div>
  </div>
)

export default StepNavigation
