import {
  useQueryParams as useQueryParameters,
  NumberParam,
  withDefault,
  StringParam,
  ArrayParam,
  useQueryParam,
} from 'use-query-params'

const useQueryParams = () => {
  const [pagination] = usePagination()
  const [sort] = useSort()
  const [contentType = []] = useContentType()
  const [useCases] = useUseCases()
  const [website] = useWebsite()

  return {
    ...pagination,
    ...sort,
    contentType,
    useCases,
    website,
  }
}

export default useQueryParams

export const usePagination = () => {
  return useQueryParameters({
    perPage: withDefault(NumberParam, 24),
    page: withDefault(NumberParam, 1),
  })
}

export const useSort = () => {
  return useQueryParameters({
    sortBy: withDefault(StringParam, ''),
    sortDirection: withDefault(StringParam, ''),
  })
}

export const useContentType = () => {
  return useQueryParam('contentType', ArrayParam)
}

export const useUseCases = () => {
  return useQueryParam('useCases', ArrayParam)
}

export const useWebsite = () => {
  return useQueryParam('website', StringParam)
}
