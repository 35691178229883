import { useState } from 'react'

export type SignInParams = {
  email: string
  password: string
}

const doSingInRequest = (username: string, password: string) => {
  return fetch('/users/sign_in.json', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      user: {
        email: username,
        password: password,
        remember_me: 1,
      },
    }),
  })
}

type LoginParams = {
  username: string
  password: string
  callback?: () => void
}

export const useAuth = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const login = async ({ username, password, callback }: LoginParams) => {
    setLoading(true)
    setError(null)
    try {
      const response = await doSingInRequest(username, password)
      if (response) {
        const responseBody = await response.json()
        if (response.ok) {
          callback && callback()
        } else {
          setError(responseBody.error)
        }
      }
    } catch (e) {
      setError(e.message)
    }
    setLoading(false)
  }

  return { error, login, loading: loading, clearError: () => setError(null) }
}
