import Spin from '../../../../common/ui/Spin'
import React, { useState } from 'react'
import {
  TaskRunMarkPassedMutation,
  TaskRunMarkPassedMutationVariables,
  TaskRunsQuery,
} from 'app/javascript/components/graphql'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline'
import { MUTATION_TASK_RUN_MARK_PASSED } from '../../../graphql/queries/workflow'
import { useMutation } from '@apollo/client'
import { BaseMessageThread } from '../../../components/SidebarChat/MessageThread'
interface TaskRunProps {
  run: TaskRunsQuery['taskRuns'][0]
}

const TaskRun = ({ run }: TaskRunProps) => {
  const [updateStatus, setUpdateStatus] = useState(false)
  const [taskRunMarkSuccessfull] = useMutation<
    TaskRunMarkPassedMutation,
    TaskRunMarkPassedMutationVariables
  >(MUTATION_TASK_RUN_MARK_PASSED)

  const handleMarkSuccessfull = async () => {
    setUpdateStatus(true)
    await taskRunMarkSuccessfull({ variables: { uuid: run.uuid } })
    setUpdateStatus(false)
  }
  return (
    <div className="flex flex-col w-full mt-4">
      <div className="flex flex-row align-middle text-xs text-gray-500 space-x-2 ">
        <div className="">
          {run.state == 'running' ? (
            <>
              <Spin />
            </>
          ) : (
            <>
              <span className="font-medium text-gray-900">Task Run</span>{' '}
              {run.runtimeSeconds && <>took {run.runtimeSeconds} seconds</>}
            </>
          )}
        </div>
        <div>
          <time dateTime="2023-01-23T15:56">
            ({formatDistanceToNow(new Date(run.startedAt), { addSuffix: true })})
          </time>
        </div>
      </div>

      <div className="mt-5 bg-white/75 shadow rounded-lg p-5 w-full">
        <BaseMessageThread
          messages={run.messageHistory}
          steps={run.stepHistory}
          agent={run.agent}
          sideEffects={run.sideEffectHistory}
          showName={false}
          showVoting={false}
        />
      </div>

      {run.evaluationMessage && (
        <div className="px-4 py-6 sm:col-span-2 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            Reason for {run.evaluationMessage?.status ? 'success' : 'failure'}
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
            {run.evaluationMessage?.reason}
          </dd>
          {!run.evaluationMessage?.status && (
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              {updateStatus ? (
                <Spin />
              ) : (
                <button
                  className="text-blue-500 hover:underline text-xs flex flex-row space-x-2"
                  onClick={() => handleMarkSuccessfull()}
                >
                  <WrenchScrewdriverIcon className="size-4" />
                  <span>mark successfull</span>
                </button>
              )}
            </dd>
          )}
        </div>
      )}
      <div className="py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{run.responseStatus}</div>
      <div className="flex flex-col">
        <div className="">{run.webhookCompletedAt}</div>
        <div className="">{run.webhookResponseStatus}</div>
        <div className="">{run.webhookStatus}</div>
      </div>
    </div>
  )
}

export default TaskRun
