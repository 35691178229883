import React from 'react'
import CardMenuItem from './index'

interface Props {
  onClick: () => void
}

const EmbedItem: React.FC<Props> = ({ onClick }) => (
  <div className="sm:hidden flex">
    <CardMenuItem label="Embed link" onClick={onClick} />
  </div>
)

export default EmbedItem
