import React from 'react'
import logoImage from '../../../../assets/images/FlowMo_Icon.png'

const Footer = () => {
  const linkCss = 'text-base text-gray-500 hover:text-gray-900'
  return (
    <footer className="relative bg-white" aria-labelledby="footerHeading">
      <div className="absolute inset-x-0 top-0 bottom-1/2 text-slate-900/10 [mask-image:linear-gradient(transparent,white)]">
        <svg aria-hidden="true" className="absolute inset-0 h-full w-full">
          <defs>
            <pattern
              id=":S7:"
              width="128"
              height="128"
              patternUnits="userSpaceOnUse"
              x="50%"
              y="100%"
            >
              <path d="M0 128V.5H128" fill="none" stroke="currentColor"></path>
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#:S7:)"></rect>
        </svg>
      </div>
      <div className="relative">
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-md mx-auto pt-12 px-4 sm:max-w-7xl sm:px-6 lg:pt-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <img src={logoImage} alt="FlowMo" className="h-10 w-auto" />
              <p className="text-gray-500 text-base">Made with love by the FlowMo team.</p>
              <div className="flex space-x-6"></div>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase"></h3>
                  <ul className="mt-4 space-y-4"></ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase"></h3>
                  <ul className="mt-4 space-y-4"></ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Company
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      <a href="/about" className={linkCss}>
                        About
                      </a>
                    </li>

                    <li>
                      <a href="/contact" className={linkCss}>
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Legal
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      <a href="/privacy" className={linkCss}>
                        Privacy
                      </a>
                    </li>

                    <li>
                      <a href="/terms" className={linkCss}>
                        Terms
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 py-8">
            <p className="text-base text-gray-400 xl:text-center">
              &copy; 2022-{new Date().getFullYear()} FlowMo, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
