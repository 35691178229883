import React, { useEffect, useMemo } from 'react'
import { Chunk } from '../../hooks/useRRWebEvents'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import * as Sentry from '@sentry/browser'

type Props = {
  chunks: Chunk[]
}

const ErrorMessage: React.FC<Props> = ({ chunks }) => {
  const error = useMemo<boolean>(() => {
    return chunks.length === 0 || chunks.filter((chunk) => chunk.error).length > 0
  }, [chunks])

  useEffect(() => {
    if (error) {
      Sentry.captureMessage('Error displaying walkthrough')
    }
  }, [error])

  if (!error) {
    return <React.Fragment />
  }

  return (
    <div className="w-full flex bg-red-50">
      <div className="w-[50px] min-h-[50px] flex items-center justify-center bg-red-700 text-white">
        <ExclamationTriangleIcon className="h-6 w-6" />
      </div>
      <div className="p-4 text-sm text-red-700">
        Error occurred while creating the walkthrough. We advise you to create a new one
      </div>
    </div>
  )
}

export default ErrorMessage
