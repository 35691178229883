import React, { Fragment, PropsWithChildren } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

type SelectProps = PropsWithChildren & {
  options: any[]
  option: any
  handleSelect: (option: any) => void
  disabled: boolean
}

const Select: React.FC<SelectProps> = ({ children, options, option, handleSelect, disabled }) => {
  const onSelect = (deviceId: number) => {
    const option = options.find((option) => option.deviceId === deviceId)
    handleSelect(option)
  }

  return (
    <Listbox value={option} onChange={onSelect}>
      <div className={`flex w-full relative ${disabled ? 'pointer-events-none opacity-50' : ''}`}>
        <Listbox.Button className="relative shrink bg-white flex-1 rounded-l-md cursor-pointer flex items-center border border-r-0 py-2 pl-3 pr-2 text-left focus:outline-none focus-visible:border-flowmo-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-flowmo-blue-300 sm:text-sm overflow-hidden">
          <span className="block whitespace-nowrap ml-0 mr-4 text-[13px] mr-8 truncate">
            {option.label}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute divide-y z-10 mt-10 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option, personIdx) => (
              <Listbox.Option
                key={personIdx}
                className={({ active }) =>
                  `cursor-pointer select-none py-2 px-3.5 pr-4 ${
                    active ? 'bg-[#F9F9F9]' : 'text-gray-900'
                  }`
                }
                value={option.deviceId}
              >
                {({ selected }) => (
                  <>
                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                      {option.label}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
        <div>{children}</div>
      </div>
    </Listbox>
  )
}

export default Select
