import React from 'react'
import InternalLayout from './InternalLayout'

interface Props {
  usePublicEndpoint?: boolean
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ usePublicEndpoint = false, children }) => {
  if (usePublicEndpoint) {
    return <main className="grow min-h-screen">{children}</main>
  }

  return (
    <div className="block md:flex">
      <div className="hidden z-50 py-6 bg-white border-gray-200 border-r w-fit lg:block">
        <nav aria-label="Sidebar" className="sticky top-4 mx-[30px] divide-y divide-gray-300">
          <InternalLayout />
        </nav>
      </div>
      <main className="grow min-h-screen">{children}</main>
    </div>
  )
}

export default Layout
