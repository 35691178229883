import { CheckCircleIcon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline'
import React from 'react'
import StepCard from '../StepCard'
import TitleDescription from './shared/TitleDescription'
import { useCopyToClipboard } from '../../../../../common/hooks/useCopyToClipboard'

const CopyPaste = ({ step, stepNumber, index }: { step: any; stepNumber: any; index: number }) => {
  const [copyTextStatus, copyText] = useCopyToClipboard(step.text)

  return (
    <StepCard step={step} stepNumber={stepNumber}>
      <TitleDescription
        index={index}
        title={step.title}
        description={step.description}
        defaultTitle="find the highlighted item and paste the text"
      />

      <div>
        <label htmlFor="username" className="block text-sm font-medium text-gray-700">
          Text to copy
        </label>
        <div className="mt-1 rounded-md shadow-sm flex">
          <input
            type="text"
            disabled
            className="focus:ring-sky-500 focus:border-sky-500 flex-grow block w-full min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300"
            defaultValue={step.text}
          />
          <button
            className="bg-gray-50 border border-l-0 border-gray-300 rounded-r-md px-3 inline-flex items-center text-gray-500 sm:text-sm hover:bg-gray-100"
            onClick={copyText}
          >
            {copyTextStatus == 'copied' ? (
              <CheckCircleIcon className="h-6 w-6 text-green-600" />
            ) : (
              <ArrowLeftOnRectangleIcon className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>
    </StepCard>
  )
}

export default CopyPaste
