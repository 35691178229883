import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChunkTime, NormalizedStepProps, PlayerState } from '../types'
import { CLOCK_INTERVAL } from '../constants'
import { RootState } from '../store'

export const clickInteractiveElement = createAsyncThunk<
  void, // Return type of the payload creator
  void, // First argument of the payload creator
  { state: RootState } // ExtraThunkArg property
>('player/clickInteractiveElement', (_, { dispatch, getState }) => {
  const state = getState()
  dispatch(stopInteractiveStep())
  const nextStep = state.player.playground.nextStep
  if (nextStep) {
    // jump to the next step
    dispatch(pause())
    dispatch(seek(nextStep.position))
    dispatch(startInteractiveStep(nextStep))
  } else {
    // jump to the end of the video
    dispatch(seek(state.player.duration))
  }
  return Promise.resolve()
})

export const seekToStep = createAsyncThunk(
  'player/seekToStep',
  (
    { step, isInteractiveMode }: { step: NormalizedStepProps; isInteractiveMode: boolean },
    { dispatch }
  ) => {
    dispatch(seek(step.position))
    if (isInteractiveMode) {
      dispatch(pause())
      dispatch(startInteractiveStep(step))
    }
    return Promise.resolve()
  }
)

const getNextStep = (steps: NormalizedStepProps[], position: number) => {
  const interactiveSteps = steps.filter((step) => step.type !== 'goto')
  let nextStep: NormalizedStepProps | null = null

  for (const step of interactiveSteps) {
    if (step.position > position) {
      // If nextStep is not assigned or the current step position is closer to the given position
      if (nextStep === null || step.position < nextStep.position) {
        nextStep = step
      }
    }
  }

  return nextStep
}

const getPreviousStep = (steps: NormalizedStepProps[], position: number) => {
  const interactiveSteps = steps.filter((step) => step.type !== 'goto')
  let previousStep: NormalizedStepProps | null = null

  for (const step of interactiveSteps) {
    if (step.position < position) {
      // If nextStep is not assigned or the current step position is closer to the given position
      if (previousStep === null || step.position > previousStep.position) {
        previousStep = step
      }
    }
  }

  return previousStep
}

const handleStopInteractiveStep = (state: PlayerState) => {
  state.playerControls.isInteractiveStep = false
  state.playground.step = null
}

const calculatePositionUpdated = (state: PlayerState) => {
  // if we are at the end of the video with the next clock tick
  // stop the video
  if (state.position + CLOCK_INTERVAL >= state.duration) {
    state.position = state.duration
  }
  const isAtVideoEnd = state.position >= state.duration
  state.playerControls.isVideoAtEnd = isAtVideoEnd

  // if we're playing and we're at the end of the video
  // then stop playing
  if (state.playerControls.isPlaying && isAtVideoEnd) {
    state.playerControls.isPlaying = false
  }

  // update the next/previous step
  state.playground.nextStep = getNextStep(state.steps, state.position)
  state.playground.previousStep = getPreviousStep(state.steps, state.position)
  calculateRRWebMeta(state)
}

const calculateIsPlayerReady = (state: PlayerState) => {
  state.isPlayerReady = state.audio.isAudioReady && state.rrwebTimeline.isChunkDataDownloadComplete
}

const calculateRRWebMeta = (state: PlayerState) => {
  const {
    position,
    chunks: { times },
  } = state

  let index = 0

  if (position === 0 || times.length === 0) {
    // the base case - we should start with the first event of the first chunk
    index = 0
  } else if (position > times[times.length - 1].previousChunkEndPosition) {
    // the position is greater than the max position in the penultimate chunk
    // so the position is either in the last chunk, or in a gap between
    // the penuimate chunk and the last chunk
    index = times.length - 1
  } else {
    // the position is either in the chunk, or in the space betewen the chunks
    index = times.findIndex(
      (time) =>
        position >= time.previousChunkEndPosition && position <= time.currentChunkEndPosition
    )
  }

  state.rrwebTimeline.currentChunkIndex = index

  if (times[index] !== undefined) {
    if (index == times.length - 1) {
      // on the last chunk, we have all the data if there is chunk data
      // and we are after the start of penultimate chunk
      state.rrwebTimeline.isChunkDataDownloadComplete =
        position >= times[index].previousChunkEndPosition
    } else {
      // we have all the data if the position is between the end of the last chunk
      // and the end of the current chunk
      state.rrwebTimeline.isChunkDataDownloadComplete =
        position >= times[index].previousChunkEndPosition &&
        position <= times[index].currentChunkEndPosition
    }

    // if there are events for the current chunk, then play rrweb
    const positionDelta = position - times[index].currentChunkStartPosition
    state.rrwebTimeline.positionDelta = positionDelta
    state.rrwebTimeline.isRRWebPlaying = state.playerControls.isPlaying && positionDelta >= 0
  }
}

const initialState: PlayerState = {
  duration: 0,
  position: 0,
  steps: [],
  isPlayerReady: false,
  playground: {
    step: null,
    nextStep: null,
    previousStep: null,
  },
  chunks: {
    times: [],
    chunkCount: 0,
  },
  rrwebTimeline: {
    positionDelta: 0,
    isRRWebPlaying: false,
    currentChunkIndex: 0,
    isChunkDataDownloadComplete: false,
  },
  audio: {
    isAudioReady: false,
    hasAudioError: false,
  },
  playerControls: {
    isPlaying: false,
    isSeeking: false,
    isInteractiveStep: false,
    isVideoAtEnd: false,
    isInteractiveMode: true,
    isMuted: false,
    seekToPosition: null,
    isControllerMinimized: false,
    hasVideoError: false,
  },
  maxHeight: 0,
}

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setDuration: (state, action: PayloadAction<number>) => {
      state.duration = action.payload
    },
    setPosition: (state, action: PayloadAction<number>) => {
      state.position = action.payload
      calculatePositionUpdated(state)
    },
    incrementPosition: (state, action: PayloadAction<number>) => {
      state.position += action.payload
      calculatePositionUpdated(state)
    },
    setIsMuted: (state, action: PayloadAction<boolean>) => {
      state.playerControls.isMuted = action.payload
    },
    setIsAudioReady: (state, action: PayloadAction<boolean>) => {
      state.audio.isAudioReady = action.payload
      calculateIsPlayerReady(state)
    },
    setHasAudioError: (state, action: PayloadAction<boolean>) => {
      state.audio.hasAudioError = action.payload
    },
    setHasVideoError: (state, action: PayloadAction<boolean>) => {
      state.playerControls.hasVideoError = action.payload
    },
    setMaxHeight: (state, action: PayloadAction<number>) => {
      state.maxHeight = action.payload
    },
    setSteps: (state, action: PayloadAction<NormalizedStepProps[]>) => {
      state.steps = action.payload
    },
    togglePlayPause: (state) => {
      state.playerControls.isPlaying = !state.playerControls.isPlaying
      calculateRRWebMeta(state)
    },
    setIsPlaying: (state, action: PayloadAction<boolean>) => {
      state.playerControls.isPlaying = action.payload
      calculateRRWebMeta(state)
    },
    setChunkTimes: (state, action: PayloadAction<ChunkTime[]>) => {
      state.chunks.times = action.payload
      calculateRRWebMeta(state)
      calculateIsPlayerReady(state)
    },
    setChunkCount: (state, action: PayloadAction<number>) => {
      state.chunks.chunkCount = action.payload
      calculateRRWebMeta(state)
      calculateIsPlayerReady(state)
    },
    play: (state) => {
      state.playerControls.isPlaying = true
      calculateRRWebMeta(state)
    },
    pause: (state) => {
      state.playerControls.isPlaying = false
      calculateRRWebMeta(state)
    },
    seek: (state, action: PayloadAction<number>) => {
      state.playerControls.seekToPosition = action.payload
      state.position = action.payload
      state.playerControls.isSeeking = true
      calculatePositionUpdated(state)
      // if we are in interactive mode, we need to stop the interactive step
      if (state.playerControls.isInteractiveStep) {
        handleStopInteractiveStep(state)
      }
    },
    clearSeekRequest: (state) => {
      state.playerControls.seekToPosition = null
      state.playerControls.isSeeking = false
    },
    restart: (state) => {
      state.position = 0
      state.playerControls.isPlaying = true
      calculatePositionUpdated(state)
      calculateRRWebMeta(state)
    },
    setIsSeeking: (state, action: PayloadAction<boolean>) => {
      state.playerControls.isSeeking = action.payload
    },
    setIsInteractiveMode: (state, action: PayloadAction<boolean>) => {
      state.playerControls.isInteractiveMode = action.payload
      if (!action.payload) {
        state.playerControls.isInteractiveStep = false
        state.playground.step = null
      }
    },
    startInteractiveStep: (state, action: PayloadAction<NormalizedStepProps>) => {
      state.playerControls.isInteractiveStep = true
      state.playground.step = action.payload
      state.playerControls.isControllerMinimized = true
    },
    stopInteractiveStep: (state) => {
      handleStopInteractiveStep(state)
    },
    setControllerIsMinimized: (state, action: PayloadAction<boolean>) => {
      state.playerControls.isControllerMinimized = action.payload
    },
  },
})

export const {
  setSteps,
  setDuration,
  setPosition,
  setIsMuted,
  setChunkTimes,
  setChunkCount,
  incrementPosition,
  setMaxHeight,
  play,
  pause,
  restart,
  seek,
  clearSeekRequest,
  setIsPlaying,
  setIsSeeking,
  setIsAudioReady,
  setHasAudioError,
  setHasVideoError,
  togglePlayPause,
  setIsInteractiveMode,
  startInteractiveStep,
  stopInteractiveStep,
  setControllerIsMinimized,
} = playerSlice.actions

export default playerSlice.reducer
