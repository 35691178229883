import React from 'react'
import DynamicForm, { FieldData } from './DynamicForm'
import Spin from '../../common/ui/Spin'
import { useMutation } from '@apollo/client'
import { CREATE_TASK_MUTATION } from '../graphql/queries/task'

export const taskFormData = ({ taskableType }: { taskableType: string }) => {
  const formData: FieldData[] = [
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      required: true,
      defaultValue: '',
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
      required: true,
      defaultValue: '',
    },
    {
      name: 'task',
      label: 'Task',
      type: 'textarea',
      required: true,
      defaultValue: '',
    },
    {
      name: 'outputWebhookUrl',
      label: 'Webhook URL',
      type: 'url',
      required: false,
      defaultValue: '',
      hidden: true,
    },
    {
      name: 'runSchedule',
      label: 'Schedule',
      type: 'cronSchedule',
      required: false,
      defaultValue: '0 8 * * 1-5',
      hidden: true,
    },
  ]
  if (taskableType === 'Workflow') {
    formData.push({
      name: 'taskType',
      label: 'Type',
      type: 'select',
      required: true,
      defaultValue: '',
      options: [
        { label: 'Pass / Fail', value: 'yes_no' },
        { label: 'Value Change', value: 'delta' },
      ],
    })
  }
  return formData
}

interface CreateTaskProps {
  taskableType: 'Workflow' | 'Analysis'
  taskableUuid: string
  onSuccess: () => void
  onCancel: () => void
}

const CreateTask = ({ taskableType, taskableUuid, onSuccess, onCancel }: CreateTaskProps) => {
  const formRef = React.useRef<{ submitForm: () => Promise<void>; resetForm: () => void }>(null)
  const [isSaving, setIsSaving] = React.useState(false)
  const [createTask] = useMutation(CREATE_TASK_MUTATION)

  const formData = taskFormData({ taskableType })

  const handleSave = async ({
    name,
    description,
    task,
    outputWebhookUrl,
    runSchedule,
    taskType,
  }: {
    name: string
    description: string
    task: string
    outputWebhookUrl: string
    runSchedule: string
    agentUuid: string
    taskType: string
  }) => {
    setIsSaving(true)

    const response = await createTask({
      variables: {
        name,
        description,
        task,
        outputWebhookUrl,
        runSchedule,
        taskType,
        taskableType,
        taskableUuid,
      },
    })

    if (response.data.createTask.errors.length > 0) {
      window.toastr.error('Error adding ' + taskableType)
    } else {
      window.toastr.success('Tasks created successfully')
      formRef.current?.resetForm() // Reset the form values here
      onSuccess()
    }
    setIsSaving(false)
  }

  const handleCancel = () => {
    formRef.current?.resetForm() // Reset the form values here
    onCancel()
  }

  return (
    <React.Fragment>
      <div className="max-w-2xl">
        <DynamicForm ref={formRef} formData={formData} handleSubmit={handleSave} />
        <div className="flex flex-row space-x-2">
          <button
            type="submit"
            onClick={() => formRef.current?.submitForm()}
            className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
            disabled={isSaving}
          >
            {isSaving ? <Spin /> : 'Create'}
          </button>
          <button
            onClick={handleCancel}
            className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-slate-600 bg-gray-200 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-gray-300"
          >
            Cancel
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}
export default CreateTask
