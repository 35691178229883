import React from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'

type LaunchStep = {
  content: JSX.Element
}

type LaunchStepsProps = {
  steps: LaunchStep[]
  current: number
}

const LaunchSteps: React.FunctionComponent<LaunchStepsProps> = ({ steps, current }) => {
  return (
    <nav aria-label="Progress" className="w-full">
      <ol className="flex items-center">
        {steps.map((step, index) => {
          const status = current > index ? 'complete' : current === index ? 'current' : 'upcoming'
          return (
            <li key={index} className={`${index !== steps.length - 1 ? 'flex-1' : ''} relative`}>
              {status === 'complete' ? (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-flowmo-orange-600" />
                  </div>
                  <div className="relative w-8 h-8 flex items-center justify-center bg-flowmo-orange-600 rounded-full">
                    <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                    <span className="sr-only">{index + 1}</span>
                  </div>
                </>
              ) : status === 'current' ? (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <div
                    className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-flowmo-orange-600 rounded-full"
                    aria-current="step"
                  >
                    <span
                      className="h-2.5 w-2.5 bg-flowmo-orange-600 rounded-full"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{index + 1}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <div className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full ">
                    <span
                      className="h-2.5 w-2.5 bg-transparent rounded-full bg-gray-300"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{index + 1}</span>
                  </div>
                </>
              )}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default LaunchSteps
