import React, { useEffect } from 'react'
import { Switch } from '@headlessui/react'
import { clsx } from 'clsx'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Field } from 'formik'

interface Props {
  name: string
  setEnableState?: any
}

const Switcher: React.FC<Props> = ({ setEnableState, ...props }) => {
  return (
    <Field {...props}>
      {({ field, form }: any) => {
        useEffect(() => {
          setEnableState && setEnableState(field.value)
        }, [field.value])

        const onChange = (value: any) => {
          form.setFieldValue(field.name, value)
          form.handleSubmit()
        }

        return (
          <Switch
            checked={field.value}
            onChange={onChange}
            className={clsx(
              field.value ? 'bg-[#29BC7E]' : 'bg-gray-200',
              'relative ml-auto inline-flex h-6 w-11 items-center flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
            )}
          >
            <span
              aria-hidden="true"
              className={clsx(
                field.value ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
            {!field.value ? (
              <XMarkIcon className="absolute h-3 w-3 left-6" />
            ) : (
              <CheckIcon className="absolute h-3 w-3 left-1 text-white" />
            )}
          </Switch>
        )
      }}
    </Field>
  )
}

export default Switcher
