import React, { PropsWithChildren } from 'react'
import './style.scss'

type ModalProps = {
  display: boolean
}

const Modal: React.FC<ModalProps & PropsWithChildren> = ({ children, display }) => {
  if (!display) {
    return <React.Fragment />
  }

  return (
    <div className="modal-screen">
      <div className="modal-content">{children}</div>
    </div>
  )
}

export default Modal
