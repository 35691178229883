import React from 'react'
import Header from './Header'
import Footer from './Footer'
import BackgroundGraphic from './BackgroundGraphic'

interface LayoutProps {
  logoutPath: string
  csrfToken: string
  isImpersonating: boolean
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="bg-white relative">
      <Header />
      <BackgroundGraphic />
      <main className="relative z-10">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
