import React from 'react'

import * as styles from './style.module.scss'

const Background: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <React.Fragment>
      <div className={styles['flow-mo-blur']} />
      <div className={styles['flow-mo-background']}>{children}</div>
    </React.Fragment>
  )
}

export default Background
