import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './Carousel/style.scss'

const AgentCard = ({ agent, index }: { agent: any; index: number }) => {
  return (
    <div className="bg-slate-50/40 shadow rounded-2xl sm:mx-16 md:mx-24 lg:mx-auto max-w-4xl">
      <div className="px-2 sm:px-5 sm:py-8 md:py10 lg:px-10">
        <h2
          className="inline-flex items-center rounded-full px-4 py-1 text-blue-600 ring-1 ring-inset ring-blue-600"
          id="author-title"
        >
          <span className="font-mono text-sm" aria-hidden="true">
            {'0' + index.toString()}
          </span>
          <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
          <span className="ml-3 text-base font-medium tracking-tight">For {agent.actor}</span>
        </h2>
        <p className="mt-8 font-display text-3xl font-extrabold tracking-tight text-slate-900 sm:text-4xl">
          <span className="block text-blue-600">{agent.title} –</span> {agent.description}
        </p>
        <p className="mt-4 text-lg tracking-tight text-slate-700">{agent.longDescription}</p>
        <div className="mt-8">
          <div className="flex flex-col text-base font-medium tracking-tight">
            <div className="ml-4 text-blue-600">I can answer questions like:</div>
            <div className="">
              <ul className="mt-4 ml-8 list-disc list-inside text-slate-700">
                {agent.prompts.map((prompt: string, index: number) => (
                  <li key={index} className="mt-2">
                    {prompt}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Hero = () => {
  const agents: any = [
    {
      title: 'E-Commerce Guru',
      description: "I'm really good at running a store.",
      actor: 'Analyst',
      longDescription:
        'I\ve been running e-commerce stores for years. I can help you with your product catalog, and help you optimize your store.',
      prompts: [
        'what is my conversion rate',
        'what are my top selling products',
        'what is my average order value',
        'what is my customer lifetime value',
      ],
    },
    {
      title: 'Analytics Manager',
      description: 'I know the best way to setup analytics, let me setup your backend.',
      longDescription:
        'I can help you pick a datawarehouse, a Business Intelligence tool, and move all your data into it. Run your business on data.',
      actor: 'Analytics',
      prompts: [
        'what data warehouse should I use',
        'what BI tool should I use',
        'how do I move my data to the cloud',
        'I want to model user behavior, what tables should I create',
      ],
    },
    {
      title: 'Onboarding Specialist',
      description: "I've been trained to onboard new users and help them get started.",
      longDescription:
        'Get new users up to speed quickly by having a co-pilot onboard them. I can help them get setup with their own personal dashboards, and teach them how to use Looker.',
      actor: 'Dashboard Viewers',
      prompts: [
        'what dashboards should I use if in marketing',
        'how do I create a dashboard',
        'where are the boards for my team',
        'what is a measure',
        'how do I do period over period on the attribution dashboard',
      ],
    },
    {
      title: 'Ad Spend Optimizer',
      description: 'I can help you dig into your paid campaigns.',
      longDescription:
        "I don't just answer questions about Looker. Since all your company data is in your instance, I can help reason about your business. I can help you dig into your paid campaigns, and help you optimize your spend.",
      actor: 'Marketing Analysts',
      prompts: [
        'what was our CPA last week',
        'what is our CAC for the last 3 months',
        'which channel is performing the best',
        'what is our contributing the most to our ROAS for the last 3 months',
      ],
    },
    {
      title: 'Board Meeting Assistant',
      description: 'I can answer high level questions about your business.',
      longDescription:
        "I can answer high level questions about your business. No need to actually login to your Looker instance. I'm like your dedicated analyst at your beck and call.",
      actor: 'C-Level Execs',
      prompts: [
        'what is our revenue for the last 3 months',
        'how are my projects doing',
        'have we been profitable for the last 3 months',
        'are we on track to hit our goals for the quarter',
      ],
    },
  ]

  const responsive = {
    largeDesktop: {
      breakpoint: { max: 5000, min: 1024 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 640 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  }

  return (
    <div className="relative mb-20 px-6 pt-14 lg:px-8">
      <div className="pt-12 sm:pt-28 lg:pt-32">
        <div className="mx-auto max-w-2xl text-center mb-20">
          <div className="flex flex-col ext-4xl font-bold tracking-tight sm:text-6xl">
            <div className="py-2 overflow-hidden break-words tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-[#217bfe] to-[#ac87eb] via-[#078efb]">
              Work Intelligence
            </div>
            <div className="text-gray-700"> that runs your business</div>
          </div>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our mission is to make your life easier by pairing your with AI teammates that can help
            you do your job. Built on top of your existing data platform, we can immediately off-oad
            some of your work onto us.
          </p>
        </div>

        <div className="slider-container max-w-7xl m-auto relative">
          <Carousel
            responsive={responsive}
            showDots={true}
            infinite={true}
            centerMode={false}
            removeArrowOnDeviceType={['mobile']}
            autoPlay={true}
            renderDotsOutside={true}
            autoPlaySpeed={10000}
            dotListClass={'absolute !-top-10 flex justify-center pb-4'}
            pauseOnHover={true}
          >
            {agents.map((agent: any, index: number) => (
              <AgentCard agent={agent} key={index} index={index + 1} />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Hero
