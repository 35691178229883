import { gql } from '@apollo/client'

export const FILE_ATTACHMENT_FRAGMENT = gql`
  fragment FileAttachmentFragment on FileAttachment {
    uuid
    fileName
    fileExtension
    contentLength
    downloadUrl
  }
`

export const GET_FILE_ATTACHMENT_QUERY = gql`
  query getFileAttachment($uuid: ID!) {
    fileAttachment(uuid: $uuid) {
      ...FileAttachmentFragment
    }
  }
  ${FILE_ATTACHMENT_FRAGMENT}
`

export const MUTATION_CREATE_FILE_ATTACHMENT = gql`
  mutation createFileAttachment($fileAttachment: Upload!) {
    createFileAttachment(fileAttachment: $fileAttachment) {
      ...FileAttachmentFragment
    }
  }
  ${FILE_ATTACHMENT_FRAGMENT}
`

export const MUTATION_DELETE_FILE_ATTACHMENT = gql`
  mutation deleteFileAttachment($uuid: ID!) {
    deleteFileAttachment(uuid: $uuid) {
      success
      errors
    }
  }
`
