import React from 'react'
import EmptyContent from './EmptyContent'
import { useQuery } from '@apollo/client'
import Spin from '../../../../common/ui/Spin'
import {
  GET_ANALYSIS_TEMPLATES_QUERY,
  GET_AGENT_ANALYSES_QUERY,
} from '../../../graphql/queries/analysis'
import {
  AgentAnalysesQuery,
  AgentAnalysesQueryVariables,
  AnalysisTemplatesQuery,
  AnalysisTemplatesQueryVariables,
} from 'app/javascript/components/graphql'
import AnalysisTemplateCard from '../AnalysisTemplateCard'
import AnalysisCard from '../../../components/AnalysisCard'

interface AnalysesListProps {
  agentUuid: string
  onUpdate: () => void
}

const AnalysesList = ({ agentUuid, onUpdate }: AnalysesListProps) => {
  const {
    data: analysisTemplatesData,
    loading: analysisTemplatesLoading,
    refetch: analysisTemplatesRefetch,
  } = useQuery<AnalysisTemplatesQuery, AnalysisTemplatesQueryVariables>(
    GET_ANALYSIS_TEMPLATES_QUERY,
    {
      variables: {
        agentUuid,
      },
      fetchPolicy: 'network-only',
    }
  )

  const {
    data: analysesData,
    loading: analysesLoading,
    refetch: analysesRefetch,
  } = useQuery<AgentAnalysesQuery, AgentAnalysesQueryVariables>(GET_AGENT_ANALYSES_QUERY, {
    variables: {
      agentUuid,
    },
    fetchPolicy: 'network-only',
  })

  const analysisTemplates = analysisTemplatesData?.analysisTemplates || []
  const analyses = analysesData?.agentAnalyses

  if (analysisTemplatesLoading || analysesLoading) {
    return (
      <div>
        <Spin />
      </div>
    )
  }

  const handleUpdate = () => {
    analysesRefetch()
    analysisTemplatesRefetch()
    onUpdate()
  }

  return (
    <div className="flex flex-col space-y-5">
      <h3 className="text-xl font-semibold mb-5 pb-5 border-b">Analyses</h3>
      <div className="flex flex-col ml-12 space-y-4">
        <h4 className="text-lg font-semibold">Active</h4>
        <div className="flex flex-wrap">
          {analyses.length == 0 && (
            <EmptyContent title="No analyses are associated with this agent." />
          )}
          {analyses?.map((analysis) => (
            <div className="m-2" key={analysis.uuid}>
              <AnalysisCard analysis={analysis} size={'small'} />
            </div>
          ))}
        </div>
      </div>

      {analysisTemplates.length > 0 && (
        <div className="flex flex-col ml-12 space-y-4">
          <h4 className="text-lg font-semibold">Templates</h4>
          <div className="flex flex-wrap">
            {analysisTemplates?.map((analysisTemplate) => (
              <div className="m-2" key={analysisTemplate.id}>
                <AnalysisTemplateCard
                  agentUuid={agentUuid}
                  analysisTemplate={analysisTemplate}
                  onUpdate={handleUpdate}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
export default AnalysesList
