import { gzip, ungzip } from 'pako'

export const compress = (data: any) => {
  return gzip(JSON.stringify(data))
}

export const decompress = (arrayBuffer: ArrayBuffer) => {
  const buffer = Buffer.from(new Uint8Array(arrayBuffer))
  const data = ungzip(buffer, { to: 'string' })
  return JSON.parse(data)
}

export const archive = async (filename: string, data: any) => {
  const compressed = await compress(data)

  const file: any = new Blob([compressed as any], { type: 'application/gzip' })
  file.name = filename

  return file
}
