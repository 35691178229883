import { useQuery } from '@apollo/client'
import React from 'react'
import { GET_DATA_SET_QUERY } from '../../graphql/queries/data_set'
import { DataSetQuery, DataSetQueryVariables } from 'app/javascript/components/graphql'
import LinearProgress from '@mui/material/LinearProgress'
import { Link } from 'react-router-dom'
import { Grid2X2Icon } from 'lucide-react'

const DataSetCard: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { loading, data } = useQuery<DataSetQuery, DataSetQueryVariables>(GET_DATA_SET_QUERY, {
    variables: { uuid },
  })
  if (loading) {
    return (
      <>
        <LinearProgress />
      </>
    )
  }
  const dataSet = data?.dataSet
  if (!dataSet) {
    return <></>
  }
  return (
    <Link
      to={`/data_set/${uuid}`}
      className="flex flex-row items-center gap-2 p-2 rounded-full border border-gray-200 cursor-pointer hover:shadow-md text-gray-500"
    >
      <Grid2X2Icon className="w-4 h-4" />
      <div className="text-xs font-semibold">{dataSet.name}</div>
    </Link>
  )
}

export default DataSetCard
