import React from 'react'
import { BeaconIcon } from '../icons'
import clsx from 'clsx'
import * as style from './style.module.scss'
import CloseIcon from './CloseIcon'
import { NormalizedStepProps } from '../../../../Player/types'

export interface Props {
  elementRef: any
  description?: string
  styles: any
  step: NormalizedStepProps
  onClose?: () => void
  onMouseOver?: () => void
  onMouseLeave?: () => void
  className?: string
}

const Beacon = ({
  elementRef,
  description,
  styles,
  step,
  onClose,
  onMouseLeave,
  onMouseOver,
  className,
}: Props) => {
  return (
    <div
      className={clsx(
        style['beacon'],
        styles && style[`beacon-${styles.horizontal}`],
        styles && style[`beacon-${styles.vertical}`],
        className
      )}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      ref={elementRef}
    >
      <div className={style['beacon-icon']}>
        <BeaconIcon type={step.type} />
      </div>
      {description && (
        <React.Fragment>
          <div className={style['beacon-content']}>
            <div className={style['beacon-text']}>{description}</div>
          </div>
          {onClose && (
            <div className={style['close-icon']} onClick={onClose}>
              <CloseIcon />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default Beacon
