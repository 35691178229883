import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { ActiveFilter, Filter as FilterType } from '../../types'
import React from 'react'
import { clsx } from 'clsx'

interface Props {
  filter: FilterType
  activeFilters: ActiveFilter[]
  handleAddFilter: (name: string, value: string) => void
  handleRemoveFilter: (name: string, value: string) => void
}

const Filter: React.FC<Props> = ({
  filter,
  activeFilters,
  handleAddFilter,
  handleRemoveFilter,
}) => {
  const handleChanged = (filterValue: string, checked: boolean) => {
    checked
      ? handleAddFilter(filter.name, filterValue)
      : handleRemoveFilter(filter.name, filterValue)
  }

  return (
    <Disclosure as="div" className="border-t border-gray-200 px-4 py-6">
      {({ open }) => (
        <>
          <h3 className="-mx-2 -my-3 flow-root">
            <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400">
              <span className="font-medium text-gray-900">{filter.label}</span>
              <span className="ml-6 flex items-center">
                <ChevronDownIcon
                  className={clsx(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="space-y-6">
              {filter.options.map((filterOption, index) => (
                <div key={index} className="flex items-center">
                  <input
                    id={`filter-mobile-${filter.name}-${index}`}
                    type="checkbox"
                    defaultChecked={activeFilters.some(
                      (option) => option.value === filterOption.value
                    )}
                    className="h-4 w-4 border-gray-300 rounded text-flowmo-blue-600 focus:ring-flowmo-blue-500"
                    onChange={({ target }) => handleChanged(filterOption.value, target.checked)}
                  />
                  <label
                    htmlFor={`filter-mobile-${filter.name}-${index}`}
                    className="ml-3 text-sm text-gray-500"
                  >
                    {filterOption.label}
                  </label>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Filter
