import React from 'react'
import { clsx } from 'clsx'

const IconContainer: React.FC<{ className?: string; children: React.ReactNode }> = ({
  className = '',
  children,
}) => (
  <div className={clsx('rounded-lg w-9 h-9 flex justify-center items-center', className)}>
    {children}
  </div>
)

export const PlusIcon = () => (
  <IconContainer className="bg-[#f0fdfa]">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 9V12M12 12V15M12 12H15M12 12H9M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke="#0F766E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconContainer>
)

export const ArrowDownOnSquareIcon = () => (
  <IconContainer className="bg-[#F5F3FF]">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 16L4.5 17C4.5 18.6569 5.84315 20 7.5 20L17.5 20C19.1569 20 20.5 18.6569 20.5 17L20.5 16M16.5 12L12.5 16M12.5 16L8.5 12M12.5 16L12.5 4"
        stroke="#6D28D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconContainer>
)

export const LibraryIcon = () => (
  <IconContainer className="bg-[#F0F9FF]">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4.35418C12.7329 3.52375 13.8053 3 15 3C17.2091 3 19 4.79086 19 7C19 9.20914 17.2091 11 15 11C13.8053 11 12.7329 10.4762 12 9.64582M15 21H3V20C3 16.6863 5.68629 14 9 14C12.3137 14 15 16.6863 15 20V21ZM15 21H21V20C21 16.6863 18.3137 14 15 14C13.9071 14 12.8825 14.2922 12 14.8027M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
        stroke="#0369A1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconContainer>
)

export const SupportIcon = () => (
  <IconContainer className="bg-[#FFFBEB]">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 12H8.51M12.5 12H12.51M16.5 12H16.51M21.5 12C21.5 16.4183 17.4706 20 12.5 20C10.9607 20 9.51172 19.6565 8.24467 19.0511L3.5 20L4.89499 16.28C4.01156 15.0423 3.5 13.5743 3.5 12C3.5 7.58172 7.52944 4 12.5 4C17.4706 4 21.5 7.58172 21.5 12Z"
        stroke="#B45309"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconContainer>
)

export const ArrowIcon: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill="currentColor"
      d="M20.5001 4H21.5001C21.5001 3.73478 21.3947 3.48043 21.2072 3.29289C21.0197 3.10536 20.7653 3 20.5001 3V4ZM19.5001 16C19.5001 16.2652 19.6054 16.5196 19.793 16.7071C19.9805 16.8946 20.2349 17 20.5001 17C20.7653 17 21.0197 16.8946 21.2072 16.7071C21.3947 16.5196 21.5001 16.2652 21.5001 16H19.5001ZM8.50008 3C8.23486 3 7.98051 3.10536 7.79297 3.29289C7.60544 3.48043 7.50008 3.73478 7.50008 4C7.50008 4.26522 7.60544 4.51957 7.79297 4.70711C7.98051 4.89464 8.23486 5 8.50008 5V3ZM3.79308 19.293C3.69757 19.3852 3.62139 19.4956 3.56898 19.6176C3.51657 19.7396 3.48898 19.8708 3.48783 20.0036C3.48668 20.1364 3.51198 20.2681 3.56226 20.391C3.61254 20.5138 3.68679 20.6255 3.78069 20.7194C3.87458 20.8133 3.98623 20.8875 4.10913 20.9378C4.23202 20.9881 4.3637 21.0134 4.49648 21.0123C4.62926 21.0111 4.76048 20.9835 4.88249 20.9311C5.00449 20.8787 5.11483 20.8025 5.20708 20.707L3.79308 19.293ZM19.5001 4V16H21.5001V4H19.5001ZM20.5001 3H8.50008V5H20.5001V3ZM19.7931 3.293L3.79308 19.293L5.20708 20.707L21.2071 4.707L19.7931 3.293Z"
    />
  </svg>
)
