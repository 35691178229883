import { configureStore, StateFromReducersMapObject } from '@reduxjs/toolkit'
import playerReducer from './slices/playerSlice'

const createStore = () => {
  return configureStore({
    reducer: {
      player: playerReducer,
    },
  })
}

type ReducersMap = {
  player: typeof playerReducer
}
const _tmpStore = createStore()
export type RootState = StateFromReducersMapObject<ReducersMap>
export type AppDispatch = typeof _tmpStore.dispatch

export default createStore
