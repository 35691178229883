import React from 'react'

export const LeftArrowIcon = () => (
  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.10355 0.646447C6.29882 0.841709 6.29882 1.15829 6.10355 1.35355L1.95711 5.5L6.10355 9.64645C6.29882 9.84171 6.29882 10.1583 6.10355 10.3536C5.90829 10.5488 5.59171 10.5488 5.39645 10.3536L0.896447 5.85355C0.701184 5.65829 0.701184 5.34171 0.896447 5.14645L5.39645 0.646447C5.59171 0.451184 5.90829 0.451184 6.10355 0.646447Z"
      fill="currentColor"
    />
  </svg>
)

export const RightArrowIcon = () => (
  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.896477 0.646447C0.701215 0.841709 0.701215 1.15829 0.896477 1.35355L5.04292 5.5L0.896477 9.64645C0.701215 9.84171 0.701215 10.1583 0.896477 10.3536C1.09174 10.5488 1.40832 10.5488 1.60358 10.3536L6.10358 5.85355C6.29885 5.65829 6.29885 5.34171 6.10358 5.14645L1.60358 0.646447C1.40832 0.451184 1.09174 0.451184 0.896477 0.646447Z"
      fill="currentColor"
    />
  </svg>
)

export const FlowMoLabsIcon = () => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.63253 2.8784C9.25791 2.8784 9.76488 2.41183 9.76488 1.83629C9.76488 1.26076 9.25791 0.794189 8.63253 0.794189C8.00714 0.794189 7.50017 1.26076 7.50017 1.83629C7.50017 2.41183 8.00714 2.8784 8.63253 2.8784ZM5.96088 4.4307C6.07883 4.32215 6.21448 4.26787 6.36782 4.26787C6.52116 4.26787 6.65091 4.32215 6.75707 4.4307C6.87502 4.5284 6.934 4.64781 6.934 4.78893H10.3762C10.6639 4.78893 10.8972 5.02221 10.8972 5.30998C10.8972 5.59775 10.6639 5.83103 10.3762 5.83103H10.3311V9.82034L13.8166 13.9562C14.1351 14.3361 14.2943 14.7595 14.2943 15.2263C14.2943 15.5953 14.194 15.9427 13.9935 16.2684C13.793 16.5832 13.5158 16.8328 13.1619 17.0174C12.8199 17.2019 12.4483 17.2942 12.0473 17.2942H2.95307C2.55203 17.2942 2.17457 17.2019 1.82071 17.0174C1.47865 16.8328 1.20736 16.5832 1.00684 16.2684C0.806315 15.9427 0.706055 15.5953 0.706055 15.2263C0.706055 14.7595 0.865292 14.3361 1.18377 13.9562L4.66929 9.82034V5.83103H4.62417C4.3364 5.83103 4.10311 5.59775 4.10311 5.30998C4.10311 5.02221 4.3364 4.78893 4.62417 4.78893H5.80164C5.80164 4.64781 5.85472 4.5284 5.96088 4.4307ZM9.1987 10.1786V5.83103H5.80164V10.1786L4.19158 12.0837H10.8088L9.1987 10.1786ZM7.65941 8.07807C7.77736 7.96952 7.91301 7.91524 8.06635 7.91524C8.21969 7.91524 8.34944 7.96952 8.4556 8.07807C8.57355 8.17577 8.63253 8.29518 8.63253 8.43629C8.63253 8.57741 8.57355 8.70225 8.4556 8.8108C8.34944 8.9085 8.21969 8.95735 8.06635 8.95735C7.91301 8.95735 7.77736 8.9085 7.65941 8.8108C7.55325 8.70225 7.50017 8.57741 7.50017 8.43629C7.50017 8.29518 7.55325 8.17577 7.65941 8.07807Z"
      fill="white"
    />
  </svg>
)

export const SuccessIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5" r="5" fill="#1B72F2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.02343 3.6079C7.21693 3.80491 7.21408 4.12148 7.01706 4.31498L4.89585 6.39831C4.80085 6.49162 4.67255 6.54317 4.5394 6.54155C4.40625 6.53992 4.27924 6.48525 4.18655 6.38966L2.97442 5.13966C2.78219 4.94142 2.78706 4.62487 2.9853 4.43264C3.18354 4.2404 3.50009 4.24527 3.69232 4.44351L4.55415 5.33227L6.31635 3.60153C6.51337 3.40803 6.82994 3.41088 7.02343 3.6079Z"
      fill="white"
    />
  </svg>
)
