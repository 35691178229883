import React from 'react'
import { hot } from 'react-hot-loader'
import LaunchCard from './LaunchCard'
import { useUserStatus } from './useUserStatus'

import * as Sentry from '@sentry/react'
import '../common/sentry'
import Spin from '../common/ui/Spin'

const App = () => {
  const { loading, ...props } = useUserStatus()

  if (loading) {
    return (
      <div className="p-4 w-full flex justify-center items-center h-8">
        <Spin />
      </div>
    )
  }

  return <LaunchCard {...props} />
}

const AppProvider = () => (
  <Sentry.ErrorBoundary>
    <App />
  </Sentry.ErrorBoundary>
)

export default hot(module)(AppProvider)
