import React from 'react'
import { getThumbnailPublicId, getThumbnailUrl } from '../../../../../Walkthrough/utils/walkthrough'
import { Walkthrough } from 'app/javascript/components/graphql'

type ThumbnailProps = {
  loading: boolean
  walkthrough: Walkthrough
}

const Thumbnail: React.FC<ThumbnailProps> = ({ loading, walkthrough }) => {
  const publicId = getThumbnailPublicId(walkthrough)
  const thumbnailUrl = publicId && getThumbnailUrl(publicId)

  if (!loading) {
    return <React.Fragment />
  }

  return (
    <div className="relative w-full h-full">
      <div
        className="w-full h-full absolute top-0 left-0"
        style={{
          zIndex: -1,
          backdropFilter: 'blur(2px)',
        }}
      />
      <img
        style={{
          zIndex: -2,
        }}
        alt={walkthrough.uuid}
        className="w-full h-auto absolute top-0 left-0"
        src={thumbnailUrl}
        width="100%"
        height="auto"
      />
    </div>
  )
}

export default Thumbnail
