import Circles from '../Circles'
import React, { useEffect } from 'react'
import { applyWithoutAnimation } from '../animation/without-description'

interface Props {
  circleRef: any
}

const PureHighlighter = ({ circleRef }: Props) => {
  useEffect(() => {
    applyWithoutAnimation()
  }, [])

  return (
    <React.Fragment>
      <Circles elementRef={circleRef} className="without-description" />
    </React.Fragment>
  )
}

export default PureHighlighter
