import React from 'react'

interface Props {
  children?: React.ReactNode
}

const Title = ({ children }: Props) => (
  <div className="font-bold sm:text-[20px] md:text-[28px] lg:text-[40px] text-white mb-[10px]">
    {children}
  </div>
)

export default Title
