import React from 'react'
import StepCard from '../StepCard'
import TitleDescription from './shared/TitleDescription'

const TextOnly = ({
  step,
  stepNumber,
  isLast,
  index,
}: {
  step: any
  stepNumber: any
  isLast: boolean
  index: number
}) => (
  <StepCard step={step} isLast={isLast} stepNumber={stepNumber}>
    <TitleDescription
      title={step.title}
      index={index}
      description={step.description}
      defaultTitle="Check out this content"
    />
  </StepCard>
)

export default TextOnly
