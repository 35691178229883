import { gql } from '@apollo/client'

export const GET_SKILL_SET_QUERY = gql`
  query SkillSet($uuid: ID!) {
    skillSet: SkillSet(uuid: $uuid) {
      id
      name
      description
      uuid
      canEdit
      canMove
      createdBy {
        id
        firstName
        lastName
      }
      sharedAccess
      canEditSharing
      completed
      isInMyLibrary
      isInMyOrganizationLibrary
      createdAt
      walkthroughs {
        id
        uuid
        name
        description
        steps
        meta
        createdBy {
          firstName
          lastName
          id
        }
        canEdit
        canMove
        isOwner
        skillSet {
          id
          name
          description
          uuid
          isInMyLibrary
          isInMyOrganizationLibrary
          sharedAccess
          canEditSharing
        }
        isInMyLibrary
        isInMyOrganizationLibrary
        canEditSharing
        canDelete
        sharedAccess
        completed
        canEditSharing
        createdAt
        skillSet {
          id
          name
          uuid
          sharedAccess
          __typename
        }
      }
    }
  }
`

export const DELETE_SKILL_SET_QUERY = gql`
  mutation deleteSkillSet($id: ID!) {
    deleteSkillSet(id: $id) {
      id
    }
  }
`

export const UPDATE_SKILL_SET_QUERY = gql`
  mutation updateSkillSet($id: ID!, $name: String!, $description: String!) {
    updateSkillSet(id: $id, name: $name, description: $description) {
      id
      name
      description
      uuid
      createdBy {
        id
        firstName
      }
      walkthroughs {
        id
        uuid
        name
      }
    }
  }
`

export interface SkillSetVars {
  uuid: string
}

export const CREATE_SKILL_SET = gql`
  mutation createSkillSet($name: String!, $description: String!) {
    data: createSkillSet(name: $name, description: $description) {
      id
      name
      description
      uuid
      createdBy {
        id
      }
      createdAt
      updatedAt
    }
  }
`

export const SHARE_SKILL_SET_QUERY = gql`
  mutation shareSkillSet($id: ID!, $shareType: ShareTypeEnum!) {
    shareSkillSet(id: $id, shareType: $shareType) {
      id
      name
      sharedAccess
      isInMyLibrary
      isInMyOrganizationLibrary
      uuid
    }
  }
`

export const MOVE_SKILL_SET_QUERY = gql`
  mutation moveSkillSet($id: ID!, $destinationType: DestinationType!, $destinationId: ID) {
    skillSet: moveSkillSet(
      id: $id
      destinationType: $destinationType
      destinationId: $destinationId
    ) {
      id
      uuid
      name
      isInMyLibrary
      isInMyOrganizationLibrary
    }
  }
`
