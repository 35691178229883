import React from 'react'

type IconProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const CreateAudioIcon = (props: IconProps) => (
  <div {...props}>
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 1H8.5C9.05228 1 9.5 1.44772 9.5 2V8C9.5 8.55229 9.05228 9 8.5 9H6.5C5.94772 9 5.5 8.55229 5.5 8V2C5.5 1.44772 5.94772 1 6.5 1ZM4.5 2C4.5 0.89543 5.39543 0 6.5 0H8.5C9.60457 0 10.5 0.895431 10.5 2V8C10.5 9.10457 9.60457 10 8.5 10H6.5C5.39543 10 4.5 9.10457 4.5 8V2ZM3.5 7C3.5 6.72386 3.27614 6.5 3 6.5C2.72386 6.5 2.5 6.72386 2.5 7V8.5C2.5 10.433 4.067 12 6 12H7V13H5.5C5.22386 13 5 13.2239 5 13.5C5 13.7761 5.22386 14 5.5 14H9.5C9.77614 14 10 13.7761 10 13.5C10 13.2239 9.77614 13 9.5 13H8V12H9C10.933 12 12.5 10.433 12.5 8.5V7C12.5 6.72386 12.2761 6.5 12 6.5C11.7239 6.5 11.5 6.72386 11.5 7V8.5C11.5 9.88071 10.3807 11 9 11H6C4.61929 11 3.5 9.88071 3.5 8.5V7Z"
        fill="white"
      />
    </svg>
  </div>
)

export const EditAudioIcon = (props: IconProps) => (
  <div {...props}>
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5C8 1.22386 7.77614 1 7.5 1C7.22386 1 7 1.22386 7 1.5V12.5C7 12.7761 7.22386 13 7.5 13C7.77614 13 8 12.7761 8 12.5V1.5ZM4.5 2C4.77614 2 5 2.22386 5 2.5L5 11.5C5 11.7761 4.77614 12 4.5 12C4.22386 12 4 11.7761 4 11.5L4 2.5C4 2.22386 4.22386 2 4.5 2ZM1.5 4C1.77614 4 2 4.22386 2 4.5L2 9.5C2 9.77614 1.77614 10 1.5 10C1.22386 10 1 9.77614 1 9.5V4.5C1 4.22386 1.22386 4 1.5 4ZM10.5 2C10.7761 2 11 2.22386 11 2.5V11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5V2.5C10 2.22386 10.2239 2 10.5 2ZM14 4.5C14 4.22386 13.7761 4 13.5 4C13.2239 4 13 4.22386 13 4.5V9.5C13 9.77614 13.2239 10 13.5 10C13.7761 10 14 9.77614 14 9.5V4.5Z"
        fill="white"
      />
    </svg>
  </div>
)

export const RecordIcon = (props: IconProps) => (
  <div {...props}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
        fill="white"
      />
      <circle cx="7" cy="7" r="3" fill="white" />
    </svg>
  </div>
)

export const DoneIcon = (props: IconProps) => (
  <div {...props}>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM5.2 4C4.53726 4 4 4.53726 4 5.2V8.8C4 9.46274 4.53726 10 5.2 10H8.8C9.46274 10 10 9.46274 10 8.8V5.2C10 4.53726 9.46274 4 8.8 4H5.2Z"
        fill="white"
      />
    </svg>
  </div>
)
