import React from 'react'
import { CursorArrowRaysIcon } from '@heroicons/react/24/solid'

interface Props {
  url?: string
}

const CardThumbnail: React.FC<Props> = ({ url }) =>
  url ? <Thumbnail url={url} /> : <DefaultThumbnail />

const Thumbnail: React.FC<{ url: string }> = ({ url }) => (
  <ImageFilter>
    <img src={url} className="w-full h-40 object-cover object-top mx-auto" alt="Thumbnail" />
  </ImageFilter>
)

const DefaultThumbnail = () => (
  <div className="w-full h-40 bg-flowmo-blue-200 flex">
    <CursorArrowRaysIcon className="h-20 w-20 m-auto justify-center text-flowmo-blue-300/30" />
  </div>
)

const ImageFilter = ({ children }: { children: React.ReactNode }) => (
  <div className="relative w-full">
    {children}
    <div className="top-0 absolute w-full h-full bg-black opacity-20" />
  </div>
)

export default CardThumbnail
