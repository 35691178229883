import React from 'react'
import { clsx } from 'clsx'

interface Props {
  name: string
  value?: string
  placeholder: string
  onChange: (event: any) => void
  error?: string
  className?: string
  label?: string
}

const Textarea: React.FC<Props> = ({
  name,
  value,
  placeholder,
  onChange,
  error,
  className = '',
  label,
}) => {
  return (
    <div>
      {label && <div className="shrink-0 text-gray-400">{label}</div>}

      <textarea
        rows={3}
        name={name}
        className={clsx(
          `block w-full border-dashed rounded-lg py-3 border-1 bg-gray-300 resize-none focus:ring-0 sm:text-sm`,
          error ? 'border-red-700' : '',
          className
        )}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />

      {error && <span className="walkthrough-form-error text-xs text-red-700">{error}</span>}
    </div>
  )
}

export default Textarea
