import React from 'react'
import CardMenuItem from './index'
import { DestinationType } from '../../../../../components/graphql'
import { SubMenu } from '@szhsin/react-menu'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../components/AI/store'

type props = {
  onMove: (variables: { destinationType: DestinationType; destinationId?: string }) => void
  subject: any
}

const buildMenuItems = (subject: any) => {
  const { user, organization } = useSelector((state: RootState) => state.app)
  if (!user || !organization) {
    return []
  }
  return [
    {
      label: 'My Library',
      disabled: subject.isInMyLibrary,
      variables: {
        destinationType: DestinationType.User,
        destinationId: user.id.toString(),
      },
    },
    {
      label: 'Organization Library',
      disabled: subject.isInMyOrganizationLibrary,
      variables: {
        destinationType: DestinationType.Organization,
        destinationId: organization.id.toString(),
      },
    },
  ]
}

const MoveMenuItem: React.FC<props> = ({ onMove, subject }) => {
  const moveItems = buildMenuItems(subject)
  return (
    <SubMenu className="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" label="Move">
      {moveItems.map((item, index) => {
        const handleMove = () => {
          onMove(item.variables)
        }

        return (
          <CardMenuItem
            key={index}
            label={item.label}
            disabled={item.disabled}
            onClick={handleMove}
          />
        )
      })}
    </SubMenu>
  )
}

export default MoveMenuItem
