import React from 'react'

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  validate?: string
  description?: string
  error?: string
  label?: string
}

const Textarea: React.FC<TextareaProps> = ({ label, validate, description, error, ...props }) => {
  const generateClasses = () => {
    const defaultClasses =
      'block border pl-3 pr-10 py-2 w-full text-base sm:text-sm rounded-md' + ' '

    if (validate === 'error') {
      return (
        defaultClasses +
        'border-red-300 text-red-900 placeholder-red-400 focus:outline-none focus:ring-red-500 focus:border-red-500'
      )
    }

    return (
      defaultClasses +
      'text-gray-900 focus:ring-flowmo-blue-500 focus:border-flowmo-blue-500 border-gray-300'
    )
  }

  const inputClasses = generateClasses()

  return (
    <div>
      {label && <div className="flex text-sm font-medium text-gray-700">{label}</div>}
      <div className="mt-1 relative rounded-md">
        <textarea className={inputClasses} {...props} />
      </div>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      {description && !error && <p className="mt-2 text-sm text-gray-500">{description}</p>}
    </div>
  )
}

export default Textarea
