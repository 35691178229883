import React from 'react'
import { Field } from 'formik'
import Textarea from './index'

interface TextareaField extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
}

export const TextareaField: React.FC<TextareaField> = ({
  rows,
  label,
  placeholder,
  className,
  ...props
}) => {
  return (
    <div className={className}>
      <Field {...props}>
        {({ field, meta }: any) => {
          return (
            <Textarea
              {...field}
              rows={rows}
              label={label}
              placeholder={placeholder}
              validate={meta.touched && meta.error && 'error'}
            />
          )
        }}
      </Field>
    </div>
  )
}
