import React from 'react'
import { SideEffectFieldsFragment } from '../../../graphql'
import { ExternalLinkIcon } from 'lucide-react'

const LookerExplore: React.FC<{ sideEffect: SideEffectFieldsFragment }> = ({ sideEffect }) => {
  const link = sideEffect.output
  if (!link) {
    return <></>
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <a
        href={link}
        target="_blank"
        className="flex flex-row items-center gap-2 p-2 rounded-full border border-gray-200 cursor-pointer hover:shadow-md text-gray-500"
        rel="noreferrer"
      >
        <ExternalLinkIcon className="w-4 h-4" />
        <div className="text-xs font-semibold">Looker Explore</div>
      </a>
    </div>
  )
}

export default LookerExplore
