import React from 'react'

const FeedItemSkeleton = () => {
  return (
    <div className="mb-10 ms-4 animate-pulse flex flex-col space-y-2 ">
      <div className="absolute w-3 h-3 bg-blue-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
      <time className="mb-1 text-sm font-normal leading-none text-gray-300 ">
        <div className="bg-gray-300 h-2 w-20 rounded"></div>
      </time>
      <h3 className="text-lg font-semibold text-gray-300 mb-2">
        <div className="h-2 bg-gray-300 rounded w-60"></div>
      </h3>
      <div className="mb-4 text-base font-normal text-gray-300">
        <div className="space-y-3">
          <div className="grid grid-cols-3 gap-4">
            <div className="h-2 bg-gray-300 rounded col-span-2"></div>
            <div className="h-2 bg-gray-300 rounded col-span-1"></div>
          </div>
          <div className="h-2 bg-gray-300 rounded"></div>
        </div>
      </div>
    </div>
  )
}

export default FeedItemSkeleton
