import { RadioGroup } from '@headlessui/react'
import { clsx } from 'clsx'
import React from 'react'

export interface ShareOptionType {
  key: string
  name: string
  description: (objectType: 'walkthrough' | 'skillSet') => string
}

export const ShareOptions: ShareOptionType[] = [
  {
    key: 'private',
    name: 'Private to you',
    description: (objectType: 'walkthrough' | 'skillSet') =>
      `You are the only one able to access this ${OBJECT_NAME[objectType]}`,
  },
  {
    key: 'organization',
    name: 'Private to your organization',
    description: (objectType: 'walkthrough' | 'skillSet') =>
      `Anyone in your organization will able to access this ${OBJECT_NAME[objectType]}`,
  },
  {
    key: 'public',
    name: 'Public access',
    description: (objectType: 'walkthrough' | 'skillSet') =>
      `This ${OBJECT_NAME[objectType]} would be available to anyone who has the link. Even if they aren't logged in.`,
  },
]

const OBJECT_NAME = {
  walkthrough: 'Walkthrough',
  skillSet: 'Skill Set',
}

interface Props {
  objectType: 'walkthrough' | 'skillSet'
  onSelect: (shareType: ShareOptionType) => void
  value: ShareOptionType
}

const OptionsInput: React.FC<Props> = ({ value, onSelect, objectType }) => {
  return (
    <RadioGroup value={value} onChange={onSelect}>
      <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px">
        {ShareOptions.map((setting, settingIdx) => (
          <RadioGroup.Option
            key={setting.name}
            value={setting}
            className={({ checked }) =>
              clsx(
                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                settingIdx === ShareOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'bg-flowmo-blue-50 border-flowmo-blue-200 z-10' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={clsx(
                    checked ? 'bg-flowmo-blue-600 border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-flowmo-blue-500' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col flex-1">
                  <RadioGroup.Label
                    as="span"
                    className={clsx(
                      checked ? 'text-flowmo-blue-900' : 'text-gray-900',
                      'block text-sm font-medium'
                    )}
                  >
                    {setting.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={clsx(
                      checked ? 'text-flowmo-blue-700' : 'text-gray-500',
                      'block text-sm'
                    )}
                  >
                    {setting.description(objectType)}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

export default OptionsInput
