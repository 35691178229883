import React, {
  useRef,
  useEffect,
  ReactElement,
  useContext,
  useCallback,
  useState,
  useMemo,
  CSSProperties,
} from 'react'
import { ResizeContext } from '../../modules/Resize'
import * as rrweb from '@flowmo/rrweb'

import * as styles from './style.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

type InitPlayer = (events: any[], root: HTMLElement) => rrweb.Replayer

const initPlayer: InitPlayer = (events, root) => {
  const defaultOptions = {
    useVirtualDom: false,
    skipInactive: false,
    pauseAnimation: false,
    UNSAFE_replayCanvas: true,
  }
  return new rrweb.Replayer(events, {
    root,
    ...defaultOptions,
  })
}

type ReplayerProps = {
  events: any[]
  className?: string
  children(props: ReplayerChildrenProps): ReactElement
}

export type ReplayerChildrenProps = {
  replayer: rrweb.Replayer
}

const Replayer: React.FC<ReplayerProps> = ({ children, events }) => {
  const [ready, setReady] = useState<boolean>(false)
  const [resized, setResized] = useState<boolean>(false)
  const context = useContext(ResizeContext)
  const replayer = useRef<rrweb.Replayer>(null)
  const ref = useRef<HTMLDivElement | null>(null)
  const {
    maxHeight,
    playerControls: { isInteractiveStep },
  } = useSelector((state: RootState) => state.player)

  const resize = useCallback(() => {
    if (replayer.current) {
      if (isInteractiveStep) {
        replayer.current.wrapper.style.transformOrigin = ''
        replayer.current.wrapper.style.transform = ''
        replayer.current.wrapper.style.height = `${maxHeight}px`
        replayer.current.iframe.style.width = '100%'
        replayer.current.iframe.style.height = context.size.height * context.scale + 'px'
      } else {
        replayer.current.wrapper.style.transformOrigin = `left top`
        replayer.current.wrapper.style.transform = `scale(${context.scale})`
        replayer.current.iframe.style.height = ''
        replayer.current.iframe.style.width = ''
      }
      setResized(true)
    }
  }, [context.scale, isInteractiveStep])

  useEffect(() => {
    if (ref.current) {
      replayer.current && replayer.current.destroy()
      // @ts-ignore
      replayer.current = initPlayer(events, ref.current)

      replayer.current.on('resize', ({ width, height }: any) => {
        context.resize({ width, height })
      })

      replayer.current.on('fullsnapshot-rebuilded', () => {
        setReady(true)
      })

      replayer.current.on('destroy', () => {
        setReady(false)
        setResized(false)
      })

      // replayer.current.on('event-cast', (event: any) => {
      //   if (event.type === rrweb.EventType.IncrementalSnapshot && event.meta != undefined) {
      //     // we just got to a user event
      //     const currentStep = steps.find((step) => step.timestamp === event.timestamp)
      //     if (currentStep !== undefined) {
      //       dispatch(startInteractiveStep(currentStep))
      //     }
      //   }
      // })
    }
  }, [ref, events])

  useEffect(() => {
    if (!replayer.current) {
      return
    }

    if (isInteractiveStep) {
      replayer.current.enableInteract()
    } else {
      replayer.current.disableInteract()
    }
  }, [replayer.current, isInteractiveStep])

  useEffect(() => {
    ready && resize()
  }, [context.scale, ready, isInteractiveStep])

  useEffect(() => {
    return () => {
      replayer?.current?.destroy()
    }
  }, [])

  type StyleObject = {
    height?: number | string
    width?: number | string
    left?: string
    position?: CSSProperties['position']
    opacity?: number
  }

  const style = useMemo<StyleObject>(() => {
    if (isInteractiveStep) {
      return {
        height: context.height && context.height < context.size.height ? context.height : maxHeight,
        width: context.width && context.width < context.size.width ? context.width : '100%',
      }
    } else {
      return context.width && context.width > context.size.width * context.scale
        ? {
            width: context.size.width * context.scale,
            height: context.size.height * context.scale,
          }
        : { left: '0px', position: 'absolute' }
    }
  }, [context.width, context.height, context.scale, isInteractiveStep])

  return (
    <React.Fragment>
      <div
        className={
          styles['flow-mo-replayer'] +
          ' ' +
          (isInteractiveStep ? styles['flow-mo-interactive'] : '')
        }
        style={{ opacity: resized ? 1 : 0, ...style }}
        ref={ref}
      />
      {replayer.current && children({ replayer: replayer.current })}
    </React.Fragment>
  )
}

export default Replayer
