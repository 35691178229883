import React from 'react'
import Paragraph from '../Paragraph'

const Header = () => {
  return (
    <div className="py-9">
      <Paragraph>
        Learn and teach key skills with interactive walkthroughs. To get started, be sure you have
        the FlowMo chrome extension downloaded.
      </Paragraph>
    </div>
  )
}

export default Header
