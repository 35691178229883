import React from 'react'

export const MuteIcon = () => (
  <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.96313 0C3.68422 0 2.64746 1.03676 2.64746 2.31567V8.53687C2.64746 9.81577 3.68422 10.8525 4.96313 10.8525H7.03686C8.31577 10.8525 9.35253 9.81577 9.35253 8.53687V2.31567C9.35253 1.03676 8.31577 0 7.03686 0H4.96313ZM4.1682 2.31567C4.1682 1.87664 4.5241 1.52074 4.96313 1.52074H7.03686C7.47589 1.52074 7.83179 1.87664 7.83179 2.31567V8.53687C7.83179 8.97589 7.47589 9.3318 7.03686 9.3318H4.96313C4.5241 9.3318 4.1682 8.97589 4.1682 8.53687V2.31567ZM1.3341 6.73963C0.914159 6.73963 0.57373 7.08006 0.57373 7.5V9.0553C0.57373 11.1932 2.30682 12.9263 4.4447 12.9263H5.23963V13.4793L3.92626 13.4793C3.50633 13.4793 3.1659 13.8197 3.1659 14.2396C3.1659 14.6596 3.50633 15 3.92626 15L8.07373 15C8.49367 15 8.8341 14.6596 8.8341 14.2396C8.8341 13.8197 8.49367 13.4793 8.07373 13.4793L6.76037 13.4793V12.9263H7.5553C9.69317 12.9263 11.4263 11.1932 11.4263 9.0553V7.5C11.4263 7.08006 11.0858 6.73963 10.6659 6.73963C10.246 6.73963 9.90553 7.08006 9.90553 7.5V9.0553C9.90553 10.3533 8.85329 11.4055 7.5553 11.4055H4.4447C3.1467 11.4055 2.09447 10.3533 2.09447 9.0553V7.5C2.09447 7.08006 1.75404 6.73963 1.3341 6.73963Z"
      fill="#434E5F"
    />
  </svg>
)

export const UnMuteIcon = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.97787 0C5.69896 0 4.6622 1.03676 4.6622 2.31567V2.85756L2.24209 0.437449C1.94515 0.140506 1.46371 0.140506 1.16677 0.437449C0.869826 0.734391 0.869826 1.21583 1.16677 1.51277L13.758 14.104C14.055 14.401 14.5364 14.401 14.8333 14.104C15.1303 13.8071 15.1303 13.3256 14.8333 13.0287L12.8755 11.0708C13.2342 10.4837 13.441 9.79332 13.441 9.0553V7.5C13.441 7.08006 13.1006 6.73963 12.6806 6.73963C12.2607 6.73963 11.9203 7.08006 11.9203 7.5V9.0553C11.9203 9.36949 11.8587 9.66891 11.7471 9.94248L11.202 9.39739C11.3087 9.13112 11.3673 8.8406 11.3673 8.53687V2.31567C11.3673 1.03676 10.3305 0 9.0516 0H6.97787ZM6.18294 2.31567C6.18294 1.87664 6.53884 1.52074 6.97787 1.52074H9.0516C9.49063 1.52074 9.84653 1.87664 9.84653 2.31567V8.04189L6.18294 4.37829V2.31567ZM6.18294 7.31084L4.6622 5.7901V8.53687C4.6622 9.81577 5.69896 10.8525 6.97787 10.8525H9.0516C9.08412 10.8525 9.11651 10.8519 9.14875 10.8505L9.69984 10.8277L8.2039 9.3318H6.97787C6.53884 9.3318 6.18294 8.97589 6.18294 8.53687V7.31084ZM3.34883 6.73963C2.92889 6.73963 2.58847 7.08006 2.58847 7.5V9.0553C2.58847 11.1932 4.32156 12.9263 6.45943 12.9263H7.25436V13.4793L5.941 13.4793C5.52106 13.4793 5.18063 13.8197 5.18063 14.2396C5.18063 14.6596 5.52106 15 5.941 15L10.0885 15C10.5084 15 10.8488 14.6596 10.8488 14.2396C10.8488 13.8197 10.5084 13.4793 10.0885 13.4793L8.7751 13.4793V12.9263H9.57003C10.0886 12.9263 10.5839 12.8242 11.0365 12.6388L11.373 12.5009L10.197 11.3249L10.0681 11.3527C9.9078 11.3873 9.74119 11.4055 9.57003 11.4055H6.45943C5.16144 11.4055 4.1092 10.3533 4.1092 9.0553V7.5C4.1092 7.08006 3.76878 6.73963 3.34883 6.73963Z"
      fill="#BDC3CF"
    />
  </svg>
)

export const PlayIcon = () => (
  <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.46244 0.140122C1.33694 0.0563915 1.19106 0.00830107 1.04038 0.000981524C0.889686 -0.00633802 0.739837 0.0273879 0.606814 0.0985614C0.473792 0.169735 0.362586 0.275686 0.285062 0.405111C0.207538 0.534535 0.166603 0.682578 0.166626 0.833444V14.1666C0.166603 14.3174 0.207538 14.4655 0.285062 14.5949C0.362586 14.7243 0.473792 14.8303 0.606814 14.9014C0.739837 14.9726 0.889686 15.0063 1.04038 14.999C1.19106 14.9917 1.33694 14.9436 1.46244 14.8599L11.4623 8.19332C11.5764 8.11722 11.67 8.01412 11.7347 7.89318C11.7994 7.77223 11.8333 7.63718 11.8333 7.5C11.8333 7.36283 11.7994 7.22777 11.7347 7.10682C11.67 6.98588 11.5764 6.88278 11.4623 6.80668L1.46244 0.140122ZM1.83326 12.6091V2.39092L9.4998 7.5L1.83326 12.6091Z"
      fill="#434E5F"
    />
  </svg>
)

export const PauseIcon = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.16671 0C0.945694 0 0.733732 0.0877973 0.577452 0.244078C0.421172 0.400358 0.333374 0.61232 0.333374 0.833333V14.1667C0.333374 14.3877 0.421172 14.5996 0.577452 14.7559C0.733732 14.9122 0.945694 15 1.16671 15H5.33337C5.55439 15 5.76635 14.9122 5.92263 14.7559C6.07891 14.5996 6.16671 14.3877 6.16671 14.1667V0.833333C6.16671 0.61232 6.07891 0.400358 5.92263 0.244078C5.76635 0.0877973 5.55439 0 5.33337 0H1.16671ZM4.50004 13.3333H2.00004V1.66667H4.50004V13.3333ZM8.66671 0C8.44569 0 8.23373 0.0877973 8.07745 0.244078C7.92117 0.400358 7.83337 0.61232 7.83337 0.833333V14.1667C7.83337 14.3877 7.92117 14.5996 8.07745 14.7559C8.23373 14.9122 8.44569 15 8.66671 15H12.8334C13.0544 15 13.2663 14.9122 13.4226 14.7559C13.5789 14.5996 13.6667 14.3877 13.6667 14.1667V0.833333C13.6667 0.61232 13.5789 0.400358 13.4226 0.244078C13.2663 0.0877973 13.0544 0 12.8334 0H8.66671ZM12 13.3333H9.50004V1.66667H12V13.3333Z"
      fill="#434E5F"
    />
  </svg>
)

export const DeleteIcon = () => (
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.7 0.5C5.32083 0.5 5.00938 0.625 4.76562 0.875C4.52187 1.125 4.4 1.44444 4.4 1.83333V2.5H1.16667C0.798476 2.5 0.5 2.79848 0.5 3.16667V3.18333C0.5 3.54232 0.791015 3.83333 1.15 3.83333V14.5C1.15 15.0417 1.33958 15.5139 1.71875 15.9167C2.11146 16.3056 2.57187 16.5 3.1 16.5H10.9C11.4281 16.5 11.8818 16.3056 12.2609 15.9167C12.6536 15.5139 12.85 15.0417 12.85 14.5V3.83333C13.209 3.83333 13.5 3.54232 13.5 3.18333V3.16667C13.5 2.79848 13.2015 2.5 12.8333 2.5H9.6V1.83333C9.6 1.44444 9.47812 1.125 9.23438 0.875C8.99062 0.625 8.67917 0.5 8.3 0.5H5.7ZM5.7 1.83333H8.3V2.5H5.7V1.83333ZM2.45 3.83333H11.55V14.5C11.55 14.6806 11.4823 14.8403 11.3469 14.9792C11.225 15.1042 11.076 15.1667 10.9 15.1667H3.1C2.92396 15.1667 2.76823 15.1042 2.63281 14.9792C2.51094 14.8403 2.45 14.6806 2.45 14.5V3.83333ZM4.4 5.83333C4.04101 5.83333 3.75 6.12435 3.75 6.48333V12.5167C3.75 12.8757 4.04101 13.1667 4.4 13.1667C4.75898 13.1667 5.05 12.8757 5.05 12.5167V6.48333C5.05 6.12435 4.75898 5.83333 4.4 5.83333ZM7 5.83333C6.64101 5.83333 6.35 6.12435 6.35 6.48333V12.5167C6.35 12.8757 6.64101 13.1667 7 13.1667C7.35898 13.1667 7.65 12.8757 7.65 12.5167V6.48333C7.65 6.12435 7.35898 5.83333 7 5.83333ZM9.6 5.83333C9.24101 5.83333 8.95 6.12435 8.95 6.48333V12.5167C8.95 12.8757 9.24101 13.1667 9.6 13.1667C9.95898 13.1667 10.25 12.8757 10.25 12.5167V6.48333C10.25 6.12435 9.95898 5.83333 9.6 5.83333Z"
      fill="#434E5F"
    />
  </svg>
)
