import React, { useState, useCallback, useEffect, useRef } from 'react'
import { GET_FEED_ITEMS } from '../../graphql/queries/feed'
import { useLazyQuery } from '@apollo/client'
import FeedItemSkeleton from './FeedItemSkeleton'
import { FeedItem } from '../../../../components/graphql'
import BaseFeedItem from './FeedItem'
import { Link } from 'react-router-dom'
import FeedFilter from './FeedFilter'

const Workspace = () => {
  const [feedItems, setFeedItems] = useState<FeedItem[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [loading, setIsLoading] = useState(false)
  const [currentOffset, setCurrentOffset] = useState(0) // Track offset
  const [activeSelections, setActiveSelections] = useState<string[]>([])
  const limit = 20
  const loadMoreRef = useRef<HTMLDivElement | null>(null) // Create a ref for the load more div

  // Create a lazy query using useLazyQuery (doesn't trigger on component mount)
  const [fetchFeedItems] = useLazyQuery(GET_FEED_ITEMS, {
    fetchPolicy: 'network-only',
  })

  // Load more feed items
  const loadMore = useCallback(
    (offset: number, types: string[]) => {
      setIsLoading(true)
      fetchFeedItems({
        variables: {
          limit,
          offset,
          types,
        },
      }).then((result) => {
        const newFeedItems = result.data?.feedItems
        setCurrentOffset(offset + limit)
        if (newFeedItems) {
          setFeedItems((prev) => [...prev, ...newFeedItems])
          setHasMore(newFeedItems.length === limit)
        } else {
          setHasMore(false)
        }
        setIsLoading(false)
      })
    },
    [fetchFeedItems, activeSelections]
  )

  const observerCallback: IntersectionObserverCallback = useCallback(
    (entries) => {
      if (entries[0].isIntersecting && hasMore && currentOffset > 0) {
        loadMore(currentOffset, activeSelections)
      }
    },
    [loadMore, hasMore, currentOffset, activeSelections]
  )

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, { threshold: 1.0 })

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current)
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current)
      }
    }
  }, [loadMoreRef, observerCallback])

  useEffect(() => {
    loadMore(0, [])
  }, [])

  const handleSelectionChange = useCallback(
    (activeSelectionsFromFilter: string[]) => {
      setActiveSelections(activeSelectionsFromFilter)
      setCurrentOffset(0)
      setFeedItems([])
      loadMore(0, activeSelectionsFromFilter)
    },
    [loadMore]
  )

  return (
    <div className="workspace flex-grow mb-10">
      <div className="mb-5">
        <FeedFilter onFilterChange={handleSelectionChange} />
      </div>
      <div className="relative border-s border-gray-200">
        {feedItems.map((item) => {
          switch (item.__typename) {
            case 'Agent':
              return (
                <BaseFeedItem
                  key={item.uuid}
                  title="Agent Created"
                  createdAt={item.createdAt}
                  actionLabel="View"
                  actionUrl={'/agent/' + item.uuid}
                  dataTable={{
                    Name: item.firstName,
                    Description: item.description,
                  }}
                />
              )
            case 'AnalysisRun':
              return (
                <BaseFeedItem
                  key={item.uuid}
                  title="Analysis Run"
                  actionLabel="View"
                  actionUrl={'/analysis/run/' + item.uuid}
                  createdAt={item.createdAt}
                  dataTable={{
                    'analysis name': item.analysis.name,
                  }}
                />
              )
            case 'Suggestion':
              return (
                <BaseFeedItem
                  key={item.uuid}
                  title="Suggestion"
                  createdAt={item.createdAt}
                  dataTable={{
                    Suggestion: item.body,
                  }}
                />
              )
            case 'Workflow':
              return (
                <BaseFeedItem
                  key={item.uuid}
                  title="Workflow Created"
                  actionLabel="View"
                  actionUrl={'/workflow/' + item.uuid}
                  createdAt={item.createdAt}
                  dataTable={{
                    Name: item.name,
                    Description: item.description,
                  }}
                />
              )
            case 'TaskRun':
              return (
                <BaseFeedItem
                  key={item.uuid}
                  title="Task Run"
                  actionLabel="View Task"
                  actionUrl={'/task/' + item.task.uuid}
                  createdAt={item.createdAt}
                  dataTable={{
                    'task name': <Link to={'/task/' + item.task.uuid}>{item.task.name}</Link>,
                    'run status': item.status,
                    for: (
                      <Link
                        to={
                          '/' + item.task.taskableType.toLowerCase() + '/' + item.task.taskableUuid
                        }
                      >
                        {item.task.taskableType}
                      </Link>
                    ),
                  }}
                />
              )
            default:
              return null
          }
        })}

        {loading && <FeedItemSkeleton />}
      </div>
      {!loading && feedItems.length === 0 && (
        <div className="text-sm text-gray-400">Nothing in your workstream!</div>
      )}

      {/* Load more div to trigger fetching when visible */}
      {hasMore && !loading && currentOffset > 0 && (
        <div ref={loadMoreRef} className="mt-4 h-10 bg-transparent"></div>
      )}
    </div>
  )
}

export default Workspace
