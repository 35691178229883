import anime from 'animejs'

export const applyInitialDescriptionAnimation = ({
  beaconClass,
  contentClass,
}: {
  beaconClass: string
  contentClass: string
}) =>
  anime
    .timeline({
      loop: false,
      update: (anim) => {
        const beacon = document.querySelector(beaconClass) as any
        const content = document.querySelector(contentClass) as any

        if (anim.currentTime > 2900) {
          if (beacon) {
            beacon.style.pointerEvents = 'all'
          }

          if (content) {
            content.style.display = 'flex'
          }
        }
      },
    })
    .add(
      {
        targets: '.with-description.initial-animation .circle-1',
        backgroundColor: [
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 100,
            duration: 100,
            easing: 'easeInOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 1)',
            delay: 300 + 100 + 100 + 100 + 300,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300,
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 200,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 1)',
            delay: 100 + 100 + 100 + 300,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.with-description.initial-animation .circle-2',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.47)',
            delay: 200,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 1)',
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0.34)',
            delay: 300 + 100 + 100,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 1)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.with-description.initial-animation .circle-3',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.5)',
            delay: 500,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300 + 100 + 100,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0.5)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.with-description.initial-animation .circle-4',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.5)',
            delay: 200,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300 + 100 + 100 + 100,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0.5)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.with-description.initial-animation .circle-5',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.3)',
            delay: 500,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300 + 100 + 100,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0.3)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.with-description.initial-animation .circle-6',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.1)',
            delay: 500,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 1600,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.with-description.initial-animation.circle-7',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.4)',
            delay: 800,
            duration: 100,
            easing: 'easeInOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300 + 100 + 300 + 100,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.with-description',
        opacity: [
          {
            delay: 1600,
            duration: 300,
            value: 1,
            easing: 'easeOutCubic',
          },
          {
            value: 1,
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.with-description .beacon-icon',
        opacity: [
          {
            delay: 1900,
            duration: 300,
            value: 1,
            easing: 'easeOutCubic',
          },
          {
            value: 1,
            duration: 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.with-description .beacon-icon svg',
        opacity: [
          {
            delay: 2100,
            duration: 300,
            value: 1,
            easing: 'easeOutCubic',
          },
          {
            value: 1,
            duration: 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets:
          '.beacon.with-description.beacon-right.beacon-top, .beacon.with-description.beacon-right.beacon-default',
        translateX: [
          {
            value: 25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 25,
            duration: 1200,
          },
        ],
        translateY: [
          {
            value: -25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: -25,
            duration: 1200,
          },
        ],
        borderBottomLeftRadius: [
          {
            value: 0,
            delay: 1900,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 0,
            duration: 600,
          },
          {
            value: 15,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.with-description.beacon-right.beacon-bottom',
        translateX: [
          {
            value: 25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 25,
            duration: 1200,
          },
        ],
        translateY: [
          {
            value: 25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 25,
            duration: 1200,
          },
        ],
        borderTopLeftRadius: [
          {
            value: 0,
            delay: 1900,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 0,
            duration: 600,
          },
          {
            value: 15,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
      },
      0
    )
    .add(
      {
        targets:
          '.beacon.with-description.beacon-left.beacon-top, .beacon.with-description.beacon-left.beacon-default',
        translateX: [
          {
            value: -25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: -25,
            duration: 1200,
          },
        ],
        translateY: [
          {
            value: -25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: -25,
            duration: 1200,
          },
        ],
        borderBottomRightRadius: [
          {
            value: 0,
            delay: 1900,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 0,
            duration: 600,
          },
          {
            value: 15,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.with-description.beacon-left.beacon-bottom',
        translateX: [
          {
            value: -25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: -25,
            duration: 1200,
          },
        ],
        translateY: [
          {
            value: 25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 25,
            duration: 1200,
          },
        ],
        borderTopRightRadius: [
          {
            value: 0,
            delay: 1900,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 0,
            duration: 600,
          },
          {
            value: 15,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
      },
      0
    )

export const applyLoopDescriptionAnimation = () =>
  anime
    .timeline({
      loop: true,
      update: (anim) => {
        const beacon = document.querySelector('.beacon') as any

        if (beacon) {
          if (anim.currentTime > 2900 && anim.currentTime < 4400) {
            beacon.style.pointerEvents = 'all'
          }

          if (anim.currentTime > 4400) {
            beacon.style.pointerEvents = 'none'
          }
        }
      },
    })
    .add(
      {
        targets: '.with-description.loop-animation .circle-1',
        backgroundColor: [
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 100,
            duration: 100,
            easing: 'easeInOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 1)',
            delay: 300 + 100 + 100 + 100 + 300,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300,
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 200,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 1)',
            delay: 100 + 100 + 100 + 300,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.with-description.loop-animation .circle-2',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.47)',
            delay: 200,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 1)',
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0.34)',
            delay: 300 + 100 + 100,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 1)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.with-description.loop-animation .circle-3',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.5)',
            delay: 500,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300 + 100 + 100,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0.5)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.with-description.loop-animation .circle-4',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.5)',
            delay: 200,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300 + 100 + 100 + 100,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0.5)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.with-description.loop-animation .circle-5',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.3)',
            delay: 500,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300 + 100 + 100,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0.3)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.with-description.loop-animation .circle-6',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.1)',
            delay: 500,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 1600,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.with-description.loop-animation.circle-7',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.4)',
            delay: 800,
            duration: 100,
            easing: 'easeInOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300 + 100 + 300 + 100,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.with-description',
        opacity: [
          {
            delay: 1600,
            duration: 300,
            value: 1,
            easing: 'easeOutCubic',
          },
          {
            value: 1,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.with-description .beacon-icon',
        opacity: [
          {
            delay: 1900,
            duration: 300,
            value: 1,
            easing: 'easeOutCubic',
          },
          {
            value: 1,
            duration: 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.with-description .beacon-icon svg',
        opacity: [
          {
            delay: 2100,
            duration: 300,
            value: 1,
            easing: 'easeOutCubic',
          },
          {
            value: 1,
            duration: 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets:
          '.beacon.with-description.beacon-right.beacon-top, .beacon.with-description.beacon-right.beacon-default',
        translateX: [
          {
            value: 25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        translateY: [
          {
            value: -25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: -25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        borderBottomLeftRadius: [
          {
            value: 0,
            delay: 1900,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 0,
            duration: 900,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.with-description.beacon-right.beacon-bottom',
        translateX: [
          {
            value: 25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        translateY: [
          {
            value: 25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        borderTopLeftRadius: [
          {
            value: 0,
            delay: 1900,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 0,
            duration: 900,
          },
        ],
      },
      0
    )
    .add(
      {
        targets:
          '.beacon.with-description.beacon-left.beacon-top, .beacon.with-description.beacon-left.beacon-default',
        translateX: [
          {
            value: -25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: -25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        translateY: [
          {
            value: -25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: -25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        borderBottomRightRadius: [
          {
            value: 0,
            delay: 1900,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 0,
            duration: 900,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.with-description.beacon-left.beacon-bottom',
        translateX: [
          {
            value: -25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: -25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        translateY: [
          {
            value: 25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        borderTopRightRadius: [
          {
            value: 0,
            delay: 1900,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 0,
            duration: 900,
          },
        ],
      },
      0
    )
