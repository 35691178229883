import { useMutation } from '@apollo/client'
import { DELETE_SKILL_SET_QUERY } from '../../App/graphql/queries/skill_set'
import { DeleteSkillSetMutation } from '../../graphql'
import { DeleteSkillSetMutationVariables } from '../../graphql'

const useDeleteSkillSetMutation = (id: string) => {
  return useMutation<DeleteSkillSetMutation, DeleteSkillSetMutationVariables>(
    DELETE_SKILL_SET_QUERY,
    {
      variables: {
        id,
      },
    }
  )
}

export default useDeleteSkillSetMutation
