import React from 'react'
import { clsx } from 'clsx'

interface Props {
  icon?: React.ReactElement
  onClick?: () => void
  disabled?: boolean
  color?: any
  type?: 'button' | 'submit'
  className?: string
  children?: React.ReactNode
}

const COLOR_CLASS_NAME: any = {
  primary: 'border-transparent text-white bg-flowmo-blue-600 hover:bg-flowmo-blue-700',
  success: 'border-transparent text-white bg-[#29BC7E]',
  neutral: 'border-transparent text-white bg-[#BDC3CF]',
  secondary: 'text-gray-700 bg-white hover:bg-gray-50 border-gray-300',
  action:
    'text-flowmo-blue-600 bg-white hover:bg-gray-50 border-flowmo-blue-600 !ring-0 !ring-offset-0 !ring-transparent',
}

const Button: React.FC<Props> = ({
  children,
  onClick,
  icon,
  disabled,
  color = 'secondary',
  type = 'button',
  className,
}) => (
  <button
    type={type}
    className={clsx(
      'inline-flex items-center px-4 py-2 justify-center border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowmo-blue-500 ',
      disabled && 'opacity-50',
      className,
      COLOR_CLASS_NAME[color]
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
    {icon}
  </button>
)

export default Button
