import React from 'react'
import { SuccessIcon } from '../../../../../../Embed/Content/Labs/icons'
import { launcherOptions } from '../../Launcher'
import { useLabsContext } from '../../../../../../Embed/context/LabsContext'

const RefreshIcon = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33573 1.46846C7.60609 1.1981 7.60609 0.759756 7.33573 0.489393C7.06536 0.21903 6.62702 0.21903 6.35666 0.489393L4.15375 2.6923L6.35666 4.89521C6.62702 5.16557 7.06536 5.16557 7.33573 4.89521C7.60609 4.62485 7.60609 4.1865 7.33573 3.91614L6.42028 3.00069C7.02613 3.05391 7.62047 3.22204 8.17029 3.50049C9.12144 3.98217 9.88531 4.76659 10.3416 5.73019C10.7979 6.69378 10.9206 7.78178 10.6905 8.82282C10.4604 9.86385 9.89047 10.7987 9.07056 11.4802C8.25064 12.1617 7.2273 12.5511 6.16174 12.587C5.09619 12.6229 4.04896 12.3033 3.18504 11.6785C2.32111 11.0538 1.68958 10.1593 1.38992 9.13615C1.18334 8.43079 1.14257 7.69186 1.26508 6.97582C1.2869 6.84833 1.31388 6.72156 1.34604 6.59584C1.35606 6.55666 1.36264 6.51708 1.36592 6.47757C1.38945 6.19427 1.24306 5.91419 0.973292 5.80804C0.665892 5.68708 0.315516 5.83771 0.22575 6.15562C0.21225 6.20343 0.199353 6.25138 0.18706 6.29945C0.146451 6.45824 0.112435 6.61837 0.0850303 6.77942C-0.0668707 7.6721 -0.0156432 8.59312 0.241868 9.47238C0.616153 10.7504 1.40496 11.8675 2.48403 12.6479C3.5631 13.4282 4.87111 13.8274 6.20202 13.7826C7.53294 13.7378 8.81111 13.2514 9.83522 12.4002C10.8593 11.549 11.5711 10.3813 11.8586 9.08102C12.146 7.78074 11.9927 6.42179 11.4228 5.21823C10.8529 4.01467 9.89877 3.0349 8.71076 2.43326C8.15117 2.14987 7.55467 1.95796 6.94354 1.86065L7.33573 1.46846Z"
      fill="currentColor"
    />
  </svg>
)

const CompletedTryIt = () => {
  const { walkthrough, reset } = useLabsContext()
  return (
    <div
      className="absolute h-full w-full top-0 flex items-center px-[70px] space-x-[30px]"
      style={{
        background:
          'linear-gradient(90deg, #214A86 0%, #305B9A 25.52%, rgba(46, 46, 46, 0.71) 63.44%, rgba(46, 46, 46, 0.71) 100%)',
      }}
    >
      <SuccessIcon />
      <div className="flex flex-col">
        <div className="flex flex-col px-[30px] border-l border-l-[rgba(255,255,255,0.18)]">
          <div className="font-bold text-[23px] text-white">
            Yay! You completed the walkthrough successfully
          </div>
          <div className="text-[18px] text-white mt-2">Try other learning modes!</div>

          <div className="flex space-x-[20px] mt-[26px]">
            {launcherOptions.labs.button(`/labs/walkthrough/${walkthrough.uuid}`)}
          </div>
        </div>

        <button
          className="text-[14px] flex items-center space-x-[6px] text-white hover:underline hover:text-[#CBDDFF] mt-[22px] ml-[31px]"
          onClick={reset}
        >
          <span>Retry</span>
          <RefreshIcon />
        </button>
      </div>
    </div>
  )
}

export default CompletedTryIt
