import React from 'react'
import { SideEffectFieldsFragment } from '../../../graphql'
import NivoChart from './NivoChart'

const ChartResults: React.FC<{ config: SideEffectFieldsFragment['data'] }> = ({ config }) => {
  return (
    <div className="flex flex-row justify-start max-w-2xl bg-white rounded-lg p-4">
      <NivoChart config={config} />
    </div>
  )
}

export default ChartResults
