import React from 'react'

const ThreeDots = ({ speed = 2.4 }: { speed?: number }) => {
  const duration = `${speed}s`
  return (
    <div className="flex justify-center items-center">
      <svg
        width="50"
        height="15"
        viewBox="0 0 50 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {['0s', `${speed * 0.2}s`, `${speed * 0.4}s`].map((begin, i) => (
          <circle key={i} className="dot" cx={7.5 + 17.5 * i} cy="7.5" r="3" fill="currentColor">
            <animate
              attributeName="r"
              from="3"
              to="6"
              begin={begin}
              dur={duration}
              values="3;6;3"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill-opacity"
              from="1"
              to="0.3"
              begin={begin}
              dur={duration}
              values="1;.3;1"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
        ))}
      </svg>
    </div>
  )
}

export default ThreeDots
