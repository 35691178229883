import { useMutation } from '@apollo/client'
import {
  GET_WALKTHROUGH_QUERY,
  UPDATE_WALKTHROUGH_QUERY,
} from '../../App/graphql/queries/walkthrough'
import { WalkthroughByUuidQuery, WalkthroughByUuidQueryVariables } from '../../graphql'

const useUpdateWalkthroughMutation = () =>
  useMutation(UPDATE_WALKTHROUGH_QUERY, {
    update(cache, { data: { updateWalkthrough } }) {
      const uuid = updateWalkthrough.uuid

      const data = cache.readQuery<WalkthroughByUuidQuery, WalkthroughByUuidQueryVariables>({
        query: GET_WALKTHROUGH_QUERY,
        variables: { uuid },
      })

      cache.writeQuery({
        query: GET_WALKTHROUGH_QUERY,
        variables: { uuid },
        data: {
          walkthrough: {
            ...data.walkthrough,
            name: updateWalkthrough.name,
            description: updateWalkthrough.description,
            steps: updateWalkthrough.steps,
          },
        },
      })
    },
  })

export default useUpdateWalkthroughMutation
