import React from 'react'
import MessageStream from '../Chat/MessageStream'
import { useChatSelector } from '../../store'
import Message from '../Chat/Message'
import RunStep from '../../pages/app/OneAgentChat/RunStep'
import {
  AgentFieldsFragment,
  CompanyAgentFieldsFragment,
  MessageHistoryFieldsFragment,
  SideEffectFieldsFragment,
  StepHistoryFieldsFragment,
} from 'app/javascript/components/graphql'
import SideEffect from '../SideEffect'

interface BaseMessageThreadProps {
  messages: MessageHistoryFieldsFragment[]
  steps: StepHistoryFieldsFragment[]
  agent: AgentFieldsFragment | CompanyAgentFieldsFragment
  showName: boolean
  showVoting: boolean
  sideEffects: SideEffectFieldsFragment[]
}

export const BaseMessageThread = ({
  messages,
  steps,
  sideEffects,
  agent,
  showName,
  showVoting,
}: BaseMessageThreadProps) => {
  const messagesWithType = messages.map((message) => ({
    ...message,
    type: 'message',
  }))
  const stepsWithType = steps.map((step) => ({
    ...step,
    type: 'step',
  }))
  const sideEffectsWithType = sideEffects.map((sideEffect) => ({
    ...sideEffect,
    type: 'sideEffect',
  }))

  const interleavedItems: any[] = []
  let stepIndex = 0
  let sideEffectIndex = 0
  let sideEffectQueue: any[] = []

  for (let i = 0; i < messagesWithType.length; i++) {
    const currentMessage = messagesWithType[i]
    const nextMessage = messagesWithType[i + 1]

    // Push the current message
    interleavedItems.push(currentMessage)

    // Now, if sideEffectQueue is not empty, push sideEffects collected in previous iteration
    if (sideEffectQueue.length > 0) {
      interleavedItems.push(...sideEffectQueue)
      sideEffectQueue = []
    }

    // Collect sideEffects between currentMessage and nextMessage
    while (
      sideEffectIndex < sideEffectsWithType.length &&
      (!nextMessage ||
        sideEffectsWithType[sideEffectIndex].createdAtMilli < nextMessage.createdAtMilli)
    ) {
      if (sideEffectsWithType[sideEffectIndex].createdAtMilli > currentMessage.createdAtMilli) {
        sideEffectQueue.push(sideEffectsWithType[sideEffectIndex])
      }
      sideEffectIndex++
    }

    // Process steps as before
    while (
      stepIndex < stepsWithType.length &&
      (!nextMessage || stepsWithType[stepIndex].createdAtMilli < nextMessage.createdAtMilli)
    ) {
      if (stepsWithType[stepIndex].createdAtMilli > currentMessage.createdAtMilli) {
        interleavedItems.push(stepsWithType[stepIndex])
      }
      stepIndex++
    }
  }

  // After the loop, push any remaining sideEffects
  if (sideEffectQueue.length > 0) {
    interleavedItems.push(...sideEffectQueue)
    sideEffectQueue = []
  }

  // Add any remaining steps
  while (stepIndex < stepsWithType.length) {
    interleavedItems.push(stepsWithType[stepIndex])
    stepIndex++
  }

  // Add any remaining sideEffects
  while (sideEffectIndex < sideEffectsWithType.length) {
    interleavedItems.push(sideEffectsWithType[sideEffectIndex])
    sideEffectIndex++
  }

  return (
    <div className="flex flex-col space-y-6">
      {interleavedItems.map((item, index) => {
        const previousItem = index > 0 ? interleavedItems[index - 1] : null
        const showNextName = !previousItem || previousItem.actor !== item.actor
        if (item.type === 'message') {
          return (
            <Message
              agent={agent}
              message={item as MessageHistoryFieldsFragment}
              key={index}
              showName={showName && showNextName}
              showVoting={showVoting}
            />
          )
        } else if (item.type === 'step') {
          return (
            <RunStep
              agent={agent}
              step={item as StepHistoryFieldsFragment}
              key={index}
              showName={showName && showNextName}
            />
          )
        } else if (item.type === 'sideEffect') {
          return <SideEffect sideEffect={item as SideEffectFieldsFragment} key={index} />
        }
        return null
      })}
    </div>
  )
}

interface MessageThreadProps {
  chatId: string
  showName: boolean
}

const MessageThread = ({ chatId, showName }: MessageThreadProps) => {
  const chatState = useChatSelector(chatId)
  if (!chatState) {
    return <></>
  }
  const { isQuerying, messages, steps, agent, sideEffects } = chatState

  return (
    <div className="flex flex-col space-y-6">
      <BaseMessageThread
        messages={messages}
        steps={steps}
        sideEffects={sideEffects}
        agent={agent}
        showName={showName}
        showVoting={true}
      />
      {isQuerying && <MessageStream chatId={chatId} agent={agent} showName={showName} />}
    </div>
  )
}

export default MessageThread
