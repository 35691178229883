import React, { useCallback, useEffect, useState } from 'react'
import { FieldData } from '.'
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Dialog,
  DialogPanel,
  DialogBackdrop,
  TransitionChild,
  DialogTitle,
} from '@headlessui/react'
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { DocumentNode } from 'graphql'
import { useQuery } from '@apollo/client'
import Pager from '../../../Walkthrough/ui/Pager'
import FilterPanel from '../../../common/components/FilterPanel'
import { ActiveFilter } from '../../../common/components/FilterPanel/types'
import { SortOption } from '../../../common/components/FilterPanel/types'
import {
  GET_ORGANIZATION_CONTENT,
  CREATED_CONTENT_QUERY,
} from '../../../../components/App/graphql/queries/content'
import {
  Content,
  ContentType,
  GetCreatedContentQuery,
  GetCreatedContentQueryVariables,
  KnowledgeInput,
} from 'app/javascript/components/graphql'
import Spin from '../../../common/ui/Spin'
import CardThumbnail from '../../../../components/Walkthrough/ui/Walkthrough/Card/Thumbnail'
import CardContent from '../../../../components/Walkthrough/ui/Walkthrough/Card/Content'
import CardDescription from '../../../../components/Walkthrough/ui/Walkthrough/Card/Description'
import CardTitle from '../../../../components/Walkthrough/ui/Walkthrough/Card/Title'
import { getThumbnailPublicId } from '../../../../components/Walkthrough/utils/walkthrough'
import { getThumbnailUrl } from '../../../../components/Walkthrough/utils/walkthrough'
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { GET_WALKTHROUGH_QUERY } from '../../../../components/App/graphql/queries/walkthrough'
import { GET_SKILL_SET_QUERY } from '../../../../components/App/graphql/queries/skill_set'
import client from '../../../../components/common/ApolloClient'
import EmptyContentBox from './EmptyContentBox'
import ActionButton from './ActionButton'

export interface SelectableContentProps {
  uuid: string
  type: 'Walkthrough' | 'SkillSet'
  content: Content
}

const SelectableWalkthroughCard = ({ walkthrough }: { walkthrough: any }) => {
  if (!walkthrough) {
    return null
  }
  const publicId = getThumbnailPublicId(walkthrough)
  const thumbnailUrl = publicId && getThumbnailUrl(publicId)
  const url = `/walkthrough/${walkthrough.uuid}`
  return (
    <div className={clsx('h-full flex flex-col gap-2')}>
      <CardThumbnail url={thumbnailUrl} />
      <div className="flex-grow">
        <CardContent>
          <Link
            className="text-blue-500 hover:text-blue-600 hover:underline text-sm flex flex-row gap-2 items-center justify-start"
            target="_blank"
            to={url}
          >
            <CardTitle>{walkthrough.name}</CardTitle>
          </Link>
          <CardDescription>{ReactHtmlParser(walkthrough.description)}</CardDescription>
        </CardContent>
      </div>
    </div>
  )
}

const SelectableSkillSetCard = ({ skillSet }: { skillSet: any }) => {
  if (!skillSet) {
    return null
  }
  const publicId = getThumbnailPublicId(skillSet)
  const thumbnailUrl = publicId && getThumbnailUrl(publicId)
  const url = `/skill_set/${skillSet.uuid}`
  return (
    <div className="w-56 h-72 border rounded-lg bg-white ">
      <div
        className={clsx(
          '-mt-2 -ml-2 flex h-full w-full flex-col gap-2 border rounded-lg bg-white shadow-sm'
        )}
      >
        <CardThumbnail url={thumbnailUrl} />
        <div className="flex-grow">
          <CardContent>
            <Link
              className="text-blue-500 hover:text-blue-600 hover:underline text-sm flex flex-row gap-2 items-center justify-start"
              target="_blank"
              to={url}
            >
              <CardTitle>{skillSet.name}</CardTitle>
            </Link>
            <CardDescription>{ReactHtmlParser(skillSet.description)}</CardDescription>
          </CardContent>
        </div>
      </div>
    </div>
  )
}

const SelectableContentCard = ({
  content,
  isAdded,
  onRemove,
  onAdd,
}: {
  content: Content
  isAdded: boolean
  onRemove: (content: Content) => void
  onAdd?: (content: Content) => void
}) => {
  return (
    <div className="relative m-2 w-56 h-72 bg-white rounded-lg border">
      {content.__typename === 'Walkthrough' && <SelectableWalkthroughCard walkthrough={content} />}
      {content.__typename === 'SkillSet' && <SelectableSkillSetCard skillSet={content} />}
      <div className="absolute -top-3 -right-3 flex flex-row gap-2 items-center justify-center">
        {isAdded && (
          <button
            className="p-1 text-white rounded-full bg-red-500 hover:bg-red-600"
            onClick={() => onRemove(content)}
          >
            <MinusIcon className="w-4 h-4" />
          </button>
        )}
      </div>
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 flex flex-row items-center justify-center">
        {!isAdded && (
          <button
            className="rounded-xl px-2 py-1 bg-green-500 hover:bg-green-600 text-white flex flex-row items-center justify-center"
            onClick={() => onAdd && onAdd(content)}
          >
            <PlusIcon className="w-4 h-4" />
            <div className="">Add</div>
          </button>
        )}
      </div>
    </div>
  )
}

const ContentLibrary = ({
  handleAddContent,
  handleRemoveContent,
  query,
  selectedContent,
}: {
  title: string
  handleAddContent: (content: Content) => void
  handleRemoveContent: (content: Content) => void
  query: DocumentNode
  selectedContent: Content[]
}) => {
  const [page, setPage] = useState(1)
  const perPage = 12

  const { data, loading, refetch } = useQuery<
    GetCreatedContentQuery,
    GetCreatedContentQueryVariables
  >(query, {
    variables: {
      page,
      perPage,
      sortBy: 'created_at',
      sortDirection: 'DESC',
      contentType: [],
    },
  })

  if (!data || loading) {
    return <Spin className="h-4 w-4 text-ddu-blue m-auto" />
  }

  const contentList = data?.items
  const handlePage = (newPage: number) => {
    refetch({ page: newPage })
    setPage(newPage)
  }

  const handleSort = (sortOption: SortOption) => {
    refetch({
      sortBy: sortOption.sortBy,
      sortDirection: sortOption.sortDirection,
    })
  }

  const handleFilters = (filters: ActiveFilter[]) => {
    refetch({
      contentType: filters.map((filter) => filter.value as ContentType),
    })
  }

  const total = data?.total.count || 0

  return (
    <div className="flex flex-col gap-4">
      <FilterPanel handleSort={handleSort} handleFilters={handleFilters} />

      <div className="flex flex-wrap">
        {contentList.map((item) => (
          <SelectableContentCard
            key={item.uuid}
            content={item as Content}
            isAdded={selectedContent.find((c) => c.uuid === item.uuid) !== undefined}
            onAdd={handleAddContent}
            onRemove={handleRemoveContent}
          />
        ))}

        {total === 0 && (
          <EmptyContentBox title="No content found" widthClass="w-56" heightClass="h-72" />
        )}
      </div>

      {total > 0 && <Pager page={page} total={total} perPage={perPage} setPage={handlePage} />}
    </div>
  )
}

const MyLibrary = ({
  handleAddContent,
  handleRemoveContent,
  selectedContent,
}: {
  handleAddContent: (content: Content) => void
  handleRemoveContent: (content: Content) => void
  selectedContent: Content[]
}) => {
  return (
    <ContentLibrary
      query={CREATED_CONTENT_QUERY}
      title="My Library"
      handleAddContent={handleAddContent}
      handleRemoveContent={handleRemoveContent}
      selectedContent={selectedContent}
    />
  )
}

const OrganizationLibrary = ({
  handleAddContent,
  handleRemoveContent,
  selectedContent,
}: {
  handleAddContent: (content: Content) => void
  handleRemoveContent: (content: Content) => void
  selectedContent: Content[]
}) => {
  return (
    <ContentLibrary
      query={GET_ORGANIZATION_CONTENT}
      title="Organization Library"
      handleAddContent={handleAddContent}
      handleRemoveContent={handleRemoveContent}
      selectedContent={selectedContent}
    />
  )
}

const SelectedContent = ({
  selectedContent,
  handleRemoveContent,
}: {
  selectedContent: Content[]
  handleRemoveContent: (content: Content) => void
}) => {
  return (
    <div className="flex flex-wrap">
      {selectedContent.map((content) => (
        <SelectableContentCard
          key={content.uuid}
          content={content}
          onRemove={handleRemoveContent}
          isAdded={selectedContent.find((c) => c.uuid === content.uuid) !== undefined}
        />
      ))}
      {selectedContent.length === 0 && (
        <EmptyContentBox title="No content selected" widthClass="w-56" heightClass="h-72" />
      )}
    </div>
  )
}

export const ContentSelector = ({
  defaultSelectedContent,
  updateSelectedContent,
}: {
  defaultSelectedContent: Content[]
  updateSelectedContent: (contentList: Content[]) => void
}) => {
  const handleAddContent = (content: Content) => {
    if (selectedContent.includes(content)) {
      return
    }
    const newSelectedContent = [...selectedContent, content]
    setSelectedContent(newSelectedContent)
    updateSelectedContent(newSelectedContent)
  }

  const handleRemoveContent = (content: Content) => {
    const newSelectedContent = selectedContent.filter((c) => c.uuid !== content.uuid)
    setSelectedContent(newSelectedContent)
    updateSelectedContent(newSelectedContent)
  }

  const [selectedContent, setSelectedContent] = useState<Content[]>(defaultSelectedContent)

  const tabs = [
    {
      label: 'My Library',
      component: (
        <MyLibrary
          handleAddContent={handleAddContent}
          handleRemoveContent={handleRemoveContent}
          selectedContent={selectedContent}
        />
      ),
    },
    {
      label: 'Organization Library',
      component: (
        <OrganizationLibrary
          handleAddContent={handleAddContent}
          handleRemoveContent={handleRemoveContent}
          selectedContent={selectedContent}
        />
      ),
    },
  ]

  return (
    <div className="flex flex-col gap-4">
      <SelectedContent
        selectedContent={selectedContent}
        handleRemoveContent={handleRemoveContent}
      />
      <div className="">
        <TabGroup className="flex flex-row">
          <TabList className="flex flex-col">
            {tabs.map((tab) => (
              <Tab
                className="rounded-l-full py-2 px-3 text-sm/6 font-semibold text-blue-400 focus:outline-none data-[selected]:cursor-default data-[selected]:bg-blue-500/10 data-[hover]:bg-blue-500/5 data-[selected]:data-[hover]:bg-blue-500/10 data-[focus]:outline-1 data-[focus]:outline-blue-500"
                key={tab.label}
              >
                {tab.label}
              </Tab>
            ))}
          </TabList>
          <TabPanels className="flex-grow bg-gray-100 rounded-r-xl rounded-bl-xl shadow-inner">
            {tabs.map((tab) => (
              <TabPanel className="rounded-xl p-3" key={tab.label}>
                {tab.component}
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  )
}

export const ContentSelectorModal = ({
  isOpen,
  onClose,
  defaultSelectedContent,
  onUpdateSelectedContent,
}: {
  isOpen: boolean
  onClose: () => void
  defaultSelectedContent: Content[]
  onUpdateSelectedContent: (contentList: Content[]) => void
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50 ">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-screen max-w-6xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <TransitionChild>
                <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    onClick={onClose}
                    className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <DialogTitle className="text-base font-semibold text-gray-900">
                    Add Videos from Library
                  </DialogTitle>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <ContentSelector
                    defaultSelectedContent={defaultSelectedContent}
                    updateSelectedContent={onUpdateSelectedContent}
                  />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const KnowledgeField = ({ field, formik }: { field: FieldData; formik: any }) => {
  const [isVideosOpen, setIsVideosOpen] = useState(false)
  const [isDefaultContentLoading, setIsDefaultContentLoading] = useState(false)
  const [selectedContent, setSelectedContent] = useState<Content[]>([])

  const defaultSelectedContent: any[] = formik.values[field.name].map((content: KnowledgeInput) => {
    return {
      uuid: content.uuid,
      type: content.type as 'Walkthrough' | 'SkillSet',
    }
  })

  useEffect(() => {
    const getContent = async (type: 'Walkthrough' | 'SkillSet', uuid: string) => {
      if (type === 'Walkthrough') {
        const { data } = await client.query({
          query: GET_WALKTHROUGH_QUERY,
          variables: { uuid },
        })
        return data?.walkthrough
      } else if (type === 'SkillSet') {
        const { data } = await client.query({
          query: GET_SKILL_SET_QUERY,
          variables: { uuid },
        })
        return data?.skillSet
      }
      return null
    }

    const fetchData = async () => {
      setIsDefaultContentLoading(true)
      const contents = await Promise.all(
        defaultSelectedContent.map(async (oneContent: SelectableContentProps) => {
          const data = await getContent(oneContent.type, oneContent.uuid)
          return data
        })
      )
      setSelectedContent(contents)
      setIsDefaultContentLoading(false)
    }

    fetchData()
  }, [])

  const updateSelectedContent = (contentList: Content[]) => {
    setSelectedContent(contentList)
  }

  const handleRemoveContent = useCallback(
    (content: Content) => {
      setSelectedContent(selectedContent.filter((c) => c.uuid !== content.uuid))
    },
    [selectedContent]
  )

  // Update Formik's field value whenever selectedContent changes
  useEffect(() => {
    formik.setFieldValue(
      field.name,
      selectedContent.map((c) => ({ type: c.__typename, uuid: c.uuid }))
    )
  }, [selectedContent])

  return (
    <>
      {isDefaultContentLoading ? (
        <Spin className="h-4 w-4 text-ddu-blue m-auto" />
      ) : (
        <>
          <SelectedContent
            selectedContent={selectedContent}
            handleRemoveContent={handleRemoveContent}
          />
          <div className="flex flex-row gap-2 items-center justify-start mt-2">
            <ActionButton disabled={isDefaultContentLoading} onClick={() => setIsVideosOpen(true)}>
              <PlusIcon className="w-4 h-4" /> Add Videos
            </ActionButton>
          </div>
        </>
      )}
      <ContentSelectorModal
        isOpen={isVideosOpen}
        onClose={() => setIsVideosOpen(false)}
        defaultSelectedContent={selectedContent}
        onUpdateSelectedContent={updateSelectedContent}
      />
    </>
  )
}

export default KnowledgeField
