import React, { useState } from 'react'
import Countdown from 'react-countdown'
import './style.scss'

type CounterProps = {
  handleDone: () => void
}

const Counter: React.FC<CounterProps> = ({ handleDone }) => {
  const [started, setStarted] = useState<boolean>(false)

  const handleComplete = () => {
    setStarted(true)
    handleDone && handleDone()
  }

  if (started) {
    return <React.Fragment />
  }

  return (
    <div className="counter-screen">
      <div className="flex w-full items-center flex-col pb-8 lg:pb-20">
        <div className="text-lg text-white font-medium">Audio recording will start in</div>
        <Countdown
          date={Date.now() + 3000}
          onComplete={handleComplete}
          renderer={({ seconds }) => {
            return <span className="text-white text-[80px]">{seconds}</span>
          }}
        />
      </div>
    </div>
  )
}

export default Counter
