import React from 'react'

interface Props {
  onClick?: (event: any) => void
  className?: string
  children?: React.ReactNode
}

const CardContainer: React.FC<Props> = ({ children, onClick, className }) => (
  <div className={'shadow rounded bg-white w-full h-full ' + (className || '')} onClick={onClick}>
    {children}
  </div>
)

export default CardContainer
