import useSkillSetQuery from './useSkillSetQuery'

const useSkillSet = (uuid: string) => {
  const skillSetProps = useSkillSetQuery(uuid)

  return {
    skillSet: { ...skillSetProps },
  }
}

export default useSkillSet
