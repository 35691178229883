import { PlayIcon } from '@heroicons/react/24/outline'
import React from 'react'

const WalkthroughPreview = ({ walkthrough }: { walkthrough: any }) => {
  return (
    <div
      className="relative flex h-32 items-center justify-center rounded-2xl p-1 shadow-lg"
      style={{
        backgroundImage:
          'conic-gradient(from -49.8deg at 50% 50%, #ff8331 0deg, #fa3e7c 59.07deg, #d11f60 185.61deg, #b08aee 284.23deg, #ff0000 329.41deg, #ffeb7c 360deg)',
      }}
    >
      <div className="bg-white h-full w-full rounded-xl p-2">
        <div className="font-semibold">{walkthrough.title}</div>
        <span className="inline-flex items-center rounded-md bg-gray-50 mt-2 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
          {walkthrough.domain}
        </span>
        <div className="absolute bottom-2 left-2 flex flex-row items-center space-x-2">
          <PlayIcon className="h-6 w-6 text-[#7331FF]" />
          <div className="text-gray-900 font-semibold">Walkthrough Video</div>
        </div>
      </div>
    </div>
  )
}

export default WalkthroughPreview
