import { useMutation } from '@apollo/client'
import { GET_SKILL_SET_QUERY, UPDATE_SKILL_SET_QUERY } from '../../App/graphql/queries/skill_set'
import { SkillSetQuery, SkillSetQueryVariables } from '../../graphql'

const useUpdateSkillSetMutation = () =>
  useMutation(UPDATE_SKILL_SET_QUERY, {
    update(cache, { data: { updateSkillSet } }) {
      const uuid = updateSkillSet.uuid

      const data = cache.readQuery<SkillSetQuery, SkillSetQueryVariables>({
        query: GET_SKILL_SET_QUERY,
        variables: { uuid },
      })

      cache.writeQuery({
        query: GET_SKILL_SET_QUERY,
        variables: { uuid },
        data: {
          skillSet: {
            ...data.skillSet,
            name: updateSkillSet.name,
            description: updateSkillSet.description,
          },
        },
      })
    },
  })

export default useUpdateSkillSetMutation
