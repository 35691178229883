import React from 'react'
import Tag from './Tag'

interface Props {
  tags: string[]
}

const CardTags: React.FC<Props> = ({ tags }) => (
  <div className="inline-block truncate">
    {tags.map((tag, index) => (
      <Tag key={index} className="my-0.5 mr-2 inline-block">
        {tag}
      </Tag>
    ))}
  </div>
)

export default CardTags
