import { gql } from '@apollo/client'

export const CREATE_EVENT = gql`
  mutation createEvent(
    $type: String!
    $subjectType: EventSubjectType!
    $subjectId: ID!
    $source: String
    $metadata: JSON
    $timestamp: Float!
  ) {
    event: createEvent(
      type: $type
      subjectType: $subjectType
      subjectId: $subjectId
      source: $source
      metadata: $metadata
      timestamp: $timestamp
    ) {
      data
    }
  }
`
