import React from 'react'
// @ts-ignore
import FlowMoLogo from '../../../../../../assets/images/FlowMo.png'

const AudioPageLayout = ({ children }: any) => {
  return (
    <div className="relative w-screen h-screen flex justify-center items-center">
      <div className="absolute top-[5%]">
        <a href="/">
          <span className="sr-only">FlowMo</span>
          <img className="mx-auto h-16 w-auto" alt="FlowMo" src={FlowMoLogo} />
        </a>
      </div>
      <div className="relative max-w-screen-md z-30">{children}</div>
      <svg
        className="absolute hidden lg:block right-0"
        width="404"
        height="384"
        fill="none"
        viewBox="0 0 404 384"
      >
        <defs>
          <pattern
            id="f210dbf6-a58d-4871-961e-36d5016a0f49"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
      </svg>
      <svg
        className="absolute hidden lg:block left-0"
        width="404"
        height="384"
        fill="none"
        viewBox="0 0 404 384"
      >
        <defs>
          <pattern
            id="f210dbf6-a58d-4871-961e-36d5016a0f49"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
      </svg>
    </div>
  )
}

export default AudioPageLayout
