import React from 'react'
import Option from './index'
import { SupportIcon } from '../icons'

const SupportOption = () => {
  const onClick = () => {
    window.location.href = 'https://flowmo.zendesk.com/hc/en-us'
  }

  return (
    <Option
      onClick={onClick}
      icon={<SupportIcon />}
      title="Get Support and Connect With Us"
      description="Read documentation, submit feedback, and suggest new features."
    />
  )
}

export default SupportOption
