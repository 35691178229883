import React, { ReactElement } from 'react'
import { SkillSet, Walkthrough } from 'app/javascript/components/graphql'
import ActionsSection from '../ActionsSection'

interface Props {
  stepsCount: number
  object: Walkthrough | SkillSet
  objectType: 'walkthrough' | 'skillSet'
  cardMenu: ReactElement
  className: string
  children?: React.ReactNode
  toggleEmbedModal?: () => void
}

const InformationBar: React.FC<Props> = ({
  object,
  objectType,
  cardMenu,
  className,
  children,
  toggleEmbedModal,
}) => {
  return (
    <div className={`information-container relative ${className}`}>
      <div className="gradient md:hidden" />
      <div className="flex py-2 max-w-[100%] pb-4 sm:pb-4 lg:pb-4 xl:pb-2 overflow-x-scroll lg:overflow-x-visible">
        {children}
      </div>
      <div className="flex relative sm:justify-between lg:justify-between flex-1 xl:mb-0 align-center">
        <ActionsSection
          object={object}
          objectType={objectType}
          cardMenu={cardMenu}
          toggleEmbedModal={toggleEmbedModal}
        />
      </div>
    </div>
  )
}

export default InformationBar
