import React from 'react'
import { useLabsContext } from '../../context/LabsContext'

const Counter = () => {
  const { stepIndex, steps } = useLabsContext()
  return (
    <div className="font-inter text-[15px] text-[#BFBFBF]">
      <span className="text-white">{stepIndex + 1}</span> / {steps.length}
    </div>
  )
}

export default Counter
