import { useCallback, useEffect, useState } from 'react'
import { imageExists } from '../../Walkthrough/utils/cloudinary'

const MAX_ATTEMPT_COUNT = 6

export const useCloudinaryImage = (url: string) => {
  const [exists, setExists] = useState(false)
  const [loading, setLoading] = useState(true)

  const checkIfExists = useCallback(
    async (attempt = 1) => {
      if (await imageExists(url)) {
        setExists(true)
        setLoading(false)
      } else {
        if (attempt < MAX_ATTEMPT_COUNT) {
          setTimeout(() => checkIfExists(attempt + 1), 500 * attempt)
        } else {
          setLoading(false)
        }
      }
    },
    [setExists, url]
  )

  useEffect(() => {
    if (url) {
      checkIfExists()
    } else {
      setExists(false)
      setLoading(false)
    }
  }, [url])

  return { exists, loading }
}
