import React, { ReactElement, useCallback, useState } from 'react'
import * as rrweb from '@flowmo/rrweb'
import { getNestedBoundingClientRect } from '../../utils/helpers/getNestedBoundingRecs'

interface Props {
  element: HTMLElement
  replayer: rrweb.Replayer
  children(position: DOMRect | any): ReactElement
}

const isRRWebFrame = (element: HTMLElement) => {
  const html = element.ownerDocument.getElementsByTagName('html')[0]

  return html?.className?.includes('rrweb')
}

const ElementPosition: React.FC<Props> = ({ replayer, children, element }) => {
  const [position, setPosition] = useState<any>(null)

  const resize = useCallback(() => {
    if (element && replayer) {
      const isIFrameElement = !isRRWebFrame(element)
      const rect = isIFrameElement
        ? getNestedBoundingClientRect(element, replayer.iframe)
        : element.getBoundingClientRect()
      const newPosition = {
        top: rect.top,
        left: rect.left,
        bottom: rect.top + rect.height,
        right: rect.left + rect.width,
        width: rect.width,
        height: rect.height,
      }

      setPosition(newPosition)
    }
  }, [position, element, replayer])

  React.useEffect(() => {
    const interval = setInterval(() => {
      resize()
    }, 20)

    return () => {
      clearInterval(interval)
    }
  }, [element])

  return children({ position })
}

export default ElementPosition
