import React from 'react'

const CloseIcon = () => (
  <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.646166 7.64662C0.431378 7.8614 0.415549 8.19382 0.610811 8.38908C0.806073 8.58434 1.13848 8.56851 1.35327 8.35372L4.49989 5.20711L7.64652 8.35374C7.86131 8.56853 8.19372 8.58436 8.38899 8.3891C8.58425 8.19384 8.56842 7.86143 8.35363 7.64664L5.20699 4.5L8.35363 1.35336C8.56842 1.13858 8.58425 0.806165 8.38899 0.610902C8.19372 0.41564 7.86131 0.43147 7.64652 0.646258L4.49989 3.79289L1.35327 0.646281C1.13848 0.431493 0.806073 0.415663 0.610811 0.610926C0.415549 0.806188 0.431378 1.1386 0.646166 1.35339L3.79278 4.5L0.646166 7.64662Z"
      fill="white"
    />
  </svg>
)

export default CloseIcon
