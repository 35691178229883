import { gql } from '@apollo/client'
import { WORKFLOW_FIELDS } from './fragments'

export const GET_WORKFLOWS_QUERY = gql`
  query workflows {
    workflows {
      ...WorkflowFields
    }
  }
  ${WORKFLOW_FIELDS}
`

export const GET_WORKFLOW_QUERY = gql`
  query workflow($uuid: ID!) {
    workflow(uuid: $uuid) {
      ...WorkflowFields
    }
  }

  ${WORKFLOW_FIELDS}
`

export const DELETE_WORKFLOW_MUTATION = gql`
  mutation deleteWorkflow($uuid: String!) {
    deleteWorkflow(uuid: $uuid) {
      success
      errors
    }
  }
`

export const CREATE_WORKFLOW_MUTATION = gql`
  mutation createWorkflow(
    $name: String!
    $description: String!
    $agentUuid: String!
    $webhookUrl: String
    $cronSchedule: String
  ) {
    createWorkflow(
      name: $name
      description: $description
      agentUuid: $agentUuid
      webhookUrl: $webhookUrl
      cronSchedule: $cronSchedule
    ) {
      workflow {
        uuid
      }
      success
      errors
    }
  }
`

export const UPDATE_WORKFLOW_MUTATION = gql`
  mutation updateWorkflow(
    $uuid: String!
    $name: String!
    $description: String!
    $agentUuid: String!
    $webhookUrl: String
    $cronSchedule: String
  ) {
    updateWorkflow(
      uuid: $uuid
      name: $name
      description: $description
      agentUuid: $agentUuid
      webhookUrl: $webhookUrl
      cronSchedule: $cronSchedule
    ) {
      success
      errors
    }
  }
`

export const GET_AGENT_WORKFLOWS = gql`
  query agentWorkflows($agentUuid: ID!) {
    agentWorkflows(agentUuid: $agentUuid) {
      ...WorkflowFields
    }
  }

  ${WORKFLOW_FIELDS}
`

export const GET_WORKFLOW_TEMPLATES = gql`
  query workflowTemplates($agentUuid: ID!) {
    workflowTemplates(agentUuid: $agentUuid) {
      uuid
      name
      description
      tasks {
        name
        description
        task
        runSchedule
        taskType
      }
    }
  }
`

export const CREATE_WORKFLOW_FROM_TEMPLATE = gql`
  mutation createWorkflowFromTemplate($templateUuid: String!, $agentUuid: String!) {
    createWorkflowFromTemplate(templateUuid: $templateUuid, agentUuid: $agentUuid) {
      success
      errors
    }
  }
`

export const MUTATION_TASK_RUN_MARK_PASSED = gql`
  mutation taskRunMarkPassed($uuid: String!) {
    taskRunMarkPassed(uuid: $uuid) {
      success
      errors
    }
  }
`

export const MUTATION_SUBMIT_RUN_WORKFLOW = gql`
  mutation submitRunWorkflow($uuid: String!) {
    submitRunWorkflow(uuid: $uuid) {
      success
      errors
    }
  }
`
