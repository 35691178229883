import React from 'react'
import { DataSetFieldsFragment } from '../../graphql'
import clsx from 'clsx'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import { RootState } from '../store'
import { useSelector } from 'react-redux'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

interface DataSetCardProps {
  data_set: DataSetFieldsFragment
}

const DataSetCard = ({ data_set }: DataSetCardProps) => {
  const { user } = useSelector((state: RootState) => state.app)
  const initials = data_set.name[0].toUpperCase()
  const canEdit = user?.isOrganizationAdmin
  const isEditable = data_set.isEditable
  return (
    <div>
      <Link to={'/data_set/' + data_set.uuid}>
        <Menu as="div" className="col-span-1 flex rounded-md shadow-sm relative w-96 hover:shadow">
          <div
            className={clsx(
              'bg-flowmo-blue-400',
              'flex w-8 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
            )}
          >
            {initials}
          </div>
          <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
            <div className="flex-1 truncate px-4 py-2 text-sm">
              <div>{data_set.name}</div>
              <div className="text-gray-500 line-clamp-1 overflow-ellipsis">
                {data_set.description}
              </div>
            </div>
            <div className="flex-shrink-0 pr-2">
              {canEdit && isEditable && (
                <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-flowmo-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </MenuButton>
              )}
            </div>
          </div>

          <MenuItems className="absolute top-10 right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
            <div className="py-1">
              <MenuItem>
                {({ focus }) => (
                  <Link
                    to={'/data_set/edit/' + data_set.uuid}
                    className={clsx(
                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    Edit
                  </Link>
                )}
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
      </Link>
    </div>
  )
}

export default DataSetCard
