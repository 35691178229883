import React, { useEffect, useRef, useState } from 'react'
import ReactSlider from 'react-slider'
import { NormalizedStepProps } from '../../../../types'
import * as styles from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { seek } from '../../../../slices/playerSlice'
import { CLOCK_INTERVAL, THUMB_WIDTH } from '../../../../constants'

const PROGRESS_INTERVAL = 50

const DomainTimeline: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const {
    position,
    steps,
    duration,
    playerControls: { isPlaying },
  } = useSelector((state: RootState) => state.player)
  const [value, setValue] = useState<number>(position)
  const [timelineWidth, setTimelineWidth] = useState<number>(0)

  const gotoSteps = steps.filter((step: NormalizedStepProps) => step.type === 'goto')

  useEffect(() => {
    const updateTimelineWidth = () => {
      if (ref.current) {
        setTimelineWidth(ref.current.clientWidth)
      }
    }

    // Initially set the timelineWidth
    updateTimelineWidth()

    // Add a 'resize' event listener to update the timelineWidth when the window is resized
    window.addEventListener('resize', updateTimelineWidth)

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', updateTimelineWidth)
    }
  }, [])

  useEffect(() => {
    setValue(position)
  }, [position])

  useEffect(() => {
    const interval =
      isPlaying &&
      setInterval(() => {
        setValue((value) => {
          if (value + PROGRESS_INTERVAL > position + CLOCK_INTERVAL) {
            return value
          }

          return value + PROGRESS_INTERVAL
        })
      }, PROGRESS_INTERVAL)
    return () => {
      interval && clearInterval(interval)
    }
  }, [isPlaying, position])

  // collapse consecutive steps from the same domain
  let domainList: any[] = gotoSteps.reduce(
    (accumulator: any[], currentItem: NormalizedStepProps) => {
      const previousItem = accumulator[accumulator.length - 1]

      if (previousItem && previousItem.domain === currentItem.domain) {
        previousItem.position = Math.min(previousItem.position, currentItem.position)
      } else {
        accumulator.push({
          domain: currentItem.domain,
          position: currentItem.position,
        })
      }
      return accumulator
    },
    []
  )

  // calcuate the interval size for each domain
  domainList = domainList.map((step, index) => {
    const nextPosition = domainList[index + 1]?.position || duration
    step.intervalSize = nextPosition - step.position
    return step
  })

  const handleSeek = (position: number) => {
    dispatch(seek(position))
  }

  return (
    <div ref={ref} className={styles['flow-mo-domain-timeline']}>
      <ReactSlider
        max={duration}
        value={value}
        onAfterChange={handleSeek}
        onSliderClick={handleSeek}
        className={styles['flow-mo-progress']}
        thumbClassName={styles['flow-mo-thumb']}
        trackClassName={'flow-mo-track'}
      />
      <div className={styles['flow-mo-domain-list']}>
        {domainList.map((oneDomain, index) => {
          return (
            <div
              className={styles['flow-mo-domain']}
              key={index}
              style={{
                width: (oneDomain.intervalSize / duration) * (timelineWidth - THUMB_WIDTH) + 'px',
              }}
            >
              <div
                className={styles['domain-line']}
                style={{
                  backgroundColor: 'hsl( 217, 89%, calc(53% - 5% * ' + index + '), 0.5)',
                  borderBottom: '1px solid rgb(28, 110, 242)',
                  borderLeft: '1px solid rgb(28, 110, 242)',
                  borderRight: '1px solid rgb(28, 110, 242)',
                }}
              ></div>
              <div className={styles['domain-label']}>{oneDomain.domain.replace('www.', '')}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DomainTimeline
