import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import React from 'react'

const Pager = ({
  total,
  page,
  perPage,
  setPage,
}: {
  page: number
  total: number
  perPage: number
  setPage: (page: number) => void
}) => {
  const totalPages = Math.ceil(total / perPage)
  const startElement = page == 1 ? 1 : (page - 1) * perPage + 1
  const endElement = Math.min(startElement + perPage - 1, total)

  const hasNext = page < totalPages
  const hasPrevious = page != 1
  const pageListCount = 6
  return (
    <div className="bg-gray-100 px-4 py-3 mb-10 flex rounded-xl items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        {hasPrevious && (
          <div
            onClick={() => setPage(page - 1)}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </div>
        )}
        {hasNext && (
          <div
            onClick={() => setPage(page + 1)}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </div>
        )}
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{startElement}</span> to{' '}
            <span className="font-medium">{endElement}</span> of{' '}
            <span className="font-medium">{total}</span> results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            {hasPrevious && (
              <div
                onClick={() => setPage(page - 1)}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border cursor-pointer border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            )}

            {/* Current: "z-10 bg-flowmo-blue-50 border-flowmo-blue-500 text-flowmo-blue-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {[...Array(Math.min(pageListCount, totalPages)).keys()].map((one_page: number) => {
              let pageNumber = one_page + 1
              const showDivider = totalPages > pageListCount && one_page == pageListCount / 2
              if (totalPages > pageListCount) {
                if (one_page < pageListCount / 2) {
                  pageNumber = one_page + 1
                } else if (one_page >= pageListCount / 2) {
                  pageNumber = totalPages - pageListCount + one_page + 1
                }
              }

              const isActive = pageNumber == page
              return (
                <div key={one_page}>
                  {showDivider && (
                    <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                      ...
                    </span>
                  )}

                  <div
                    onClick={() => setPage(pageNumber)}
                    className={
                      ' relative inline-flex items-center px-4 py-2 border text-sm font-medium' +
                      (isActive
                        ? '  z-10 bg-flowmo-blue-50 border-flowmo-blue-500 text-flowmo-blue-600 '
                        : ' cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50 ')
                    }
                  >
                    {pageNumber}
                  </div>
                </div>
              )
            })}
            {hasNext && (
              <div
                onClick={() => setPage(page + 1)}
                className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            )}
          </nav>
        </div>
      </div>
    </div>
  )
}
export default Pager
