import { Walkthrough } from 'app/javascript/components/graphql'

export const ModeTypes = {
  VIDEO: 'VIDEO',
  LABS: 'LABS',
}

export type Modes = (typeof ModeTypes)[keyof typeof ModeTypes]

export interface EmbedContextType {
  mode: Modes
  setMode: (mode: Modes) => void
  walkthrough: Walkthrough
  rootUrl: string
  enabled: boolean
  chunks: Chunk[]
}

export type Chunk = {
  loading: boolean
  events?: any[]
  error?: Error
  progress: {
    loaded: number
    total: number
  }
}
