export const getMinTimestamp = (events: any[] | undefined) =>
  events
    ? events.reduce(
        (timestamp, event) => (event.timestamp < timestamp ? event.timestamp : timestamp),
        events[0].timestamp
      )
    : 0

export const getMaxTimestamp = (events: any[] | undefined) =>
  events
    ? events.reduce(
        (timestamp, event) => (event.timestamp > timestamp ? event.timestamp : timestamp),
        events[events.length - 1].timestamp
      )
    : 0
