import React from 'react'
import PluginModalBase from './PluginModelBase'
import PluginTemplateDescription from './PluginTemplateDescription'

const PluginView = ({ setOpen, open, plugin }: any) => {
  const name = plugin.name || plugin.pluginTemplate.name
  return (
    <PluginModalBase title={name} setOpen={setOpen} open={open}>
      <PluginTemplateDescription pluginTemplate={plugin.pluginTemplate} />
    </PluginModalBase>
  )
}

export default PluginView
