import React from 'react'
import useUser from '../../../../App/hooks/useUser'
import { DestinationType } from '../../../../../components/graphql'
import { SubMenu } from '@szhsin/react-menu'
import CardMenuItem from './index'
import { htmlStrip } from '../../../utils/htmlStrip'
import { SkillSet } from 'app/javascript/components/graphql'
import { Walkthrough } from 'app/javascript/components/graphql'

type props = {
  onAddToSkillSet: (variables: { destinationType: DestinationType; destinationId?: string }) => void
  walkthrough: Walkthrough
  skillSets?: SkillSet[]
}

const AddToSkillSetMenuItem: React.FC<props> = ({ onAddToSkillSet, skillSets }) => {
  const user = useUser()
  const moveItems = user ? skillSets : []

  return (
    <SubMenu
      className="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
      label="Add to Skill Set"
    >
      <div className="overflow-auto max-h-40">
        {moveItems.map((item, index) => {
          const handleMove = () => {
            onAddToSkillSet({ destinationId: item.id, destinationType: DestinationType.SkillSet })
          }

          return (
            <CardMenuItem
              key={index}
              label={htmlStrip(item.name)}
              disabled={false}
              onClick={handleMove}
            />
          )
        })}
      </div>
    </SubMenu>
  )
}

export default AddToSkillSetMenuItem
