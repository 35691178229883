import React from 'react'

interface Props {
  className?: string
  children?: React.ReactNode
}

const Tag: React.FC<Props> = ({ children, className }) => (
  <div
    className={`text-[#4094F7] px-2 py-1 bg-[#F5F6FE] rounded-md text-xs font-semibold ${className}`}
  >
    {children}
  </div>
)

export default Tag
