import React from 'react'
import StepCard from '../StepCard'
import StepImage from '../StepImage'
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline'
import TitleDescription from './shared/TitleDescription'

const DropdownSelect = ({
  step,
  stepNumber,
  isLast,
  canEditWalkthrough,
  index,
}: {
  step: any
  stepNumber: number
  isLast: boolean
  canEditWalkthrough: boolean
  index: number
}) => {
  return (
    <StepCard step={step} isLast={isLast} stepNumber={stepNumber}>
      <TitleDescription
        canEditWalkthrough={canEditWalkthrough}
        index={index}
        title={step.title}
        description={step.description}
        defaultTitle="Click on the highlighted section."
      />

      {step.imageData && step.imageData.url ? (
        <StepImage
          isIFrame={step.isIFrame}
          screenSize={step.screenSize}
          coordinates={step.coordinates}
          url={step.imageData.url}
          position={step.elementDimensions}
          className="rounded-xl mt-2 border border-gray-100 mx-auto"
        />
      ) : (
        <div className="w-full h-48 bg-flowmo-blue-200 flex rounded-lg border">
          <CursorArrowRaysIcon className="h-20 w-20 m-auto justify-center text-flowmo-blue-300/30" />
        </div>
      )}
    </StepCard>
  )
}

export default DropdownSelect
