import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { MAX_DESCRIPTION_WIDTH } from '../../../../constants'
import ReactHtmlParser from 'react-html-parser'
import { CloseIcon } from './icons'

import './style.scss'

type DescriptionProps = {
  position: {
    top: number
    left: number
  }
  description: string
}

const Description: React.FC<DescriptionProps> = ({ position, description }) => {
  const [visible, setVisible] = useState(false)

  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)

  useEffect(() => {
    setTimeout(() => setVisible(true), 100)
  }, [])

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          boundary: document.getElementById('flowmo-description-container'),
          padding: 8,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'right', 'bottom', 'left'],
          rootBoundary: 'document',
          altBoundary: true,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      { name: 'arrow', options: { element: arrowElement } },
    ],
  })

  if (!description || description?.length === 0) {
    return <React.Fragment />
  }

  if (!visible) {
    return <React.Fragment />
  }

  return (
    <div
      className="absolute flex justify-center items-center pointer-events-none"
      style={{
        width: MAX_DESCRIPTION_WIDTH,
        height: MAX_DESCRIPTION_WIDTH,
        top: position.top - MAX_DESCRIPTION_WIDTH / 2,
        left: position.left - MAX_DESCRIPTION_WIDTH / 2,
      }}
    >
      <div className="flowmo-tooltip-reference" ref={setReferenceElement} />
      {description && description.length > 0 && (
        <div
          className="flowmo-tooltip"
          role="tooltip"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className="flowmo-tooltip-text"> {ReactHtmlParser(description)}</div>
          <div className="flowmo-tooltip-arrow" ref={setArrowElement} style={styles.arrow} />
          <div className="flowmo-tooltip-close" onClick={() => setVisible(false)}>
            <CloseIcon />
          </div>
        </div>
      )}
    </div>
  )
}

export default Description
