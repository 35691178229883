import React from 'react'
import { hot } from 'react-hot-loader'
import { ApolloProvider } from '@apollo/client/react'
import { openClient } from '../common/ApolloClient'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import * as Sentry from '@sentry/react'
import '../common/sentry'

import SkillSetShow from '../SkillSet/ui/SkillSet/Show'
import WalkthroughShow from '../Walkthrough/ui/Walkthrough/Show'
import Layout from '../Walkthrough/layout'

const PublicApp = () => {
  return (
    <Sentry.ErrorBoundary>
      <ApolloProvider client={openClient}>
        <BrowserRouter basename="/">
          <QueryParamProvider ReactRouterRoute={Route}>
            <Switch>
              <Layout usePublicEndpoint={true}>
                <Route
                  exact
                  path="/skill_set/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
                  render={(props) => (
                    <SkillSetShow uuid={props.match.params.uuid} usePublicEndpoint={true} />
                  )}
                />
                <Route
                  exact
                  path="/walkthrough/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
                  render={(props) => (
                    <WalkthroughShow uuid={props.match.params.uuid} usePublicEndpoint={true} />
                  )}
                />
              </Layout>
            </Switch>
          </QueryParamProvider>
        </BrowserRouter>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  )
}

export default hot(module)(PublicApp)
