import { PuzzlePieceIcon } from '@heroicons/react/24/solid'
import React from 'react'

interface PluginPreviewProps {
  plugin: any
}

const PluginPreview = ({ plugin }: PluginPreviewProps) => {
  return (
    <>
      <div
        className="relative flex h-32 items-center justify-center rounded-2xl p-1 shadow-lg"
        style={{
          backgroundImage:
            'conic-gradient(from -49.8deg at 50% 50%, #7331FF 0deg, #00A3FF 59.07deg, #4E51FF 185.61deg, #39DBFF 284.23deg, #B84FF1 329.41deg, #7331FF 360deg)',
        }}
      >
        <div className="relative bg-white/90 h-full w-full rounded-xl p-2">
          <div className="absolute bottom-2 left-2 flex flex-row items-center space-x-2">
            <PuzzlePieceIcon className="h-6 w-6 text-flowmo-blue-600" />
            <div className="text-gray-900 font-semibold">{plugin.name}</div>
          </div>
          <div className="text-gray-900 font-semibold">{plugin.title}</div>
          <div className="text-gray-600">{plugin.description}</div>
        </div>
      </div>
    </>
  )
}

export default PluginPreview
