import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import Spin from '../../common/ui/Spin'
import { GET_EXPERT_AGENT_TEMPLATES, MUTATION_CREATE_EXPERT_AGENT } from '../graphql/queries/agent'
import {
  CreateExpertAgentMutationVariables,
  CreateExpertAgentMutation,
  ExpertAgentTemplatesQuery,
  ExpertAgentTemplatesQueryVariables,
} from '../../graphql'
import { MoodBadOutlined } from '@mui/icons-material'
import { Button } from '@headlessui/react'
import { PlusIcon } from '@heroicons/react/24/solid'

const ExpertTemplates = () => {
  const { data, loading } = useQuery<ExpertAgentTemplatesQuery, ExpertAgentTemplatesQueryVariables>(
    GET_EXPERT_AGENT_TEMPLATES,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  const [createExpertAgent] = useMutation<
    CreateExpertAgentMutation,
    CreateExpertAgentMutationVariables
  >(MUTATION_CREATE_EXPERT_AGENT)

  if (loading) {
    return <Spin />
  }

  const handleCreateAgent = async (templateUuid: string) => {
    const response = await createExpertAgent({ variables: { uuid: templateUuid } })
    if (response.data?.createExpertAgent.success) {
      window.toastr.success('Agent created successfully')
      window.location.reload()
    } else {
      window.toastr.error('There was an error creating the agent')
    }
  }

  const templates = data?.expertAgentTemplates || []

  return (
    <>
      <div className="flex flex-wrap">
        {templates.map((template) => (
          <div key={template.uuid} className="flex flex-col m-2 w-96 bg-gray-100 rounded-md p-5">
            <div className="flex flex-col flex-grow">
              <div className="text-lg font-bold">{template.jobTitle}</div>
              <div className="text-sm text-gray-500 line-clamp-2 text-ellipsis">
                {template.shortDescription}
              </div>
            </div>
            <div className="flex flex-row justify-end">
              <Button
                className="mt-5 bg-gray-300 hover:bg-gray-400 text-gray-800 text-sm font-bold py-2 px-4 rounded flex flex-row items-center space-x-2"
                onClick={() => {
                  handleCreateAgent(template.uuid)
                }}
              >
                <PlusIcon className="w-4 h-4" />
                <div className="">Add</div>
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-20 w-full flex flex-row justify-center items-center">
        {templates.length === 0 && (
          <div className=" text-gray-500 border border-gray-200 p-4 rounded-md bg-gray-200 flex flex-row items-center space-x-2">
            <MoodBadOutlined />
            <div className="">
              There are no experts that you can add to your organization. Sorry!
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ExpertTemplates
