import React, { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Menu, Transition } from '@headlessui/react'
import { clsx } from 'clsx'
import Gravatar from '../../../common/components/Gravatar'
import { toggleSidebar } from '../../slices/layoutSettingsSlice'
import { IconButton, Tooltip } from '@mui/material'
import Add from '@mui/icons-material/Add'
import Remove from '@mui/icons-material/Remove'
import * as _ from 'underscore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

import {
  Cog6ToothIcon,
  BellAlertIcon,
  ChatBubbleLeftIcon,
  PresentationChartBarIcon,
  HomeIcon,
} from '@heroicons/react/24/outline'

import logoImage from '../../../../../assets/images/FlowMo-White.png'
import logoMark from '../../../../../assets/images/FlowMo-White-Mark.png'

type SidebarNavigationProps = {
  logoutPath: string
  csrfToken: string
  isMinimizeable: boolean
  isImpersonating: boolean
}

interface MenuItem {
  name: string
  to?: string
  href?: string
  initial: string
}

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { GET_TRUE_USER, STOP_IMPERSONATING_MUTATION } from '../../graphql/queries/user'
import { useMutation, useQuery } from '@apollo/client'
import {
  StopImpersonatingMutation,
  StopImpersonatingMutationVariables,
  TrueUserQuery,
  TrueUserQueryVariables,
} from 'app/javascript/components/graphql'

const theme = createTheme({
  palette: {
    mode: 'dark', // Switch between 'dark' and 'light'
  },
})

const userNavigation = [{ name: 'Your profile', href: '/profile' }]

const Sidebar = ({
  logoutPath,
  csrfToken,
  isMinimizeable,
  isImpersonating,
}: SidebarNavigationProps) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { user } = useSelector((state: RootState) => state.app)
  const { isSidebarExpanded } = useSelector((state: RootState) => state.layoutSettings)

  const isMinimized = isMinimizeable && !isSidebarExpanded
  const [internalExpand, setInternalExpand] = React.useState(!isMinimized)

  const isManager = _.intersection(['admin', 'organization_admin'], user.roles).length > 0

  const [stopImpersonating] = useMutation<
    StopImpersonatingMutation,
    StopImpersonatingMutationVariables
  >(STOP_IMPERSONATING_MUTATION)

  const { data: trueUserData, loading: trueUserLoading } = useQuery<
    TrueUserQuery,
    TrueUserQueryVariables
  >(GET_TRUE_USER)

  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    const form = document.createElement('form')
    form.method = 'POST'
    form.action = logoutPath

    const _methodInput = document.createElement('input')
    _methodInput.type = 'hidden'
    _methodInput.name = '_method'
    _methodInput.value = 'delete'
    form.appendChild(_methodInput)

    const csrfInput = document.createElement('input')
    csrfInput.type = 'hidden'
    csrfInput.name = 'authenticity_token'
    csrfInput.value = csrfToken
    form.appendChild(csrfInput)

    document.body.appendChild(form)
    form.submit()
  }

  const toggleDrawer = () => {
    dispatch(toggleSidebar())
  }

  const handleToggleSidebar = () => {
    if (isSidebarExpanded) {
      // closing
      setInternalExpand(false)
      setTimeout(() => toggleDrawer(), 100)
    } else {
      // opening
      toggleDrawer()
      setTimeout(() => setInternalExpand(true), 100)
    }
  }

  const handleStopImpersonating = () => {
    stopImpersonating().then((res) => {
      if (res.data?.stopImpersonating.success) {
        window.location.reload()
      }
    })
  }

  const homePath = '/'

  const navigation = [
    { name: 'Home', href: '/home', icon: HomeIcon },
    { name: 'Agents', href: '/agents', icon: ChatBubbleLeftIcon },
    { name: 'Workflows', href: '/workflows', icon: BellAlertIcon },
    { name: 'Analyses', href: '/analyses', icon: PresentationChartBarIcon },
  ]

  const managerNavigation: MenuItem[] = [
    {
      name: 'Data Sets',
      to: '/data_sets',
      initial: 'D',
    },
    {
      name: 'My Team',
      to: '/my-team',
      initial: 'T',
    },
    {
      name: 'My Library',
      to: '/my_library',
      initial: 'L',
    },
    {
      name: 'Organization Library',
      to: '/organization_library',
      initial: 'O',
    },
    {
      name: 'Credit Usage',
      to: '/credits',
      initial: 'C',
    },
  ]

  return (
    <ThemeProvider theme={theme}>
      <div className="flex flex-col h-full">
        <div
          className={clsx(
            'flex h-16 mt-4 shrink-0',
            !internalExpand ? 'flex-col justify-start' : 'flex-row items-start'
          )}
        >
          <a href={homePath} className="flex-grow">
            <img
              className={clsx(internalExpand ? 'h-8' : 'h-8 w-auto')}
              src={internalExpand ? logoImage : logoMark}
              alt="FlowMo"
            />
          </a>
          {isMinimizeable && (
            <div className={clsx('items-start', isMinimized ? 'mt-4 justify-self-center' : '')}>
              <Tooltip
                title={isMinimized ? 'Expand Menu' : 'Collapse Menu'}
                placement="bottom"
                arrow={false}
              >
                <div className="text-blue-400 w-6">
                  <IconButton onClick={handleToggleSidebar} color={'inherit'} size={'small'}>
                    {isMinimized && <Add color={'inherit'} />}
                    {internalExpand && <Remove color={'inherit'} />}
                  </IconButton>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
        <nav className="flex flex-col h-full mt-8">
          <div className="flex flex-col flex-grow space-y-8 ml-1">
            <ul role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item.name} className={clsx(isMinimized ? 'flex' : '')}>
                  <Link
                    to={item.href}
                    className={clsx(
                      item.href === location.pathname
                        ? 'bg-gray-800 text-white'
                        : 'text-gray-400 hover:text-white hover:bg-gray-800',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                    )}
                  >
                    {internalExpand ? (
                      <div className="flex flex-row space-x-2">
                        <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                        <div>{item.name}</div>
                      </div>
                    ) : (
                      <Tooltip title={item.name} placement="right" arrow={true}>
                        <div className="flex flex-row space-x-2">
                          <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                        </div>
                      </Tooltip>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
            {isManager && (
              <div>
                <div className="text-xs font-semibold leading-6 text-gray-400">
                  {internalExpand ? 'Manage' : ''}&nbsp;
                </div>

                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {managerNavigation.map((oneMenuItem) => (
                    <li key={oneMenuItem.name} className={clsx(isMinimized ? 'flex' : '')}>
                      {oneMenuItem.to ? (
                        <Link
                          to={oneMenuItem.to}
                          className={clsx(
                            oneMenuItem.to === location.pathname
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          {internalExpand ? (
                            <div className="flex flex-row space-x-2">
                              <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                {oneMenuItem.initial}
                              </div>
                              <div className="truncate">{oneMenuItem.name}</div>
                            </div>
                          ) : (
                            <Tooltip title={oneMenuItem.name} placement="right" arrow={true}>
                              <div className="flex flex-row space-x-2">
                                <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                  {oneMenuItem.initial}
                                </div>
                              </div>
                            </Tooltip>
                          )}
                        </Link>
                      ) : (
                        <a
                          href={oneMenuItem.href}
                          className={clsx(
                            'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          {!isMinimized ? (
                            <div className="flex flex-row space-x-2">
                              <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                {oneMenuItem.initial}
                              </div>
                              <div className="truncate">{oneMenuItem.name}</div>
                            </div>
                          ) : (
                            <Tooltip title={oneMenuItem.name} placement="right" arrow={true}>
                              <div className="flex flex-row space-x-2">
                                <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                  {oneMenuItem.initial}
                                </div>
                              </div>
                            </Tooltip>
                          )}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <ul>
            <li className="-mx-6 mt-auto">
              {isImpersonating && !trueUserLoading && trueUserData && (
                <div className="flex flex-col items-center text-center justify-center text-xs space-y-2 bg-red-300 rounded-lg mx-4 text-gray-800 p-2 mb-10">
                  {!isMinimized && (
                    <>
                      <div className="">
                        You ({trueUserData.trueUser.firstName} {trueUserData.trueUser.lastName}) are
                        impersonating:
                      </div>
                      <div className="">
                        {user.firstName} {user.lastName}
                      </div>
                    </>
                  )}

                  <button
                    className="flex flex-row items-center justity-center space-x-2 rounded-full bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                    onClick={handleStopImpersonating}
                  >
                    {!isMinimized && <div className="">Stop</div>}
                    <FontAwesomeIcon icon={faUserSecret} />
                  </button>
                </div>
              )}
              <Menu as="div" className="relative outline-none">
                <Menu.Button className="flex items-center justify-between  w-full group px-6 py-3 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 cursor-pointer outline-none ">
                  <div className="flex flex-row items-center gap-x-4 transition-all duration-300">
                    <Gravatar className="h-8 w-8 rounded-full" email={user.email} />
                    {internalExpand && (
                      <>
                        <div className="whitespace-nowrap" aria-hidden="true">
                          {user.firstName} {user.lastName}
                        </div>
                        <Cog6ToothIcon
                          className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-200"
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </div>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  leave="transition ease-in duration-75"
                  enterFrom="transform opacity-0 scale-95 translateY(100%)"
                  enterTo="transform opacity-100 scale-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95 translateY(100%)"
                >
                  <Menu.Items className="absolute left-0 bottom-full z-10 mb-2.5 w-64 outline-none">
                    <div className="rounded-md bg-gray-50 py-2 mx-4 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {user.isOrganizationAdmin && (
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/settings"
                              className={clsx(
                                active ? 'bg-gray-100' : '',
                                'block px-3 py-2 text-sm leading-6 text-gray-900'
                              )}
                            >
                              Settings
                            </Link>
                          )}
                        </Menu.Item>
                      )}

                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.href}
                              className={clsx(
                                active ? 'bg-gray-100' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}

                      {user.roles.includes('admin') && (
                        <div className="border-t border-b border-gray-200">
                          <Menu.Item>
                            {() => (
                              <a
                                href="/admin"
                                className={clsx('block px-3 py-1 text-sm leading-6 text-gray-900')}
                              >
                                Rails Admin
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {() => (
                              <a
                                href="/sidekiq"
                                className={clsx('block px-3 py-1 text-sm leading-6 text-gray-900')}
                              >
                                Sidekiq
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      )}

                      <Menu.Item>
                        {() => (
                          <a
                            href="#"
                            onClick={handleLogout}
                            className={`block px-3 py-1 text-sm text-gray-900 w-full text-left hover:bg-gray-100`}
                          >
                            Logout
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </li>
          </ul>
        </nav>
      </div>
    </ThemeProvider>
  )
}

export default Sidebar
