import React, { useState } from 'react'
import { GET_HISTORIC_ANALYSIS_RUNS_QUERY } from '../../../graphql/queries/analysis'
import { useQuery } from '@apollo/client'
import Spin from '../../../../../components/common/ui/Spin'
import {
  AnalysisFieldsFragment,
  HistoricAnalysisRunsQuery,
  HistoricAnalysisRunsQueryVariables,
} from 'app/javascript/components/graphql'
import EmptyContent from '../EditAgent/EmptyContent'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import AnalysisResults from './AnalysisResults'
import {
  getHumanReadableDuration,
  getRelativeTimeString,
} from '../../../../common/utils/dateDisplay'

interface AnalysisHistoryProps {
  analysis: AnalysisFieldsFragment
}
const AnalysisHistory = ({ analysis }: AnalysisHistoryProps) => {
  const [run, setRun] = useState<any>(null)
  const [open, setOpen] = useState(false)

  // get all the history
  const { data, loading } = useQuery<HistoricAnalysisRunsQuery, HistoricAnalysisRunsQueryVariables>(
    GET_HISTORIC_ANALYSIS_RUNS_QUERY,
    {
      variables: { uuid: analysis.uuid },
      fetchPolicy: 'network-only',
    }
  )

  if (loading) {
    return <Spin />
  }
  const analysisRuns = data?.historicAnalysisRuns

  return (
    <div>
      <Transition show={open}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <TransitionChild
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <TransitionChild
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <DialogPanel className="pointer-events-auto w-screen max-w-3xl h-full">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                            Historic Run
                          </DialogTitle>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-flowmo-blue-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <AnalysisResults analysis={analysis} analysisRun={run} />
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>

      {!analysisRuns || analysisRuns.length === 0 ? (
        <EmptyContent title="No history yet" />
      ) : (
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr className="divide-x divide-gray-200">
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Run Date
              </th>
              <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                Runtime
              </th>
              <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                Task Count
              </th>
              <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                Status
              </th>
              <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                Webhook
              </th>
              <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {analysisRuns.map((oneHistoryRun, index) => (
              <tr key={index} className="divide-x divide-gray-200">
                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                  {getRelativeTimeString(oneHistoryRun.createdAt)}
                </td>
                <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                  {oneHistoryRun.startedAt && oneHistoryRun.finishedAt && (
                    <>
                      {getHumanReadableDuration(oneHistoryRun.startedAt, oneHistoryRun.finishedAt)}
                    </>
                  )}
                </td>
                <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                  {oneHistoryRun.tasks.length}
                </td>
                <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                  <CheckIcon className="size-4 text-green-800" />
                </td>
                <td>
                  {oneHistoryRun.webhookUrl && (
                    <>
                      {oneHistoryRun.webhookResponse &&
                      oneHistoryRun.webhookResponse['status'] == '200' ? (
                        <CheckIcon className="size-4 text-green-800" />
                      ) : (
                        <XMarkIcon className="size-4 text-red-800" />
                      )}
                    </>
                  )}
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                  <button
                    className="text-flowmo-blue-500 hover:underline"
                    onClick={() => {
                      setRun(oneHistoryRun)
                      setOpen(true)
                    }}
                  >
                    view
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default AnalysisHistory
