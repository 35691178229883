import { gql } from '@apollo/client'

export const UPLOAD_AUDIO = gql`
  mutation uploadAudio($file: Upload!, $walkthroughId: ID!) {
    uploadAudio(file: $file, walkthroughId: $walkthroughId) {
      id
      uuid
      audioUrl
    }
  }
`

export const GET_WALKTHROUGH_QUERY = gql`
  query walkthroughByUuid($uuid: ID!) {
    walkthrough: walkthroughByUuid(uuid: $uuid) {
      id
      uuid
      name
      description
      meta
      steps
      audioEnabled
      hasAudio
      createdBy {
        firstName
        lastName
        id
      }
      skillSet {
        id
        name
        uuid
        isInMyLibrary
        isInMyOrganizationLibrary
        sharedAccess
        canEditSharing
      }
      isOwner
      isInMyLibrary
      isInMyOrganizationLibrary
      canEdit
      canMove
      canEditSharing
      createdAt
      canDelete
      sharedAccess
      completed
      rrwebEventsUrl
      audioUrl
      liveLearning
      labs
      embedEnabled
      viewCount
      interactionCount

      synthesis
      synthesisStatus
      summary
      lastSynthesisAt
      updatedAt
    }
  }
`

export const DELETE_WALKTHROUGH_QUERY = gql`
  mutation deleteWalkthrough($id: ID!, $organizationId: ID) {
    deleteWalkthrough(id: $id, organizationId: $organizationId) {
      id
    }
  }
`

export const MOVE_WALKTHROUGH_QUERY = gql`
  mutation moveWalkthrough($id: ID!, $destinationType: DestinationType!, $destinationId: ID) {
    walkthrough: moveWalkthrough(
      id: $id
      destinationType: $destinationType
      destinationId: $destinationId
    ) {
      id
      uuid
      name
      isInMyLibrary
      isInMyOrganizationLibrary
      skillSet {
        uuid
      }
    }
  }
`

export const UPDATE_WALKTHROUGH_QUERY = gql`
  mutation updateWalkthrough(
    $id: ID!
    $name: String!
    $description: String!
    $steps: [JSON!]!
    $liveLearning: Boolean
    $labs: Boolean
    $audioEnabled: Boolean
    $synthesis: String
  ) {
    updateWalkthrough(
      id: $id
      name: $name
      description: $description
      steps: $steps
      liveLearning: $liveLearning
      labs: $labs
      audioEnabled: $audioEnabled
      synthesis: $synthesis
    ) {
      id
      uuid
      description
      name
      steps
      audioEnabled
      audioUrl
      synthesis
    }
  }
`

export const SHARE_WALKTHROUGH_QUERY = gql`
  mutation shareWalkthrough($id: ID!, $shareType: ShareTypeEnum!) {
    shareWalkthrough(id: $id, shareType: $shareType) {
      id
      name
      sharedAccess
      isInMyOrganizationLibrary
      isInMyLibrary
    }
  }
`

export const SHARE_EMBED_WALKTHROUGH_QUERY = gql`
  mutation shareEmbedWalkthrough($id: ID!, $enabled: Boolean!) {
    shareEmbedWalkthrough(id: $id, enabled: $enabled) {
      id
    }
  }
`

export const GET_RECENTLY_VIEWED_WALKTHROUGHS = gql`
  query getRecentlyViewedWalkthroughs(
    $page: Int
    $perPage: Int
    $sortBy: String
    $sortDirection: String
    $contentType: ContentType
  ) {
    items: recentlyViewed(
      page: $page
      perPage: $perPage
      sortBy: $sortBy
      sortDirection: $sortDirection
      contentType: $contentType
    ) {
      ... on Walkthrough {
        id
        description
        name
        steps
        uuid
        sharedAccess
        isInMyLibrary
        isInMyOrganizationLibrary
        createdAt
        updatedAt
        completed
        canEdit
        canMove
        canEditSharing
        meta
        viewCount
        interactionCount
        skillSet {
          id
          name
          uuid
          sharedAccess
          __typename
        }
        __typename
      }
      ... on SkillSet {
        id
        name
        uuid
        completed
        canEdit
        canMove
        isInMyLibrary
        isInMyOrganizationLibrary
        sharedAccess
        canEditSharing
        isInMyLibrary
        isInMyOrganizationLibrary
        walkthroughs {
          id
          name
          steps
          uuid
          completed
          meta
        }
        __typename
      }
    }
    total: recentlyViewedMeta(contentType: $contentType) {
      count
    }
  }
`

export const GET_SHARED_WITH_ME_WALKTHROUGHS = gql`
  query getWalkthroughsSharedWithMe(
    $page: Int
    $perPage: Int
    $sortBy: String
    $sortDirection: String
    $contentType: ContentType
  ) {
    items: sharedWithMe(
      page: $page
      perPage: $perPage
      sortBy: $sortBy
      sortDirection: $sortDirection
      contentType: $contentType
    ) {
      ... on Walkthrough {
        id
        description
        name
        steps
        uuid
        meta
        createdBy {
          firstName
          lastName
          id
        }
        sharedAccess
        isInMyLibrary
        isInMyOrganizationLibrary
        createdAt
        updatedAt
        completed
        canEdit
        canMove
        canEditSharing
        viewCount
        interactionCount
        skillSet {
          id
          name
          uuid
          sharedAccess
          __typename
        }
        __typename
      }
      ... on SkillSet {
        id
        name
        uuid
        completed
        canEdit
        canMove
        isInMyLibrary
        isInMyOrganizationLibrary
        sharedAccess
        isInMyLibrary
        isInMyOrganizationLibrary
        canEditSharing
        walkthroughs {
          id
          name
          uuid
          steps
          meta
          completed
        }
        __typename
      }
    }
    total: sharedWithMeMeta(contentType: $contentType) {
      count
    }
  }
`
