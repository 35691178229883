import React from 'react'
import Button from '../../../Button'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useFormikContext } from 'formik'
import { useHistory } from 'react-router-dom'
interface Props {
  backRoute: string
}

const CancelButton: React.FC<Props> = ({ backRoute }) => {
  const history = useHistory()
  const { isSubmitting } = useFormikContext()

  const handleReset = () => {
    history.push(backRoute)
  }
  return (
    <Button
      color="neutral"
      className="w-30"
      icon={<XMarkIcon className="ml-2 h-4 w-4" />}
      onClick={handleReset}
      disabled={isSubmitting}
    >
      Cancel
    </Button>
  )
}

export default CancelButton
