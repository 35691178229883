import React from 'react'
import CardMenuItem from './index'

interface Props {
  onClick: () => void
}

const ShareMenuItem: React.FC<Props> = ({ onClick }) => (
  <CardMenuItem label="Share" onClick={onClick} />
)

export default ShareMenuItem
