import { useMutation } from '@apollo/client'
import { CREATE_EVENT } from '../../App/graphql/queries/events'
import { useCallback } from 'react'

export const useEventTracking = () => {
  const [createEvent] = useMutation(CREATE_EVENT)

  return useCallback(
    (variables: any) => {
      createEvent({ variables: { ...variables, timestamp: Date.now() } })
    },
    [createEvent]
  )
}
