import Hero from '../components/Hero'
import React from 'react'
import HowItWorks from '../components/HowItWorks'
import SignupCTA from '../components/SignupCTA'

const Index = () => {
  document.title = 'FlowMo | Work Intelligence Platform that runs your business'
  return (
    <>
      <Hero />
      <HowItWorks />
      <SignupCTA />
    </>
  )
}

export default Index
