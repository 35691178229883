import React from 'react'
import Dots from './Dots'
import * as styles from './style.module.scss'
import ErrorImage from '../ErrorImage'
import clsx from 'clsx'

export interface NotFoundProps {
  message: string
  toolbar?: React.ReactElement
  dotsEnabled?: boolean
  className?: string
}

const NotFound = ({ message, toolbar, dotsEnabled, className }: NotFoundProps) => (
  <div className={clsx('flow-mo-not-found', dotsEnabled && 'with-dots', className)}>
    {dotsEnabled && <Dots />}

    <div className={styles['flow-mo-not-found-content']}>
      <div className={styles['flow-mo-not-found-title']}>Oops!</div>

      <div className={styles['flow-mo-not-found-message']}>{message}</div>

      <ErrorImage className={styles['flow-mo-not-found-image']} />

      {toolbar}
    </div>

    {dotsEnabled && <Dots />}
  </div>
)

export default NotFound
