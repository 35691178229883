import React from 'react'
import { EnvelopeIcon } from '@heroicons/react/24/solid'

const EmailVerification: React.FC = () => (
  <>
    <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute left-full transform translate-x-1/2"
          width="404"
          height="404"
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <svg
          className="absolute right-full transform -translate-x-1/2"
          width="404"
          height="404"
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <div className="flex min-h-[calc(100vh-700px)] items-center justify-center text-center">
          <div className="relative rounded-lg bg-white text-left">
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <EnvelopeIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <div className="mt-3 flex flex-col items-center text-center sm:mt-5 max-w-[280px]">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                You&apos;re almost there!
              </h3>
              <p className="text-sm text-gray-500 mt-2">
                Please activate your account by clicking the link we&apos;ve just sent to your email
              </p>
              <p className="text-xs text-gray-400 mt-6 max-w-[220px]">
                Didn&apos;t receive an email? Check your spam folder or try{' '}
                <a href="/users/confirmation/new" className="text-blue-500 hover:underline">
                  resending it.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default EmailVerification
