import React from 'react'
import { TouchPause, TouchPlay, TouchRetry } from '../../icons'

import * as styles from './style.module.scss'
import { RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { setIsPlaying } from '../../slices/playerSlice'

type TouchscreenProps = {
  disabled?: boolean
}

const ICON_TYPE = {
  PLAY: 'PLAY',
  PAUSE: 'PAUSE',
  RETRY: 'RETRY',
}

const ICONS = {
  [ICON_TYPE.PLAY]: <TouchPlay />,
  [ICON_TYPE.PAUSE]: <TouchPause />,
  [ICON_TYPE.RETRY]: <TouchRetry />,
}

const Touchscreen: React.FC<TouchscreenProps> = ({ disabled }) => {
  const dispatch = useDispatch()
  const [icon, setIcon] = React.useState<string | undefined>(undefined)
  const { isVideoAtEnd, isPlaying } = useSelector((state: RootState) => state.player.playerControls)

  if (disabled) {
    return <React.Fragment />
  }

  const handleClick = () => {
    setIcon(isVideoAtEnd ? ICON_TYPE.RETRY : isPlaying ? ICON_TYPE.PAUSE : ICON_TYPE.PLAY)
    dispatch(setIsPlaying(!isPlaying))
  }

  return (
    <div className={styles['flow-mo-touchscreen']} onClick={handleClick}>
      {icon && (
        <div key={icon} className={styles['flow-mo-touchscreen-button']}>
          {ICONS[icon]}
        </div>
      )}
    </div>
  )
}

export default Touchscreen
