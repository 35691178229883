import React, { useEffect } from 'react'
import PageHeader from '../../../components/PageHeader'
import { useMutation, useQuery } from '@apollo/client'
import { GET_WORKFLOW_QUERY, MUTATION_SUBMIT_RUN_WORKFLOW } from '../../../graphql/queries/workflow'
import { WorkflowQuery, WorkflowQueryVariables } from '../../../../graphql'
import Spin from '../../../../common/ui/Spin'
import { Link, useHistory } from 'react-router-dom'
import HeaderActionButton from '../../../components/HeaderActionButton'
import {
  ChatBubbleBottomCenterIcon,
  ClockIcon,
  PlusCircleIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import CreateTask from '../../../components/CreateTask'
import TaskCard from './TaskCard'
import { WorkflowStatus } from '../../../components/WorkflowCard'
import { format } from 'date-fns'
import { PlayCircleIcon } from '@heroicons/react/20/solid'

const OneWorkflow = ({ uuid }: { uuid: string }) => {
  const [isCreatingTask, setIsCreatingTask] = React.useState(false)
  const [isRunning, setIsRunning] = React.useState(false)
  const [submitRunWorkflow] = useMutation(MUTATION_SUBMIT_RUN_WORKFLOW)
  const refetchInterval = React.useRef<NodeJS.Timeout | null>(null)

  const history = useHistory()
  document.title = 'Workflow | FlowMo'

  const {
    data: workflowData,
    loading: workflowLoading,
    refetch: refetchWorkflow,
  } = useQuery<WorkflowQuery, WorkflowQueryVariables>(GET_WORKFLOW_QUERY, {
    variables: { uuid: uuid },
    fetchPolicy: 'network-only',
  })

  // check if the workflow is running on initial load
  useEffect(() => {
    if (workflowData?.workflow) {
      if (workflowData.workflow.status === 'running' && !isRunning) {
        setIsRunning(true)
      }
      if (workflowData.workflow.status != 'running' && isRunning) {
        refetchWorkflow().then(() => {
          setIsRunning(false)
        })
      }
    }
  }, [workflowData])

  React.useEffect(() => {
    if (isRunning) {
      refetchInterval.current = setInterval(() => {
        refetchWorkflow()
      }, 2000)
    } else {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current)
        refetchInterval.current = null
      }
    }

    // Clear the interval when the component is unmounted
    return () => {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current)
      }
    }
  }, [isRunning, refetchWorkflow])

  if (workflowLoading) {
    return <Spin />
  }

  const workflow = workflowData?.workflow

  if (!workflow) {
    history.push('/workflows')
    return <></>
  }

  const onTaskCreate = () => {
    refetchWorkflow()
    setIsCreatingTask(false)
  }

  const onTaskCancel = () => {
    setIsCreatingTask(false)
  }

  const onTaskDelete = () => {
    refetchWorkflow()
  }

  const handleRunWorkflow = async () => {
    setIsRunning(true)
    submitRunWorkflow({
      variables: { uuid: workflow.uuid },
    })
  }

  return (
    <>
      <PageHeader
        title={
          <div className="flex flex-row space-x-2">
            <WorkflowStatus workflow={workflow} />
            <div className="border"></div>
            <div className="flex flex-col mt-2 space-y-2">
              <div>{workflow.name}</div>
              <div className="text-base font-normal text-gray-500">{workflow.description}</div>
            </div>
          </div>
        }
        breadCrumbs={[{ title: 'Workflows', to: '/workflows' }, { title: 'One Workflow' }]}
      >
        <div className="flex flex-row space-x-4">
          <Link to={'/workflow/edit/' + uuid}>
            <HeaderActionButton label="Edit" />
          </Link>

          <button onClick={handleRunWorkflow} disabled={isRunning} className="">
            {isRunning ? (
              <Spin />
            ) : (
              <PlayCircleIcon className="w-8 h-8 hover:text-flowmo-blue-600" />
            )}
          </button>
        </div>
      </PageHeader>

      <div className="flex flex-row space-x-2 bg-white border-t border-b mx-2 py-2 px-2 text-sm text-gray-600">
        <div className="flex flex-row items-center space-x-2">
          <ChatBubbleBottomCenterIcon className="size-6" />
          <Link
            className="text-flowmo-blue-900 hover:text-flowmo-blue-600 hover:underline"
            to={'/agent/' + workflow.agent.uuid}
          >
            {workflow.agent.label}
          </Link>
        </div>
        <div className="border-l border-gray-300"></div>
        <div className="flex flex-row items-center space-x-2">
          <UserIcon className="size-4" />
          <div>
            by {workflow.createdBy.firstName} {workflow.createdBy.lastName[0]}
          </div>
        </div>
        <div className="border-l border-gray-300"></div>
        <div className="flex flx-row items-center space-x-2">
          <ClockIcon className="size-4" />
          <div>on {format(new Date(workflow.createdAt), 'MMMM d, yyyy')}</div>
        </div>
      </div>

      <div className="my-10 flex flex-col space-y-2">
        {workflow.tasks.map((oneTask) => (
          <TaskCard task={oneTask} key={oneTask.uuid} onDelete={onTaskDelete} />
        ))}
      </div>

      <div className="">
        {isCreatingTask ? (
          <div className="">
            <CreateTask
              taskableType="Workflow"
              taskableUuid={workflow.uuid}
              onSuccess={onTaskCreate}
              onCancel={onTaskCancel}
            />
          </div>
        ) : (
          <div className="">
            <button
              onClick={() => setIsCreatingTask(true)}
              className="flex flex-row space-x-2 items-center hover:underline text-flowmo-blue-900 hover:text-flowmo-blue-600"
            >
              <PlusCircleIcon className="h-6 w-6" /> <span>Add Task</span>
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default OneWorkflow
