import React from 'react'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'

import * as styles from './style.module.scss'

const Completed: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isVideoAtEnd } = useSelector((state: RootState) => state.player.playerControls)

  if (!isVideoAtEnd) {
    return <React.Fragment />
  }

  return <div className={styles['flow-mo-completed']}>{children}</div>
}

export default Completed
