import React from 'react'
import Option from './index'
import { LibraryIcon } from '../icons'
import useUser from '../../../../../App/hooks/useUser'

const BrowseLibraryOption = () => {
  const user = useUser()

  const onClick = () => {
    window.location.href = user?.organization ? '/app/organization_library' : '/app/my_library'
  }

  return (
    <Option
      onClick={onClick}
      icon={<LibraryIcon />}
      title="Browse the Library"
      description="Level up or learn a new skill by seeing what you’re colleagues have built for you."
      className="border-r border-[#E5E7EB]"
    />
  )
}

export default BrowseLibraryOption
