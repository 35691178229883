import React, { ReactNode, useState, useEffect } from 'react'
import { SizeProps } from './modules/Resize'
import Audio from './modules/Audio'
import Video from './modules/Video'
import { FullscreenButton, MuteButton } from './components/Buttons'
import Thumbnail from './components/Thumbnail'
import Completed from './components/Completed'
import InteractiveController from './modules/InteractiveController'
import { Chunk, NormalizedStepProps } from './types'
import { useDispatch, useSelector } from 'react-redux'
import {
  incrementPosition,
  setChunkCount,
  setChunkTimes,
  setDuration,
  setHasVideoError,
  setIsMuted,
  setMaxHeight,
  setSteps,
} from './slices/playerSlice'
import { RootState } from './store'
import { getMaxTimestamp, getMinTimestamp } from './modules/Video/utils'
import { CLOCK_INTERVAL } from './constants'
import NotFound from '../NotFound'

type AppProps = {
  duration: number
  audio?: string
  fullscreen?: boolean
  rrwebScreenSize?: SizeProps
  chunks: Chunk[]
  maxHeight?: number
  thumbnail?: (loading: boolean) => ReactNode
  completed?: () => ReactNode
  showControlPanel?: boolean
  steps: NormalizedStepProps[]
}

const App: React.FC<AppProps> = ({
  showControlPanel = true,
  audio,
  steps,
  chunks,
  fullscreen = true,
  maxHeight,
  thumbnail,
  completed,
  duration,
}) => {
  const dispatch = useDispatch()
  const {
    isPlayerReady,
    audio: { hasAudioError },
    playerControls: { isVideoAtEnd, isPlaying, isMuted, hasVideoError },
  } = useSelector((state: RootState) => state.player)
  const [intervalID, setIntervalID] = useState<number | null>(null)

  // set the defaults
  useEffect(() => {
    dispatch(setMaxHeight(maxHeight !== undefined ? maxHeight : 0))
    dispatch(setSteps(steps))
    dispatch(setDuration(duration))
    dispatch(setChunkCount(chunks.length))
    if (chunks.length == 0) {
      dispatch(setHasVideoError(true))
    }
  }, [])

  // the clock tick for the video
  useEffect(() => {
    if (isPlaying) {
      const id = setInterval(() => {
        dispatch(incrementPosition(CLOCK_INTERVAL))
      }, CLOCK_INTERVAL)

      setIntervalID(id as unknown as number)
    } else {
      intervalID && clearInterval(intervalID)
    }
  }, [isPlaying])

  // the chunk metadata
  useEffect(() => {
    const times = chunks
      .filter((chunk) => !chunk.loading)
      .map((_, index) => {
        const start = getMinTimestamp(chunks[0].events)
        return {
          previousChunkEndPosition:
            index === 0 ? 0 : getMaxTimestamp(chunks[index - 1].events) - start,
          currentChunkStartPosition:
            index === 0 ? 0 : getMinTimestamp(chunks[index].events) - start,
          currentChunkEndPosition: getMaxTimestamp(chunks[index].events) - start,
        }
      })
    dispatch(setChunkTimes(times))
  }, [chunks])

  if (hasVideoError) {
    return <NotFound message="Video is not found" />
  }

  return (
    <React.Fragment>
      <Video chunks={chunks} />
      {!hasAudioError && <Audio url={audio} muted={isMuted} />}
      {showControlPanel && (
        <InteractiveController disabled={!isPlayerReady}>
          {audio && !hasAudioError && (
            <MuteButton muted={isMuted} toggle={() => dispatch(setIsMuted(!isMuted))} />
          )}
          {fullscreen && <FullscreenButton />}
        </InteractiveController>
      )}
      {thumbnail && <Thumbnail>{thumbnail(!isPlayerReady)}</Thumbnail>}
      {isVideoAtEnd && <Completed>{completed && completed()}</Completed>}
    </React.Fragment>
  )
}

export default App
