import React from 'react'
import DynamicForm from '../../../components/DynamicForm'
import Spin from '../../../../common/ui/Spin'
import {
  CREATE_CREDENTIAL_MUTATION,
  UPDATE_CREDENTIAL_MUTATION,
} from '../../../graphql/queries/credential'
import { useMutation } from '@apollo/client'
import {
  CreateDataCredentialsMutation,
  CreateDataCredentialsMutationVariables,
  UpdateDataCredentialsMutation,
  UpdateDataCredentialsMutationVariables,
} from 'app/javascript/components/graphql'

export type FormItem = {
  name: string
  label: string
  type: string
  required: boolean
  defaultValue: string
}

type CredentialsFormProps = {
  formData: FormItem[]
  currentValues: any
  credentialType: string
  handleSuccess: () => void
  handleError: () => void
  handleCancel: () => void
  uuid?: string
}

const CredentialsForm = ({
  formData,
  currentValues,
  credentialType,
  uuid,
  handleSuccess,
  handleError,
  handleCancel,
}: CredentialsFormProps) => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [saving, setSaving] = React.useState(false)

  // populate the default value of the form
  formData.forEach((item) => {
    if (currentValues[item.name]) {
      item.defaultValue = currentValues[item.name]
    }
  })

  const [updateCredentialsMutation] = useMutation<
    UpdateDataCredentialsMutation,
    UpdateDataCredentialsMutationVariables
  >(UPDATE_CREDENTIAL_MUTATION)
  const [createCredentialsMutation] = useMutation<
    CreateDataCredentialsMutation,
    CreateDataCredentialsMutationVariables
  >(CREATE_CREDENTIAL_MUTATION)

  const handleSubmit = async (values: any) => {
    setSaving(true)

    let response = null
    let hasError = false
    if (uuid) {
      response = await updateCredentialsMutation({
        variables: {
          credentials: values,
          uuid: uuid,
        },
      })
      hasError = response.data.updateDataCredentials.errors.length > 0
    } else {
      response = await createCredentialsMutation({
        variables: {
          credentials: values,
          credentialType: credentialType,
        },
      })
      hasError = response.data.createDataCredentials.errors.length > 0
    }

    setSaving(false)
    if (hasError) {
      handleError()
      return
    }
    handleSuccess()
  }

  return (
    <>
      <div className="max-w-xl">
        <DynamicForm ref={formRef} formData={formData} handleSubmit={handleSubmit} />
        <div className="flex flex-row space-x-2">
          <button
            type="submit"
            onClick={() => formRef.current?.submitForm()}
            className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
            disabled={saving}
          >
            {saving ? <Spin /> : 'Save'}
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-red-600 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-red-700"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}

export default CredentialsForm
