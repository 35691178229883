import React from 'react'
import {
  TextContent as TextContentType,
  ImageContent as ImageContentType,
} from 'app/javascript/components/graphql'
import TextContent from './TextContent'
import ImageContent from './ImageContent'

interface MessageBodyProps {
  messages: (TextContentType | ImageContentType)[]
}

const MessageBody = ({ messages }: MessageBodyProps) => {
  if (!messages) {
    return <></>
  }
  return (
    <div>
      <div className="flex flex-col space-x-2">
        {messages.map((content, index) => {
          return (
            <div key={index}>
              {content.__typename === 'TextContent' ? (
                <TextContent content={content} />
              ) : content.__typename === 'ImageContent' ? (
                <ImageContent content={content} />
              ) : null}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MessageBody
