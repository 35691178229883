import { useEffect, useRef, useCallback, useState } from 'react'
import { createConsumer } from '@rails/actioncable'
import { v4 as uuidv4 } from 'uuid'

const TIME_LIMIT = 300 // Time limit in seconds (5 minutes)

const useAudioConversation = (onResponse: (responseType: string, data: any) => void) => {
  const streamId = uuidv4()
  const subscriptionRef = useRef<any>(null)
  const cableRef = useRef<any>(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null) // Ref to store the timer
  const countdownRef = useRef<NodeJS.Timeout | null>(null)
  const [timeLeft, setTimeLeft] = useState(TIME_LIMIT)

  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
  const host = window.location.hostname
  const port = window.location.port ? `:${window.location.port}` : ''
  const cableURL = `${protocol}//${host}${port}/cable`

  const startConversation = useCallback(() => {
    if (cableRef.current) {
      return
    } // Prevent multiple connections

    cableRef.current = createConsumer(cableURL)
    const subscription = cableRef.current.subscriptions.create(
      { channel: 'AudioTranscriptionChannel', stream_id: streamId },
      {
        received(data: any) {
          const messageType = data.type || data.message_type
          if (messageType === 'error') {
            console.error('Error from server:', data.data)
          } else {
            onResponse(messageType, data)
          }
        },
      }
    )

    subscriptionRef.current = subscription

    // Reset timer and start countdown
    setTimeLeft(TIME_LIMIT)

    // Start a countdown interval to update `timeLeft` every second
    countdownRef.current = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 1) {
          stopConversation()
          return 0
        }
        return prevTimeLeft - 1
      })
    }, 1000)

    // Set a timer to stop the conversation automatically after the limit
    timerRef.current = setTimeout(() => {
      stopConversation()
    }, TIME_LIMIT * 1000)
  }, [onResponse, streamId, cableURL])

  const stopConversation = useCallback(() => {
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe()
      subscriptionRef.current = null
      cableRef.current = null

      // Clear timers
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
      if (countdownRef.current) {
        clearInterval(countdownRef.current)
        countdownRef.current = null
      }
      setTimeLeft(0) // Reset time left when conversation is stopped
    }
  }, [])

  const sendAudioData = (audioData: Float32Array) => {
    const base64Data = base64EncodeAudio(audioData)
    subscriptionRef.current?.perform('send_audio_chunk', {
      stream_id: streamId,
      audio_chunk: base64Data,
    })
  }

  const floatTo16BitPCM = (float32Array: Float32Array) => {
    const buffer = new ArrayBuffer(float32Array.length * 2)
    const view = new DataView(buffer)
    for (let i = 0; i < float32Array.length; i++) {
      const s = Math.max(-1, Math.min(1, float32Array[i]))
      view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7fff, true)
    }
    return buffer
  }

  const base64EncodeAudio = (float32Array: Float32Array) => {
    const arrayBuffer = floatTo16BitPCM(float32Array)
    const bytes = new Uint8Array(arrayBuffer)
    return window.btoa(String.fromCharCode(...bytes))
  }

  useEffect(() => {
    return () => {
      stopConversation()
    }
  }, [stopConversation])

  return { startConversation, stopConversation, sendAudioData, timeLeft, timeLimit: TIME_LIMIT }
}

export default useAudioConversation
