import React from 'react'
import { clsx } from 'clsx'

interface Props {
  label: string
  icon: React.ReactElement
  active: boolean
  onClick: () => void
}

const ModeOption = ({ label, active, onClick, icon }: Props) => (
  <div
    onClick={onClick}
    className={clsx(
      active ? 'text-white border-b-white' : 'text-[#CBDDFF] border-b-transparent',
      'transition-colors hover:text-white hover:border-b-white flex items-center space-x-1.5',
      'font-semibold text-[15px] cursor-pointer h-[calc(100%+2px)] border-b-2'
    )}
  >
    <div>{icon}</div>
    <div>{label}</div>
  </div>
)

export default ModeOption
