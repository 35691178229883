import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { cloudinary, getPublicId, imageExists } from '../../../utils/cloudinary'
import Spin from '../../../../common/ui/Spin'
import { CursorArrowRaysIcon } from '@heroicons/react/24/solid'
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline'
import ContentDialog from '../../../../common/ui/ContentDialog'

const DELTA_SIZE = 10
const AREA_PERCENTAGE = 0.5

const getSizeInstruction = (screenSize: any) => `w_${screenSize.width}`

const getHighlightInstructions = (position: any, screenSize: any) => {
  const boxWidth = Math.round(position.right - position.left)
  const boxHeight = Math.min(
    Math.round(position.bottom - position.top),
    Math.round(screenSize.height - position.top)
  )

  return boxWidth > 0 && boxHeight > 0
    ? [
        `l_one_pixel,e_colorize,co_rgb:ea7e61,o_30,g_north_west,`,
        `r_20,w_${boxWidth},h_${boxHeight},`,
        `x_${Math.round(position.left)},y_${Math.round(position.top)}`,
      ].join('')
    : ''
}

const getBeaconInstructions = (coordinates: any) => {
  return [
    `l_one_pixel,e_colorize,co_rgb:ea7e61,o_30,g_north_west,`,
    `r_${25},w_${50},h_${50},`,
    `x_${Math.round(coordinates.x - 25)},y_${Math.round(coordinates.y - 25)}`,
  ].join('')
}

const getBeaconPointerInstructions = (coordinates: any) =>
  [
    `l_up-arrow,e_colorize,co_rgb:ea7e61,o_80,g_north_west,w_180,`,
    `x_${Math.round(coordinates.x - 150)},y_${Math.round(coordinates.y - 155)},a_135`,
  ].join('')

const getHighlightPointerInstructions = (position: any) =>
  [
    `l_up-arrow,e_colorize,co_rgb:ea7e61,o_80,g_north_west,w_180,`,
    `x_${Math.round(position.left - 155)},y_${Math.round(position.top - 155)},a_135`,
  ].join('')

const getCloudUrl = (
  url: string,
  position: any,
  coordinates: any,
  screenSize: any,
  isIFrame: boolean
) => {
  if (position) {
    const screen = {
      width: screenSize?.width || position.innerWidth,
      height: screenSize?.height || position.innerHeight,
    }
    const element = {
      width: position.right - position.left,
      height: position.bottom - position.top,
    }

    const isElementOversize =
      element.width + DELTA_SIZE >= screen.width ||
      element.height + DELTA_SIZE >= screen.height ||
      (element.width * element.height) / (screen.width * screen.height) > AREA_PERCENTAGE

    if ((isElementOversize || isIFrame) && coordinates && screenSize) {
      return cloudinary
        .image(getPublicId(url))
        .addTransformation(getSizeInstruction(screenSize))
        .addTransformation(getBeaconInstructions(coordinates))
        .addTransformation(getBeaconPointerInstructions(coordinates))
        .toURL()
    }

    if (!isIFrame) {
      return cloudinary
        .image(getPublicId(url))
        .addTransformation(getSizeInstruction(screen))
        .addTransformation(getHighlightInstructions(position, screen))
        .addTransformation(getHighlightPointerInstructions(position))
        .toURL()
    }
  }

  return cloudinary.image(getPublicId(url)).toURL()
}

const MAX_ATTEMPT_COUNT = 6

export const useStepImage = (
  url: string,
  position: any,
  coordinates: any,
  screenSize: any,
  isIFrame: boolean
) => {
  const [exists, setExists] = useState(false)
  const [loading, setLoading] = useState(true)

  const cloudUrl = useMemo(
    () => url && getCloudUrl(url, position, coordinates, screenSize, isIFrame),
    [url, position, coordinates, screen]
  )

  const checkIfExists = useCallback(
    async (attempt = 1) => {
      if (await imageExists(cloudUrl)) {
        setExists(true)
        setLoading(false)
      } else {
        attempt < MAX_ATTEMPT_COUNT
          ? setTimeout(() => checkIfExists(attempt + 1), 500 * attempt)
          : setLoading(false)
      }
    },
    [setExists, setLoading]
  )

  useEffect(() => {
    checkIfExists(1)
  }, [])

  return { cloudUrl, exists, loading }
}

interface Props {
  url: string
  className?: string
  position?: any
  coordinates?: any
  screenSize?: any
  isIFrame?: boolean
}

const StepImage: React.FC<Props> = ({
  url,
  className,
  position,
  coordinates,
  screenSize,
  isIFrame,
}) => {
  const { cloudUrl, exists, loading } = useStepImage(
    url,
    position,
    coordinates,
    screenSize,
    isIFrame
  )
  const [showImage, setShowImage] = useState(false)
  const toggleImageDialog = () => setShowImage(!showImage)
  if (loading) {
    return <Spin className="h-20 w-20 text-ddu-blue m-auto my-6" />
  }

  if (exists) {
    return (
      <>
        <ContentDialog open={showImage} close={toggleImageDialog}>
          <img src={cloudUrl} className={`${className} h-[80vh]`} alt="Step Screenshot" />
        </ContentDialog>
        <div className="relative">
          <div
            onClick={toggleImageDialog}
            className="flex w-12 h-12 cursor-pointer justify-center items-center text-center rounded-full bg-gray-200 hover:bg-gray-300 absolute top-12 right-5 "
          >
            <ArrowsPointingOutIcon className="inline-block w-6 h-10" />
          </div>
          <img src={cloudUrl} className={`${className} max-h-[500px]`} alt="Step Screenshot" />
        </div>
      </>
    )
  }

  return (
    <div className="w-full h-40 bg-flowmo-blue-200 flex h-[500px]">
      <CursorArrowRaysIcon className="h-20 w-20 m-auto justify-center text-flowmo-blue-300/30" />
    </div>
  )
}

export default StepImage
