import { FolderIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link } from 'react-router-dom'
type SkillsetCardProps = {
  skillset: any
}

const SkillsetCard = ({ skillset }: SkillsetCardProps) => {
  return (
    <Link to={`/skill_set/${skillset.uuid}`}>
      <div
        className="relative flex h-32 w-72 items-center justify-center rounded-2xl p-1 shadow-lg group"
        style={{
          backgroundImage:
            'conic-gradient(from -49.8deg at 50% 50%, #ff8331 0deg, #fa3e7c 59.07deg, #d11f60 185.61deg, #b08aee 284.23deg, #ff0000 329.41deg, #ffeb7c 360deg)',
        }}
      >
        <div className="bg-white/90 h-full w-full rounded-xl p-2">
          <div className="font-semibold text-sm line-clamp-2">{skillset.name}</div>
          <div className="text-gray-600 text-sm line-clamp-2">{skillset.description}</div>
          <div className="absolute bottom-2 left-2 flex flex-row items-center space-x-2">
            <FolderIcon className="h-6 w-6 text-[#7331FF]" />
            <div className="text-gray-900 font-semibold">Skill Set</div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default SkillsetCard
