import React from 'react'
import DomainTimeline from '../DomainTimeline'
import StepTimeline from '../StepTimeline'

import * as styles from './style.module.scss'

const Progress = () => {
  return (
    <div className={styles['progress-panel']}>
      <StepTimeline />
      <DomainTimeline />
    </div>
  )
}

export default Progress
