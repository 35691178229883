import React, { useContext, useEffect, useMemo } from 'react'

import Timer from '../../components/Timer'
import { PlayPauseButton, PreviousNextButtons } from '../../components/Buttons'
import { ControllerUI } from './components'
import Touchscreen from '../../components/Touchscreen'
import useActionDelayer from '../../../../hooks/useActionDelayer'
import { ResizeContext } from '../Resize'
import Progress from './components/Progress'

import * as styles from './style.module.scss'
import ModeToggle from '../../components/ModeToggle'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setControllerIsMinimized } from '../../slices/playerSlice'

type InteractiveControllerProps = React.PropsWithChildren & {
  disabled?: boolean
}

const InteractiveController: React.FC<InteractiveControllerProps> = ({ children, disabled }) => {
  const dispatch = useDispatch()
  const { root } = useContext(ResizeContext)
  const {
    duration,
    position,
    playerControls: {
      isPlaying,
      isSeeking,
      isVideoAtEnd,
      isInteractiveMode,
      isInteractiveStep,
      isControllerMinimized,
    },
  } = useSelector((state: RootState) => state.player)
  const { actionTracker } = useActionDelayer(() => {
    dispatch(setControllerIsMinimized(true))
  }, 3000)

  useEffect(() => {
    function handleAction() {
      dispatch(setControllerIsMinimized(false))
      actionTracker()
    }
    function handleLeave() {
      dispatch(setControllerIsMinimized(true))
    }
    if (!isInteractiveStep) {
      root?.addEventListener('mousemove', handleAction)
      root?.addEventListener('click', handleAction)
      root?.addEventListener('mouseleave', handleLeave)
    }
    return () => {
      root?.removeEventListener('mousemove', handleAction)
      root?.removeEventListener('click', handleAction)
      root?.removeEventListener('mouseleave', handleLeave)
    }
  }, [isInteractiveStep])

  const isMinimized = useMemo(() => {
    if (!isControllerMinimized) {
      return false
    }

    // seek loading
    if (!isPlaying && isSeeking) {
      return true
    }

    // liner loading
    if (!isPlaying) {
      return true
    }

    return isPlaying
  }, [isControllerMinimized, isPlaying, isSeeking])

  return (
    <React.Fragment>
      {!isInteractiveMode && <Touchscreen disabled={disabled} />}
      <ControllerUI disabled={disabled} isMinimized={isMinimized}>
        {isMinimized ? (
          <React.Fragment>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39645 7.14645C9.20118 7.34171 9.20118 7.65829 9.39645 7.85355L13.5429 12L9.39645 16.1464C9.20118 16.3417 9.20118 16.6583 9.39645 16.8536C9.59171 17.0488 9.90829 17.0488 10.1036 16.8536L14.6036 12.3536C14.7988 12.1583 14.7988 11.8417 14.6036 11.6464L10.1036 7.14645C9.90829 6.95118 9.59171 6.95118 9.39645 7.14645Z"
                fill="#E4E7ED"
              />
            </svg>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={styles['top-row']}>
              <Progress />
            </div>
            <div className={styles['bottom-row']}>
              <div className={styles['left-control-buttons']}>
                <PlayPauseButton completed={isVideoAtEnd} playing={isPlaying} />
                <PreviousNextButtons />
              </div>
              <div className={styles['center-control-panel']}>
                <Timer position={position} duration={duration} />
              </div>
              <div className={styles['right-control-panel']}>
                {children && <div className={styles['controller-ui-children']}>{children}</div>}
                <ModeToggle />
              </div>
            </div>
          </React.Fragment>
        )}
      </ControllerUI>
    </React.Fragment>
  )
}

export default InteractiveController
