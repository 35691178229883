import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

const humanize = (str: string) => {
  const frags = str.split('_')
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(' ')
}

export const usePageTitle = (title?: string) => {
  const location = useLocation()

  useEffect(() => {
    if (title) {
      const parsed = ReactHtmlParser(title)

      if (parsed && parsed[0]) {
        document.title = `${parsed[0]}`
      } else {
        document.title = `${title}`
      }
    } else {
      const title = location.pathname
        .split('/')
        .filter((str) => str.trim().length > 0)
        .reduce((acc, val) => (acc ? `${acc} | ${humanize(val)}` : humanize(val)), '')
      document.title = `${title}`
    }
  }, [title, location])
}
