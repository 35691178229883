import { useHistory, useLocation } from 'react-router-dom'
import { useCallback } from 'react'

interface Params {
  page?: number
  limit?: number
  search?: string
}

const removeEmptyFields = (object: any) => {
  Object.keys(object).forEach(
    (key) =>
      (object[key] === undefined || object[key] === null || object[key] === '') &&
      delete object[key]
  )
  return object
}

export const usePaginationRedirect = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  return useCallback(
    (params: Params) => {
      const filteredParams = removeEmptyFields(params)
      const query = Object.keys(filteredParams)
        .map((k) => `${k}=${filteredParams[k]}`)
        .join('&')
      history.push(`${pathname}?${query}`)
    },
    [history]
  )
}
