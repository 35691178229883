import React, { useState } from 'react'
import {
  DELETE_WORKFLOW_MUTATION,
  GET_WORKFLOW_QUERY,
  UPDATE_WORKFLOW_MUTATION,
} from '../../graphql/queries/workflow'
import {
  DeleteWorkflowMutation,
  DeleteWorkflowMutationVariables,
  OrganizationAgentsQuery,
  OrganizationAgentsQueryVariables,
  UpdateWorkflowMutation,
  UpdateWorkflowMutationVariables,
  WorkflowQuery,
  WorkflowQueryVariables,
} from '../../../../components/graphql'
import Spin from '../../../../components/common/ui/Spin'
import { useMutation, useQuery } from '@apollo/client'
import PageHeader from '../../components/PageHeader'
import DynamicForm from '../../components/DynamicForm'
import { GET_ORGANIZATION_AGENTS_QUERY } from '../../graphql/queries/agent'
import { useHistory } from 'react-router-dom'
import HeaderActionButton from '../../components/HeaderActionButton'
import DeleteModal from '../../components/DeleteModal'
import { workflowFormData } from './CreateWorkflow'

const EditTask = ({ uuid }: { uuid: string }) => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [isSaving, setIsSaving] = React.useState(false)
  const history = useHistory()
  const { data, loading } = useQuery<WorkflowQuery, WorkflowQueryVariables>(GET_WORKFLOW_QUERY, {
    variables: { uuid },
  })
  const [open, setOpen] = useState(false)

  const { data: agentsData, loading: agentsLoading } = useQuery<
    OrganizationAgentsQuery,
    OrganizationAgentsQueryVariables
  >(GET_ORGANIZATION_AGENTS_QUERY)

  const [updateWorkflow] = useMutation<UpdateWorkflowMutation, UpdateWorkflowMutationVariables>(
    UPDATE_WORKFLOW_MUTATION
  )

  const [deleteWorkflow] = useMutation<DeleteWorkflowMutation, DeleteWorkflowMutationVariables>(
    DELETE_WORKFLOW_MUTATION
  )

  if (loading || agentsLoading) {
    return <Spin />
  }

  const workflow = data?.workflow
  const agents = agentsData?.organizationAgents

  const handleDelete = async () => {
    const response = await deleteWorkflow({
      variables: {
        uuid,
      },
    })
    if (response.data?.deleteWorkflow.errors.length > 0) {
      window.toastr.error('Error deleting workflow')
    } else {
      window.toastr.success('Workflow deleted successfully')
      history.push('/workflows')
    }
    return
  }

  const handleSubmit = async (values: any) => {
    setIsSaving(true)
    const response = await updateWorkflow({
      variables: {
        uuid,
        name: values.name,
        description: values.description,
        agentUuid: values.agentUuid,
        cronSchedule: values.cronSchedule,
        webhookUrl: values.webhookUrl,
      },
    })
    if (response.data?.updateWorkflow.errors.length > 0) {
      window.toastr.error('Error updating workflow')
      history.push('/workflows')
    } else {
      window.toastr.success('Workflow updated successfully')
      history.push(`/workflow/${uuid}`)
    }
    setIsSaving(false)
    return
  }

  const initialData = {
    name: workflow?.name,
    description: workflow?.description,
    agentUuid: workflow?.agent.uuid,
    cronSchedule: workflow?.cronSchedule,
    webhookUrl: workflow?.webhookUrl,
  }

  const formData = workflowFormData(agents)

  return (
    <>
      <PageHeader
        title={workflow.name}
        breadCrumbs={[
          { title: 'Workflows', to: '/workflows' },
          { title: workflow.name, to: `/workflow/${workflow.uuid}` },
          { title: 'Edit Workflow' },
        ]}
      >
        <HeaderActionButton onClick={() => setOpen(true)} label="Delete" />
      </PageHeader>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        onDelete={handleDelete}
        title="Delete Workflow"
        buttonLabel="Delete"
        description="Are you sure you want to delete the workflow? All of your data will be permanently removed from our servers forever. This action cannot be undone."
      />
      <div className="max-w-2xl">
        <DynamicForm
          ref={formRef}
          formData={formData}
          initialData={initialData}
          handleSubmit={handleSubmit}
        />
        <div className="flex flex-row space-x-4">
          <button
            type="submit"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-flowmo-blue-600 hover:bg-flowmo-blue-700"
            onClick={() => formRef.current?.submitForm()}
          >
            {isSaving ? <Spin /> : 'Save'}
          </button>
          <button
            type="button"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-300 hover:bg-gray-500"
            onClick={() => history.push(`/workflow/${uuid}`)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}
export default EditTask
