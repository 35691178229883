import React from 'react'
import { useEmbedContext } from '../../../../context/EmbedContext'
import Title from '../../../Title'
import Description from '../../../Description'
import { shareLink } from '../../../../../common/utils/links'

const OvalNormalSVG = () => (
  <svg width="100" height="39" viewBox="0 0 100 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5851 4.55157C32.4038 -0.49345 51.3668 -0.756176 67.738 0.940528C75.8117 1.77728 80.4531 3.16146 87.9337 5.93542C90.0716 6.72817 92.8319 7.93821 95.1578 9.57674C97.4542 11.1945 99.5189 13.366 99.9 16.1334C100.351 19.4062 99.2446 22.0373 97.3808 24.1263C95.547 26.1817 92.9787 27.719 90.4401 28.9199C71.2553 37.9946 54.3068 36.6439 32.4538 33.7054C31.9064 33.6318 31.5224 33.1284 31.596 32.581C31.6696 32.0337 32.173 31.6496 32.7203 31.7232C54.543 34.6577 70.9713 35.9165 89.5849 27.1119C92.0341 25.9534 94.3187 24.5542 95.8885 22.7948C97.4284 21.0687 98.2768 19.0065 97.9187 16.4063C97.6528 14.4753 96.1582 12.728 94.006 11.2118C91.8831 9.71628 89.3053 8.57712 87.2384 7.81065C79.8544 5.07255 75.3842 3.74368 67.5318 2.92987C51.2913 1.24672 32.6396 1.53061 17.1928 6.45701C14.2982 7.38016 10.2607 8.85908 7.06022 11.149C3.86551 13.4348 1.68084 16.3893 2.03904 20.2895C2.30399 23.1744 4.0157 25.6085 6.63097 27.5549C9.24994 29.5042 12.7007 30.9019 16.225 31.6933C29.98 34.782 45.574 37 61.661 37C62.2133 37 62.661 37.4477 62.661 38C62.661 38.5523 62.2133 39 61.661 39C45.3956 39 29.6519 36.7581 15.7868 33.6447C12.0714 32.8104 8.33979 31.3199 5.43687 29.1593C2.53024 26.996 0.380484 24.0989 0.0474242 20.4724C-0.403445 15.5632 2.42228 12.0082 5.89645 9.52244C9.36488 7.0408 13.6547 5.48614 16.5851 4.55157Z"
      fill="#EA6A29"
    />
  </svg>
)

const OvalLargeSVG = () => (
  <svg width="143" height="55" viewBox="0 0 143 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5578 6.49921C46.2351 -0.701304 73.4497 -1.08229 96.9752 1.34513C108.542 2.53867 115.182 4.50921 125.924 8.47485C128.984 9.60461 132.916 11.3224 136.217 13.6382C139.489 15.9328 142.337 18.9515 142.86 22.7363C143.489 27.2806 141.952 30.9273 139.341 33.8408C136.761 36.7207 133.128 38.8926 129.495 40.6034C102.019 53.5429 77.7486 51.6284 46.3139 47.42C45.7665 47.3467 45.3821 46.8435 45.4554 46.2961C45.5287 45.7487 46.0318 45.3644 46.5792 45.4377C77.984 49.642 101.736 51.4653 128.643 38.794C132.186 37.1251 135.536 35.0907 137.852 32.506C140.138 29.9549 141.414 26.8786 140.879 23.0103C140.471 20.0649 138.198 17.4701 135.069 15.2756C131.97 13.1021 128.221 11.4548 125.231 10.3511C114.586 6.42105 108.117 4.50535 96.7699 3.33457C73.3745 0.920569 46.4698 1.32257 24.1631 8.40543C19.9905 9.73032 14.1258 11.8654 9.46214 15.1875C4.80358 18.506 1.52256 22.8677 2.05784 28.6703C2.45369 32.9615 5.01086 36.5483 8.83789 39.384C12.6683 42.2223 17.6951 44.245 22.8081 45.3881C42.6229 49.8179 65.0923 53.0003 88.2774 53.0003C88.8297 53.0003 89.2774 53.448 89.2774 54.0003C89.2774 54.5525 88.8297 55.0003 88.2774 55.0003C64.9147 55.0003 42.2962 51.7942 22.3718 47.3399C17.0685 46.1543 11.7615 44.0396 7.64719 40.991C3.52946 37.9398 0.530845 33.89 0.0662935 28.854C-0.5625 22.0376 3.3653 17.0749 8.30177 13.5585C13.2332 10.0457 19.3497 7.83538 23.5578 6.49921Z"
      fill="#EA6A29"
    />
  </svg>
)

const ArrowNormalSVG = () => (
  <svg width="52" height="35" viewBox="0 0 52 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M49.3291 6.6168C49.7482 8.55036 50.1907 10.4182 50.6493 12.5517C51.7454 11.8725 52.0777 10.8422 51.9666 9.91113C51.6334 7.1178 51.2346 4.3007 50.5917 1.54365C50.2308 -0.0347914 48.8477 -0.313308 47.6759 0.783881C45.6939 2.58969 43.8196 4.50876 41.8797 6.40406C41.767 6.51168 41.6096 6.64027 41.6071 6.75071C41.5314 7.1687 41.5659 7.58951 41.5558 8.03128C41.931 8.01878 42.3904 8.18525 42.6369 8.01475C43.5101 7.44035 44.2967 6.79744 45.1043 6.19926C45.5527 5.87921 45.9354 5.53538 46.754 5.42372C46.5159 6.19118 46.2989 7.00339 46.0608 7.77085C40.4551 23.4079 25.5893 33.1279 9.00524 32.1288C6.86924 31.9858 4.78288 31.6008 2.6494 31.3473C1.90151 31.2619 1.17465 31.2212 0.426755 31.1357C0.335061 31.2881 0.308987 31.4642 0.217293 31.6166C0.540352 31.9564 0.837336 32.4723 1.27564 32.5941C2.69825 33.0725 4.12086 33.5509 5.61412 33.8323C20.4576 36.6875 35.9873 29.7693 44.2742 16.4773C45.8541 13.9319 46.9771 11.1095 48.2734 8.42421C48.551 7.85667 48.7184 7.28632 48.9304 6.695C48.9985 6.60834 49.1533 6.5902 49.3291 6.6168Z"
      fill="#EA6A29"
    />
  </svg>
)

const ArrowLargeSVG = () => (
  <svg width="76" height="50" viewBox="0 0 76 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M71.259 10.004C71.8602 12.7783 72.4952 15.4582 73.1531 18.5193C74.7259 17.5448 75.2026 16.0666 75.0433 14.7307C74.5651 10.7229 73.9929 6.68092 73.0706 2.72516C72.5527 0.460426 70.5683 0.0608148 68.8869 1.63505C66.0432 4.22599 63.354 6.97945 60.5706 9.69879C60.4089 9.8532 60.1832 10.0377 60.1796 10.1962C60.0709 10.7959 60.1205 11.3997 60.106 12.0335C60.6443 12.0156 61.3034 12.2544 61.657 12.0098C62.9099 11.1857 64.0385 10.2632 65.1972 9.40495C65.8406 8.94575 66.3898 8.45243 67.5642 8.29222C67.2226 9.39336 66.9113 10.5587 66.5697 11.6598C58.5267 34.0956 37.1974 48.0417 13.4029 46.6083C10.3382 46.403 7.3447 45.8508 4.28363 45.487C3.21056 45.3644 2.16767 45.306 1.0946 45.1834C0.963043 45.402 0.925631 45.6548 0.79407 45.8734C1.25759 46.3609 1.6837 47.1012 2.31258 47.2758C4.35371 47.9623 6.39484 48.6487 8.53736 49.0524C29.8346 53.149 52.1163 43.2229 64.0063 24.1518C66.273 20.4996 67.8843 16.4501 69.7443 12.5973C70.1426 11.783 70.3827 10.9646 70.6869 10.1162C70.7847 9.99189 71.0068 9.96586 71.259 10.004Z"
      fill="#EA6A29"
    />
  </svg>
)

const Completed: React.FC = () => {
  const { walkthrough, rootUrl } = useEmbedContext()
  const creator = walkthrough.createdBy

  return (
    <div className="relative w-full h-full">
      <div
        className="w-full h-full flex"
        style={{
          background:
            'linear-gradient(90deg, #214A86 0%, #305B9A 25.52%, rgba(46, 46, 46, 0.71) 63.44%, rgba(46, 46, 46, 0.71) 100%)',
        }}
      >
        <div className="flex flex-col my-auto ml-[5%] w-[60%] pb-[5%]">
          <div className="relative flex items-center mb-10 pl-[10px] lg:pl-[25px]">
            <div className="text-base lg:text-xl text-white mr-2 lg:mr-6">Try it Now!</div>
            <div className="text-base lg:text-xl text-white mx-4">Easy to learn!</div>
            <div className="absolute left-0">
              <span className="lg:hidden">
                <OvalNormalSVG />
              </span>
              <span className="hidden lg:inline">
                <OvalLargeSVG />
              </span>
            </div>
            <div className="relative -top-4 lg:-top-6 right-0">
              <span className="lg:hidden">
                <ArrowNormalSVG />
              </span>
              <span className="hidden lg:inline">
                <ArrowLargeSVG />
              </span>
            </div>
          </div>
          <Title>Yay! You watched the video</Title>
          <Description>
            <a
              href={shareLink(walkthrough.uuid, 'walkthrough')}
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Walkthrough
            </a>{' '}
            from {creator.firstName} {creator.lastName} on{' '}
            <a href={rootUrl} className="underline" target="_blank" rel="noreferrer">
              FlowMo
            </a>
          </Description>
        </div>
      </div>
      <div
        className="w-full h-full absolute top-0 left-0"
        style={{
          zIndex: -1,
          backdropFilter: 'blur(2px)',
        }}
      />
    </div>
  )
}

export default Completed
