import React from 'react'
import PageHeader from '../../components/PageHeader'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import HeaderActionButton from '../../components/HeaderActionButton'
import Spin from '../../../../components/common/ui/Spin'
import EmptyState from '../../../../components/Walkthrough/ui/EmptyState'
import DataSetCard from '../../components/DataSetCard'
import { GET_ORGANIZATION_DATA_SETS } from '../../graphql/queries/data_set'
import { useQuery } from '@apollo/client'
import {
  OrganizationDataSetsQuery,
  OrganizationDataSetsQueryVariables,
} from 'app/javascript/components/graphql'

const DataSets = () => {
  const { user } = useSelector((state: RootState) => state.app)
  const { data, loading } = useQuery<OrganizationDataSetsQuery, OrganizationDataSetsQueryVariables>(
    GET_ORGANIZATION_DATA_SETS,
    {
      fetchPolicy: 'cache-and-network',
    }
  )
  const data_sets = data?.organizationDataSets
  return (
    <React.Fragment>
      <PageHeader title="Data Sets" breadCrumbs={[{ title: 'Data Sets' }]}>
        {user?.isOrganizationAdmin && (
          <Link to="/data_set/create">
            <HeaderActionButton label="Create" />
          </Link>
        )}
      </PageHeader>
      {loading ? (
        <Spin />
      ) : (
        <>
          {data_sets?.length === 0 && (
            <EmptyState
              title="No data sets yet"
              description="Create your first data sets and share it with your organization"
            />
          )}

          <div className="flex flex-wrap">
            {data_sets?.map((data_set) => (
              <div key={data_set.uuid} className="m-2">
                <DataSetCard data_set={data_set} />
              </div>
            ))}
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default DataSets
