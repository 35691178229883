import React, { Fragment } from 'react'
import WalkthroughBody from '../WalkthroughBody'
import Step from '../Step'

interface Props {
  steps: any[]
  canEditWalkthrough: boolean
}

const StepList: React.FC<Props> = ({ steps, canEditWalkthrough }) => (
  <WalkthroughBody>
    <div className="text-lg lg:text-xl xl:text-2xl font-bold text-gray-900">Walkthrough steps</div>
    {steps.map((step: any, index: any) => {
      return (
        <Fragment key={index}>
          <Step steps={steps} canEditWalkthrough={canEditWalkthrough} index={index} />
        </Fragment>
      )
    })}
  </WalkthroughBody>
)

export default StepList
