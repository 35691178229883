import { gql } from '@apollo/client'
import Role from '../../models/Role'

export const ROLES_QUERY = gql`
  query allRoles {
    roles: allRoles {
      id
      name
      createdAt
      updatedAt
    }
  }
`

export interface RolesData {
  roles: Role[]
}
