import React, { forwardRef } from 'react'
import { clsx } from 'clsx'

interface Props {
  name: string
  value?: string
  placeholder: string
  onChange: (event: any) => void
  error?: string
  className?: string
  label?: string
  onKeyDown?: (event: any) => void
}

// eslint-disable-next-line react/display-name
const TextInput: React.FC<Props> = forwardRef(
  (
    { name, value, placeholder, onChange, error, className = '', label, onKeyDown }: Props,
    ref: any
  ) => (
    <div>
      {label && <div className="shrink-0 text-gray-400">{label}</div>}

      <input
        type="text"
        name={name}
        className={clsx(
          `focus:border-flowmo-blue-500 border-1 border-dashed block bg-gray-300 rounded-md`,
          error ? 'border-red-700' : '',
          className
        )}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        ref={ref}
        onKeyDown={onKeyDown}
      />

      {error && <span className="walkthrough-form-error text-xs text-red-700">{error}</span>}
    </div>
  )
)

export default TextInput
