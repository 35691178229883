import React from 'react'
import StepCard from '../StepCard'
import TitleDescription from './shared/TitleDescription'
import GoToLink from '../../GoToLink'

const Goto = ({
  step,
  stepNumber,
  isLast,
  index,
}: {
  step: any
  stepNumber: number
  isLast: boolean
  index: number
  canEditWalkthrough: boolean
}) => (
  <StepCard step={step} isLast={isLast} stepNumber={stepNumber}>
    <TitleDescription index={index} title="Navigate To" />
    <GoToLink url={step.url} />
  </StepCard>
)

export default Goto
