import React from 'react'
import BasePluginCard from './BasePluginCard'
import PluginAdd from './modals/PluginAdd'
import { PencilIcon, PlusIcon } from '@heroicons/react/24/solid'
import PluginView from './modals/PluginView'
import PluginEdit from './modals/PluginEdit'
import {
  PluginFieldsFragment,
  PluginTemplateFieldsFragment,
} from 'app/javascript/components/graphql'

export type PluginTemplateDefinitionType = {
  name: string
  label: string
  type: string
  required: boolean
  defaultValue: string | string[]
}

type PluginCardProps = {
  pluginTemplate: PluginTemplateFieldsFragment
  hoverIcon: React.ReactNode
  plugin?: PluginFieldsFragment
  colorful?: boolean
  handleCardClick?: () => void
}

export const AddPluginCard = ({ agentUuid, pluginTemplate, onAction }: any) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <PluginCard
        colorful={false}
        hoverIcon={<PlusIcon />}
        pluginTemplate={pluginTemplate}
        handleCardClick={() => setOpen(true)}
      />

      <PluginAdd
        setOpen={setOpen}
        pluginTemplate={pluginTemplate}
        agentUuid={agentUuid}
        onAction={onAction}
        open={open}
      />
    </>
  )
}

type ViewPluginCardProps = {
  plugin: PluginFieldsFragment
  onAction: () => void
}

export const ViewPluginCard = ({ plugin, onAction }: ViewPluginCardProps) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <PluginView setOpen={setOpen} plugin={plugin} onAction={onAction} open={open} />
    </>
  )
}

type EditPluginCardProps = {
  plugin: PluginFieldsFragment
  onAction: () => void
}

export const EditPluginCard = ({ onAction, plugin }: EditPluginCardProps) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <PluginCard
        handleCardClick={() => setOpen(true)}
        key={plugin.id}
        plugin={plugin}
        pluginTemplate={plugin.pluginTemplate}
        colorful={true}
        hoverIcon={<PencilIcon />}
      />

      <PluginEdit setOpen={setOpen} open={open} plugin={plugin} onAction={onAction} />
    </>
  )
}

export const PluginCard = ({
  hoverIcon,
  plugin,
  pluginTemplate,
  handleCardClick,
  colorful,
}: PluginCardProps) => {
  return (
    <>
      <div className="relative group" onClick={handleCardClick}>
        <BasePluginCard
          colorful={colorful}
          plugin={plugin}
          pluginTemplate={pluginTemplate}
          handleCardClick={handleCardClick}
        />
        <div className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 m-1 flex items-center justify-center transition-opacity duration-300 hover:cursor-pointer">
          <div className="h-12 w-12 text-flowmo-blue-700 rounded-full bg-white p-2 opacity-75 font-bold">
            {hoverIcon}
          </div>
        </div>
      </div>
    </>
  )
}
