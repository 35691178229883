import { gql } from '@apollo/client'
import { DATA_SET_FIELDS } from './fragments'

export const CREATE_DATA_SET_MUTATION = gql`
  mutation createDataSet($name: String!, $description: String!, $definition: JSON!) {
    createDataSet(name: $name, description: $description, definition: $definition) {
      dataSet {
        ...DataSetFields
      }
      success
      errors
    }
  }
  ${DATA_SET_FIELDS}
`

export const DELETE_DATA_SET_MUTATION = gql`
  mutation deleteDataSet($uuid: String!) {
    deleteDataSet(uuid: $uuid) {
      success
      errors
    }
  }
`

export const UPDATE_DATA_SET_MUTATION = gql`
  mutation updateDataSet(
    $uuid: String!
    $name: String!
    $description: String!
    $definition: JSON!
  ) {
    updateDataSet(uuid: $uuid, name: $name, description: $description, definition: $definition) {
      success
      errors
    }
  }
`

export const GET_DATA_SET_QUERY = gql`
  query dataSet($uuid: String!) {
    dataSet(uuid: $uuid) {
      ...DataSetFields
    }
  }
  ${DATA_SET_FIELDS}
`

export const GET_AGENT_DATA_SETS = gql`
  query agentDataSets($uuid: String!) {
    agentDataSets(uuid: $uuid) {
      ...DataSetFields
    }
  }
  ${DATA_SET_FIELDS}
`

export const GET_ORGANIZATION_DATA_SETS = gql`
  query organizationDataSets {
    organizationDataSets {
      ...DataSetFields
    }
  }
  ${DATA_SET_FIELDS}
`

export const ADD_DATASET_TO_AGENT_MUTATION = gql`
  mutation addDataSetToAgent($agentUuid: String!, $dataSetUuid: String!) {
    addDataSetToAgent(agentUuid: $agentUuid, dataSetUuid: $dataSetUuid) {
      success
      errors
    }
  }
`

export const REMOVE_DATASET_FROM_AGENT_MUTATION = gql`
  mutation removeDataSetFromAgent($agentUuid: String!, $dataSetUuid: String!) {
    removeDataSetFromAgent(agentUuid: $agentUuid, dataSetUuid: $dataSetUuid) {
      success
      errors
    }
  }
`

export const REFRESH_DATA_SET_MUTATION = gql`
  mutation refreshDataSet($uuid: String!) {
    refreshDataSet(uuid: $uuid) {
      success
      errors
    }
  }
`
