import React, { useEffect, useState } from 'react'
import Step from '../../Step'

interface Props {
  steps: any[]
  canEditWalkthrough: boolean
  usePublicEndpoint?: boolean
}

const stepsAreVisible = 3

const PublicStepList: React.FC<Props> = ({ steps, canEditWalkthrough }) => {
  const [showMore, setShowMore] = useState(false)
  const [visible, setVisible] = useState(stepsAreVisible)

  const handleClick = () => {
    setShowMore(!showMore)
  }

  const visibleSteps = steps.slice(0, visible)

  useEffect(() => {
    setVisible(showMore ? steps.length : stepsAreVisible)
  }, [showMore])
  return (
    <div className="max-w-7xl mx-auto">
      <div className="text-xl font-bold text-gray-900 mb-6">Walkthrough steps</div>
      {visibleSteps.map((step: any, index: any) => {
        return (
          <div className="mt-10" key={index}>
            <Step steps={steps} canEditWalkthrough={canEditWalkthrough} index={index} />
          </div>
        )
      })}
      {steps.length > stepsAreVisible && (
        <div className="text-center z-30 bg-white relative pt-[30px]">
          <span
            className="text-[#1B72F2] hover:text-blue-700 font-normal	font-inter text-base underline cursor-pointer"
            onClick={handleClick}
          >
            {showMore ? 'Show less' : 'Show all steps'}
          </span>
        </div>
      )}
    </div>
  )
}

export default PublicStepList
