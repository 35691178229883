import React from 'react'
import { hot } from 'react-hot-loader'
import client from '../common/ApolloClient'
import { ApolloProvider } from '@apollo/client/react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import AppRouter from './routes'

import '@szhsin/react-menu/dist/transitions/slide.css'
import '@szhsin/react-menu/dist/index.css'
import { QueryParamProvider } from 'use-query-params'
import * as Sentry from '@sentry/react'
import '../common/sentry'
import ScrollToTop from '../common/components/ScrollToTop'

const App = () => {
  return <AppRouter />
}

const AppProviders = (props: any) => (
  <Sentry.ErrorBoundary>
    <ApolloProvider client={client}>
      <Router basename="/internal">
        <QueryParamProvider ReactRouterRoute={Route}>
          <ScrollToTop />
          <App {...props} />
        </QueryParamProvider>
      </Router>
    </ApolloProvider>
  </Sentry.ErrorBoundary>
)

export default hot(module)(AppProviders)
