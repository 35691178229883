import {
  CreateWorkflowFromTemplateMutation,
  CreateWorkflowFromTemplateMutationVariables,
  WorkflowTemplatesQuery,
} from 'app/javascript/components/graphql'
import React from 'react'
import { CREATE_WORKFLOW_FROM_TEMPLATE } from '../../graphql/queries/workflow'
import { useMutation } from '@apollo/client'
import { CubeTransparentIcon, PlusIcon } from '@heroicons/react/24/solid'
import { clsx } from 'clsx'
import Spin from '../../../../components/common/ui/Spin'

interface WorkflowTemplateCardProps {
  agentUuid: string
  workflowTemplate: WorkflowTemplatesQuery['workflowTemplates'][0]
  onUpdate: () => void
}

const WorkflowTemplateCard = ({
  agentUuid,
  workflowTemplate,
  onUpdate,
}: WorkflowTemplateCardProps) => {
  const [installing, setInstalling] = React.useState(false)

  const [createWorkflowFromTemplate] = useMutation<
    CreateWorkflowFromTemplateMutation,
    CreateWorkflowFromTemplateMutationVariables
  >(CREATE_WORKFLOW_FROM_TEMPLATE)

  const handleInstall = async () => {
    setInstalling(true)
    const response = await createWorkflowFromTemplate({
      variables: {
        agentUuid: agentUuid,
        templateUuid: workflowTemplate.uuid,
      },
    })
    if (response.data.createWorkflowFromTemplate.errors.length > 0) {
      window.toastr.error('There was an error installing the workflow template.')
    } else {
      window.toastr.success('Workflow template installed successfully.')
    }
    onUpdate()
    setInstalling(false)
  }
  const colorful = false
  let style = {
    backgroundImage:
      'conic-gradient(from -49.8deg at 50% 50%, #8c8b8e 0deg, #dceef8 59.07deg, #535469 185.61deg, #637f86 284.23deg, #aba8ac 329.41deg, #605871 360deg)',
  }
  if (colorful) {
    style = {
      backgroundImage:
        'conic-gradient(from -49.8deg at 50% 50%, #7331FF 0deg, #00A3FF 59.07deg, #4E51FF 185.61deg, #39DBFF 284.23deg, #B84FF1 329.41deg, #7331FF 360deg)',
    }
  }
  return (
    <div className="relative group h-32 w-64 " onClick={handleInstall}>
      <div
        className="relative flex  h-32 w-64 items-center justify-center rounded-2xl p-1 shadow-lg hover:shadow-xl transition duration-300 ease-in-out"
        style={style}
      >
        <div className="relative bg-white/90 h-full w-full rounded-xl p-2">
          <div className="absolute bottom-2 left-2 flex flex-row items-center space-x-2">
            <CubeTransparentIcon
              className={clsx('h-6 w-6', colorful ? ' text-flowmo-blue-600' : 'text-gray-500')}
            />
            <div className="text-gray-900 font-semibold">Workflow Template</div>
          </div>
          <div className="text-gray-900 font-semibold text-sm truncate mb-1">
            {workflowTemplate.name}
          </div>
          <div className="text-gray-600 text-sm line-clamp-2">{workflowTemplate.description}</div>
        </div>
      </div>
      <div className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 m-1 flex items-center justify-center transition-opacity duration-300 hover:cursor-pointer">
        <div className="h-12 w-12 text-flowmo-blue-700 rounded-full bg-white p-2 opacity-75 font-bold">
          {installing ? <Spin /> : <PlusIcon />}
        </div>
      </div>
    </div>
  )
}

export default WorkflowTemplateCard
