import { createSlice } from '@reduxjs/toolkit'

export interface LayoutSettingsState {
  isSidebarExpanded: boolean
}

const initialState: LayoutSettingsState = {
  isSidebarExpanded: true,
}

export const layoutSettingsSlice = createSlice({
  name: 'layoutSettings',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarExpanded = !state.isSidebarExpanded
    },
  },
})

export const { toggleSidebar } = layoutSettingsSlice.actions

export default layoutSettingsSlice.reducer
