import React from 'react'
import Container from './Container'
import RecordWalkthroughOption from './option/RecordWalkthroughOption'
import InstallExtensionOption from './option/InstallExtensionOption'
import BrowseLibraryOption from './option/BrowseLibraryOption'
import SupportOption from './option/SupportOption'

const Options = () => (
  <Container>
    <RecordWalkthroughOption />
    <InstallExtensionOption />
    <BrowseLibraryOption />
    <SupportOption />
  </Container>
)

export default Options
