import React from 'react'

const ThumbnailImage = ({
  imageUrl,
  width,
  height,
  className = '',
}: {
  imageUrl: string
  className?: string
  width?: number
  height?: number
}) => {
  let cloudUrl = null
  if (imageUrl) {
    const filePath = imageUrl.split('/').slice(-2).join('/')
    let resizeInstructions = 'c_fill'
    if (width) {
      resizeInstructions += ',w_' + width
    }
    if (height) {
      resizeInstructions += ',h_' + height
    }
    cloudUrl =
      'https://res.cloudinary.com/data-driven/image/upload/' + resizeInstructions + '/' + filePath
  }

  return <>{cloudUrl && <img src={cloudUrl} className={className} />}</>
}

export default ThumbnailImage
