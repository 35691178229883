import React from 'react'
import { clsx } from 'clsx'

export const Sizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
}

const SizeClassNames = {
  [Sizes.SMALL]: 'w-[24px] h-[24px]',
  [Sizes.MEDIUM]: 'w-[34px] h-[34px]',
}

interface Props {
  size: (typeof Sizes)[keyof typeof Sizes]
  children?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
}

const IconButton = ({ children, size, onClick, disabled }: Props) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={clsx(
      SizeClassNames[size],
      'text-white transition-colors flex items-center justify-center',
      'border border-transparent hover:border-[rgba(255,255,255,0.26)]',
      'bg-transparent active:bg-[rgba(255,255,255,0.26)] rounded-full',
      'disabled:text-[#BFBFBF] disabled:hover:text-[#BFBFBF] disabled:active:text-[#BFBFBF]',
      'disabled:hover:border-transparent disabled:active:border-transparent',
      'disabled:active:bg-transparent cursor-pointer'
    )}
  >
    {children}
  </button>
)

export default IconButton
