import { CheckCircleIcon, LinkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { shareLink } from '../../utils/links'
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard'

type Props = {
  objectUuid: string
  objectType: 'walkthrough' | 'skillSet'
  className: string
}

const ShareButton: React.FC<Props> = ({ objectUuid, objectType, className }) => {
  const shareUrl = new URL(shareLink(objectUuid, objectType), document.baseURI).href
  const [copyTextStatus, copyText] = useCopyToClipboard(shareUrl, 2000)
  const isTextStatusCopied = copyTextStatus === 'copied'

  return (
    <div className="flex whitespace-nowrap">
      <button
        type="button"
        className={`${className} py-2 md:py-[9px] px-4 md:px-6 inline-flex items-center text-white text-[13px] sm:text-sm hover:bg-green-400 ${
          isTextStatusCopied ? 'bg-[#18D987]' : 'bg-[#29BC7E]'
        }`}
        onClick={copyText}
      >
        Copy <span className="hidden sm:block ml-1">link</span>
        {isTextStatusCopied ? (
          <CheckCircleIcon className="h-4 w-4 text-white ml-2" />
        ) : (
          <LinkIcon className="h-4 w-4 ml-2" />
        )}
      </button>
    </div>
  )
}

export default ShareButton
