import React, { useEffect, useState } from 'react'
import * as style from './style.module.scss'
import clsx from 'clsx'

interface Props {
  element?: HTMLElement
}

const arrow = {
  common: {
    position: 'aboslute',
  },
}

const CHEVRON_WIDTH = 40

const ChevronScroll: React.FC<Props> = ({ element }) => {
  const [chevronStyle, setChevronStyle] = useState<Record<string, any>>({ display: 'none' })
  const onClick = () => element && element.scrollIntoView({ behavior: 'smooth', block: 'center' })

  useEffect(() => {
    const interval = setInterval(() => {
      const currentChevronStyle = getStyle()
      setChevronStyle(currentChevronStyle)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [element])

  const getStyle = () => {
    if (element) {
      const rect = element.getBoundingClientRect()
      const innerWidth = element?.ownerDocument?.defaultView?.innerWidth || window.innerWidth
      const innerHeight = element?.ownerDocument?.defaultView?.innerHeight || window.innerHeight

      if (rect.bottom < 0) {
        return {
          ...arrow.common,
          transform: 'rotate(180deg)',
          top: 45,
          left: innerWidth / 2 - CHEVRON_WIDTH + 15,
        }
      } else if (rect.top > innerHeight) {
        return {
          ...arrow.common,
          bottom: 0,
          left: innerWidth / 2 - CHEVRON_WIDTH + 15,
        }
      }
    }
    return { display: 'none' }
  }

  return (
    <div className={style['labs-chevron-scroll']} style={chevronStyle} onClick={onClick}>
      <div className={clsx(style['new-arrows'], style['black'])} />
    </div>
  )
}

export default ChevronScroll
