import { useEffect, useRef } from 'react'

export default (eventName: string, handler: (event: any) => void, element = window) => {
  const savedHandler = useRef<(event: any) => void>(handler)

  useEffect(() => {
    const eventListener = (event: any) => savedHandler.current(event)

    element.addEventListener(eventName, eventListener)

    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}
