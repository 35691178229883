import React from 'react'
import { ActiveFilter, Filter } from '../types'
import OneActiveFilter from './OneActiveFilter'

interface Props {
  filters: Filter[]
  activeFilters: ActiveFilter[]
  handleRemoveFilter: (name: string, value: string) => void
}

const ActiveFilters: React.FC<Props> = ({ filters, activeFilters, handleRemoveFilter }) => {
  const cleanActiveFilters = activeFilters.filter((activeFilter) =>
    filters.some((filter) => filter.name === activeFilter.name)
  )

  return (
    <div className="bg-gray-100">
      <div
        className="max-w-7xl mx-auto py-2 px-4 sm:flex sm:items-center sm:px-6 lg:px-8"
        style={{ minHeight: '50px' }}
      >
        <h3 className="text-xs font-semibold uppercase tracking-wide text-gray-500">
          Filters
          <span className="sr-only">, active</span>
        </h3>

        <div aria-hidden="true" className="hidden w-px h-5 bg-gray-300 sm:block sm:ml-4" />

        <div className="mt-2 sm:mt-0 sm:ml-4">
          <div className="-m-1 flex flex-wrap items-center">
            {cleanActiveFilters.map((activeFilter, index) => (
              <OneActiveFilter
                activeFilter={activeFilter}
                key={index}
                handleRemoveFilter={handleRemoveFilter}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActiveFilters
