import anime from 'animejs'

export const applyWithoutAnimation = () =>
  anime
    .timeline({
      loop: true,
    })
    .add(
      {
        targets: '.without-description .circle-1',
        backgroundColor: [
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 100,
            duration: 100,
            easing: 'easeInOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 1)',
            delay: 300 + 100 + 100 + 100 + 300,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300,
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 200,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 1)',
            delay: 100 + 100 + 100 + 300,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.without-description .circle-2',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.47)',
            delay: 200,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 1)',
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0.34)',
            delay: 300 + 100 + 100,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 1)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.without-description .circle-3',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.5)',
            delay: 500,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300 + 100 + 100,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0.5)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.without-description .circle-4',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.5)',
            delay: 200,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300 + 100 + 100 + 100,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0.5)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.without-description .circle-5',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.3)',
            delay: 500,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300 + 100 + 100,
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0.3)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300,
            easing: 'easeInCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.without-description .circle-6',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.1)',
            delay: 500,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 100,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 1600,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.without-description.circle-7',
        borderColor: [
          {
            value: 'rgba(234, 106, 41, 0.4)',
            delay: 800,
            duration: 100,
            easing: 'easeInOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            delay: 300 + 100 + 300 + 100,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 'rgba(234, 106, 41, 0)',
            duration: 300 + 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.without-description',
        opacity: [
          {
            delay: 1600,
            duration: 300,
            value: 1,
            easing: 'easeOutCubic',
          },
          {
            value: 1,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.without-description .beacon-icon',
        opacity: [
          {
            delay: 1900,
            duration: 300,
            value: 1,
            easing: 'easeOutCubic',
          },
          {
            value: 1,
            duration: 300 + 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.without-description .beacon-icon svg',
        opacity: [
          {
            delay: 2100,
            duration: 300,
            value: 1,
            easing: 'easeOutCubic',
          },
          {
            value: 1,
            duration: 300 + 300,
          },
        ],
      },
      0
    )
    .add(
      {
        targets:
          '.beacon.without-description.beacon-right.beacon-top, .beacon.without-description.beacon-right.beacon-default',
        translateX: [
          {
            value: 25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        translateY: [
          {
            value: -25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: -25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        borderBottomLeftRadius: [
          {
            value: 0,
            delay: 1900,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 0,
            duration: 900,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.without-description.beacon-right.beacon-bottom',
        translateX: [
          {
            value: 25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        translateY: [
          {
            value: 25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        borderTopLeftRadius: [
          {
            value: 0,
            delay: 1900,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 0,
            duration: 900,
          },
        ],
      },
      0
    )
    .add(
      {
        targets:
          '.beacon.without-description.beacon-left.beacon-top, .beacon.without-description.beacon-left.beacon-default',
        translateX: [
          {
            value: -25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: -25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        translateY: [
          {
            value: -25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: -25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        borderBottomRightRadius: [
          {
            value: 0,
            delay: 1900,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 0,
            duration: 900,
          },
        ],
      },
      0
    )
    .add(
      {
        targets: '.beacon.without-description.beacon-left.beacon-bottom',
        translateX: [
          {
            value: -25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: -25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        translateY: [
          {
            value: 25,
            delay: 1600,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 25,
            duration: 2700,
          },
          {
            value: 0,
            duration: 300,
            easing: 'easeOutCubic',
          },
        ],
        borderTopRightRadius: [
          {
            value: 0,
            delay: 1900,
            duration: 300,
            easing: 'easeOutCubic',
          },
          {
            value: 0,
            duration: 900,
          },
        ],
      },
      0
    )
