import { useQuery } from '@apollo/client'
import {
  AgentKnowledgeQuery,
  AgentKnowledgeQueryVariables,
  AgentQuery,
  AgentQueryVariables,
} from 'app/javascript/components/graphql'
import React from 'react'
import { GET_AGENT_KNOWLEDGE, GET_AGENT_QUERY } from '../../../graphql/queries/agent'
import Spin from '../../../../../components/common/ui/Spin'
import { ChevronDoubleLeftIcon, DocumentTextIcon, FolderIcon } from '@heroicons/react/24/solid'
import KnowledgeItem from './KnowledgeItem'
import clsx from 'clsx'
import AgentHeader from '../OneAgent/AgentHeader'

interface AgentKnowledgeProps {
  uuid: string
}

const AgentKnowledge = ({ uuid }: AgentKnowledgeProps) => {
  const [knowledgeItemUuid, setKnowledgeItemUuid] = React.useState<string | null>(null)

  // get the agent information
  const { data, loading } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    variables: { uuid },
  })

  // get all the associated content
  const { data: contentData, loading: contentLoading } = useQuery<
    AgentKnowledgeQuery,
    AgentKnowledgeQueryVariables
  >(GET_AGENT_KNOWLEDGE, {
    variables: { uuid },
  })
  const agent = data?.agent
  if (loading) {
    return <Spin />
  }

  document.title = agent.label + ' - Agent Knowledge - FlowMo'

  const folders: Record<string, AgentKnowledgeQuery['agentKnowledge']['0'][]> = {}
  contentData?.agentKnowledge.forEach((knowledge) => {
    if (!folders[knowledge.sourceType]) {
      folders[knowledge.sourceType] = []
    }
    folders[knowledge.sourceType].push(knowledge)
  })

  // sort the items in each folder by the sourceName
  Object.entries(folders).forEach(([, items]) => {
    items.sort((a, b) => a.sourceName.localeCompare(b.sourceName))
  })

  const handleItemClick = (itemUuid: string) => setKnowledgeItemUuid(itemUuid)

  return (
    <>
      <AgentHeader agent={agent} />

      <div className="relative flex flex-row mt-10 px-16">
        <div className="w-64">
          <div className="text-base mb-4 font-semibold text-gray-600">Content</div>
          <div className="flex flex-col text-sm items-center space-y-4">
            {contentLoading ? (
              <Spin />
            ) : (
              <>
                {Object.entries(folders).map(([folder, items]) => (
                  <div key={folder} className="flex flex-col">
                    <div className="flex flex-row items-center space-x-2 text-sm text-gray-500">
                      <FolderIcon className="h-4 w-4" />
                      <div className="text-sm">{folder}</div>
                    </div>

                    <div className="mt-2 text-xs">
                      {items.map((item) => (
                        <div
                          key={item.uuid}
                          className={clsx(
                            knowledgeItemUuid === item.uuid
                              ? 'bg-flowmo-blue-200 text-gray-800'
                              : 'text-gray-500 hover:bg-gray-100',
                            'flex flex-row cursor-pointer pr-1 text-xs hover:text-gray-800 hover:underline'
                          )}
                          onClick={() => handleItemClick(item.uuid)}
                        >
                          <div className="flex flex-row space-x-1">
                            <div
                              className={clsx(
                                knowledgeItemUuid === item.uuid ? 'bg-flowmo-blue-500' : '',
                                'w-2'
                              )}
                            ></div>
                            <div className="py-2 flex flex-row space-x-1">
                              <div className="w-4">
                                <DocumentTextIcon className="size-4 text-gray-400" />
                              </div>
                              <div className="">{item.sourceName}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="pl-4 w-full border-l border-gray-200">
          {knowledgeItemUuid === null ? (
            <div className="flex flex-row text-gray-500 text-lg items-center h-full space-x-2 ml-4">
              <ChevronDoubleLeftIcon className="size-6" />
              <div className="text-lg">Select a piece of content to view</div>
            </div>
          ) : (
            <KnowledgeItem agentUuid={uuid} itemUuid={knowledgeItemUuid} />
          )}
        </div>
      </div>
    </>
  )
}

export default AgentKnowledge
