import { useEffect, useRef } from 'react'

const BEACON_SIZE = 30
const BEACON_RADIUS = BEACON_SIZE / 2
const BEACON_OFFSET = 25
const BEACON_MAX_WIDTH = 225
const BEACON_MAX_HEIGHT = 230
const HEADER_HEIGHT = 55

const getLeftPosition = (position: DOMRect) => {
  return { left: position.left + position.width / 2 - BEACON_RADIUS }
}

const getRightPosition = (position: DOMRect) => {
  return { right: window.innerWidth - position.right + position.width / 2 - BEACON_RADIUS }
}

const getTopPosition = (position: DOMRect) => {
  return { top: position.top + 2 + position.height / 2 - BEACON_RADIUS }
}

const getBottomPosition = (position: DOMRect) => {
  return { bottom: window.innerHeight - position.bottom + 2 + position.height / 2 - BEACON_RADIUS }
}

const getCenterPosition = (position: DOMRect) => ({
  left: position.left + position.width / 2,
  right: window.innerWidth - position.right + position.width / 2,
  top: position.top + position.height / 2,
  bottom: window.innerHeight - position.bottom + position.height / 2,
})

export const VERTICAL_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
  DEFAULT: 'default',
}

const verticalPosition = {
  [VERTICAL_POSITION.BOTTOM]: getTopPosition,
  [VERTICAL_POSITION.DEFAULT]: getTopPosition,
  [VERTICAL_POSITION.TOP]: getBottomPosition,
}

export const HORIZONTAL_POSITION = {
  RIGHT: 'right',
  LEFT: 'left',
}

const horizontalPosition = {
  [HORIZONTAL_POSITION.LEFT]: getRightPosition,
  [HORIZONTAL_POSITION.RIGHT]: getLeftPosition,
}

const getHorizontalPosition = (position: DOMRect) => {
  const center = getCenterPosition(position)

  if (center.right - BEACON_OFFSET - BEACON_MAX_WIDTH > 0) {
    return HORIZONTAL_POSITION.RIGHT
  } else {
    return HORIZONTAL_POSITION.LEFT
  }
}

const getVerticalPosition = (position: DOMRect) => {
  const center = getCenterPosition(position)

  if (
    center.top - BEACON_OFFSET - BEACON_SIZE - HEADER_HEIGHT > 0 &&
    center.bottom + BEACON_OFFSET - BEACON_MAX_HEIGHT > 0
  ) {
    return VERTICAL_POSITION.DEFAULT
  }

  if (center.top - BEACON_OFFSET - BEACON_SIZE - HEADER_HEIGHT <= 0) {
    return VERTICAL_POSITION.BOTTOM
  }

  return VERTICAL_POSITION.TOP
}

const getBeaconPosition = (position: DOMRect) => {
  const horizontal = getHorizontalPosition(position)
  const vertical = getVerticalPosition(position)

  const styles = {
    ...horizontalPosition[horizontal](position),
    ...verticalPosition[vertical](position),
  }

  return {
    horizontal,
    vertical,
    position: toPureStyles(styles),
  }
}

const toPureStyles = (position: Record<string, number>) => {
  return Object.keys(position).reduce((styles, key) => {
    const newStyles = { ...styles }
    newStyles[key] = `${position[key]}px`
    return newStyles
  }, {} as any)
}

const useBeaconPosition = (position?: DOMRect) => {
  const beaconRef = useRef<HTMLDivElement>(null)

  const styles = position && getBeaconPosition(position)

  useEffect(() => {
    if (styles && beaconRef.current) {
      Object.assign(beaconRef.current.style, styles.position)
    }
  }, [styles, beaconRef.current])

  return { beaconRef, styles }
}

export default useBeaconPosition
