import React, { useEffect, useState } from 'react'
import { SortOption, ActiveFilter } from './types'
import Container from './container'
import Filters from './Filters'
import Sort from './sort'
import ActiveFilters from './ActiveFilters'

interface Props {
  defaultSortOption?: {
    sortBy: string
    sortDirection: string
  }
  defaultFilterValues?: {
    name: string
    value: string
  }[]
  handleSort: (sortOption: SortOption) => void
  handleFilters: (filters: ActiveFilter[]) => void
}

const FilterPanel: React.FC<Props> = ({
  defaultSortOption,
  defaultFilterValues,
  handleSort,
  handleFilters,
}) => {
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>([])

  const sortOptions = [
    { label: 'Newest', sortBy: 'created_at', sortDirection: 'DESC' },
    { label: 'Oldest', sortBy: 'created_at', sortDirection: 'ASC' },
    { label: 'Name: A to Z', sortBy: 'name', sortDirection: 'ASC' },
    { label: 'Name: Z to A', sortBy: 'name', sortDirection: 'DESC' },
  ]

  const filterOptions = [
    {
      name: 'contentType',
      label: 'Content Type',
      options: [
        { value: 'Walkthrough', label: 'Walkthrough' },
        { value: 'SkillSet', label: 'Skill Set' },
      ],
    },
  ]

  if (!defaultSortOption) {
    defaultSortOption = sortOptions[0]
  }

  if (!defaultFilterValues) {
    defaultFilterValues = []
  }

  useEffect(() => {
    setActiveFilters(
      defaultFilterValues.map((filter) => ({
        name: filter.name,
        value: filter.value,
        label: filter.value,
      }))
    )
  }, [])

  const cleanDefaultSortOption =
    sortOptions.find((option) => option.sortBy === defaultSortOption.sortBy) || sortOptions[0]

  const handleRemoveFilter = (name: string, value: string) => {
    const oneFilter = filterOptions.find((filter) => filter.name === name)
    const oneFilterValue = oneFilter?.options.find((option) => option.value === value)

    if (!oneFilter || !oneFilterValue) {
      return
    }

    const newActiveFilters = activeFilters.filter((f) => f.name !== name || f.value !== value)
    setActiveFilters(newActiveFilters)
    handleFilters(newActiveFilters)
  }

  const handleAddFilter = (name: string, value: string) => {
    const oneFilter = filterOptions.find((filter) => filter.name === name)
    const oneFilterValue = oneFilter?.options.find((option) => option.value === value)

    if (!oneFilter || !oneFilterValue) {
      return
    }

    const oneActiveFilter: ActiveFilter = {
      name,
      value: oneFilterValue.value,
      label: oneFilterValue.label,
    }
    const newActiveFilters = [...activeFilters, oneActiveFilter]
    setActiveFilters(newActiveFilters)
    handleFilters(newActiveFilters)
  }

  return (
    <>
      <Container>
        <Sort
          defaultSortOption={cleanDefaultSortOption}
          sortOptions={sortOptions}
          handleSort={handleSort}
        />
        <Filters
          filters={filterOptions}
          activeFilters={activeFilters}
          handleAddFilter={handleAddFilter}
          handleRemoveFilter={handleRemoveFilter}
        />
      </Container>
      <ActiveFilters
        filters={filterOptions}
        activeFilters={activeFilters}
        handleRemoveFilter={handleRemoveFilter}
      />
    </>
  )
}

export default FilterPanel
