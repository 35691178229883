import React, { useCallback } from 'react'
import * as styles from './style.module.scss'
import { setControllerIsMinimized } from '../../slices/playerSlice'
import { useDispatch } from 'react-redux'

type ControllerUIType = React.PropsWithChildren & {
  disabled?: boolean
  isMinimized?: boolean
}

export const ControllerUI = ({ children, disabled, isMinimized }: ControllerUIType) => {
  const dispatch = useDispatch()
  const onHover = useCallback(() => {
    if (isMinimized) {
      dispatch(setControllerIsMinimized(false))
    }
  }, [isMinimized])
  return (
    <div
      className={
        styles['flow-mo-controller'] +
        ' ' +
        (disabled ? styles['flow-mo-disabled'] : '') +
        ' ' +
        (isMinimized ? styles['flow-mo-minimized'] : '')
      }
      onMouseOver={onHover}
    >
      {children}
    </div>
  )
}
