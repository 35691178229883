import React from 'react'
import Card from '../Card'
import SkillSetCard from '../../../../SkillSet/ui/SkillSet/Card'

interface Props {
  content: any
}

const List: React.FC<Props> = ({ content }) => {
  return (
    <ul className="inter-font grid grid-cols-1 gap-y-16 gap-x-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 mb-10">
      {content.map((oneContent: any, index: number) =>
        oneContent.__typename === 'Walkthrough' ? (
          <Card walkthrough={oneContent} key={index} />
        ) : (
          <SkillSetCard skillSet={oneContent} key={index} />
        )
      )}
    </ul>
  )
}

export default List
