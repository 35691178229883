import React from 'react'
import { ActiveFilter, Filter } from '../types'
import MobileFilters from './mobile'
import DesktopFilters from './desktop'

interface Props {
  filters: Filter[]
  activeFilters: ActiveFilter[]
  handleAddFilter: (name: string, value: string) => void
  handleRemoveFilter: (name: string, value: string) => void
}

const Filters: React.FC<Props> = ({
  filters,
  activeFilters,
  handleAddFilter,
  handleRemoveFilter,
}) => (
  <>
    <MobileFilters
      filters={filters}
      activeFilters={activeFilters}
      handleAddFilter={handleAddFilter}
      handleRemoveFilter={handleRemoveFilter}
    />
    <DesktopFilters
      filters={filters}
      activeFilters={activeFilters}
      handleAddFilter={handleAddFilter}
      handleRemoveFilter={handleRemoveFilter}
    />
  </>
)

export default Filters
