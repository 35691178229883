import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ShareIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import Divider from './Divider'
import { Walkthrough, SkillSet } from 'app/javascript/components/graphql'
import { useMutation } from '@apollo/client'
import { SHARE_WALKTHROUGH_QUERY } from '../../App/graphql/queries/walkthrough'
import { SHARE_SKILL_SET_QUERY } from '../../App/graphql/queries/skill_set'
import useCurrentOrganization from '../../App/hooks/useCurrentOrganization'
import ShareButton from '../components/ShareButton'

const shareOptions = [
  {
    key: 'private',
    name: 'Private to you',
    description: 'Restrict the Walkthrough access',
  },
  {
    key: 'organization',
    name: 'Private to your organization',
    description: 'Anyone in your organization',
  },
  {
    key: 'public',
    name: 'Anyone with the link',
    description: 'Anyone on the internet with the link can view',
  },
]

const getSharedOptionName = (key: string) => shareOptions.find((el) => el.key === key)

const QUERIES = {
  walkthrough: SHARE_WALKTHROUGH_QUERY,
  skillSet: SHARE_SKILL_SET_QUERY,
}

const getSharingPermissions = (object: Walkthrough | SkillSet) => {
  let permissionName
  if ('skillSet' in object && object.skillSet) {
    permissionName = object.skillSet.isInMyOrganizationLibrary
      ? getSharedOptionName('organization')
      : getSharedOptionName(object.skillSet.sharedAccess)
  } else if (object.isInMyOrganizationLibrary) {
    permissionName = getSharedOptionName('organization')
  } else {
    permissionName = getSharedOptionName(object.sharedAccess)
  }

  return permissionName
}

type Props = {
  object: Walkthrough | SkillSet
  objectType: 'walkthrough' | 'skillSet'
}

const SharingSection: React.FC<Props> = ({ object, objectType }) => {
  const organization = useCurrentOrganization()
  const sharedAccess = getSharingPermissions(object)
  const [shareObject] = useMutation(QUERIES[objectType])

  const handleSelect = (shareType: any) => {
    shareObject({
      variables: { id: object.id, shareType: shareType, organizationId: organization.id },
    })
  }

  return (
    <Listbox value={sharedAccess.key} onChange={handleSelect}>
      <div className="flex w-full">
        <Listbox.Button className="relative rounded-l-md cursor-pointer flex items-center w-full border border-r-0 bg-red py-2 pl-3 pr-2 text-left focus:outline-none focus-visible:border-flowmo-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
          <ShareIcon className="h-4 w-4 hidden sm:inline-block" />
          <Divider className="border-r h-5 mx-2 hidden sm:block" />
          <span className="block whitespace-nowrap ml-0 mr-4 w-44 text-[13px]">
            {sharedAccess.name}
          </span>
          {(('skillSet' in object && !object.skillSet) || objectType === 'skillSet') &&
            !object.isInMyOrganizationLibrary &&
            object.canEdit && (
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            )}
        </Listbox.Button>
        {(('skillSet' in object && !object.skillSet) || objectType === 'skillSet') &&
          !object.isInMyOrganizationLibrary &&
          object.canEdit && (
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute divide-y z-10 mt-10 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {shareOptions.map((option) => (
                  <Listbox.Option
                    key={option.key}
                    className={({ active }) =>
                      `cursor-pointer select-none py-2 px-3.5 pr-4 ${
                        active ? 'bg-[#F9F9F9]' : 'text-gray-900'
                      }`
                    }
                    value={option.key}
                  >
                    {({ selected }) => (
                      <div className="flex flex-col space-y-0.5">
                        <div
                          className={`text-sm ${
                            selected ? 'text-flowmo-blue-600' : 'text-[#2C2C2C]'
                          }`}
                        >
                          {option.name}
                        </div>
                        <div className="text-sm font-normal text-[#646464]">
                          {option.description}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          )}
        <ShareButton
          objectUuid={object.uuid}
          objectType={objectType}
          className="rounded-r-md border-l-0"
        />
      </div>
    </Listbox>
  )
}

export default SharingSection
