import React, { useEffect, useRef, useState } from 'react'
import { Walkthrough, SkillSet } from 'app/javascript/components/graphql'
import useRRWebEvents from '../../../../../hooks/useRRWebEvents'
import PublicPlayer from './PublicPlayer'
import useWindowSize from '../../../../../../AI/hooks/useWindowSize'

interface Props {
  walkthrough: Walkthrough
  skillSets?: SkillSet[]
}

const PublicWalkthroughContent: React.FC<Props> = ({ walkthrough }) => {
  const ref = useRef<HTMLDivElement>(null)
  const sizes = useWindowSize()
  const [fullscreen, setFullscreen] = useState<boolean>(!!document.fullscreenElement)

  useEffect(() => {
    const fullscreenChanged = () => {
      setFullscreen(!!document.fullscreenElement)
    }

    document.addEventListener('fullscreenchange', fullscreenChanged)

    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChanged)
    }
  }, [])

  const chunks = useRRWebEvents(walkthrough)
  return (
    <div className="w-full overflow-hidden" ref={ref}>
      <PublicPlayer
        chunks={chunks}
        walkthrough={walkthrough}
        maxHeight={fullscreen ? sizes.height - 60 : 750}
      />
    </div>
  )
}

export default PublicWalkthroughContent
