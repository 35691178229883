import { useMutation, useQuery } from '@apollo/client'
import {
  AddSkillSetToAgentMutation,
  OrganizationAgentsQuery,
  OrganizationAgentsQueryVariables,
  RemoveSkillSetFromAgentMutation,
  SkillSetQuery,
  SkillSetQueryVariables,
} from 'app/javascript/components/graphql'
import React from 'react'
import PageHeader from '../../components/PageHeader'
import Spin from '../../../../components/common/ui/Spin'
import {
  ADD_SKILLSET_TO_AGENT_MUTATION,
  GET_ORGANIZATION_AGENTS_QUERY,
  REMOVE_SKILLSET_FROM_AGENT_MUTATION,
} from '../../graphql/queries/agent'
import { clsx } from 'clsx'
import { Switch } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { GET_SKILL_SET_QUERY } from '../../../../components/App/graphql/queries/skill_set'

const AddSkillSetToAgent = ({ uuid }: { uuid: string }) => {
  document.title = 'Add SkillSet To Agent - FlowMo'
  const [loadingSwitches, setLoadingSwitches] = React.useState<string[]>([]) // array of agent UUIDs that are currently loading
  const [addSkillSetToAgent] = useMutation<AddSkillSetToAgentMutation>(
    ADD_SKILLSET_TO_AGENT_MUTATION
  )
  const [removeSkillSetFromAgent] = useMutation<RemoveSkillSetFromAgentMutation>(
    REMOVE_SKILLSET_FROM_AGENT_MUTATION
  )

  // get the skillset info
  const { data, loading } = useQuery<SkillSetQuery, SkillSetQueryVariables>(GET_SKILL_SET_QUERY, {
    variables: { uuid: uuid },
  })

  // get a list of agents
  const {
    loading: agentsLoading,
    data: agentsData,
    refetch: refetchAgents,
  } = useQuery<OrganizationAgentsQuery, OrganizationAgentsQueryVariables>(
    GET_ORGANIZATION_AGENTS_QUERY,
    {
      fetchPolicy: 'network-only', // always fetches from the network, skips cache
    }
  )

  if (loading || agentsLoading) {
    return <Spin />
  }

  const skillSet = data?.skillSet
  const agents = agentsData.organizationAgents
  const handleCheckboxChange = async (agentUuid: string, isChecked: boolean) => {
    // Add the agentUuid to the loading state
    setLoadingSwitches((prev) => [...prev, agentUuid])

    try {
      if (isChecked) {
        const response = await addSkillSetToAgent({
          variables: {
            agentUuid,
            skillSetUuid: uuid,
          },
        })
        if (response.data.addSkillSetToAgent.errors.length > 0) {
          window.toastr.error('Error adding skill set to agent', 'Error')
        } else {
          window.toastr.success('Successfully added skill set to agent', 'Added')
        }
      } else {
        const response = await removeSkillSetFromAgent({
          variables: {
            agentUuid,
            skillSetUuid: uuid,
          },
        })
        if (response.data.removeSkillSetFromAgent.errors.length > 0) {
          window.toastr.error('Error removing walkthrough to agent', 'Error')
        } else {
          window.toastr.success('Successfully removed the walkthrough to agent', 'Removed')
        }
      }
    } catch (err) {
      window.toastr.error('An unexpected error occurred', 'Error')
    } finally {
      // Remove the agentUuid from the loading state after the operation completes
      setLoadingSwitches((prev) => prev.filter((id) => id !== agentUuid))
      refetchAgents()
    }
  }

  return (
    <>
      <PageHeader
        title="Add Skill Set To Agent"
        breadCrumbs={[
          skillSet.isInMyLibrary
            ? { title: 'My Library', to: '/my_library' }
            : { title: 'Organization Library', to: '/organization_library' },
          { title: skillSet.name, to: `/skill_set/${skillSet.uuid}` },
          { title: 'Add To Agent' },
        ]}
      />

      <div className="flex flex-col space-y-5">
        {agents.map((agent) => {
          const enabled = agent.skillSets?.some((wt) => wt.uuid === uuid)
          const isLoading = loadingSwitches.includes(agent.uuid)

          return (
            <div key={agent.uuid} className="flex flex-row p-5 border rounded">
              <div className="flex flex-row">
                <div className="flex flex-col">
                  <div className="text-lg font-semibold">
                    <Link to={`/agent/${agent.uuid}`}>{agent.label}</Link>
                  </div>
                  <div className="text-md text-gray-800">{agent.description}</div>
                </div>
                <div className="justify-center">
                  <Switch
                    checked={enabled}
                    onChange={(checked) => handleCheckboxChange(agent.uuid, checked)}
                    className={clsx(
                      enabled ? 'bg-flowmo-blue-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-flowmo-blue-600 focus:ring-offset-2'
                    )}
                    disabled={isLoading}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={clsx(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default AddSkillSetToAgent
