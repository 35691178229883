import React, { useEffect, useState } from 'react'
import ReactSlider from 'react-slider'
import Wave from '../Wave'

import './style.scss'

type ProgressProps = {
  duration: number
  position: number
  playing: boolean
  wave: number[]
  replay?: boolean
  audioDuration?: number
}

const PROGRESS_INTERVAL = 50

const Progress: React.FC<ProgressProps> = ({
  duration,
  position,
  playing,
  wave,
  replay,
  audioDuration,
}) => {
  const [value, setValue] = useState<number>(position)

  useEffect(() => {
    setValue(position)
  }, [position])

  useEffect(() => {
    const interval =
      playing &&
      setInterval(() => {
        setValue((value) => value + PROGRESS_INTERVAL)
      }, PROGRESS_INTERVAL)
    return () => {
      interval && clearInterval(interval)
    }
  }, [playing])

  const canvas = <Wave peaks={wave} />

  return (
    <ReactSlider
      snapDragDisabled={true}
      max={duration}
      value={value}
      className="react-slider-progress"
      thumbClassName="react-slider-thumb"
      trackClassName="react-slider-track"
      renderThumb={(props: any) => <div {...props} />}
      renderTrack={(props) => {
        if (replay) {
          const audioWidth = audioDuration ? (audioDuration * 100) / duration : 100
          return (
            <React.Fragment key={`${props.key}-fragment`}>
              <div {...props} />
              {props.key === 'react-slider-track-0' && (
                <div style={{ width: `${audioWidth}%` }}>{canvas}</div>
              )}
            </React.Fragment>
          )
        }

        if (props.key === 'react-slider-track-0') {
          return (
            <div {...props}>
              <div style={{ width: '100%', marginTop: '-20px' }}>{canvas}</div>
            </div>
          )
        }

        return <div {...props} />
      }}
    />
  )
}

export default Progress
