import React from 'react'
import { hot } from 'react-hot-loader'

import AudioApp from './app'

import '../common/sentry'

interface Props {
  basePath: string
}

const AudioPage: React.FC<Props> = () => {
  return <AudioApp />
}

export default hot(module)(AudioPage)
