import React from 'react'

interface Props {
  className?: string
}

const Divider: React.FC<Props> = ({ className = 'border-t' }) => (
  <div className="relative inset-0 flex items-center" aria-hidden="true">
    <div className={`w-full ${className} border-gray-300`} />
  </div>
)

export default Divider
