import React from 'react'
import { useLabsContext } from '../../context/LabsContext'
import Background from '../Background'
import { ResetIcon, SuccessIcon } from './icons'
import { shareLink } from '../../../common/utils/links'
import Button from '../Button'
import Title from '../Title'
import Description from '../Description'

const SuccessView = () => {
  const { reset, walkthrough } = useLabsContext()

  const creator = walkthrough.createdBy
  return (
    <Background>
      <div className="flex items-center space-x-[36px] ml-[24px]">
        <SuccessIcon />

        <div className="border-l border-l-[rgba(255,255,255,0.18)] pl-[36px]">
          <Title>Yay! You completed the walkthrough</Title>

          <Description>
            <a
              href={shareLink(walkthrough.uuid, 'walkthrough')}
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Walkthrough
            </a>{' '}
            from {creator.firstName} {creator.lastName} on{' '}
            <a href="/" className="underline" target="_blank" rel="noreferrer">
              FlowMo
            </a>
          </Description>

          <div className="mb-2.5 flex items-center justify-start space-x-[30px]">
            <a href={`/users/sign_up`} target="_blank" rel="noreferrer">
              <Button type="primary">Record your own</Button>
            </a>

            <Button onClick={reset} type="secondary">
              <div>Retry</div>
              <ResetIcon />
            </Button>
          </div>
        </div>
      </div>
    </Background>
  )
}

export default SuccessView
