import React from 'react'
import { useFormik } from 'formik'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { SignInParams } from '../../useAuth'

type SignInProps = {
  handleSubmit: (props: SignInParams) => void
  handleBlur?: () => void
  error?: string
  loading: boolean
  redirect?: () => void
}

const validate = (values: SignInParams) => {
  const errors = {} as any

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(values.email)) {
    errors.email = 'Invalid email address!'
  }

  if (!values.password || values.password.length === 0) {
    errors.password = `Password can't be blank!`
  }

  if (!values.email || values.email.length === 0) {
    errors.email = `Email can't be blank!`
  }

  return errors
}

export default function SignIn({ handleSubmit, error, loading, handleBlur }: SignInProps) {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    onSubmit: handleSubmit,
  })

  return (
    <form className="mt-4 space-y-6" onSubmit={formik.handleSubmit}>
      {formik.touched.email && (formik.errors.email || error || formik.errors.password) ? (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {formik.errors.email || formik.errors.password || error}
              </h3>
            </div>
          </div>
        </div>
      ) : null}
      <input type="hidden" name="remember" defaultValue="true" />
      <div className="rounded-md shadow-sm -space-y-px">
        <div>
          <label htmlFor="email" className="sr-only">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="text"
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-flowmo-orange-500 focus:border-flowmo-orange-500 focus:z-10 sm:text-sm"
            placeholder="Email"
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={handleBlur || formik.handleBlur}
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-flowmo-orange-500 focus:border-flowmo-orange-500 focus:z-10 sm:text-sm"
            placeholder="Password"
            onChange={formik.handleChange}
            value={formik.values.password}
            onBlur={handleBlur || formik.handleBlur}
          />
        </div>
      </div>

      <div>
        <button
          disabled={loading}
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-flowmo-orange-600 hover:bg-flowmo-orange-700 focus:outline-none disabled:opacity-50"
        >
          {loading && (
            <div
              style={{ borderTopColor: 'white' }}
              className="animate-spin bg-flowmo-orange-600 rounded-full border-4 border-t-4 border-gray-200 -ml-1 mr-2 h-5 w-5"
            />
          )}
          Sign In
        </button>
      </div>

      <div className="flex items-center justify-between">
        <div className="text-sm">
          <a
            href="/users/sign_up"
            className="font-medium text-flowmo-orange-600 hover:text-flowmo-orange-500 cursor-pointer"
          >
            Sign Up
          </a>
        </div>
        <div className="text-sm">
          <a
            target="_blank"
            href="/users/password/new"
            className="font-medium text-flowmo-orange-600 hover:text-flowmo-orange-500 cursor-pointer"
          >
            Forgot your password?
          </a>
        </div>
      </div>
    </form>
  )
}
