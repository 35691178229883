import { useState, useCallback, useEffect } from 'react'

export const useCopyToClipboard = (text: any, notifyTimeout = 2500): [string, () => void] => {
  const [copyStatus, setCopyStatus] = useState('inactive')
  const copy = useCallback(() => {
    navigator.clipboard.writeText(text).then(
      () => setCopyStatus('copied'),
      () => setCopyStatus('failed')
    )
  }, [text])

  useEffect(() => {
    if (copyStatus === 'inactive') {
      return undefined
    }

    const timeoutId = setTimeout(() => setCopyStatus('inactive'), notifyTimeout)

    return () => clearTimeout(timeoutId)
  }, [copyStatus])

  return [copyStatus, copy]
}
