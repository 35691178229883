import React, { useMemo } from 'react'
import { StepTypes } from '../../../../App/models/Walkthrough'
import Tooltip from './steps/Tooltip'
import Input from './steps/Input'
import DropdownSelect from './steps/DropdownSelect'
import Goto from './steps/Goto'
import CopyPaste from './steps/CopyPaste'
import Highlight from './steps/Highlight'
import TextOnly from './steps/TextOnly'
import Media from './steps/Media'
import Video from './steps/Video'
import EnsureSelector from './steps/EnsureSelector'
import Section from './steps/Section'

const Steps = {
  [StepTypes.cssSelector]: Tooltip,
  [StepTypes.input]: Input,
  [StepTypes.click]: Tooltip,
  [StepTypes.dropdownSelect]: DropdownSelect,
  [StepTypes.goto]: Goto,
  [StepTypes.copyPaste]: CopyPaste,
  [StepTypes.highlighting]: Highlight,
  [StepTypes.textOnly]: TextOnly,
  [StepTypes.inputText]: TextOnly,
  [StepTypes.media]: Media,
  [StepTypes.video]: Video,
  [StepTypes.ensureSelector]: EnsureSelector,
  [StepTypes.section]: Section,
}

interface Props {
  steps: any
  index: number
  canEditWalkthrough: boolean
}

const Step: React.FC<Props> = ({ steps, index, canEditWalkthrough }) => {
  const Component = useMemo(() => Steps[steps[index].type], [steps[index].type])

  if (!steps[index]) {
    return <></>
  }

  return (
    <Component
      stepNumber={getStepNumber(steps, index)}
      isLast={index === steps.length - 1}
      canEditWalkthrough={canEditWalkthrough}
      step={steps[index]}
      index={index}
    />
  )
}

const getStepNumber = (steps: any[], index: number) =>
  steps.reduce(
    (stepNumber: number, step: any, stepIndex: number) =>
      step.type !== StepTypes.section && stepIndex <= index ? stepNumber + 1 : stepNumber,
    0
  )

export default Step
