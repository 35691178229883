export const StepTypes = {
  cssSelector: 'css_selector',
  click: 'click',
  input: 'input',
  dropdownSelect: 'dropdown_select',
  goto: 'goto',
  copyPaste: 'copy_paste',
  highlighting: 'highlighting',
  textOnly: 'text_only',
  inputText: 'input_text',
  media: 'media',
  video: 'video',
  ensureSelector: 'ensure_selector',
  section: 'section',
}
