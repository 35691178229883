import React from 'react'

import './style.scss'

const Loading: React.FC = () => {
  return (
    <div className="loading-screen">
      <div className="flex w-full items-center flex-col pb-8 lg:pb-20">
        <Loader />
      </div>
    </div>
  )
}

const Loader = () => (
  <div className="lds-ring">
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default Loading
