import React from 'react'

interface EmptyContentBoxProps {
  title: string
  widthClass?: string
  heightClass?: string
}

const EmptyContentBox = ({ title, widthClass, heightClass }: EmptyContentBoxProps) => {
  return (
    <div
      className={`border-2 border-dashed border-gray-300 rounded-lg p-4 ${widthClass} ${heightClass} text-gray-300 flex flex-col items-center justify-center text-center`}
    >
      <div className="text-sm">{title}</div>
    </div>
  )
}

export default EmptyContentBox
