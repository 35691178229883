import { Squares2X2Icon } from '@heroicons/react/24/outline'
import React from 'react'
import Paragraph from './Paragraph'

interface Props {
  title?: string
  description: string
  actions?: React.ReactElement
  className?: string
}

const EmptyState: React.FC<Props> = ({
  title = "There's nothing here...yet!",
  description,
  actions,
  className,
}) => (
  <div className={`max-w-2xl bg-gray-50/50 border p-6 m-auto ${className} rounded-lg text-center`}>
    <Squares2X2Icon className="mx-auto h-12 w-12 text-gray-300" />
    <h2 className="mt-2 text-md font-medium text-gray-900">{title}</h2>
    <Paragraph>{description}</Paragraph>
    {actions && (
      <div className="w-full flex justify-center items-center space-x-2 my-2">{actions}</div>
    )}
  </div>
)

export default EmptyState
