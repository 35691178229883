import React, { useContext } from 'react'
import EventListener from '../EventListener'
import ChevronScroll from '../../components/ChevronScroll'
import Position from '../Position'
import Highlighter from '../../components/Highlighter'
import useWindowSize from '../../../../hooks/useWindowSize'
import BoundedBox from '../../components/BoundedBox'
import { NormalizedStepProps } from '../../../Player/types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../Player/store'
import { ResizeContext } from '../../../Player/modules/Resize'
import * as rrweb from '@flowmo/rrweb'

const generateSelector = (context: any) => {
  let pathSelector
  if (context == 'null') {
    return undefined
  }
  // call getIndex function
  const index = getIndex(context)

  while (context && context.tagName) {
    // selector path
    pathSelector = context.localName + (pathSelector ? '>' + pathSelector : '')
    context = context.parentNode
  }
  // selector path for nth of type
  pathSelector = pathSelector + `:nth-of-type(${index})`
  return pathSelector
}

const getIndex = (node: any) => {
  let i = 1
  const tagName = node.tagName

  while (node.previousSibling) {
    node = node.previousSibling
    if (node.nodeType === 1 && tagName.toLowerCase() == node.tagName.toLowerCase()) {
      i++
    }
  }
  return i
}

type ElementLayerProps = {
  replayer: rrweb.Replayer
  element: HTMLElement
  step: NormalizedStepProps
}

const ElementLayer: React.FC<ElementLayerProps> = ({ replayer, element, step }) => {
  const {
    playerControls: { isInteractiveStep },
  } = useSelector((state: RootState) => state.player)
  const context = useContext(ResizeContext)
  const { width, height } = useWindowSize()
  if (!element || !step) {
    return <React.Fragment></React.Fragment>
  }

  let style = {}
  if (!isInteractiveStep) {
    style = {
      transformOrigin: 'left top',
      transform: `scale(${context.scale})`,
      position: 'absolute',
    }
    if (context.width && context.width > context.size.width * context.scale) {
      style = {
        width: context.size.width * context.scale + 'px',
        height: context.size.height * context.scale + 'px',
        ...style,
      }
    } else {
      style = {
        left: '0px',
        ...style,
      }
    }
  }

  return (
    <div style={style}>
      <Position
        replayer={replayer}
        element={element}
        key={`${width} ${height} ${generateSelector(element)}`}
      >
        {({ position }) => (
          <React.Fragment>
            <BoundedBox element={element} position={position} />
            <Highlighter element={element} position={position} step={step} />
            <ChevronScroll element={element} />
          </React.Fragment>
        )}
      </Position>
      <EventListener element={element} />
    </div>
  )
}

export default ElementLayer
