import React from 'react'
import { ActiveFilter } from '../types'

interface Props {
  activeFilter: ActiveFilter
  handleRemoveFilter: (name: string, value: string) => void
}

const OneActiveFilter: React.FC<Props> = ({ activeFilter, handleRemoveFilter }) => {
  return (
    <>
      <span
        key={`${activeFilter.name}-${activeFilter.value}`}
        className="m-1 inline-flex rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-2 text-sm font-medium bg-white text-gray-900"
      >
        <span>{activeFilter.label}</span>
        <button
          type="button"
          className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
          onClick={() => handleRemoveFilter(activeFilter.name, activeFilter.value)}
        >
          <span className="sr-only">Remove filter for {activeFilter.label}</span>
          <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
          </svg>
        </button>
      </span>
    </>
  )
}

export default OneActiveFilter
