import React from 'react'

const SignupCTA = () => {
  return (
    <div className="bg-gradient-to-tr from-[#a080ff]/10 to-[#5349e4]/10 ">
      <div className="mx-auto max-w-5xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Supercharge your work.
          <br />
          Get started today.
        </h2>
        <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
          <a
            href="/users/sign_up"
            className="rounded-md bg-flowmo-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-flowmo-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-flowmo-blue-600 cursor-pointer"
          >
            Sign Up
          </a>
        </div>
      </div>
    </div>
  )
}

export default SignupCTA
