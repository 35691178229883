import React from 'react'
import { clsx } from 'clsx'
import { useLabsContext } from '../../../context/LabsContext'
import Loader from './Loader'
import { useCloudinaryImage } from '../../../../common/hooks/useCloudinaryImage'
import Highlighter from './Highlighter'
import { ArrowIcon } from '../icons'
import Button from '../../Button'
import { useResizeDetector } from 'react-resize-detector'
import NotFound from '../../../../AI/components/NotFound'

interface Props {
  step: any
  active: boolean
  notFoundHeight: string
}

const Screenshot = ({ step, active, notFoundHeight }: Props) => {
  const { completed, nextStep } = useLabsContext()
  const { loading, exists } = useCloudinaryImage(step.imageData.url)
  const { width, height, ref } = useResizeDetector()

  if (loading && active) {
    return <Loader />
  }

  if (!exists) {
    return (
      <div className={clsx(active || 'hidden')}>
        <NotFound
          message="Screenshot is not found"
          className={notFoundHeight}
          dotsEnabled
          toolbar={
            <Button
              onClick={nextStep}
              type="action"
              className="mt-[30px] lg:mt-[50px] md:mt-[40px]"
            >
              <div>Go to next step</div>
              <ArrowIcon />
            </Button>
          }
        />
      </div>
    )
  }

  return (
    <div className={clsx(active || 'hidden', 'relative mx-auto h-full w-fit')}>
      <img
        ref={ref}
        src={step.imageData.url}
        alt="Screenshot"
        className={completed ? 'w-[100vw] h-auto blur-sm' : 'max-h-full w-auto'}
      />
      {active && <Highlighter step={step} imgWidth={width} imgHeight={height} />}
    </div>
  )
}

export default Screenshot
