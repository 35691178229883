import { useEffect, useState } from 'react'

const doUserStatusRequest = () => {
  return fetch('/users/status', {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
}

export const useAcceptPrivacyConsent = () => {
  const [loading, setLoading] = useState(false)
  const acceptPrivacyConsent = async () => {
    setLoading(true)
    await fetch(`/privacy_consent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ accepted: true }),
    })
    setLoading(false)
  }
  return { loading, acceptPrivacyConsent }
}

export const useNotifyAboutInstallation = () => {
  const [loading, setLoading] = useState(false)
  const notifyAboutInstallation = async () => {
    setLoading(true)
    await fetch(`/extension_installed`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ installed: true }),
    })
    setLoading(false)
  }
  return { loading, notifyAboutInstallation }
}

export const useUserStatus = () => {
  const [loading, setLoading] = useState(true)
  const [isConsented, setConsented] = useState(false)
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [isExtensionInstalled, setExtensionInstalled] = useState(false)

  const checkConsent = async () => {
    const response = await doUserStatusRequest()
    const json = await response.json()

    if (json) {
      if (
        typeof json.accepted_privacy_policy !== 'undefined' &&
        json.accepted_privacy_policy !== 'declined'
      ) {
        setConsented(true)
      }

      setLoggedIn(json.is_user_logged_in)
      setExtensionInstalled(json.extension_installed)
    }

    setLoading(false)
  }

  useEffect(() => {
    checkConsent().catch((error) => console.error(error))
  }, [])

  return { isConsented, isLoggedIn, isExtensionInstalled, refetch: checkConsent, loading }
}
