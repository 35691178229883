import React from 'react'
import StepCard from '../StepCard'
import TitleDescription from './shared/TitleDescription'

const EnsureSelector = ({
  step,
  stepNumber,
  isLast,
  index,
}: {
  step: any
  stepNumber: any
  isLast: boolean
  index: number
}) => (
  <StepCard step={step} isLast={isLast} stepNumber={stepNumber}>
    <TitleDescription index={index} title={step.title} description={step.description} />
  </StepCard>
)

export default EnsureSelector
