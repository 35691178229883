import { useLocation } from 'react-router-dom'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const usePaginationParams = (defaultLimit = 10) => {
  const query = useQuery()

  const page = parseInt(query.get('page'))
  const limit = parseInt(query.get('limit'))
  const search = query.get('search')

  return {
    page: isNaN(page) ? 1 : page,
    limit: isNaN(limit) ? defaultLimit : limit,
    search,
  }
}
