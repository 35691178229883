import React, { ReactNode, useMemo } from 'react'
import Resize, { SizeProps } from './modules/Resize'
import App from './App'
import { stepNormalizer } from './utils'
import { Chunk } from './types'
import { Provider } from 'react-redux'
import createStore from './store'

type PlayerProps = {
  children?: React.ReactNode
  duration: number
  audio?: string
  fullscreen?: boolean
  maxHeight?: number
  rrwebScreenSize?: SizeProps
  chunks: Chunk[]
  steps: any[]
  thumbnail?: (loading: boolean) => ReactNode
  completed?: () => ReactNode
  showControlPanel?: boolean
}

const Player: React.FC<PlayerProps> = ({
  children,
  showControlPanel = true,
  duration,
  audio,
  chunks,
  steps,
  fullscreen = true,
  maxHeight,
  rrwebScreenSize,
  thumbnail,
  completed,
}) => {
  const normalizedSteps = useMemo(() => stepNormalizer(steps), [steps])
  const store = useMemo(() => createStore(), [])

  return (
    <Provider store={store}>
      <Resize
        rrwebScreenSize={rrwebScreenSize}
        enabledFullscreen={fullscreen}
        maxHeight={maxHeight}
      >
        <App
          thumbnail={thumbnail}
          completed={completed}
          audio={audio}
          showControlPanel={showControlPanel}
          duration={duration}
          chunks={chunks}
          steps={normalizedSteps}
          maxHeight={maxHeight}
        />
      </Resize>
      {children}
    </Provider>
  )
}

export default Player
