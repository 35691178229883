import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { useMutation } from '@apollo/client'
import {
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables,
} from 'app/javascript/components/graphql'
import { UPDATE_ORGANIZATION_MUTATION } from '../../../graphql/queries/organization'
import DynamicForm from '../../../components/DynamicForm'
import Spin from '../../../../../components/common/ui/Spin'

type OrganizationSettingsFormProps = {
  handleSuccess: () => void
  handleError: () => void
}

const OrganizationSettingsForm = ({
  handleSuccess,
  handleError,
}: OrganizationSettingsFormProps) => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [saving, setSaving] = React.useState(false)
  const { organization } = useSelector((state: RootState) => state.app)
  const [updateOrganizationMutation] = useMutation<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >(UPDATE_ORGANIZATION_MUTATION)

  const handleSubmit = async ({ name }: { name: string }) => {
    setSaving(true)
    try {
      const { data } = await updateOrganizationMutation({
        variables: {
          id: organization?.id,
          name,
        },
      })
      if (data?.updateOrganization?.success) {
        handleSuccess()
      } else {
        handleError()
      }
    } catch (e) {
      handleError()
    } finally {
      setSaving(false)
    }
  }

  const formData = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      required: true,
      defaultValue: organization?.name || '',
    },
    {
      name: 'emailNamespace',
      label: 'Email Namespace',
      type: 'text',
      required: false,
      disabled: true,
      defaultValue: organization?.emailNamespace || '',
    },
  ]

  return (
    <div className="max-w-md">
      <DynamicForm ref={formRef} formData={formData} handleSubmit={handleSubmit} />
      <button
        type="submit"
        onClick={() => formRef.current?.submitForm()}
        className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
        disabled={saving}
      >
        {saving ? <Spin /> : 'Save'}
      </button>
    </div>
  )
}

export default OrganizationSettingsForm
