import { useMutation, useQuery } from '@apollo/client'
import { GET_WALKTHROUGH_QUERY } from '../../../../components/App/graphql/queries/walkthrough'
import {
  AddWalkthroughToAgentMutation,
  OrganizationAgentsQuery,
  OrganizationAgentsQueryVariables,
  RemoveWalkthroughFromAgentMutation,
  WalkthroughByUuidQuery,
  WalkthroughByUuidQueryVariables,
} from 'app/javascript/components/graphql'
import React from 'react'
import PageHeader from '../../components/PageHeader'
import Spin from '../../../../components/common/ui/Spin'
import {
  ADD_WALKTROUGH_TO_AGENT_MUTATION,
  GET_ORGANIZATION_AGENTS_QUERY,
  REMOVE_WALKTROUGH_FROM_AGENT_MUTATION,
} from '../../graphql/queries/agent'
import { clsx } from 'clsx'
import { Switch } from '@headlessui/react'
import { Link } from 'react-router-dom'

const AddWalkthroughToAgent = ({ uuid }: { uuid: string }) => {
  document.title = 'Add Walkthrough To Agent - FlowMo'
  const [loadingSwitches, setLoadingSwitches] = React.useState<string[]>([]) // array of agent UUIDs that are currently loading
  const [addWalkthroughToAgent] = useMutation<AddWalkthroughToAgentMutation>(
    ADD_WALKTROUGH_TO_AGENT_MUTATION
  )
  const [removeWalkthroughFromAgent] = useMutation<RemoveWalkthroughFromAgentMutation>(
    REMOVE_WALKTROUGH_FROM_AGENT_MUTATION
  )

  // get the walkthrough info
  const { data, loading } = useQuery<WalkthroughByUuidQuery, WalkthroughByUuidQueryVariables>(
    GET_WALKTHROUGH_QUERY,
    {
      variables: { uuid: uuid },
    }
  )

  // get a list of agents
  const {
    loading: agentsLoading,
    data: agentsData,
    refetch: refetchAgents,
  } = useQuery<OrganizationAgentsQuery, OrganizationAgentsQueryVariables>(
    GET_ORGANIZATION_AGENTS_QUERY,
    {
      fetchPolicy: 'network-only', // always fetches from the network, skips cache
    }
  )

  if (loading || agentsLoading) {
    return <Spin />
  }

  const walkthrough = data?.walkthrough
  const agents = agentsData.organizationAgents
  const handleCheckboxChange = async (agentUuid: string, isChecked: boolean) => {
    // Add the agentUuid to the loading state
    setLoadingSwitches((prev) => [...prev, agentUuid])

    try {
      if (isChecked) {
        const response = await addWalkthroughToAgent({
          variables: {
            agentUuid,
            walkthroughUuid: uuid,
          },
        })
        if (response.data.addWalkthroughToAgent.errors.length > 0) {
          window.toastr.error('Error adding walkthrough to agent', 'Error')
        } else {
          window.toastr.success('Successfully added walkthrough to agent', 'Added')
        }
      } else {
        const response = await removeWalkthroughFromAgent({
          variables: {
            agentUuid,
            walkthroughUuid: uuid,
          },
        })
        if (response.data.removeWalkthroughFromAgent.errors.length > 0) {
          window.toastr.error('Error removing walkthrough to agent', 'Error')
        } else {
          window.toastr.success('Successfully removed the walkthrough to agent', 'Removed')
        }
      }
    } catch (err) {
      window.toastr.error('An unexpected error occurred', 'Error')
    } finally {
      // Remove the agentUuid from the loading state after the operation completes
      setLoadingSwitches((prev) => prev.filter((id) => id !== agentUuid))
      refetchAgents()
    }
  }

  return (
    <>
      <PageHeader
        title="Add Walkthrough To Agent"
        breadCrumbs={[
          walkthrough.isInMyLibrary
            ? { title: 'My Library', to: '/my_library' }
            : { title: 'Organization Library', to: '/organization_library' },
          { title: walkthrough.name, to: `/walkthrough/${walkthrough.uuid}` },
          { title: 'Add To Agent' },
        ]}
      />

      <div className="flex flex-col space-y-5">
        {agents.map((agent) => {
          const enabled = agent.walkthroughs?.some((wt) => wt.uuid === uuid)
          const isLoading = loadingSwitches.includes(agent.uuid)

          return (
            <div key={agent.uuid} className="flex flex-row p-5 border rounded">
              <div className="flex flex-row">
                <div className="flex flex-col">
                  <div className="text-lg font-semibold">
                    <Link to={`/agent/${agent.uuid}`}>{agent.label}</Link>
                  </div>
                  <div className="text-md text-gray-800">{agent.description}</div>
                </div>
                <div className="justify-center">
                  <Switch
                    checked={enabled}
                    onChange={(checked) => handleCheckboxChange(agent.uuid, checked)}
                    className={clsx(
                      enabled ? 'bg-flowmo-blue-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-flowmo-blue-600 focus:ring-offset-2'
                    )}
                    disabled={isLoading}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={clsx(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default AddWalkthroughToAgent
