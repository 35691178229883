import React, { useEffect } from 'react'
import PageHeader from '../../../components/PageHeader'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_TASK_RUNS_QUERY,
  GET_TASK_QUERY,
  SUBMIT_RUN_TASK_MUTATION,
} from '../../../graphql/queries/task'
import {
  SubmitRunTaskMutation,
  SubmitRunTaskMutationVariables,
  TaskQuery,
  TaskQueryVariables,
  TaskRunsQuery,
  TaskRunsQueryVariables,
} from '../../../../graphql'
import Spin from '../../../../common/ui/Spin'
import { Link } from 'react-router-dom'
import HeaderActionButton from '../../../components/HeaderActionButton'
import { CheckCircleIcon, PlayCircleIcon } from '@heroicons/react/24/solid'
import { TaskStatus, TaskType } from '../OneWorkflow/TaskCard'
import EmptyContent from '../EditAgent/EmptyContent'
import TaskRun from './TaskRun'
import { QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import TaskVisualization from './TaskVisualization'

const OneTask = ({ uuid }: { uuid: string }) => {
  document.title = 'Task | FlowMo'

  const { data, loading, refetch } = useQuery<TaskQuery, TaskQueryVariables>(GET_TASK_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
  })

  const {
    data: taskRunsData,
    loading: taskRunsLoading,
    refetch: refetchTaskRuns,
  } = useQuery<TaskRunsQuery, TaskRunsQueryVariables>(GET_TASK_RUNS_QUERY, {
    variables: { taskUuid: uuid },
    fetchPolicy: 'network-only',
  })

  const [isRunning, setIsRunning] = React.useState(false)
  const [submitRunTask] = useMutation<SubmitRunTaskMutation, SubmitRunTaskMutationVariables>(
    SUBMIT_RUN_TASK_MUTATION
  )

  const refetchTaskRunsInterval = React.useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    refetch()
  }, [isRunning])

  useEffect(() => {
    if (!isRunning && data?.task.status === 'running') {
      setIsRunning(true)
    }
  }, [data])

  useEffect(() => {
    if (isRunning && taskRunsData?.taskRuns.length > 0) {
      const lastRunStatus = taskRunsData.taskRuns[0].status
      if (lastRunStatus !== 'running' && lastRunStatus !== 'pending') {
        setIsRunning(false)
      }
    }
  }, [taskRunsData])

  // Set up an interval to refetch workflow runs every 2 seconds when isRunning is true
  React.useEffect(() => {
    if (isRunning) {
      refetchTaskRunsInterval.current = setInterval(() => {
        refetchTaskRuns()
      }, 2000)
    } else {
      if (refetchTaskRunsInterval.current) {
        clearInterval(refetchTaskRunsInterval.current)
        refetchTaskRunsInterval.current = null
      }
    }

    // Clear the interval when the component is unmounted
    return () => {
      if (refetchTaskRunsInterval.current) {
        clearInterval(refetchTaskRunsInterval.current)
      }
    }
  }, [isRunning, refetchTaskRuns])

  if (loading) {
    return <Spin />
  }

  const task = data?.task

  const handleRun = async () => {
    if (isRunning) {
      window.toastr.error('Task is already running')
      return
    }
    setIsRunning(true)
    const response = await submitRunTask({ variables: { uuid } })
    if (response.data.submitRunTask.errors.length > 0) {
      window.toastr.error('Error submitting task to run')
      setIsRunning(false)
    } else {
      window.toastr.success('Task submitted successfully')
    }
  }

  const pages = []
  if (task.taskableType == 'Workflow') {
    pages.push({
      title: 'Workflows',
      to: '/workflows',
    })
  } else {
    pages.push({
      title: 'Analyses',
      to: '/analyses',
    })
  }

  return (
    <>
      <PageHeader
        title={
          <div className="flex flex-row items-center space-x-2">
            <TaskStatus status={task.status} />
            <div>{task.name}</div>
          </div>
        }
        breadCrumbs={[
          ...pages,
          {
            title: task.taskable.name,
            to: `/${task.taskableType.toLowerCase()}/${task.taskable.uuid}`,
          },
          { title: 'One Task' },
        ]}
      >
        <div className="flex flex-row space-x-4">
          <Link to={'/task/edit/' + uuid}>
            <HeaderActionButton label="Edit" />
          </Link>

          <button onClick={handleRun} disabled={isRunning} className="">
            {isRunning ? (
              <Spin />
            ) : (
              <PlayCircleIcon className="w-8 h-8 hover:text-flowmo-blue-600" />
            )}
          </button>
        </div>
      </PageHeader>
      <div className="mb-20">
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-6 sm:px-6">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Details</h3>
          </div>
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Type</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <TaskType taskType={task.taskType} />
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Description</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {task.description}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Task</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <TaskVisualization task={task.task} />
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="">
        {taskRunsLoading ? (
          <Spin />
        ) : (
          <>
            {taskRunsData?.taskRuns.length === 0 ? (
              <EmptyContent title="The task hasn't been run." />
            ) : (
              <ul role="list" className="space-y-6">
                {taskRunsData?.taskRuns.map((run, runIndex) => (
                  <li key={run.uuid} className="relative flex gap-x-4">
                    <div
                      className={clsx(
                        runIndex === taskRunsData.taskRuns.length - 1 ? 'h-6' : '-bottom-6',
                        'absolute left-0 top-0 flex w-6 justify-center -bottom-6'
                      )}
                    >
                      <div className="w-px bg-gray-200"></div>
                    </div>
                    {run.status == 'succeeded' && (
                      <CheckCircleIcon className="text-green-500 relative mt-3 h-6 w-6 flex-none rounded-full" />
                    )}
                    {run.status == 'failed' && (
                      <XCircleIcon className=" text-red-500 relative mt-3 h-6 w-6 flex-none rounded-full" />
                    )}
                    {run.status == 'pending' && (
                      <QuestionMarkCircleIcon className="text-yellow-500 relative mt-3 h-6 w-6 flex-none rounded-full" />
                    )}
                    <TaskRun run={run} />
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default OneTask
