import React from 'react'
import { htmlStrip } from '../../../../../common/utils/htmlStrip'

const Section = ({ step }: { step: any }) => (
  <div className="mt-5 mb-10 text-2xl font-extrabold tracking-tight text-gray-900">
    {htmlStrip(step.title)}
  </div>
)

export default Section
