import { useMutation } from '@apollo/client'
import { MOVE_WALKTHROUGH_QUERY } from '../../App/graphql/queries/walkthrough'
import { MoveWalkthroughMutation } from '../../graphql'
import { MoveWalkthroughMutationVariables } from '../../graphql'

const useMoveWalkthroughMutation = () => {
  return useMutation<MoveWalkthroughMutation, MoveWalkthroughMutationVariables>(
    MOVE_WALKTHROUGH_QUERY
  )
}

export default useMoveWalkthroughMutation
