import { gql } from '@apollo/client'
import {
  AGENT_FIELDS,
  MESSAGE_HISTORY_FIELDS,
  SIDE_EFFECT_FIELDS,
  STEP_HISTORY_FIELDS,
  TASK_FIELDS,
  USER_FIELDS,
} from './fragments'

export const GET_TASKS_QUERY = gql`
  query tasks($taskableUuid: ID!, $taskableType: String!) {
    tasks(taskableUuid: $taskableUuid, taskableType: $taskableType) {
      ...TaskFields
    }
  }
  ${TASK_FIELDS}
  ${USER_FIELDS}
`

export const GET_TASK_QUERY = gql`
  query task($uuid: ID!) {
    task(uuid: $uuid) {
      ...TaskFields
      taskable {
        __typename
        ... on Workflow {
          uuid
          name
          description
        }
        ... on Analysis {
          uuid
          name
          description
        }
      }
    }
  }
  ${TASK_FIELDS}
  ${USER_FIELDS}
`

export const CREATE_TASK_MUTATION = gql`
  mutation createTask(
    $name: String!
    $description: String!
    $task: String!
    $outputWebhookUrl: String!
    $runSchedule: String!
    $taskableUuid: String!
    $taskableType: String!
    $taskType: String
  ) {
    createTask(
      name: $name
      description: $description
      task: $task
      outputWebhookUrl: $outputWebhookUrl
      runSchedule: $runSchedule
      taskableUuid: $taskableUuid
      taskableType: $taskableType
      taskType: $taskType
    ) {
      success
      errors
    }
  }
`

export const UPDATE_TASK_MUTATION = gql`
  mutation updateTask(
    $uuid: String!
    $name: String!
    $description: String!
    $task: String!
    $outputWebhookUrl: String!
    $runSchedule: String!
    $taskType: String
  ) {
    updateTask(
      uuid: $uuid
      name: $name
      description: $description
      task: $task
      outputWebhookUrl: $outputWebhookUrl
      runSchedule: $runSchedule
      taskType: $taskType
    ) {
      success
      errors
    }
  }
`

export const DELETE_TASK_MUTATION = gql`
  mutation deleteTask($uuid: String!) {
    deleteTask(uuid: $uuid) {
      success
      errors
    }
  }
`

export const SUBMIT_RUN_TASK_MUTATION = gql`
  mutation submitRunTask($uuid: String!) {
    submitRunTask(uuid: $uuid) {
      success
      errors
    }
  }
`

export const GET_TASK_RUNS_QUERY = gql`
  query taskRuns($taskUuid: ID!) {
    taskRuns(taskUuid: $taskUuid) {
      id
      uuid
      status
      state
      createdAt
      startedAt
      completedAt
      runtimeSeconds

      webhookResponseStatus
      webhookStatus
      webhookCompletedAt

      task {
        task
      }
      messageHistory {
        ...MessageHistoryFields
      }

      stepHistory {
        ...StepHistoryFields
      }

      sideEffectHistory {
        ...SideEffectFields
      }

      agent {
        ...AgentFields
      }

      requestMessage
      responseMessage
      responseStatus

      evaluationMessage {
        reason
        status
      }
    }
  }

  ${MESSAGE_HISTORY_FIELDS}
  ${STEP_HISTORY_FIELDS}
  ${AGENT_FIELDS}
  ${SIDE_EFFECT_FIELDS}
`
