import IconButton, { Sizes } from '../IconButton'
import { LeftArrowIcon } from './icons'
import React from 'react'
import { useLabsContext } from '../../context/LabsContext'

const PrevStepButton = () => {
  const { prevStep, completed, stepIndex } = useLabsContext()
  return (
    <IconButton size={Sizes.SMALL} onClick={prevStep} disabled={completed || stepIndex === 0}>
      <LeftArrowIcon />
    </IconButton>
  )
}

export default PrevStepButton
